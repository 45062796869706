import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintApiManager_ShellService } from './FootPrintApiManager.shell.service';
import { FootPrintApiManager_OperationService } from './FootPrintApiManager.operation.service';
import { FootPrintApiManager_DatasourceService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_FlowService } from './FootPrintApiManager.flow.index';
import { FootPrintApiManager_ReportService } from './FootPrintApiManager.report.index';
import { FootPrintApiManager_LocalizationService } from './FootPrintApiManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintApiManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintApiManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



type EntityType = { 
    Id?: number, LookupCode?: string}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintApiManager-footprint_api_runner',
  templateUrl: './FootPrintApiManager.footprint_api_runner.component.html'
})
export class FootPrintApiManager_footprint_api_runnerComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { title?: string } = { title: null };
  //#region Inputs
  @Input('title') set $inParams_title(v: string) {
    this.inParams.title = v;
  }
  get $inParams_title(): string {
    return this.inParams.title;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    order_class: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    material: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    packaging: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    shipment_id: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    order_lookup: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    render_lots: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    render_license_plates: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    export_to_file: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    async: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    code_box: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    details_box: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      ingress: new ToolModel(new SplitButtonModel<{ order_sample: ButtonModel, order_import: ButtonModel }>(
        'ingress',
        new ButtonStyles(null, null),
        false,
        'Import',
        'icon-ic_fluent_padding_down_20_regular',
        null,
        [
          new ButtonModel('order_sample', new ButtonStyles(null, null), false, false, 'Order sample', 'icon-ic_fluent_document_flowchart_20_regular'),
          new ButtonModel('order_import', new ButtonStyles(null, null), false, false, 'Order import from payload', 'icon-ic_fluent_cloud_arrow_up_20_regular')
        ])
    , false),
      egress: new ToolModel(new SplitButtonModel<{ order_sample: ButtonModel, order_export: ButtonModel, inventory_export: ButtonModel }>(
        'egress',
        new ButtonStyles(null, null),
        false,
        'Export',
        'icon-ic_fluent_padding_top_20_regular',
        null,
        [
          new ButtonModel('order_sample', new ButtonStyles(null, null), false, false, 'Order sample', 'icon-ic_fluent_document_flowchart_20_regular'),
          new ButtonModel('order_export', new ButtonStyles(null, null), false, false, 'Order export by shipment / order', 'icon-ic_fluent_cloud_arrow_down_20_regular'),
          new ButtonModel('inventory_export', new ButtonStyles(null, null), false, false, 'Inventory export by project lookup', 'icon-ic_fluent_cloud_arrow_down_20_regular')
        ])
    , false)
  };

  fields = {
    project: new FieldModel(new TextBoxModel(this.formGroup.controls['project'] as DatexFormControl, null, false, 'Project for the inventory export', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
    warehouse: new FieldModel(new TextBoxModel(this.formGroup.controls['warehouse'] as DatexFormControl, null, false, 'Overrides the warehouse', null)
, new ControlContainerStyles(null, null), 'Warehouse', false, false),
    order_class: new FieldModel(new TextBoxModel(this.formGroup.controls['order_class'] as DatexFormControl, null, false, 'Overrides the order class', null)
, new ControlContainerStyles(null, null), 'Order class', false, false),
    material: new FieldModel(new TextBoxModel(this.formGroup.controls['material'] as DatexFormControl, null, false, 'Specifies a material for the inventory export', null)
, new ControlContainerStyles(null, null), 'Material', false, false),
    packaging: new FieldModel(new TextBoxModel(this.formGroup.controls['packaging'] as DatexFormControl, null, false, 'Overrides the packaging for all lines', null)
, new ControlContainerStyles(null, null), 'Packaging', false, false),
    shipment_id: new FieldModel(new NumberBoxModel(this.formGroup.controls['shipment_id'] as DatexFormControl, null, false, '', 'Shipment to export', null)
, new ControlContainerStyles(null, null), 'Shipment ID', false, false),
    order_lookup: new FieldModel(new TextBoxModel(this.formGroup.controls['order_lookup'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Order code', false, false),
    render_lots: new FieldModel(new CheckBoxModel(this.formGroup.controls['render_lots'] as DatexFormControl, null, false, 'Render lots', null)
, new ControlContainerStyles(null, null), '', false, false),
    render_license_plates: new FieldModel(new CheckBoxModel(this.formGroup.controls['render_license_plates'] as DatexFormControl, null, false, 'Render license plates', null)
, new ControlContainerStyles(null, null), '', false, false),
    export_to_file: new FieldModel(new CheckBoxModel(this.formGroup.controls['export_to_file'] as DatexFormControl, null, false, 'Export to file', null)
, new ControlContainerStyles(null, null), '', false, false),
    async: new FieldModel(new CheckBoxModel(this.formGroup.controls['async'] as DatexFormControl, null, false, 'Submit async', null)
, new ControlContainerStyles(null, null), '', false, false),
    code_box: new FieldModel( new CodeBoxModel(this.formGroup.controls['code_box'] as DatexFormControl, null, null,'json', null)
, new ControlContainerStyles(null, null), '', false, false),
    details_box: new FieldModel( new CodeBoxModel(this.formGroup.controls['details_box'] as DatexFormControl, null, null,'json', null)
, new ControlContainerStyles(null, null), '', false, false),
  };

  fieldsets = {
  settings: new FieldsetModel('Settings', false, true, true, false),
  payload: new FieldsetModel('Payload', false, true, true, false),
  messages: new FieldsetModel('Messages', false, true, true, false),
};


  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    project: this.fields.project.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    order_class: this.fields.order_class.control.valueChanges
    ,
    material: this.fields.material.control.valueChanges
    ,
    packaging: this.fields.packaging.control.valueChanges
    ,
    shipment_id: this.fields.shipment_id.control.valueChanges
    ,
    order_lookup: this.fields.order_lookup.control.valueChanges
    ,
    render_lots: this.fields.render_lots.control.valueChanges
    ,
    render_license_plates: this.fields.render_license_plates.control.valueChanges
    ,
    export_to_file: this.fields.export_to_file.control.valueChanges
    ,
    async: this.fields.async.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintApiManager_ShellService,
    private datasources: FootPrintApiManager_DatasourceService,
    private flows: FootPrintApiManager_FlowService,
    private reports: FootPrintApiManager_ReportService,
    private localization: FootPrintApiManager_LocalizationService,
    private operations: FootPrintApiManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Runner';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
    };

    const data = await this.datasources.FootPrintApiManager.ds_message_staging.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    private async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.project.control as TextBoxModel).reset("362500");
    
    
    (this.fields.material.control as TextBoxModel).reset("504914");
    
    (this.fields.shipment_id.control as NumberBoxModel).reset(570270);
    (this.fields.order_lookup.control as TextBoxModel).reset("6152");
    (this.fields.render_lots.control as CheckBoxModel).reset(true);
    (this.fields.render_license_plates.control as CheckBoxModel).reset(true);
    (this.fields.export_to_file.control as CheckBoxModel).reset(false);
    (this.fields.async.control as CheckBoxModel).reset(true);

    await this.on_init();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .project
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .order_class
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .material
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .packaging
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .shipment_id
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .order_lookup
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .render_lots
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .render_license_plates
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .export_to_file
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .async
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintApiManager_footprint_api_runnerComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  $editor.title = "Utility to run functions.";
  
  $editor.fields.packaging.hidden = true;
  $editor.fields.warehouse.hidden = true;
  $editor.fields.order_class.hidden = true;
  
  /*
  $editor.toolbar.ingress.control.buttons.check_order_status.readOnly = true;
  $editor.toolbar.ingress.control.buttons.confirm_orders.readOnly = true;
  $editor.toolbar.ingress.control.buttons.map_orders.readOnly = true;
  $editor.toolbar.ingress.control.buttons.purge.readOnly = true;
  $editor.toolbar.ingress.control.buttons.submit_orders.readOnly = true;
  */
  }
  inventory_export(event = null) {
    return this.inventory_exportInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async inventory_exportInternal(
    $editor: FootPrintApiManager_footprint_api_runnerComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 01/04/2023
  
  const start_time = new Date();
  let step_time = new Date();
  let messages = [];
  
  let project_lookup_code: string; //362500
  let material_lookup_code: string; //504914
  
  if ($utils.isDefined($editor.fields.project.control.value)) {
      project_lookup_code = $editor.fields.project.control.value;
  } else {
      project_lookup_code = "362500" //For testing
  };
  
  if (!$utils.isDefined(project_lookup_code)) {
      throw ({ message: `The project lookup code was not supplied.` });
  };
  
  if ($utils.isDefined($editor.fields.material.control.value)) {
      material_lookup_code = $editor.fields.material.control.value;
  };
  
  let render_lots: boolean = false;
  let render_license_plates: boolean = false;
  
  //If not explicitly set, we will not render the lot segments
  if ($utils.isDefined($editor.fields.render_lots.control.value)) {
      render_lots = $editor.fields.render_lots.control.value;
  };
  
  //If not explicitly set, we will not render the license plate segments under the lot segments
  if ($utils.isDefined($editor.fields.render_license_plates.control.value)) {
      render_license_plates = $editor.fields.render_license_plates.control.value;
  };
  
  try {
  
      const inventory_schema = (await $flows.FootPrintApiManager.footprint_generic_json_inventory_schema({}));
  
      const inventory_export = (await $flows.FootPrintApiManager.footprint_generic_json_inventory_export_flow({
          project_lookup_code: project_lookup_code,
          material_lookup_code: material_lookup_code,
          render_lots: render_lots,
          render_license_plates: render_license_plates
      }));
  
      if ($utils.isDefined(inventory_export.messages)) {
          add_message({ messages: inventory_export.messages });
      };
  
      const Inventory: typeof inventory_schema.DatexInventoryInfo = inventory_export.DatexInventoryInfo;
  
      $editor.fields.code_box.control.value = {
          DatexInventoryInfo: Inventory
      };
  
      if (!$utils.isDefined(Inventory.Projects)) {
          throw Error(`No payload was returned from the [footprint_generic_json_inventory_export_flow] flow.`);
      };
  
      const render_time = `${(((new Date()).getTime() - start_time.getTime()) / 1000)} sec`
      add_message({ RenderTime: render_time });
  
      if (!$editor.fieldsets.payload.expanded) {
          $editor.fieldsets.payload.toggle();
      };
      if (!$editor.fieldsets.messages.expanded) {
          $editor.fieldsets.payload.toggle();
      };
  
      if ($editor.fields.export_to_file.control.value) {
          try {
  
              step_time = new Date();
  
              const now = new Date();
              const day = now.getDate();
              const month = now.getMonth() + 1;
              const year = now.getFullYear();
              const hour = now.getHours();
              const minute = now.getMinutes();
              const second = now.getSeconds();
  
              const lookup_array = [`${year}`, (`00${month}`).slice(-2), (`00${day}`).slice(-2), (`00${hour}`).slice(-2), (`00${minute}`).slice(-2), (`00${second}`).slice(-2)];
              const lookup_seed = lookup_array.join("");
  
              const inventory_blob = new Blob([JSON.stringify(Inventory)]);
  
              await $utils.blob.saveFile(inventory_blob, {
                  fileName: `DatexInventoryInfo_${lookup_seed}.txt`,
                  extensions: ['.txt']
              });
  
              const export_time = `${(((new Date()).getTime() - step_time.getTime()) / 1000)} sec`
              add_message({ ExportTime: export_time });
  
          } catch (error) {
              add_message({ message: get_error(error) });
          };
      };
  } catch (error) {
      add_message({ message: get_error(error) });
  
      if ($editor.fieldsets.payload.expanded) {
          $editor.fieldsets.payload.toggle();
      };
      if (!$editor.fieldsets.messages.expanded) {
          $editor.fieldsets.payload.toggle();
      };
  };
  
  $editor.fields.details_box.control.value = messages;
  
  function add_message(messsage: any) {
      if ($utils.isDefined(messsage)) {
          messages.push(messsage);
      };
  };
  
  function get_error(error: any) {
      // Get inner-most error message
      let errorMessage: string;
      let targetError = error;
      while ($utils.isDefined(targetError?.error)) {
          targetError = targetError.error;
      };
      if (!$utils.isDefined(targetError?.message)) {
          targetError = { "message": `Uncaught exception ${JSON.stringify(targetError)}` }
      };
      errorMessage = `[inventory_export] Error ${targetError.message} (${(((new Date()).getTime() - start_time.getTime()) / 1000)} sec)`;
      return errorMessage;
  };
  }
  order_export(event = null) {
    return this.order_exportInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async order_exportInternal(
    $editor: FootPrintApiManager_footprint_api_runnerComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 02/06/2024
  
  const start_time = new Date();
  let step_time = new Date();
  let messages = [];
  
  let shipment_id: number;
  let order_lookup: string;
  
  if ($utils.isDefined($editor.fields.shipment_id.control.value)) {
      shipment_id = $editor.fields.shipment_id.control.value;
  };
  
  if (!$utils.isDefined(shipment_id)) {
      if ($utils.isDefined($editor.fields.order_lookup.control.value)) {
          order_lookup = $editor.fields.order_lookup.control.value;
      };
  };
  
  try {
  
      const order_schema = (await $flows.FootPrintApiManager.footprint_generic_json_order_schema({}));
  
      const order_export = (await $flows.FootPrintApiManager.footprint_generic_json_order_export_flow({
          shipment_id: shipment_id,
          order_lookup: order_lookup
      }));
  
      if ($utils.isDefined(order_export.messages)) {
          add_message({ messages: order_export.messages });
      };
  
      const Order: typeof order_schema.DatexOrderInfo = order_export.DatexOrderInfo;
  
      $editor.fields.code_box.control.value = {
          DatexOrderInfo: Order
      };
  
      if (!$utils.isDefined(Order.Orders)) {
          throw Error(`No payload was returned from the [footprint_generic_json_order_export_flow] flow.`);
      };
  
      const render_time = `${(((new Date()).getTime() - start_time.getTime()) / 1000)} sec`
      add_message({ RenderTime: render_time });
  
      if (!$editor.fieldsets.payload.expanded) {
          $editor.fieldsets.payload.toggle();
      };
      if (!$editor.fieldsets.messages.expanded) {
          $editor.fieldsets.payload.toggle();
      };
  
      if ($editor.fields.export_to_file.control.value) {
          try {
  
              step_time = new Date();
  
              const now = new Date();
              const day = now.getDate();
              const month = now.getMonth() + 1;
              const year = now.getFullYear();
              const hour = now.getHours();
              const minute = now.getMinutes();
              const second = now.getSeconds();
  
              const lookup_array = [`${year}`, (`00${month}`).slice(-2), (`00${day}`).slice(-2), (`00${hour}`).slice(-2), (`00${minute}`).slice(-2), (`00${second}`).slice(-2)];
              const lookup_seed = lookup_array.join("");
  
              const order_blob = new Blob([JSON.stringify(Order)]);
  
              await $utils.blob.saveFile(order_blob, {
                  fileName: `DatexOrderInfo_${shipment_id}_${lookup_seed}.txt`,
                  extensions: ['.txt']
              });
  
              const export_time = `${(((new Date()).getTime() - step_time.getTime()) / 1000)} sec`
              add_message({ ExportTime: export_time });
  
          } catch (error) {
              add_message({ message: get_error(error) });
          };
      };
  } catch (error) {
      add_message({ message: get_error(error) });
  
      if ($editor.fieldsets.payload.expanded) {
          $editor.fieldsets.payload.toggle();
      };
      if (!$editor.fieldsets.messages.expanded) {
          $editor.fieldsets.payload.toggle();
      };
  };
  
  $editor.fields.details_box.control.value = messages;
  
  function add_message(messsage: any) {
      if ($utils.isDefined(messsage)) {
          messages.push(messsage);
      };
  };
  
  function get_error(error: any) {
      // Get inner-most error message
      let errorMessage: string;
      let targetError = error;
      while ($utils.isDefined(targetError?.error)) {
          targetError = targetError.error;
      };
      if (!$utils.isDefined(targetError?.message)) {
          targetError = { "message": `Uncaught exception ${JSON.stringify(targetError)}` }
      };
      errorMessage = `[order_export] Error ${targetError.message} (${(((new Date()).getTime() - start_time.getTime()) / 1000)} sec)`;
      return errorMessage;
  };
  }
  import_order(event = null) {
    return this.import_orderInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async import_orderInternal(
    $editor: FootPrintApiManager_footprint_api_runnerComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/13/2024
  
  const start_time = new Date();
  let messages: any[] = [];
  
  const payload = JSON.parse($editor.fields.code_box.control.value);
  const order_schema = (await $flows.FootPrintApiManager.footprint_generic_json_order_schema({}));
  
  let order: typeof order_schema.DatexOrderInfo;
  
  let submit_async: boolean = $editor.fields.async.control.value ?? true;
  
  try {
  
      if (!$utils.isDefined(payload)) {
          throw Error("No payload was defined.")
      };
  
      if (!$utils.isDefined(payload.DatexOrderInfo)) {
          order = payload;
          if (!$utils.isDefined(order.Orders)) {
              throw Error("The payload does not match the order schema.")
          };
      } else {
          order = payload.DatexOrderInfo;
      };
  
      if (!$utils.isDefined(order.Orders)) {
          throw Error("The payload does not contain any orders.")
      };
  
      const submit = (await $flows.FootPrintApiManager.footprint_generic_json_order_import_flow({
          orders: [order],
          transaction_id: null,
          source_lookup_code: null,
          source_platform: `footprint_api_runner`,
          order_type: null,
          order_action: null,
          account_action: null,
          submitAsync: submit_async
      }));
  
      if ($utils.isDefined(submit)) {
  
          if ($utils.isDefined(submit.messages)) {
              add_message({ messages: submit.messages });
          };
  
          if ($utils.isDefined(submit.reasons)) {
              add_message({ reasons: submit.reasons });
          };
  
          $editor.fields.code_box.control.value = submit.results;
      } else {
          throw Error("No response received from the [footprint_generic_json_order_import_flow] flow.")
      };
  
      add_message({ time: `${(((new Date()).getTime() - start_time.getTime()) / 1000)} sec` });
  
      $editor.fields.details_box.control.value = messages;
  
  } catch (error) {
      add_message({ message: get_error(error) });
      add_message({ order: order });
      add_message({ payload: payload });
  
      let payload_notes = {
          error: get_error(error),
          order: order,
          payload: payload
      };
  
      await message_add({
          status: `Error`,
          direction: `In`,
          process: `import_order`,
          ingress_id: null,
          transaction_id: null,
          payload: JSON.stringify(payload),
          notes: JSON.stringify(payload_notes)
      });
  };
  
  if ($editor.fieldsets.payload.expanded) {
      $editor.fieldsets.payload.toggle();
  };
  
  if (!$editor.fieldsets.messages.expanded) {
      $editor.fieldsets.messages.toggle();
  };
  
  function add_message(messsage: any) {
      if ($utils.isDefined(messsage)) {
          messages.push(messsage);
      };
  };
  
  function get_error(error: any) {
      // Get inner-most error message
      let errorMessage: string;
      let targetError = error;
      while ($utils.isDefined(targetError?.error)) {
          targetError = targetError.error;
      };
      if (!$utils.isDefined(targetError?.message)) {
          targetError = { "message": `Uncaught exception ${JSON.stringify(targetError)}` }
      };
      errorMessage = `[import_order] Error ${targetError.message} (${(((new Date()).getTime() - start_time.getTime()) / 1000)} sec)`;
      return errorMessage;
  };
  
  const message_schema = (await $flows.FootPrintApiManager.storage_messages({action: "", integration_name: ""})).payload;
  async function message_add(input: typeof message_schema[0]) {
      await $flows.FootPrintApiManager.storage_messages({ action: "Write", payload: input, integration_name: "FootprintApiManager" });
  };
  }
  order_sample(event = null) {
    return this.order_sampleInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async order_sampleInternal(
    $editor: FootPrintApiManager_footprint_api_runnerComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 01/23/2023
  
  const start_time = new Date();
  let step_time = new Date();
  let messages = [];
  
  try {
  
      const order_schema = (await $flows.FootPrintApiManager.footprint_generic_json_order_schema({ example: true }));
  
      const Order: typeof order_schema.DatexOrderInfo = order_schema.DatexOrderInfo;
  
      $editor.fields.code_box.control.value = {
          DatexOrderInfo: Order
      };
  
      const render_time = `${(((new Date()).getTime() - start_time.getTime()) / 1000)} sec`
      add_message({ RenderTime: render_time });
  
      if (!$editor.fieldsets.payload.expanded) {
          $editor.fieldsets.payload.toggle();
      };
      if (!$editor.fieldsets.messages.expanded) {
          $editor.fieldsets.payload.toggle();
      };
  
      if ($editor.fields.export_to_file.control.value) {
          try {
  
              step_time = new Date();
  
              const now = new Date();
              const day = now.getDate();
              const month = now.getMonth() + 1;
              const year = now.getFullYear();
              const hour = now.getHours();
              const minute = now.getMinutes();
              const second = now.getSeconds();
  
              const lookup_array = [`${year}`, (`00${month}`).slice(-2), (`00${day}`).slice(-2), (`00${hour}`).slice(-2), (`00${minute}`).slice(-2), (`00${second}`).slice(-2)];
              const lookup_seed = lookup_array.join("");
  
              const order_blob = new Blob([JSON.stringify(Order)]);
  
              await $utils.blob.saveFile(order_blob, {
                  fileName: `DatexOrderInfo_Sample_${lookup_seed}.txt`,
                  extensions: ['.txt']
              });
  
              const export_time = `${(((new Date()).getTime() - step_time.getTime()) / 1000)} sec`
              add_message({ ExportTime: export_time });
  
          } catch (error) {
              add_message({ message: get_error(error) });
          };
      };
  } catch (error) {
      add_message({ message: get_error(error) });
  
      if ($editor.fieldsets.payload.expanded) {
          $editor.fieldsets.payload.toggle();
      };
      if (!$editor.fieldsets.messages.expanded) {
          $editor.fieldsets.payload.toggle();
      };
  };
  
  $editor.fields.details_box.control.value = messages;
  
  function add_message(messsage: any) {
      if ($utils.isDefined(messsage)) {
          messages.push(messsage);
      };
  };
  
  function get_error(error: any) {
      // Get inner-most error message
      let errorMessage: string;
      let targetError = error;
      while ($utils.isDefined(targetError?.error)) {
          targetError = targetError.error;
      };
      if (!$utils.isDefined(targetError?.message)) {
          targetError = { "message": `Uncaught exception ${JSON.stringify(targetError)}` }
      };
      errorMessage = `[order_sample] Error ${targetError.message} (${(((new Date()).getTime() - start_time.getTime()) / 1000)} sec)`;
      return errorMessage;
  };
  }
  //#endregion private flows
  
}
