import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class WarehouseTransfers_ds_warehouse_transfer_inbound_order_licenseplates_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { orderId: number, shipmentId: number, $top?: number, $skip?: number }): Promise<{ result?: { LookupCode?: string, Id?: number, StatusId?: number, StatusName?: string, LocationId?: number, LocationName?: string }[], totalCount?: number }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/WarehouseTransfers/datasources/ds_warehouse_transfer_inbound_order_licenseplates_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId?: number, shipmentId?: number, $keys: number[] }): Promise<{ result?: { LookupCode?: string, Id?: number, StatusId?: number, StatusName?: string, LocationId?: number, LocationName?: string }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/WarehouseTransfers/datasources/ds_warehouse_transfer_inbound_order_licenseplates_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      orderId: number, shipmentId: number    }) {
        if(isNil(inParams.orderId)) {
          return true; 
        }
        if(isNil(inParams.shipmentId)) {
          return true; 
        }
      return false;
    }
}
