import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Dynamics365BusinessCentral_ds_get_order_materials_by_lookupcodesService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { lookupcodes?: string, projects?: number, owners?: number }): 
  Promise<{ result: { Id?: number, ControllerTypeId?: number, IsFixedWeight?: boolean, LookupCode?: string, Name?: string, ProjectId?: number, PackagingLookups?: { MaterialId?: number, PackagingId?: number, AutogenerateSerialNumbers?: boolean, DimensionUomId?: number, Height?: number, Length?: number, ShippingVolume?: number, ShippingWeight?: number, SubPackagingId?: number, SubPackagingQuantity?: number, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, Width?: number, Packaging?: { Id?: number, Name?: string, ShortName?: string } }[], VendorLots?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string, Lots?: { Id?: number, LookupCode?: string, ReceiveDate?: string, StatusId?: number, TypeId?: number }[] }[], Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string } } }[] }> 
  {
    let url = `${environment.backendUrl}api/Dynamics365BusinessCentral/datasources/ds_get_order_materials_by_lookupcodes/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { lookupcodes?: string, projects?: number, owners?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ControllerTypeId?: number, IsFixedWeight?: boolean, LookupCode?: string, Name?: string, ProjectId?: number, PackagingLookups?: { MaterialId?: number, PackagingId?: number, AutogenerateSerialNumbers?: boolean, DimensionUomId?: number, Height?: number, Length?: number, ShippingVolume?: number, ShippingWeight?: number, SubPackagingId?: number, SubPackagingQuantity?: number, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, Width?: number, Packaging?: { Id?: number, Name?: string, ShortName?: string } }[], VendorLots?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string, Lots?: { Id?: number, LookupCode?: string, ReceiveDate?: string, StatusId?: number, TypeId?: number }[] }[], Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Dynamics365BusinessCentral/datasources/ds_get_order_materials_by_lookupcodes/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { lookupcodes?: string, projects?: number, owners?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ControllerTypeId?: number, IsFixedWeight?: boolean, LookupCode?: string, Name?: string, ProjectId?: number, PackagingLookups?: { MaterialId?: number, PackagingId?: number, AutogenerateSerialNumbers?: boolean, DimensionUomId?: number, Height?: number, Length?: number, ShippingVolume?: number, ShippingWeight?: number, SubPackagingId?: number, SubPackagingQuantity?: number, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, Width?: number, Packaging?: { Id?: number, Name?: string, ShortName?: string } }[], VendorLots?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string, Lots?: { Id?: number, LookupCode?: string, ReceiveDate?: string, StatusId?: number, TypeId?: number }[] }[], Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Dynamics365BusinessCentral/datasources/ds_get_order_materials_by_lookupcodes/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      lookupcodes?: string, projects?: number, owners?: number    }) {
      return false;
    }
}
