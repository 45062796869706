import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { SerialNumbers_serialnumber_statuses_dd_singleComponent } from './SerialNumbers.serialnumber_statuses_dd_single.component'
import { SerialNumbers_measurement_units_dd_singleComponent } from './SerialNumbers.measurement_units_dd_single.component'

type EntityType = { 
    Id?: number, Archived?: boolean, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LookupCode?: string, LotId?: number, NetVolume?: number, NetWeight?: number, Notes?: string, PackagingId?: number, Width?: number, DimensionsUom?: { Id?: number, Short_name?: string }, VolumeUom?: { Id?: number, Short_name?: string }, WeightUom?: { Id?: number, Short_name?: string }, Packaging?: { Id?: number, ShortName?: string }, Status?: { Id?: number, Name?: string }}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => SerialNumbers_serialnumber_statuses_dd_singleComponent),
    forwardRef(() => SerialNumbers_measurement_units_dd_singleComponent),
  ],
  selector: 'FootPrintManager-serialnumber_editor',
  templateUrl: './FootPrintManager.serialnumber_editor.component.html'
})
export class FootPrintManager_serialnumber_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { serialnumberId: number } = { serialnumberId: null };
  //#region Inputs
  @Input('serialnumberId') set $inParams_serialnumberId(v: number) {
    this.inParams.serialnumberId = v;
  }
  get $inParams_serialnumberId(): number {
    return this.inParams.serialnumberId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    archived: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    length: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    width: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    height: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    dimension_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    net_weight: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    gross_weight: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    net_volume: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    gross_volume: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    weight_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    volume_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, 'Confirm', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false)
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Serial number', false, false),
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Status', false, false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Notes', false, false),
    archived: new FieldModel(new CheckBoxModel(this.formGroup.controls['archived'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Archived', false, false),
    length: new FieldModel(new NumberBoxModel(this.formGroup.controls['length'] as DatexFormControl, null, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Length', false, false),
    width: new FieldModel(new NumberBoxModel(this.formGroup.controls['width'] as DatexFormControl, null, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Width', false, false),
    height: new FieldModel(new NumberBoxModel(this.formGroup.controls['height'] as DatexFormControl, null, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Height', false, false),
    dimension_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['dimension_uom'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Dimension UOM', false, false),
    net_weight: new FieldModel(new NumberBoxModel(this.formGroup.controls['net_weight'] as DatexFormControl, null, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Net weight', false, false),
    gross_weight: new FieldModel(new NumberBoxModel(this.formGroup.controls['gross_weight'] as DatexFormControl, null, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Gross weight', false, false),
    net_volume: new FieldModel(new NumberBoxModel(this.formGroup.controls['net_volume'] as DatexFormControl, null, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Net volume', false, false),
    gross_volume: new FieldModel(new NumberBoxModel(this.formGroup.controls['gross_volume'] as DatexFormControl, null, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Gross volume', false, false),
    weight_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['weight_uom'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Weight UOM', false, false),
    volume_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['volume_uom'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Volume UOM', false, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true, false),
  newGroup2: new FieldsetModel('Details', false, true, false, false),
};


  //#region fields inParams
  get $fields_dimension_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_weight_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 3;
    
    return expr;
  }

  get $fields_volume_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 2;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    status: this.fields.status.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
    archived: this.fields.archived.control.valueChanges
    ,
    length: this.fields.length.control.valueChanges
    ,
    width: this.fields.width.control.valueChanges
    ,
    height: this.fields.height.control.valueChanges
    ,
    dimension_uom: this.fields.dimension_uom.control.valueChanges
    ,
    net_weight: this.fields.net_weight.control.valueChanges
    ,
    gross_weight: this.fields.gross_weight.control.valueChanges
    ,
    net_volume: this.fields.net_volume.control.valueChanges
    ,
    gross_volume: this.fields.gross_volume.control.valueChanges
    ,
    weight_uom: this.fields.weight_uom.control.valueChanges
    ,
    volume_uom: this.fields.volume_uom.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.serialnumberId)) {
        this.$missingRequiredInParams.push('serialnumberId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Serialnumber';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      serialnumberId:  $editor.inParams.serialnumberId 
    };

    const data = await this.datasources.SerialNumbers.ds_serialnumber_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    private async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.status.control as SelectBoxModel).reset($editor.entity.Status?.Id);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.Notes);
    (this.fields.archived.control as CheckBoxModel).reset($editor.entity.Archived);
    (this.fields.length.control as NumberBoxModel).reset($editor.entity.Length);
    (this.fields.width.control as NumberBoxModel).reset($editor.entity.Width);
    (this.fields.height.control as NumberBoxModel).reset($editor.entity.Height);
    (this.fields.dimension_uom.control as SelectBoxModel).reset($editor.entity.DimensionsUom?.Id);
    (this.fields.net_weight.control as NumberBoxModel).reset($editor.entity.NetWeight);
    (this.fields.gross_weight.control as NumberBoxModel).reset($editor.entity.GrossWeight);
    (this.fields.net_volume.control as NumberBoxModel).reset($editor.entity.NetVolume);
    (this.fields.gross_volume.control as NumberBoxModel).reset($editor.entity.GrossVolume);
    (this.fields.weight_uom.control as SelectBoxModel).reset($editor.entity.WeightUom?.Id);
    (this.fields.volume_uom.control as SelectBoxModel).reset($editor.entity.VolumeUom?.Id);

  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .status
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .archived
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .length
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .width
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .height
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .dimension_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .net_weight
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .gross_weight
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .net_volume
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .gross_volume
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .weight_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .volume_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_serialnumber_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.outParams.confirm = false;
  $editor.close();
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $editor: FootPrintManager_serialnumber_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.toolbar.confirm.control.readOnly = true;
  let payload: any = {};
  
  
  // serialnumbers Changes
  if ($editor.fields.lookupcode.control.isChanged) {
  
      const serialnumber = $editor.fields.lookupcode.control.value;
    
      // Check if serial exists
      const serialnumbers = (await $datasources.SerialNumbers.ds_find_serialnumbers.get({
          lotId: $editor.entity.LotId,
          packagingId: $editor.entity.PackagingId,
          lookupcode: serialnumber
      })).result;
      if ($utils.isDefined(serialnumbers)) {
          $editor.toolbar.confirm.control.readOnly = false;
          $shell.SerialNumbers.openErrorDialog('Serial Edit Error', 'Serialnumber already exists.');
          return;
  
      } else {
          payload.LookupCode = $editor.fields.lookupcode.control.value;
      }
  }
  
  
  if ($editor.fields.notes.control.isChanged) {
      payload.Notes = $editor.fields.notes.control.value;
  }
  
  if ($editor.fields.status.control.isChanged) {
      payload.StatusId = $editor.fields.status.control.value;
  }
  // Dimensions
  if ($editor.fields.length.control.isChanged) {
      payload.Length = $editor.fields.length.control.value;
  }
  
  if ($editor.fields.width.control.isChanged) {
      payload.Width = $editor.fields.width.control.value;
  }
  
  if ($editor.fields.height.control.isChanged) {
      payload.Height = $editor.fields.height.control.value;
  }
  if ($editor.fields.dimension_uom.control.isChanged) {
      payload.DimensionUomId = $editor.fields.dimension_uom.control.value;
  }
  // Weight
  if ($editor.fields.net_weight.control.isChanged) {
      payload.NetWeight = $editor.fields.net_weight.control.value;
  }
  
  if ($editor.fields.gross_weight.control.isChanged) {
      payload.GrossWeight = $editor.fields.gross_weight.control.value;
  }
  
  if ($editor.fields.weight_uom.control.isChanged) {
      payload.WeightUomId = $editor.fields.weight_uom.control.value;
  }
  // Volume
  if ($editor.fields.net_volume.control.isChanged) {
      payload.NetVolume = $editor.fields.net_volume.control.value;
  }
  
  if ($editor.fields.gross_volume.control.isChanged) {
      payload.GrossVolume = $editor.fields.gross_volume.control.value;
  }
  
  if ($editor.fields.volume_uom.control.isChanged) {
      payload.VolumeUomId = $editor.fields.volume_uom.control.value;
  }
  
  
  
  
  try {
      $editor.toolbar.confirm.control.readOnly = false;
      await $flows.Utilities.crud_update_flow({ entitySet: 'SerialNumbers', id: $editor.entity.Id, entity: payload });
      $editor.outParams.confirm = true;
      $editor.close();
  
  }
  
  catch (error) {
      $editor.toolbar.confirm.control.readOnly = false;
      $shell.SerialNumbers.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  
  }
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_serialnumber_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.outParams.confirm = false;
  
  
  
  }
  //#endregion private flows
  
}
