import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class DockAppointments_ds_dock_appointments_dashboardService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseIds: number[] }): 
  Promise<{ result: { Id?: number, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, OrderClass?: { LookupCode?: string, OrderClassTypeId?: number, OrderTypeId?: number }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, Status?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { WaveId?: number } }[], dock_appointment?: { Id?: number, DockAppointmentId?: number, DockAppointment?: { AssignedlLocationContainerId?: number, CheckedInOn?: string, LookupCode?: string, ScheduledArrival?: string, ScheduledDeparture?: string, ScheduledLocationContainerId?: number, StatusId?: number, TypeId?: number, WarehouseId?: number, Warehouse?: { TimeZoneId?: string }, AssignedLocation?: { Name?: string }, ScheduledLocation?: { Name?: string } }, convertedScheduledArrival?: { id?: number, convertedDate?: string }, convertedCheckInOn?: { id?: number, convertedDate?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseIds)) {
      missingRequiredInParams.push('\'warehouseIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_dock_appointments_dashboard/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseIds: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, OrderClass?: { LookupCode?: string, OrderClassTypeId?: number, OrderTypeId?: number }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, Status?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { WaveId?: number } }[], dock_appointment?: { Id?: number, DockAppointmentId?: number, DockAppointment?: { AssignedlLocationContainerId?: number, CheckedInOn?: string, LookupCode?: string, ScheduledArrival?: string, ScheduledDeparture?: string, ScheduledLocationContainerId?: number, StatusId?: number, TypeId?: number, WarehouseId?: number, Warehouse?: { TimeZoneId?: string }, AssignedLocation?: { Name?: string }, ScheduledLocation?: { Name?: string } }, convertedScheduledArrival?: { id?: number, convertedDate?: string }, convertedCheckInOn?: { id?: number, convertedDate?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseIds)) {
      missingRequiredInParams.push('\'warehouseIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_dock_appointments_dashboard/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseIds: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, OrderClass?: { LookupCode?: string, OrderClassTypeId?: number, OrderTypeId?: number }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, Status?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { WaveId?: number } }[], dock_appointment?: { Id?: number, DockAppointmentId?: number, DockAppointment?: { AssignedlLocationContainerId?: number, CheckedInOn?: string, LookupCode?: string, ScheduledArrival?: string, ScheduledDeparture?: string, ScheduledLocationContainerId?: number, StatusId?: number, TypeId?: number, WarehouseId?: number, Warehouse?: { TimeZoneId?: string }, AssignedLocation?: { Name?: string }, ScheduledLocation?: { Name?: string } }, convertedScheduledArrival?: { id?: number, convertedDate?: string }, convertedCheckInOn?: { id?: number, convertedDate?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseIds)) {
      missingRequiredInParams.push('\'warehouseIds\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_dock_appointments_dashboard/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseIds: number[]    }) {
        if(isNil(inParams.warehouseIds)) {
          return true; 
        }
      return false;
    }
}
