import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  TemplateRef,
  forwardRef
} from '@angular/core';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { StepConfig } from './components/wizard.component';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_dock_appointments_selection_gridComponent } from './FootPrintManager.dock_appointments_selection_grid.component';


@Component({
  standalone: true,
  imports: [
    SharedModule,

    forwardRef(() => FootPrintManager_dock_appointments_selection_gridComponent),
  ],
  selector: 'FootPrintManager-dock_appointment_selection_wizard',
  templateUrl: './FootPrintManager.dock_appointment_selection_wizard.component.html'
})
export class FootPrintManager_dock_appointment_selection_wizardComponent extends BaseComponent implements OnInit, OnChanges {
  _stepConfigs: StepConfig[];

  inParams: { warehouseId?: number, dockDoor?: number, carrierId?: number, orderId?: number, scheduledProjectId?: number, excludedDockAppointmentIds?: number[], typeIds?: number[] } = { warehouseId: null, dockDoor: null, carrierId: null, orderId: null, scheduledProjectId: null, excludedDockAppointmentIds: [], typeIds: [] };
  //#region Inputs
  @Input('warehouseId') set $inParams_warehouseId(v: number) {
    this.inParams.warehouseId = v;
  }
  get $inParams_warehouseId(): number {
    return this.inParams.warehouseId;
  }
  @Input('dockDoor') set $inParams_dockDoor(v: number) {
    this.inParams.dockDoor = v;
  }
  get $inParams_dockDoor(): number {
    return this.inParams.dockDoor;
  }
  @Input('carrierId') set $inParams_carrierId(v: number) {
    this.inParams.carrierId = v;
  }
  get $inParams_carrierId(): number {
    return this.inParams.carrierId;
  }
  @Input('orderId') set $inParams_orderId(v: number) {
    this.inParams.orderId = v;
  }
  get $inParams_orderId(): number {
    return this.inParams.orderId;
  }
  @Input('scheduledProjectId') set $inParams_scheduledProjectId(v: number) {
    this.inParams.scheduledProjectId = v;
  }
  get $inParams_scheduledProjectId(): number {
    return this.inParams.scheduledProjectId;
  }
  @Input('excludedDockAppointmentIds') set $inParams_excludedDockAppointmentIds(v: number[]) {
    this.inParams.excludedDockAppointmentIds = v;
  }
  get $inParams_excludedDockAppointmentIds(): number[] {
    return this.inParams.excludedDockAppointmentIds;
  }
  @Input('typeIds') set $inParams_typeIds(v: number[]) {
    this.inParams.typeIds = v;
  }
  get $inParams_typeIds(): number[] {
    return this.inParams.typeIds;
  }
  //#endregion Inputs

  //#region Outputs
  @Output() 
  $commandsTmpRef = new EventEmitter<TemplateRef<any>>();
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean, dockAppointmentId?: number } = { confirm: null, dockAppointmentId: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  async $init() {
    this.title = 'Dock Appointment Selection';
  
    const $wizard = this;
    const $utils = this.utils;

    await this.on_init();

    this.initialized = true;
  }

  steps: {
    step1?: {
      outParams?: { dockAppointmentId?: number }
    }
  } = { };

  stepsResultFunc(result: any) {
    this.steps = result;
  }
  
  getStepConfigs(): StepConfig[] {
    if (!this._stepConfigs) {
      const $wizard = this;
      const $utils = this.utils;

      this._stepConfigs = [
      {
          id: 'step1',
          title: 'Select Dock Appointment',
          component: FootPrintManager_dock_appointments_selection_gridComponent,
          inParamsFunc: ($index?: number) => {
            return {
              typeIds: $wizard.inParams.typeIds,
              carrierId: $wizard.inParams.carrierId,
              statusIds: null,
              warehouseId: $wizard.inParams.warehouseId,
              scheduledProjectId: $wizard.inParams.scheduledProjectId,
              excludedDockAppointmentIds: $wizard.inParams.excludedDockAppointmentIds,
            }
          },
          nextButtonLabel: 'Finish',
      },
      ];
    }

    return this._stepConfigs;
  }

  async finish() {
    const $wizard = this;
    const $utils = this.utils;

    await this.on_finish();

    this.close();
  }

  close() {
    this.$finish.emit();
  }

  commandsTmpRefChange(tmp: any) {
    this.$commandsTmpRef.emit(tmp);
  }
 
  //#region private flows
  on_finish(event = null) {
    return this.on_finishInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_finishInternal(
    $wizard: FootPrintManager_dock_appointment_selection_wizardComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  if ($utils.isDefined($wizard.steps?.step1?.outParams?.dockAppointmentId)) {
      var selectedDockAppointmentId = $wizard.steps.step1.outParams.dockAppointmentId;
  
      if ($utils.isDefined($wizard.inParams.orderId)) {
          const numberArray: number[] = [$wizard.inParams.orderId];
  
          if ($utils.isDefined(numberArray)) {
              const orderId: number = parseInt(numberArray.join(''));
  
              // Associated selected dock appointment to order
              const dockAppointmentItemRequest = (await $flows.DockAppointments.associate_orders_from_dock_appointment_flow({
                  dockAppointmentId: selectedDockAppointmentId,
                  orderId: orderId
              }));
          }
      }
  
      $wizard.outParams.confirm = true;
      $wizard.outParams.dockAppointmentId = selectedDockAppointmentId;
  }
  else {
  
      $wizard.outParams.confirm = false;
  }
  
  
  
  
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $wizard: FootPrintManager_dock_appointment_selection_wizardComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $wizard.outParams.confirm = false;
  }
  //#endregion private flows
}
