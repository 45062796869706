import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Returns_ds_get_default_licenseplate_for_return_taskService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shippingContainerId: number, projectId: number, locationId: number }): 
  Promise<{ result: { Id?: number, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string, Status?: { Id?: number, Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shippingContainerId)) {
      missingRequiredInParams.push('\'shippingContainerId\'');
    }
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (isNil(inParams.locationId)) {
      missingRequiredInParams.push('\'locationId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Returns/datasources/ds_get_default_licenseplate_for_return_task/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { shippingContainerId: number, projectId: number, locationId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string, Status?: { Id?: number, Name?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shippingContainerId)) {
      missingRequiredInParams.push('\'shippingContainerId\'');
    }
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (isNil(inParams.locationId)) {
      missingRequiredInParams.push('\'locationId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Returns/datasources/ds_get_default_licenseplate_for_return_task/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { shippingContainerId: number, projectId: number, locationId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string, Status?: { Id?: number, Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shippingContainerId)) {
      missingRequiredInParams.push('\'shippingContainerId\'');
    }
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (isNil(inParams.locationId)) {
      missingRequiredInParams.push('\'locationId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Returns/datasources/ds_get_default_licenseplate_for_return_task/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      shippingContainerId: number, projectId: number, locationId: number    }) {
        if(isNil(inParams.shippingContainerId)) {
          return true; 
        }
        if(isNil(inParams.projectId)) {
          return true; 
        }
        if(isNil(inParams.locationId)) {
          return true; 
        }
      return false;
    }
}
