import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { SerialNumbers_create_audit_taskService } from './SerialNumbers.flow.index';
import { SerialNumbers_create_serial_numbers_flowService } from './SerialNumbers.flow.index';
import { SerialNumbers_generate_child_serial_numbers_flowService } from './SerialNumbers.flow.index';
import { SerialNumbers_get_serialnumber_parent_pathService } from './SerialNumbers.flow.index';
import { SerialNumbers_move_serial_numbers_flowService } from './SerialNumbers.flow.index';
import { SerialNumbers_update_serial_numbers_flowService } from './SerialNumbers.flow.index';
import { SerialNumbers_update_serial_numbers_statuses_flowService } from './SerialNumbers.flow.index';

import { $frontendTypes } from './SerialNumbers.frontend.types'

@Injectable({ providedIn: 'root' })
export class SerialNumbers_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public SerialNumbers: SerialNumbers_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _create_audit_task: SerialNumbers_create_audit_taskService;
  public async create_audit_task(inParams: { isAbort: boolean, entity: { warehouseId: number, locationId?: number, shippingContainerId?: number, licensePlateId?: number }, reasonCodeId?: number, expectedSerialNumberCodes?: string[], scannedSerialNumberCodes?: string[], startDateTime?: string }): Promise< { reasons?: string[] }> {
    if(!this._create_audit_task) { 
      this._create_audit_task = this.injector.get(SerialNumbers_create_audit_taskService);
    }
    return this._create_audit_task.run(inParams);
  }
   
   

   
 
  private _create_serial_numbers_flow: SerialNumbers_create_serial_numbers_flowService;
  public async create_serial_numbers_flow(inParams: { serialNumbers: { Archived?: boolean, ChainHead?: number, DimensionUomId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateId?: number, LotId?: number, NetVolume?: number, NetWeight?: number, Notes?: string, OutboundShipmentId?: number, PackagingId?: number, StatusId?: number, VolumeUomId?: number, WeightUomId?: number, Width?: number, LookupCode?: string, MaterialId?: number }[] }): Promise< { reasons?: string[], serialNumberIds?: number[] }> {
    if(!this._create_serial_numbers_flow) { 
      this._create_serial_numbers_flow = this.injector.get(SerialNumbers_create_serial_numbers_flowService);
    }
    return this._create_serial_numbers_flow.run(inParams);
  }
   
   

   
 
  private _generate_child_serial_numbers_flow: SerialNumbers_generate_child_serial_numbers_flowService;
  public async generate_child_serial_numbers_flow(inParams: { serialNumberIds: number[] }): Promise< { reasons?: string[] }> {
    if(!this._generate_child_serial_numbers_flow) { 
      this._generate_child_serial_numbers_flow = this.injector.get(SerialNumbers_generate_child_serial_numbers_flowService);
    }
    return this._generate_child_serial_numbers_flow.run(inParams);
  }
   
   

   
 
  private _get_serialnumber_parent_path: SerialNumbers_get_serialnumber_parent_pathService;
  public async get_serialnumber_parent_path(inParams: { serialnumberIds: number[] }): Promise< { serialInfo?: { serialId?: number, serialPath?: string }[] }> {
    if(!this._get_serialnumber_parent_path) { 
      this._get_serialnumber_parent_path = this.injector.get(SerialNumbers_get_serialnumber_parent_pathService);
    }
    return this._get_serialnumber_parent_path.run(inParams);
  }
   
   

   
 
  private _move_serial_numbers_flow: SerialNumbers_move_serial_numbers_flowService;
  public async move_serial_numbers_flow(inParams: { serialNumberIds: number[], targetLicensePlateId: number }): Promise< { reasons?: string[] }> {
    if(!this._move_serial_numbers_flow) { 
      this._move_serial_numbers_flow = this.injector.get(SerialNumbers_move_serial_numbers_flowService);
    }
    return this._move_serial_numbers_flow.run(inParams);
  }
   
   

   
 
  private _update_serial_numbers_flow: SerialNumbers_update_serial_numbers_flowService;
  public async update_serial_numbers_flow(inParams: { serial_numbers: { id: number, properties: $frontendTypes.SerialNumbers.SerialNumber }[] }): Promise< { reasons?: string[], results?: { succeeded?: number, failed?: number, duration?: number } }> {
    if(!this._update_serial_numbers_flow) { 
      this._update_serial_numbers_flow = this.injector.get(SerialNumbers_update_serial_numbers_flowService);
    }
    return this._update_serial_numbers_flow.run(inParams);
  }
   
   

   
 
  private _update_serial_numbers_statuses_flow: SerialNumbers_update_serial_numbers_statuses_flowService;
  public async update_serial_numbers_statuses_flow(inParams: { serialNumberIds: number[], targetStatusId: number }): Promise<void> {
    if(!this._update_serial_numbers_statuses_flow) { 
      this._update_serial_numbers_statuses_flow = this.injector.get(SerialNumbers_update_serial_numbers_statuses_flowService);
    }
    return this._update_serial_numbers_statuses_flow.run(inParams);
  }
   
   

   
}
