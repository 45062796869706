import { Inject, Injectable, Injector } from '@angular/core';


import { Discussions_storage_discussionsService } from './Discussions.flow.index';

import { $frontendTypes } from './Discussions.frontend.types'

@Injectable({ providedIn: 'root' })
export class Discussions_FlowService {

  constructor(
    private injector: Injector
  ) {
  }

  public Discussions: Discussions_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _storage_discussions: Discussions_storage_discussionsService;
  public async storage_discussions(inParams: { action?: string, payload?: { id?: string, entity_name?: string, entity_id?: string, reference?: string, discussion?: string, created_by?: string, created_on?: string, read_on?: string, alerted_on?: string, application_id?: string }, take?: number, skip?: number }): Promise< { payload?: { id?: string, entity_name?: string, entity_id?: string, reference?: string, discussion?: string, created_by?: string, created_on?: string, read_on?: string, alerted_on?: string, application_id?: string }[] }> {
    if(!this._storage_discussions) { 
      this._storage_discussions = this.injector.get(Discussions_storage_discussionsService);
    }
    return this._storage_discussions.run(inParams);
  }
   
   

   
}
