import { Injectable } from '@angular/core';
import { MissingTranslationHandler, TranslateCompiler, TranslateParser, TranslateService, TranslateStore } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Language, LocalizationService, LocalizationSettings } from './localization.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Utilities_LocalizationService } from './Utilities.localization.service';
import { Attachments_LocalizationService } from './Attachments.localization.service';
import { Instructions_LocalizationService } from './Instructions.localization.service';
import { Owners_LocalizationService } from './Owners.localization.service';
import { Notifications_LocalizationService } from './Notifications.localization.service';
import { Inventory_LocalizationService } from './Inventory.localization.service';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { Materials_LocalizationService } from './Materials.localization.service';
import { PurchaseOrders_LocalizationService } from './PurchaseOrders.localization.service';
import { SalesOrders_LocalizationService } from './SalesOrders.localization.service';

@Injectable({
  providedIn: 'root'
})
export class FootPrintApiManager_LocalizationService
  extends LocalizationService<{  }> {

  public FootPrintApiManager: FootPrintApiManager_LocalizationService = this;

  public override get settings(): LocalizationSettings {
    return {"default":"","languages":[]}
  }
  
  private _referencedServices: LocalizationService<any>[] = [];

  constructor(
    httpClient: HttpClient,
    compiler: TranslateCompiler,
    parser: TranslateParser,
    missingHandler: MissingTranslationHandler,
    public Utilities: Utilities_LocalizationService,
    public Attachments: Attachments_LocalizationService,
    public Instructions: Instructions_LocalizationService,
    public Owners: Owners_LocalizationService,
    public Notifications: Notifications_LocalizationService,
    public Inventory: Inventory_LocalizationService,
    public Invoices: Invoices_LocalizationService,
    public Materials: Materials_LocalizationService,
    public PurchaseOrders: PurchaseOrders_LocalizationService,
    public SalesOrders: SalesOrders_LocalizationService
    ) {
    super(
      new TranslateService(
        new TranslateStore(),
        new TranslateHttpLoader(httpClient, '../assets/i18n/FootPrintApiManager/', '.json'),
        compiler,
        parser,
        missingHandler,
        true,
        true,
        false,
        ''));

    this._referencedServices.push(this.Utilities);

    this._referencedServices.push(this.Attachments);

    this._referencedServices.push(this.Instructions);

    this._referencedServices.push(this.Owners);

    this._referencedServices.push(this.Notifications);

    this._referencedServices.push(this.Inventory);

    this._referencedServices.push(this.Invoices);

    this._referencedServices.push(this.Materials);

    this._referencedServices.push(this.PurchaseOrders);

    this._referencedServices.push(this.SalesOrders);
    
    this.initialize();

  }

  public override setLanguage(lang: Language): void{
    super.setLanguage(lang);
  
    for (let i of this._referencedServices){
      i.setLanguage(lang);
    }
  }
}