import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class PurchaseOrders_ds_purchase_order_details_reportService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, PackagedAmount?: number, Price?: number, TotalPrice?: number, ShipmentLines?: { ActualPackagedAmount?: number, ActualPackaged?: { ShortName?: string } }[], InventoryMeasurementUnit?: { ShortName?: string }, Material?: { ControllerTypeId?: number, Description?: string, LookupCode?: string }, Lot?: { LookupCode?: string, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string } }, Totals?: { TotalPackagedAmount?: number, TotalGrossWeight?: number, OrderId?: number, OrderLineNumber?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PurchaseOrders/datasources/ds_purchase_order_details_report/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, PackagedAmount?: number, Price?: number, TotalPrice?: number, ShipmentLines?: { ActualPackagedAmount?: number, ActualPackaged?: { ShortName?: string } }[], InventoryMeasurementUnit?: { ShortName?: string }, Material?: { ControllerTypeId?: number, Description?: string, LookupCode?: string }, Lot?: { LookupCode?: string, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string } }, Totals?: { TotalPackagedAmount?: number, TotalGrossWeight?: number, OrderId?: number, OrderLineNumber?: number } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PurchaseOrders/datasources/ds_purchase_order_details_report/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, $keys: { OrderId?: number, LineNumber?: number }[] }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, PackagedAmount?: number, Price?: number, TotalPrice?: number, ShipmentLines?: { ActualPackagedAmount?: number, ActualPackaged?: { ShortName?: string } }[], InventoryMeasurementUnit?: { ShortName?: string }, Material?: { ControllerTypeId?: number, Description?: string, LookupCode?: string }, Lot?: { LookupCode?: string, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string } }, Totals?: { TotalPackagedAmount?: number, TotalGrossWeight?: number, OrderId?: number, OrderLineNumber?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PurchaseOrders/datasources/ds_purchase_order_details_report/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId: number    }) {
        if(isNil(inParams.orderId)) {
          return true; 
        }
      return false;
    }
}
