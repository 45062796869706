import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { Addresses_DatasourceService } from './Addresses.datasource.index';
import { Carriers_DatasourceService } from './Carriers.datasource.index';
import { PalletTransactions_DatasourceService } from './PalletTransactions.datasource.index';
import { Lots_DatasourceService } from './Lots.datasource.index';
import { Owners_DatasourceService } from './Owners.datasource.index';
import { Discussions_DatasourceService } from './Discussions.datasource.index';
import { ExcelOrderImport_DatasourceService } from './ExcelOrderImport.datasource.index';
import { Document360_DatasourceService } from './Document360.datasource.index';
import { Usersnap_DatasourceService } from './Usersnap.datasource.index';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Inventory_DatasourceService } from './Inventory.datasource.index';
import { Waves_DatasourceService } from './Waves.datasource.index';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Materials_DatasourceService } from './Materials.datasource.index';
import { Locations_DatasourceService } from './Locations.datasource.index';
import { ExcelInventoryImport_DatasourceService } from './ExcelInventoryImport.datasource.index';
import { DockAppointments_DatasourceService } from './DockAppointments.datasource.index';
import { PrintNode_DatasourceService } from './PrintNode.datasource.index';
import { PurchaseOrders_DatasourceService } from './PurchaseOrders.datasource.index';
import { SalesOrders_DatasourceService } from './SalesOrders.datasource.index';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintApiManager_DatasourceService } from './FootPrintApiManager.datasource.index';
import { Dynamics365BusinessCentral_DatasourceService } from './Dynamics365BusinessCentral.datasource.index';
import { Ultraship_DatasourceService } from './Ultraship.datasource.index';
import { Reports_DatasourceService } from './Reports.datasource.index';

import { app_atest_callService } from './app.datasource.index';
import { app_custom_ds_account_contactService } from './app.datasource.index';
import { app_custom_ds_account_contact_lookupService } from './app.datasource.index';
import { app_custom_ds_carriers_ddService } from './app.datasource.index';
import { app_custom_ds_freight_billing_order_detailsService } from './app.datasource.index';
import { app_custom_ds_get_account_name_by_idService } from './app.datasource.index';
import { app_custom_ds_get_carrier_id_by_nameService } from './app.datasource.index';
import { app_custom_ds_get_lots_for_order_importService } from './app.datasource.index';
import { app_custom_ds_get_orderLines_with_totals_by_orderIdService } from './app.datasource.index';
import { app_custom_ds_get_order_class_id_by_nameService } from './app.datasource.index';
import { app_custom_ds_get_orders_by_lookupcodes_types_projects_warehousesService } from './app.datasource.index';
import { app_custom_ds_get_orders_ready_to_waveService } from './app.datasource.index';
import { app_custom_ds_get_pallet_detailsService } from './app.datasource.index';
import { app_custom_ds_get_project_lookup_by_nameService } from './app.datasource.index';
import { app_custom_ds_inventory_by_location_selection_gridService } from './app.datasource.index';
import { app_custom_ds_inventory_licenseplate_gridService } from './app.datasource.index';
import { app_custom_ds_inventory_location_gridService } from './app.datasource.index';
import { app_custom_ds_inventory_lot_gridService } from './app.datasource.index';
import { app_custom_ds_inventory_material_gridService } from './app.datasource.index';
import { app_custom_ds_lotcoo_ddService } from './app.datasource.index';
import { app_custom_ds_materials_udf_gridService } from './app.datasource.index';
import { app_custom_ds_order_class_ddService } from './app.datasource.index';
import { app_custom_ds_order_import_gridService } from './app.datasource.index';
import { app_custom_ds_projects_ddService } from './app.datasource.index';
import { app_custom_ds_sales_order_editorService } from './app.datasource.index';
import { app_custom_ds_udf_allergen_ddService } from './app.datasource.index';
import { app_custom_invoice_reportService } from './app.datasource.index';
import { app_ds_get_invoice_detail_grid_sumService } from './app.datasource.index';
import { app_ds_get_invoice_line_detail_gridService } from './app.datasource.index';
import { app_ds_get_order_id_by_invoiceidService } from './app.datasource.index';
import { app_ds_get_owner_billing_address_by_invoiceIdService } from './app.datasource.index';
import { app_ds_get_owner_opeartional_address_by_invoiceIdService } from './app.datasource.index';

@Injectable({ providedIn: 'root' })
export class app_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.Addresses = this.injector.get(Addresses_DatasourceService);
    this.Carriers = this.injector.get(Carriers_DatasourceService);
    this.PalletTransactions = this.injector.get(PalletTransactions_DatasourceService);
    this.Lots = this.injector.get(Lots_DatasourceService);
    this.Owners = this.injector.get(Owners_DatasourceService);
    this.Discussions = this.injector.get(Discussions_DatasourceService);
    this.ExcelOrderImport = this.injector.get(ExcelOrderImport_DatasourceService);
    this.Document360 = this.injector.get(Document360_DatasourceService);
    this.Usersnap = this.injector.get(Usersnap_DatasourceService);
    this.Notifications = this.injector.get(Notifications_DatasourceService);
    this.Inventory = this.injector.get(Inventory_DatasourceService);
    this.Waves = this.injector.get(Waves_DatasourceService);
    this.Invoices = this.injector.get(Invoices_DatasourceService);
    this.Materials = this.injector.get(Materials_DatasourceService);
    this.Locations = this.injector.get(Locations_DatasourceService);
    this.ExcelInventoryImport = this.injector.get(ExcelInventoryImport_DatasourceService);
    this.DockAppointments = this.injector.get(DockAppointments_DatasourceService);
    this.PrintNode = this.injector.get(PrintNode_DatasourceService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_DatasourceService);
    this.SalesOrders = this.injector.get(SalesOrders_DatasourceService);
    this.FootPrintManager = this.injector.get(FootPrintManager_DatasourceService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_DatasourceService);
    this.Dynamics365BusinessCentral = this.injector.get(Dynamics365BusinessCentral_DatasourceService);
    this.Ultraship = this.injector.get(Ultraship_DatasourceService);
    this.Reports = this.injector.get(Reports_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
    public Addresses: Addresses_DatasourceService;
    public Carriers: Carriers_DatasourceService;
    public PalletTransactions: PalletTransactions_DatasourceService;
    public Lots: Lots_DatasourceService;
    public Owners: Owners_DatasourceService;
    public Discussions: Discussions_DatasourceService;
    public ExcelOrderImport: ExcelOrderImport_DatasourceService;
    public Document360: Document360_DatasourceService;
    public Usersnap: Usersnap_DatasourceService;
    public Notifications: Notifications_DatasourceService;
    public Inventory: Inventory_DatasourceService;
    public Waves: Waves_DatasourceService;
    public Invoices: Invoices_DatasourceService;
    public Materials: Materials_DatasourceService;
    public Locations: Locations_DatasourceService;
    public ExcelInventoryImport: ExcelInventoryImport_DatasourceService;
    public DockAppointments: DockAppointments_DatasourceService;
    public PrintNode: PrintNode_DatasourceService;
    public PurchaseOrders: PurchaseOrders_DatasourceService;
    public SalesOrders: SalesOrders_DatasourceService;
    public FootPrintManager: FootPrintManager_DatasourceService;
    public FootPrintApiManager: FootPrintApiManager_DatasourceService;
    public Dynamics365BusinessCentral: Dynamics365BusinessCentral_DatasourceService;
    public Ultraship: Ultraship_DatasourceService;
    public Reports: Reports_DatasourceService;
  public app: app_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _atest_call: app_atest_callService;
  public get atest_call(): app_atest_callService {
    if(!this._atest_call) {
      this._atest_call = this.injector.get(app_atest_callService);
    }
    return this._atest_call;
  }
  private _custom_ds_account_contact: app_custom_ds_account_contactService;
  public get custom_ds_account_contact(): app_custom_ds_account_contactService {
    if(!this._custom_ds_account_contact) {
      this._custom_ds_account_contact = this.injector.get(app_custom_ds_account_contactService);
    }
    return this._custom_ds_account_contact;
  }
  private _custom_ds_account_contact_lookup: app_custom_ds_account_contact_lookupService;
  public get custom_ds_account_contact_lookup(): app_custom_ds_account_contact_lookupService {
    if(!this._custom_ds_account_contact_lookup) {
      this._custom_ds_account_contact_lookup = this.injector.get(app_custom_ds_account_contact_lookupService);
    }
    return this._custom_ds_account_contact_lookup;
  }
  private _custom_ds_carriers_dd: app_custom_ds_carriers_ddService;
  public get custom_ds_carriers_dd(): app_custom_ds_carriers_ddService {
    if(!this._custom_ds_carriers_dd) {
      this._custom_ds_carriers_dd = this.injector.get(app_custom_ds_carriers_ddService);
    }
    return this._custom_ds_carriers_dd;
  }
  private _custom_ds_freight_billing_order_details: app_custom_ds_freight_billing_order_detailsService;
  public get custom_ds_freight_billing_order_details(): app_custom_ds_freight_billing_order_detailsService {
    if(!this._custom_ds_freight_billing_order_details) {
      this._custom_ds_freight_billing_order_details = this.injector.get(app_custom_ds_freight_billing_order_detailsService);
    }
    return this._custom_ds_freight_billing_order_details;
  }
  private _custom_ds_get_account_name_by_id: app_custom_ds_get_account_name_by_idService;
  public get custom_ds_get_account_name_by_id(): app_custom_ds_get_account_name_by_idService {
    if(!this._custom_ds_get_account_name_by_id) {
      this._custom_ds_get_account_name_by_id = this.injector.get(app_custom_ds_get_account_name_by_idService);
    }
    return this._custom_ds_get_account_name_by_id;
  }
  private _custom_ds_get_carrier_id_by_name: app_custom_ds_get_carrier_id_by_nameService;
  public get custom_ds_get_carrier_id_by_name(): app_custom_ds_get_carrier_id_by_nameService {
    if(!this._custom_ds_get_carrier_id_by_name) {
      this._custom_ds_get_carrier_id_by_name = this.injector.get(app_custom_ds_get_carrier_id_by_nameService);
    }
    return this._custom_ds_get_carrier_id_by_name;
  }
  private _custom_ds_get_lots_for_order_import: app_custom_ds_get_lots_for_order_importService;
  public get custom_ds_get_lots_for_order_import(): app_custom_ds_get_lots_for_order_importService {
    if(!this._custom_ds_get_lots_for_order_import) {
      this._custom_ds_get_lots_for_order_import = this.injector.get(app_custom_ds_get_lots_for_order_importService);
    }
    return this._custom_ds_get_lots_for_order_import;
  }
  private _custom_ds_get_orderLines_with_totals_by_orderId: app_custom_ds_get_orderLines_with_totals_by_orderIdService;
  public get custom_ds_get_orderLines_with_totals_by_orderId(): app_custom_ds_get_orderLines_with_totals_by_orderIdService {
    if(!this._custom_ds_get_orderLines_with_totals_by_orderId) {
      this._custom_ds_get_orderLines_with_totals_by_orderId = this.injector.get(app_custom_ds_get_orderLines_with_totals_by_orderIdService);
    }
    return this._custom_ds_get_orderLines_with_totals_by_orderId;
  }
  private _custom_ds_get_order_class_id_by_name: app_custom_ds_get_order_class_id_by_nameService;
  public get custom_ds_get_order_class_id_by_name(): app_custom_ds_get_order_class_id_by_nameService {
    if(!this._custom_ds_get_order_class_id_by_name) {
      this._custom_ds_get_order_class_id_by_name = this.injector.get(app_custom_ds_get_order_class_id_by_nameService);
    }
    return this._custom_ds_get_order_class_id_by_name;
  }
  private _custom_ds_get_orders_by_lookupcodes_types_projects_warehouses: app_custom_ds_get_orders_by_lookupcodes_types_projects_warehousesService;
  public get custom_ds_get_orders_by_lookupcodes_types_projects_warehouses(): app_custom_ds_get_orders_by_lookupcodes_types_projects_warehousesService {
    if(!this._custom_ds_get_orders_by_lookupcodes_types_projects_warehouses) {
      this._custom_ds_get_orders_by_lookupcodes_types_projects_warehouses = this.injector.get(app_custom_ds_get_orders_by_lookupcodes_types_projects_warehousesService);
    }
    return this._custom_ds_get_orders_by_lookupcodes_types_projects_warehouses;
  }
  private _custom_ds_get_orders_ready_to_wave: app_custom_ds_get_orders_ready_to_waveService;
  public get custom_ds_get_orders_ready_to_wave(): app_custom_ds_get_orders_ready_to_waveService {
    if(!this._custom_ds_get_orders_ready_to_wave) {
      this._custom_ds_get_orders_ready_to_wave = this.injector.get(app_custom_ds_get_orders_ready_to_waveService);
    }
    return this._custom_ds_get_orders_ready_to_wave;
  }
  private _custom_ds_get_pallet_details: app_custom_ds_get_pallet_detailsService;
  public get custom_ds_get_pallet_details(): app_custom_ds_get_pallet_detailsService {
    if(!this._custom_ds_get_pallet_details) {
      this._custom_ds_get_pallet_details = this.injector.get(app_custom_ds_get_pallet_detailsService);
    }
    return this._custom_ds_get_pallet_details;
  }
  private _custom_ds_get_project_lookup_by_name: app_custom_ds_get_project_lookup_by_nameService;
  public get custom_ds_get_project_lookup_by_name(): app_custom_ds_get_project_lookup_by_nameService {
    if(!this._custom_ds_get_project_lookup_by_name) {
      this._custom_ds_get_project_lookup_by_name = this.injector.get(app_custom_ds_get_project_lookup_by_nameService);
    }
    return this._custom_ds_get_project_lookup_by_name;
  }
  private _custom_ds_inventory_by_location_selection_grid: app_custom_ds_inventory_by_location_selection_gridService;
  public get custom_ds_inventory_by_location_selection_grid(): app_custom_ds_inventory_by_location_selection_gridService {
    if(!this._custom_ds_inventory_by_location_selection_grid) {
      this._custom_ds_inventory_by_location_selection_grid = this.injector.get(app_custom_ds_inventory_by_location_selection_gridService);
    }
    return this._custom_ds_inventory_by_location_selection_grid;
  }
  private _custom_ds_inventory_licenseplate_grid: app_custom_ds_inventory_licenseplate_gridService;
  public get custom_ds_inventory_licenseplate_grid(): app_custom_ds_inventory_licenseplate_gridService {
    if(!this._custom_ds_inventory_licenseplate_grid) {
      this._custom_ds_inventory_licenseplate_grid = this.injector.get(app_custom_ds_inventory_licenseplate_gridService);
    }
    return this._custom_ds_inventory_licenseplate_grid;
  }
  private _custom_ds_inventory_location_grid: app_custom_ds_inventory_location_gridService;
  public get custom_ds_inventory_location_grid(): app_custom_ds_inventory_location_gridService {
    if(!this._custom_ds_inventory_location_grid) {
      this._custom_ds_inventory_location_grid = this.injector.get(app_custom_ds_inventory_location_gridService);
    }
    return this._custom_ds_inventory_location_grid;
  }
  private _custom_ds_inventory_lot_grid: app_custom_ds_inventory_lot_gridService;
  public get custom_ds_inventory_lot_grid(): app_custom_ds_inventory_lot_gridService {
    if(!this._custom_ds_inventory_lot_grid) {
      this._custom_ds_inventory_lot_grid = this.injector.get(app_custom_ds_inventory_lot_gridService);
    }
    return this._custom_ds_inventory_lot_grid;
  }
  private _custom_ds_inventory_material_grid: app_custom_ds_inventory_material_gridService;
  public get custom_ds_inventory_material_grid(): app_custom_ds_inventory_material_gridService {
    if(!this._custom_ds_inventory_material_grid) {
      this._custom_ds_inventory_material_grid = this.injector.get(app_custom_ds_inventory_material_gridService);
    }
    return this._custom_ds_inventory_material_grid;
  }
  private _custom_ds_lotcoo_dd: app_custom_ds_lotcoo_ddService;
  public get custom_ds_lotcoo_dd(): app_custom_ds_lotcoo_ddService {
    if(!this._custom_ds_lotcoo_dd) {
      this._custom_ds_lotcoo_dd = this.injector.get(app_custom_ds_lotcoo_ddService);
    }
    return this._custom_ds_lotcoo_dd;
  }
  private _custom_ds_materials_udf_grid: app_custom_ds_materials_udf_gridService;
  public get custom_ds_materials_udf_grid(): app_custom_ds_materials_udf_gridService {
    if(!this._custom_ds_materials_udf_grid) {
      this._custom_ds_materials_udf_grid = this.injector.get(app_custom_ds_materials_udf_gridService);
    }
    return this._custom_ds_materials_udf_grid;
  }
  private _custom_ds_order_class_dd: app_custom_ds_order_class_ddService;
  public get custom_ds_order_class_dd(): app_custom_ds_order_class_ddService {
    if(!this._custom_ds_order_class_dd) {
      this._custom_ds_order_class_dd = this.injector.get(app_custom_ds_order_class_ddService);
    }
    return this._custom_ds_order_class_dd;
  }
  private _custom_ds_order_import_grid: app_custom_ds_order_import_gridService;
  public get custom_ds_order_import_grid(): app_custom_ds_order_import_gridService {
    if(!this._custom_ds_order_import_grid) {
      this._custom_ds_order_import_grid = this.injector.get(app_custom_ds_order_import_gridService);
    }
    return this._custom_ds_order_import_grid;
  }
  private _custom_ds_projects_dd: app_custom_ds_projects_ddService;
  public get custom_ds_projects_dd(): app_custom_ds_projects_ddService {
    if(!this._custom_ds_projects_dd) {
      this._custom_ds_projects_dd = this.injector.get(app_custom_ds_projects_ddService);
    }
    return this._custom_ds_projects_dd;
  }
  private _custom_ds_sales_order_editor: app_custom_ds_sales_order_editorService;
  public get custom_ds_sales_order_editor(): app_custom_ds_sales_order_editorService {
    if(!this._custom_ds_sales_order_editor) {
      this._custom_ds_sales_order_editor = this.injector.get(app_custom_ds_sales_order_editorService);
    }
    return this._custom_ds_sales_order_editor;
  }
  private _custom_ds_udf_allergen_dd: app_custom_ds_udf_allergen_ddService;
  public get custom_ds_udf_allergen_dd(): app_custom_ds_udf_allergen_ddService {
    if(!this._custom_ds_udf_allergen_dd) {
      this._custom_ds_udf_allergen_dd = this.injector.get(app_custom_ds_udf_allergen_ddService);
    }
    return this._custom_ds_udf_allergen_dd;
  }
  private _custom_invoice_report: app_custom_invoice_reportService;
  public get custom_invoice_report(): app_custom_invoice_reportService {
    if(!this._custom_invoice_report) {
      this._custom_invoice_report = this.injector.get(app_custom_invoice_reportService);
    }
    return this._custom_invoice_report;
  }
  private _ds_get_invoice_detail_grid_sum: app_ds_get_invoice_detail_grid_sumService;
  public get ds_get_invoice_detail_grid_sum(): app_ds_get_invoice_detail_grid_sumService {
    if(!this._ds_get_invoice_detail_grid_sum) {
      this._ds_get_invoice_detail_grid_sum = this.injector.get(app_ds_get_invoice_detail_grid_sumService);
    }
    return this._ds_get_invoice_detail_grid_sum;
  }
  private _ds_get_invoice_line_detail_grid: app_ds_get_invoice_line_detail_gridService;
  public get ds_get_invoice_line_detail_grid(): app_ds_get_invoice_line_detail_gridService {
    if(!this._ds_get_invoice_line_detail_grid) {
      this._ds_get_invoice_line_detail_grid = this.injector.get(app_ds_get_invoice_line_detail_gridService);
    }
    return this._ds_get_invoice_line_detail_grid;
  }
  private _ds_get_order_id_by_invoiceid: app_ds_get_order_id_by_invoiceidService;
  public get ds_get_order_id_by_invoiceid(): app_ds_get_order_id_by_invoiceidService {
    if(!this._ds_get_order_id_by_invoiceid) {
      this._ds_get_order_id_by_invoiceid = this.injector.get(app_ds_get_order_id_by_invoiceidService);
    }
    return this._ds_get_order_id_by_invoiceid;
  }
  private _ds_get_owner_billing_address_by_invoiceId: app_ds_get_owner_billing_address_by_invoiceIdService;
  public get ds_get_owner_billing_address_by_invoiceId(): app_ds_get_owner_billing_address_by_invoiceIdService {
    if(!this._ds_get_owner_billing_address_by_invoiceId) {
      this._ds_get_owner_billing_address_by_invoiceId = this.injector.get(app_ds_get_owner_billing_address_by_invoiceIdService);
    }
    return this._ds_get_owner_billing_address_by_invoiceId;
  }
  private _ds_get_owner_opeartional_address_by_invoiceId: app_ds_get_owner_opeartional_address_by_invoiceIdService;
  public get ds_get_owner_opeartional_address_by_invoiceId(): app_ds_get_owner_opeartional_address_by_invoiceIdService {
    if(!this._ds_get_owner_opeartional_address_by_invoiceId) {
      this._ds_get_owner_opeartional_address_by_invoiceId = this.injector.get(app_ds_get_owner_opeartional_address_by_invoiceIdService);
    }
    return this._ds_get_owner_opeartional_address_by_invoiceId;
  }
}

