<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div *ngIf="!$hasMissingRequiredInParams"
          class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template 
                toolbarToolDef="update" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_edit_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="separator1" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <div class="tool-separator"></div>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="create_inventory" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_create_inventory_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="separator2" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <div class="tool-separator"></div>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="on_delete" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_delete($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="separator3" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <div class="tool-separator"></div>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="print" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_print_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="attachments" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_attachments_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized && !$hasMissingRequiredInParams">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden && !filtersets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-inventory_type" *ngIf="!filters.inventory_type.hidden && !filters.inventory_type.removed" 
                                  class="field-container double {{filters.inventory_type.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.inventory_type.styles.style"
                                  [ngClass]="filters.inventory_type.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.inventory_type.label + (filters.inventory_type.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.inventory_type.label}}<span *ngIf="filters.inventory_type.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Inventory-inventory_aggregation_dd_single 
                                  data-cy="selector"
                                  [type]="filters.inventory_type.control.type"
                                  formControlName="inventory_type"
                                  (displayTextChange)="filters.inventory_type.control.displayText=$event"
                                  [placeholder]="filters.inventory_type.control.placeholder"
                                  [styles]="filters.inventory_type.control.styles"
                                  [tooltip]="filters.inventory_type.control.tooltip"
                              >
                              </Inventory-inventory_aggregation_dd_single>
                              <ng-container *ngIf="filters.inventory_type.invalid">
                                <ng-container *ngFor="let error of filters.inventory_type.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
          <div class="hub-tools">
              <app-toolbar [toolbar]="actionbar">
                  <ng-template 
                    toolbarToolDef="assign_containers_button" let-tool>
                    <ng-container  *ngIf="tool.hasButtonsToShow">
                    <button mat-button
                            data-cy="button"
                            class="datex-button"
                            [disabled]="tool.control.readOnly"
                            [ngStyle]="tool.control.styles.style"
                            [ngClass]="tool.control.styles.classes"
                            [matTooltip]="tool.control.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_assign_clicked($event)"
                            >
                      <div class="button-label">
                        <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                          <div class="button-icon">
                            <i data-cy="button-icon" class="datex-default-spinner"></i>
                          </div>
                        </ng-container>
                        <ng-template #showIcon>
                          <div class="button-icon">
                            <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                          </div>
                        </ng-template>
                        <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                      </div>
                    
                    </button>
                    </ng-container>
                  </ng-template>
                  <ng-template 
                    toolbarToolDef="material_substitutions_button" let-tool>
                    <ng-container  *ngIf="tool.hasButtonsToShow">
                    <button mat-button
                            data-cy="button"
                            class="datex-button"
                            [disabled]="tool.control.readOnly"
                            [ngStyle]="tool.control.styles.style"
                            [ngClass]="tool.control.styles.classes"
                            [matTooltip]="tool.control.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_substitutions_clicked($event)"
                            >
                      <div class="button-label">
                        <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                          <div class="button-icon">
                            <i data-cy="button-icon" class="datex-default-spinner"></i>
                          </div>
                        </ng-container>
                        <ng-template #showIcon>
                          <div class="button-icon">
                            <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                          </div>
                        </ng-template>
                        <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                      </div>
                    
                    </button>
                    </ng-container>
                  </ng-template>
                  <ng-template 
                    toolbarToolDef="material_aliases" let-tool>
                    <ng-container  *ngIf="tool.hasButtonsToShow">
                    <button mat-button
                            data-cy="button"
                            class="datex-button"
                            [disabled]="tool.control.readOnly"
                            [ngStyle]="tool.control.styles.style"
                            [ngClass]="tool.control.styles.classes"
                            [matTooltip]="tool.control.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_aliases_clicked($event)"
                            >
                      <div class="button-label">
                        <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                          <div class="button-icon">
                            <i data-cy="button-icon" class="datex-default-spinner"></i>
                          </div>
                        </ng-container>
                        <ng-template #showIcon>
                          <div class="button-icon">
                            <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                          </div>
                        </ng-template>
                        <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                      </div>
                    
                    </button>
                    </ng-container>
                  </ng-template>
              </app-toolbar>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.total_available_amount_widget.hidden && !widgets.total_available_amount_widget.removed" class="widget-container">
            <Inventory-total_available_amount_widget
            #$widgets_total_available_amount_widget 
              [materialId]="$widgets_total_available_amount_widget_inParams_materialId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_available_amount_widget')"
            >
            </Inventory-total_available_amount_widget>
          </div>
          <div *ngIf="!widgets.total_available_base_amount_widget.hidden && !widgets.total_available_base_amount_widget.removed" class="widget-container">
            <Inventory-total_available_base_amount_widget
            #$widgets_total_available_base_amount_widget 
              [materialId]="$widgets_total_available_base_amount_widget_inParams_materialId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_available_base_amount_widget')"
            >
            </Inventory-total_available_base_amount_widget>
          </div>
          <div *ngIf="!widgets.total_gross_weight_widget.hidden && !widgets.total_gross_weight_widget.removed" class="widget-container">
            <Inventory-total_gross_weight_widget
            #$widgets_total_gross_weight_widget 
              [materialId]="$widgets_total_gross_weight_widget_inParams_materialId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_gross_weight_widget')"
            >
            </Inventory-total_gross_weight_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.inventory.hasTabToShow" class="tab" data-cy="tab-inventory">
              <h2 [className]="tabs.inventory.active? 'active': ''" (click)="tabs.inventory.activate()">{{tabs.inventory.title}}</h2>
            </div>
            <div *ngIf="tabs.licenseplates.hasTabToShow" class="tab" data-cy="tab-licenseplates">
              <h2 [className]="tabs.licenseplates.active? 'active': ''" (click)="tabs.licenseplates.activate()">{{tabs.licenseplates.title}}</h2>
            </div>
            <div *ngIf="tabs.tasks.hasTabToShow" class="tab" data-cy="tab-tasks">
              <h2 [className]="tabs.tasks.active? 'active': ''" (click)="tabs.tasks.activate()">{{tabs.tasks.title}}</h2>
            </div>
            <div *ngIf="tabs.packagings.hasTabToShow" class="tab" data-cy="tab-packagings">
              <h2 [className]="tabs.packagings.active? 'active': ''" (click)="tabs.packagings.activate()">{{tabs.packagings.title}}</h2>
            </div>
            <div *ngIf="tabs.material_kits.hasTabToShow" class="tab" data-cy="tab-material_kits">
              <h2 [className]="tabs.material_kits.active? 'active': ''" (click)="tabs.material_kits.activate()">{{tabs.material_kits.title}}</h2>
            </div>
            <div *ngIf="tabs.udfs.hasTabToShow" class="tab" data-cy="tab-udfs">
              <h2 [className]="tabs.udfs.active? 'active': ''" (click)="tabs.udfs.activate()">{{tabs.udfs.title}}</h2>
            </div>
            <div *ngIf="tabs.serialnumbers.hasTabToShow" class="tab" data-cy="tab-serialnumbers">
              <h2 [className]="tabs.serialnumbers.active? 'active': ''" (click)="tabs.serialnumbers.activate()">{{tabs.serialnumbers.title}}</h2>
            </div>
            <div *ngIf="tabs.replenishments.hasTabToShow" class="tab" data-cy="tab-replenishments">
              <h2 [className]="tabs.replenishments.active? 'active': ''" (click)="tabs.replenishments.activate()">{{tabs.replenishments.title}}</h2>
            </div>
            <div *ngIf="tabs.instructions.hasTabToShow" class="tab" data-cy="tab-instructions">
              <h2 [className]="tabs.instructions.active? 'active': ''" (click)="tabs.instructions.activate()">{{tabs.instructions.title}}</h2>
            </div>
          </div>
        
              <ng-container
                *ngIf="tabs.inventory.active">
                  <mat-select (selectionChange)="onTabSelected($event)"
                              *ngIf="subTabGroups.inventory.tabs.length > 1"
                              class="datex-queryselector"
                              [value]="subTabGroups.inventory.activeTab"
                              disableOptionCentering
                              panelClass="dropdown-below datex-dropdownpanel">
                    <mat-option [value]="tab" *ngFor="let tab of subTabGroups.inventory.tabs">{{tab.title}}</mat-option>
                  </mat-select>
                
                  <FootPrintManager-inventory_location_grid
                   *ngIf="tabs.inventory.tabs.inventory_by_location.active"
                  #$tabs_inventory_inventory_by_location
                  [materialIds]="$tabs_inventory_inventory_by_location_inventory_location_grid_inParams_materialIds"
                  ($refreshEvent)="refresh(false, false, '$tabs_inventory_inventory_by_location')">
                  </FootPrintManager-inventory_location_grid>
                  <FootPrintManager-inventory_licenseplate_grid
                   *ngIf="tabs.inventory.tabs.inventory_by_lp.active"
                  #$tabs_inventory_inventory_by_lp
                  [materialIds]="$tabs_inventory_inventory_by_lp_inventory_licenseplate_grid_inParams_materialIds"
                  ($refreshEvent)="refresh(false, false, '$tabs_inventory_inventory_by_lp')">
                  </FootPrintManager-inventory_licenseplate_grid>
                  <FootPrintManager-inventory_lot_grid
                   *ngIf="tabs.inventory.tabs.inventory_by_lot.active"
                  #$tabs_inventory_inventory_by_lot
                  [materialIds]="$tabs_inventory_inventory_by_lot_inventory_lot_grid_inParams_materialIds"
                  ($refreshEvent)="refresh(false, false, '$tabs_inventory_inventory_by_lot')">
                  </FootPrintManager-inventory_lot_grid>
                  <FootPrintManager-inventory_material_grid
                   *ngIf="tabs.inventory.tabs.inventory_by_material.active"
                  #$tabs_inventory_inventory_by_material
                  [materialIds]="$tabs_inventory_inventory_by_material_inventory_material_grid_inParams_materialIds"
                  ($refreshEvent)="refresh(false, false, '$tabs_inventory_inventory_by_material')">
                  </FootPrintManager-inventory_material_grid>
              </ng-container>
              <FootPrintManager-licenseplate_list *ngIf="tabs.licenseplates.active"
              #$tabs_licenseplates
              [materialId]="$tabs_licenseplates_licenseplate_list_inParams_materialId"
              ($refreshEvent)="refresh(false, false, '$tabs_licenseplates')">
              </FootPrintManager-licenseplate_list>
              <FootPrintManager-inventory_tasks_grid *ngIf="tabs.tasks.active"
              #$tabs_tasks
              [materialId]="$tabs_tasks_inventory_tasks_grid_inParams_materialId"
              ($refreshEvent)="refresh(false, false, '$tabs_tasks')">
              </FootPrintManager-inventory_tasks_grid>
              <FootPrintManager-material_packagings_grid *ngIf="tabs.packagings.active"
              #$tabs_packagings
              [materialId]="$tabs_packagings_material_packagings_grid_inParams_materialId"
              ($refreshEvent)="refresh(false, false, '$tabs_packagings')">
              </FootPrintManager-material_packagings_grid>
              <FootPrintManager-material_kits_grid *ngIf="tabs.material_kits.active"
              #$tabs_material_kits
              [material_id]="$tabs_material_kits_material_kits_grid_inParams_material_id"
              [project_id]="$tabs_material_kits_material_kits_grid_inParams_project_id"
              ($refreshEvent)="refresh(false, false, '$tabs_material_kits')">
              </FootPrintManager-material_kits_grid>
              <FootPrintManager-materials_udf_grid *ngIf="tabs.udfs.active"
              #$tabs_udfs
              [materialIds]="$tabs_udfs_materials_udf_grid_inParams_materialIds"
              ($refreshEvent)="refresh(false, false, '$tabs_udfs')">
              </FootPrintManager-materials_udf_grid>
              <FootPrintManager-serialnumbers_grid *ngIf="tabs.serialnumbers.active"
              #$tabs_serialnumbers
              [materialIds]="$tabs_serialnumbers_serialnumbers_grid_inParams_materialIds"
              [archived]="$tabs_serialnumbers_serialnumbers_grid_inParams_archived"
              ($refreshEvent)="refresh(false, false, '$tabs_serialnumbers')">
              </FootPrintManager-serialnumbers_grid>
              <FootPrintManager-locations_replenishments_grid *ngIf="tabs.replenishments.active"
              #$tabs_replenishments
              [materialId]="$tabs_replenishments_locations_replenishments_grid_inParams_materialId"
              ($refreshEvent)="refresh(false, false, '$tabs_replenishments')">
              </FootPrintManager-locations_replenishments_grid>
              <FootPrintManager-instructions_grid *ngIf="tabs.instructions.active"
              #$tabs_instructions
              [entityName]="$tabs_instructions_instructions_grid_inParams_entityName"
              [enabled]="$tabs_instructions_instructions_grid_inParams_enabled"
              [entityKey1]="$tabs_instructions_instructions_grid_inParams_entityKey1"
              ($refreshEvent)="refresh(false, false, '$tabs_instructions')">
              </FootPrintManager-instructions_grid>
        </div>

      </ng-container>
      <div *ngIf="$hasMissingRequiredInParams"
           class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>