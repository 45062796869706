<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"


[rowCanExpand]="true"
>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="send_request" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_send_request_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator1" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="delete_rows" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-unsent_only" *ngIf="!filters.unsent_only.hidden && !filters.unsent_only.removed" 
            class="field-container standard {{filters.unsent_only.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.unsent_only.styles.style"
            [ngClass]="filters.unsent_only.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.unsent_only.label + (filters.unsent_only.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.unsent_only.label}}<span *ngIf="filters.unsent_only.required" class="required-asterisk">*</span>
          </label>
        </div>
        <mat-slide-toggle data-cy="slideToggle" formControlName="unsent_only"
                      color="primary"
                      class="datex-toggle"
                      [ngStyle]="filters.unsent_only.control.styles.style"
                      [ngClass]="filters.unsent_only.control.styles.classes"
                      [matTooltip]="filters.unsent_only.control.tooltip"
                      matTooltipClass="datex-control-tooltip">{{filters.unsent_only.control.label}}</mat-slide-toggle>
        <ng-container *ngIf="filters.unsent_only.invalid">
          <ng-container *ngFor="let error of filters.unsent_only.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="rule_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.rule_id.displayControl.styles.style"
          [ngClass]="row.cells.rule_id.displayControl.styles.classes"
          [matTooltip]="row.cells.rule_id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.rule_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="entity_type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.entity_type.displayControl.styles.style"
          [ngClass]="row.cells.entity_type.displayControl.styles.classes"
          [matTooltip]="row.cells.entity_type.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.entity_type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.status.displayControl.styles.style"
          [ngClass]="row.cells.status.displayControl.styles.classes"
          [matTooltip]="row.cells.status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="to">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.to.displayControl.styles.style"
          [ngClass]="row.cells.to.displayControl.styles.classes"
          [matTooltip]="row.cells.to.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.to.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="cc">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.cc.displayControl.styles.style"
          [ngClass]="row.cells.cc.displayControl.styles.classes"
          [matTooltip]="row.cells.cc.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.cc.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="bcc">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.bcc.displayControl.styles.style"
          [ngClass]="row.cells.bcc.displayControl.styles.classes"
          [matTooltip]="row.cells.bcc.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.bcc.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="subject">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.subject.displayControl.styles.style"
          [ngClass]="row.cells.subject.displayControl.styles.classes"
          [matTooltip]="row.cells.subject.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.subject.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="body">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.body.displayControl.styles.style"
          [ngClass]="row.cells.body.displayControl.styles.classes"
          [matTooltip]="row.cells.body.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.body.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.created_on.displayControl.styles.style"
          [ngClass]="row.cells.created_on.displayControl.styles.classes"
          [matTooltip]="row.cells.created_on.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.created_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="sent_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.sent_on.displayControl.styles.style"
          [ngClass]="row.cells.sent_on.displayControl.styles.classes"
          [matTooltip]="row.cells.sent_on.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.sent_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>

<ng-template expandableRowDef
              let-row>
    <Notifications-email_request_attachments_grid
    [requestId]="row.$rowExpand_Notifications_email_request_attachments_grid_inParams_requestId"
    ($refreshEvent)="row.refresh()">>
  </Notifications-email_request_attachments_grid>
</ng-template>

</datex-grid>
