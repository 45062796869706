import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class LoadContainers_ds_unassigned_orders_ddService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { projectId?: number, orderTypeId?: number, fromDate?: string, toDate?: string, dateType?: string, take?: number, skip?: number, statusIds?: number[], fullTextSearch?: string, warehouseId?: number, orderIds?: number[], ownerId?: number }): 
  Promise<{ result: { Id?: number, CancelThresholdDate?: string, CreatedSysDateTime?: string, FulfillmentDate?: string, LookupCode?: string, ModifiedSysDateTime?: string, Notes?: string, OwnerReference?: string, ReadyToExportDate?: string, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Name?: string }, OrderClass?: { Name?: string }, Project?: { Name?: string, Owner?: { Name?: string } }, PreferredWarehouse?: { Name?: string }, PreferredCarrier?: { Name?: string }, PreferredCarrierServiceType?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { LoadContainerSequence?: number, SealId?: string } }[] }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/LoadContainers/datasources/ds_unassigned_orders_dd/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { projectId?: number, orderTypeId?: number, fromDate?: string, toDate?: string, dateType?: string, take?: number, skip?: number, statusIds?: number[], fullTextSearch?: string, warehouseId?: number, orderIds?: number[], ownerId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CancelThresholdDate?: string, CreatedSysDateTime?: string, FulfillmentDate?: string, LookupCode?: string, ModifiedSysDateTime?: string, Notes?: string, OwnerReference?: string, ReadyToExportDate?: string, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Name?: string }, OrderClass?: { Name?: string }, Project?: { Name?: string, Owner?: { Name?: string } }, PreferredWarehouse?: { Name?: string }, PreferredCarrier?: { Name?: string }, PreferredCarrierServiceType?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { LoadContainerSequence?: number, SealId?: string } }[] }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/LoadContainers/datasources/ds_unassigned_orders_dd/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { projectId?: number, orderTypeId?: number, fromDate?: string, toDate?: string, dateType?: string, take?: number, skip?: number, statusIds?: number[], fullTextSearch?: string, warehouseId?: number, orderIds?: number[], ownerId?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, CancelThresholdDate?: string, CreatedSysDateTime?: string, FulfillmentDate?: string, LookupCode?: string, ModifiedSysDateTime?: string, Notes?: string, OwnerReference?: string, ReadyToExportDate?: string, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Name?: string }, OrderClass?: { Name?: string }, Project?: { Name?: string, Owner?: { Name?: string } }, PreferredWarehouse?: { Name?: string }, PreferredCarrier?: { Name?: string }, PreferredCarrierServiceType?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { LoadContainerSequence?: number, SealId?: string } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LoadContainers/datasources/ds_unassigned_orders_dd/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      projectId?: number, orderTypeId?: number, fromDate?: string, toDate?: string, dateType?: string, take?: number, skip?: number, statusIds?: number[], fullTextSearch?: string, warehouseId?: number, orderIds?: number[], ownerId?: number    }) {
      return false;
    }
}
