import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Owners_owner_library_homeComponent } from './Owners.owner_library_home.component';
import { Owners_owner_project_tester_formComponent } from './Owners.owner_project_tester_form.component';
import { Owners_owner_project_statuses_dd_singleComponent } from './Owners.owner_project_statuses_dd_single.component';
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component';
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component';
import { Owners_owner_project_statuses_dd_multiComponent } from './Owners.owner_project_statuses_dd_multi.component';
import { Owners_owners_dd_multiComponent } from './Owners.owners_dd_multi.component';
import { Owners_projects_dd_multiComponent } from './Owners.projects_dd_multi.component';
import { Owners_owners_by_count_widgetComponent } from './Owners.owners_by_count_widget.component';
import { Owners_owners_by_status_widgetComponent } from './Owners.owners_by_status_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Owners_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Owners: Owners_ShellService = this;

  public openowner_library_home(replaceCurrentView?: boolean) {
    this.logger.log('Owners', 'owner_library_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'Owners_owner_library_home',
        component: Owners_owner_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openowner_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Owners', 'owner_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Owners_owner_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openowner_project_tester_form(replaceCurrentView?: boolean) {
    this.logger.log('Owners', 'owner_project_tester_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Test harness',
        referenceName: 'Owners_owner_project_tester_form',
        component: Owners_owner_project_tester_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openowner_project_tester_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Owners', 'owner_project_tester_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Owners_owner_project_tester_formComponent,
      'Test harness',
      mode,
      dialogSize
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Owners_owner_library_home') {
      this.logger.log('Owners', 'owner_library_home');
      const title = 'Home';
      const component = Owners_owner_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Owners_owner_project_tester_form') {
      this.logger.log('Owners', 'owner_project_tester_form');
      const title = 'Test harness';
      const component = Owners_owner_project_tester_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Owners_owner_project_statuses_dd_single') {
      const title = 'Owner and Project status dropdown';
      const component = Owners_owner_project_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Owners_owners_dd_single') {
      const title = 'Owners dropdown';
      const component = Owners_owners_dd_singleComponent;
      const inParams:{ statusId?: number, projectId?: number } = { statusId: null, projectId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Owners_projects_dd_single') {
      const title = 'Projects dropdown';
      const component = Owners_projects_dd_singleComponent;
      const inParams:{ statusId?: number, ownerId?: number, excludedProjectIds?: number[] } = { statusId: null, ownerId: null, excludedProjectIds: [] };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('excludedProjectIds'))) {
        const paramValueString = params.get('excludedProjectIds');
        inParams.excludedProjectIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Owners_owner_project_statuses_dd_multi') {
      const title = 'Owner and Project status dropdown';
      const component = Owners_owner_project_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Owners_owners_dd_multi') {
      const title = 'Owners dropdown';
      const component = Owners_owners_dd_multiComponent;
      const inParams:{ statusId?: number, projectId?: number } = { statusId: null, projectId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Owners_projects_dd_multi') {
      const title = 'Projects dropdown';
      const component = Owners_projects_dd_multiComponent;
      const inParams:{ statusId?: number, ownerId?: number, excludedProjectIds?: number[] } = { statusId: null, ownerId: null, excludedProjectIds: [] };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('excludedProjectIds'))) {
        const paramValueString = params.get('excludedProjectIds');
        inParams.excludedProjectIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Owners_owners_by_count_widget') {
      const title = 'Total owners';
      const component = Owners_owners_by_count_widgetComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Owners_owners_by_status_widget') {
      this.logger.log('Owners', 'owners_by_status_widget');
      const title = 'Total statuses by owner';
      const component = Owners_owners_by_status_widgetComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
