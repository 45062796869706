import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_ds_get_adjustment_tasksService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { hasTransmissions?: boolean, project?: string, warehouse?: string, modifiedFrom?: string, modifiedTo?: string, material?: string, taskIds?: number[], take?: number, operationTypeIds?: number[] }): 
  Promise<{ result: { Id?: number, ActualInventoryAmount?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualSourceLicensePlateId?: number, ActualSourceLocationId?: number, ActualTargetLicensePlateId?: number, ActualTargetLocationId?: number, CompletedDateTime?: string, DimensionsUomId?: number, Employee?: string, ExpectedSerialNumberId?: number, ExpectedSourceLicensePlateId?: number, ExpectedTargetLicensePlateId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LotId?: number, MaterialId?: number, NetVolume?: number, NetWeight?: number, Notes?: string, OperationCodeId?: number, ProjectId?: number, ReasonCodeId?: number, SerialNumberId?: number, VendorLotId?: number, VolumeUomId?: number, WarehouseId?: number, WeightUomId?: number, Width?: number, ReasonCode?: { Label?: string, LookupCode?: string, Name?: string }, Project?: { LookupCode?: string, Name?: string }, OperationCode?: { Name?: string, OperationTypeId?: number, OperationType?: { Name?: string } }, VolumeUOM?: { Short_name?: string }, WeightUOM?: { Short_name?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_get_adjustment_tasks/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { hasTransmissions?: boolean, project?: string, warehouse?: string, modifiedFrom?: string, modifiedTo?: string, material?: string, taskIds?: number[], take?: number, operationTypeIds?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualInventoryAmount?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualSourceLicensePlateId?: number, ActualSourceLocationId?: number, ActualTargetLicensePlateId?: number, ActualTargetLocationId?: number, CompletedDateTime?: string, DimensionsUomId?: number, Employee?: string, ExpectedSerialNumberId?: number, ExpectedSourceLicensePlateId?: number, ExpectedTargetLicensePlateId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LotId?: number, MaterialId?: number, NetVolume?: number, NetWeight?: number, Notes?: string, OperationCodeId?: number, ProjectId?: number, ReasonCodeId?: number, SerialNumberId?: number, VendorLotId?: number, VolumeUomId?: number, WarehouseId?: number, WeightUomId?: number, Width?: number, ReasonCode?: { Label?: string, LookupCode?: string, Name?: string }, Project?: { LookupCode?: string, Name?: string }, OperationCode?: { Name?: string, OperationTypeId?: number, OperationType?: { Name?: string } }, VolumeUOM?: { Short_name?: string }, WeightUOM?: { Short_name?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_get_adjustment_tasks/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { hasTransmissions?: boolean, project?: string, warehouse?: string, modifiedFrom?: string, modifiedTo?: string, material?: string, taskIds?: number[], take?: number, operationTypeIds?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualInventoryAmount?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualSourceLicensePlateId?: number, ActualSourceLocationId?: number, ActualTargetLicensePlateId?: number, ActualTargetLocationId?: number, CompletedDateTime?: string, DimensionsUomId?: number, Employee?: string, ExpectedSerialNumberId?: number, ExpectedSourceLicensePlateId?: number, ExpectedTargetLicensePlateId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LotId?: number, MaterialId?: number, NetVolume?: number, NetWeight?: number, Notes?: string, OperationCodeId?: number, ProjectId?: number, ReasonCodeId?: number, SerialNumberId?: number, VendorLotId?: number, VolumeUomId?: number, WarehouseId?: number, WeightUomId?: number, Width?: number, ReasonCode?: { Label?: string, LookupCode?: string, Name?: string }, Project?: { LookupCode?: string, Name?: string }, OperationCode?: { Name?: string, OperationTypeId?: number, OperationType?: { Name?: string } }, VolumeUOM?: { Short_name?: string }, WeightUOM?: { Short_name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_get_adjustment_tasks/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      hasTransmissions?: boolean, project?: string, warehouse?: string, modifiedFrom?: string, modifiedTo?: string, material?: string, taskIds?: number[], take?: number, operationTypeIds?: number[]    }) {
      return false;
    }
}
