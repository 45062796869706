import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Owners_delete_owner_flowService } from './Owners.flow.index';
import { Owners_delete_project_flowService } from './Owners.flow.index';
import { Owners_is_owner_deletable_flowService } from './Owners.flow.index';
import { Owners_is_project_deletable_flowService } from './Owners.flow.index';

import { $frontendTypes } from './Owners.frontend.types'

@Injectable({ providedIn: 'root' })
export class Owners_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Owners: Owners_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _delete_owner_flow: Owners_delete_owner_flowService;
  public async delete_owner_flow(inParams: { ownerId: number }): Promise< { reason?: string }> {
    if(!this._delete_owner_flow) { 
      this._delete_owner_flow = this.injector.get(Owners_delete_owner_flowService);
    }
    return this._delete_owner_flow.run(inParams);
  }
   
   

   
 
  private _delete_project_flow: Owners_delete_project_flowService;
  public async delete_project_flow(inParams: { projectId: number }): Promise< { reason?: string }> {
    if(!this._delete_project_flow) { 
      this._delete_project_flow = this.injector.get(Owners_delete_project_flowService);
    }
    return this._delete_project_flow.run(inParams);
  }
   
   

   
 
  private _is_owner_deletable_flow: Owners_is_owner_deletable_flowService;
  public async is_owner_deletable_flow(inParams: { ownerId: number }): Promise< { reason?: string }> {
    if(!this._is_owner_deletable_flow) { 
      this._is_owner_deletable_flow = this.injector.get(Owners_is_owner_deletable_flowService);
    }
    return this._is_owner_deletable_flow.run(inParams);
  }
   
   

   
 
  private _is_project_deletable_flow: Owners_is_project_deletable_flowService;
  public async is_project_deletable_flow(inParams: { projectId: number }): Promise< { reason?: string }> {
    if(!this._is_project_deletable_flow) { 
      this._is_project_deletable_flow = this.injector.get(Owners_is_project_deletable_flowService);
    }
    return this._is_project_deletable_flow.run(inParams);
  }
   
   

   
}
