<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="new_rule" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_new_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="add_rule_from_template" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_add_rule_from_template_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator1" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="delete_rules" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator2" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="field_selector" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button 
                    data-cy="splitbutton"
                    class="datex-button splitbutton" 
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matMenuTriggerFor]="dropdownMenuOne"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip">
              <div class="button-label">
                <div *ngIf="tool.control.icon"
                      class="button-icon">
                  <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                </div>
                <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                <div class="button-icon splitbutton-drop-icon">
                  <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                </div>
              </div>
            </button>
            <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
              <div data-cy="splitbutton-options">
                <div *ngIf="!tool.control.buttons.fs_priority.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_priority.readOnly"
                          [ngStyle]="tool.control.buttons.fs_priority.styles.style"
                          [ngClass]="tool.control.buttons.fs_priority.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_priority.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_changed($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_priority.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_priority.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_priority.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.fs_id.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_id.readOnly"
                          [ngStyle]="tool.control.buttons.fs_id.styles.style"
                          [ngClass]="tool.control.buttons.fs_id.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_id.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_changed($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_id.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_id.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_id.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.fs_name.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_name.readOnly"
                          [ngStyle]="tool.control.buttons.fs_name.styles.style"
                          [ngClass]="tool.control.buttons.fs_name.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_name.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_changed($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_name.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_name.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_name.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.fs_active.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_active.readOnly"
                          [ngStyle]="tool.control.buttons.fs_active.styles.style"
                          [ngClass]="tool.control.buttons.fs_active.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_active.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_changed($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_active.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_active.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_active.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.fs_project_id.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_project_id.readOnly"
                          [ngStyle]="tool.control.buttons.fs_project_id.styles.style"
                          [ngClass]="tool.control.buttons.fs_project_id.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_project_id.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_changed($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_project_id.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_project_id.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_project_id.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.fs_type.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_type.readOnly"
                          [ngStyle]="tool.control.buttons.fs_type.styles.style"
                          [ngClass]="tool.control.buttons.fs_type.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_type.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_changed($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_type.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_type.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_type.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.fs_invoice_term.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_invoice_term.readOnly"
                          [ngStyle]="tool.control.buttons.fs_invoice_term.styles.style"
                          [ngClass]="tool.control.buttons.fs_invoice_term.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_invoice_term.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_changed($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_invoice_term.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_invoice_term.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_invoice_term.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.fs_tax_schedule.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_tax_schedule.readOnly"
                          [ngStyle]="tool.control.buttons.fs_tax_schedule.styles.style"
                          [ngClass]="tool.control.buttons.fs_tax_schedule.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_tax_schedule.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_changed($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_tax_schedule.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_tax_schedule.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_tax_schedule.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.fs_invoice_per_shipment.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_invoice_per_shipment.readOnly"
                          [ngStyle]="tool.control.buttons.fs_invoice_per_shipment.styles.style"
                          [ngClass]="tool.control.buttons.fs_invoice_per_shipment.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_invoice_per_shipment.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_changed($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_invoice_per_shipment.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_invoice_per_shipment.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_invoice_per_shipment.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.fs_invoice_per_warehouse.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_invoice_per_warehouse.readOnly"
                          [ngStyle]="tool.control.buttons.fs_invoice_per_warehouse.styles.style"
                          [ngClass]="tool.control.buttons.fs_invoice_per_warehouse.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_invoice_per_warehouse.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_changed($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_invoice_per_warehouse.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_invoice_per_warehouse.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_invoice_per_warehouse.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.fs_use_existing_invoice.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_use_existing_invoice.readOnly"
                          [ngStyle]="tool.control.buttons.fs_use_existing_invoice.styles.style"
                          [ngClass]="tool.control.buttons.fs_use_existing_invoice.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_use_existing_invoice.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_changed($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_use_existing_invoice.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_use_existing_invoice.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_use_existing_invoice.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.fs_billing_aggregation_strategies.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_billing_aggregation_strategies.readOnly"
                          [ngStyle]="tool.control.buttons.fs_billing_aggregation_strategies.styles.style"
                          [ngClass]="tool.control.buttons.fs_billing_aggregation_strategies.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_billing_aggregation_strategies.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_changed($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_billing_aggregation_strategies.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_billing_aggregation_strategies.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_billing_aggregation_strategies.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.fs_created_on.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_created_on.readOnly"
                          [ngStyle]="tool.control.buttons.fs_created_on.styles.style"
                          [ngClass]="tool.control.buttons.fs_created_on.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_created_on.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_changed($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_created_on.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_created_on.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_created_on.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.fs_created_by.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_created_by.readOnly"
                          [ngStyle]="tool.control.buttons.fs_created_by.styles.style"
                          [ngClass]="tool.control.buttons.fs_created_by.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_created_by.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_changed($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_created_by.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_created_by.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_created_by.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.fs_modified_on.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_modified_on.readOnly"
                          [ngStyle]="tool.control.buttons.fs_modified_on.styles.style"
                          [ngClass]="tool.control.buttons.fs_modified_on.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_modified_on.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_changed($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_modified_on.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_modified_on.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_modified_on.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.fs_modified_by.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_modified_by.readOnly"
                          [ngStyle]="tool.control.buttons.fs_modified_by.styles.style"
                          [ngClass]="tool.control.buttons.fs_modified_by.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_modified_by.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_changed($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_modified_by.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_modified_by.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_modified_by.label}}</div>
                    </div>
                  </button>
                </div>
              </div>
            </mat-menu>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="priority">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.priority.displayControl.styles.style"
          [ngClass]="row.cells.priority.displayControl.styles.classes"
          [matTooltip]="row.cells.priority.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.priority.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="raise_priority">

    <ng-template gridCellDisplayControlDef let-row>
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="row.cells.raise_priority.displayControl.readOnly"
            [ngStyle]="row.cells.raise_priority.displayControl.styles.style"
            [ngClass]="row.cells.raise_priority.displayControl.styles.classes"
            [matTooltip]="row.cells.raise_priority.displayControl.tooltip"
            matTooltipClass="datex-control-tooltip"
            (click)="row.on_raise_priority_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="row.cells.raise_priority.displayControl.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{row.cells.raise_priority.displayControl.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{row.cells.raise_priority.displayControl.label}}</div>
      </div>
    
    </button>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lower_priority">

    <ng-template gridCellDisplayControlDef let-row>
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="row.cells.lower_priority.displayControl.readOnly"
            [ngStyle]="row.cells.lower_priority.displayControl.styles.style"
            [ngClass]="row.cells.lower_priority.displayControl.styles.classes"
            [matTooltip]="row.cells.lower_priority.displayControl.tooltip"
            matTooltipClass="datex-control-tooltip"
            (click)="row.on_lower_priority_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="row.cells.lower_priority.displayControl.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{row.cells.lower_priority.displayControl.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{row.cells.lower_priority.displayControl.label}}</div>
      </div>
    
    </button>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.id.displayControl.styles.style"
          [ngClass]="row.cells.id.displayControl.styles.classes"
          [matTooltip]="row.cells.id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.name.displayControl.styles.style"
          [ngClass]="row.cells.name.displayControl.styles.classes"
          [matTooltip]="row.cells.name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="active">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['active_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.active.displayControl.styles.style"
                  [ngClass]="row.cells.active.displayControl.styles.classes"
                  [matTooltip]="row.cells.active.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.active.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="projectId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.projectId.displayControl.styles.style"
          [ngClass]="row.cells.projectId.displayControl.styles.classes"
          [matTooltip]="row.cells.projectId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.projectId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.type.displayControl.styles.style"
          [ngClass]="row.cells.type.displayControl.styles.classes"
          [matTooltip]="row.cells.type.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="term">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.term.displayControl.styles.style"
          [ngClass]="row.cells.term.displayControl.styles.classes"
          [matTooltip]="row.cells.term.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.term.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="tax_schedule">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.tax_schedule.displayControl.styles.style"
          [ngClass]="row.cells.tax_schedule.displayControl.styles.classes"
          [matTooltip]="row.cells.tax_schedule.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.tax_schedule.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="group_by">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.group_by.displayControl.styles.style"
          [ngClass]="row.cells.group_by.displayControl.styles.classes"
          [matTooltip]="row.cells.group_by.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.group_by.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="use_existing_invoice">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['use_existing_invoice_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.use_existing_invoice.displayControl.styles.style"
                  [ngClass]="row.cells.use_existing_invoice.displayControl.styles.classes"
                  [matTooltip]="row.cells.use_existing_invoice.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.use_existing_invoice.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="billing_aggregation_strategies">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.billing_aggregation_strategies.displayControl.styles.style"
          [ngClass]="row.cells.billing_aggregation_strategies.displayControl.styles.classes"
          [matTooltip]="row.cells.billing_aggregation_strategies.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.billing_aggregation_strategies.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="createdOn">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.createdOn.displayControl.styles.style"
          [ngClass]="row.cells.createdOn.displayControl.styles.classes"
          [matTooltip]="row.cells.createdOn.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.createdOn.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="createdBy">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.createdBy.displayControl.styles.style"
          [ngClass]="row.cells.createdBy.displayControl.styles.classes"
          [matTooltip]="row.cells.createdBy.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.createdBy.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="modifiedOn">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.modifiedOn.displayControl.styles.style"
          [ngClass]="row.cells.modifiedOn.displayControl.styles.classes"
          [matTooltip]="row.cells.modifiedOn.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.modifiedOn.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="modifiedBy">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.modifiedBy.displayControl.styles.style"
          [ngClass]="row.cells.modifiedBy.displayControl.styles.classes"
          [matTooltip]="row.cells.modifiedBy.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.modifiedBy.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
