import { Inject, Injectable, Injector } from '@angular/core';


import { ShipTheory_create_shipment_manifestService } from './ShipTheory.flow.index';
import { ShipTheory_get_carrier_service_idService } from './ShipTheory.flow.index';
import { ShipTheory_get_items_contentService } from './ShipTheory.flow.index';
import { ShipTheory_get_tokenService } from './ShipTheory.flow.index';
import { ShipTheory_is_shipment_manifestable_flowService } from './ShipTheory.flow.index';

import { $frontendTypes } from './ShipTheory.frontend.types'

@Injectable({ providedIn: 'root' })
export class ShipTheory_FlowService {

  constructor(
    private injector: Injector
  ) {
  }

  public ShipTheory: ShipTheory_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _create_shipment_manifest: ShipTheory_create_shipment_manifestService;
  public async create_shipment_manifest(inParams: { referenceNumber?: string, carrier: string, service: string, containerCount?: number, weight?: number, fromAddress: any, toAddress: any, value?: number, products?: any[] }): Promise< { reason?: string, label?: string, tracking?: string }> {
    if(!this._create_shipment_manifest) { 
      this._create_shipment_manifest = this.injector.get(ShipTheory_create_shipment_manifestService);
    }
    return this._create_shipment_manifest.run(inParams);
  }
   
   

   
 
  private _get_carrier_service_id: ShipTheory_get_carrier_service_idService;
  public async get_carrier_service_id(inParams: { carrier_name: string, carrier_service_name: string }): Promise< { carrier_service_id?: number, reason?: string, carrier_id?: number }> {
    if(!this._get_carrier_service_id) { 
      this._get_carrier_service_id = this.injector.get(ShipTheory_get_carrier_service_idService);
    }
    return this._get_carrier_service_id.run(inParams);
  }
   
   

   
 
  private _get_items_content: ShipTheory_get_items_contentService;
  public async get_items_content(inParams: { shipmentId: number }): Promise< { error?: string, content?: any[] }> {
    if(!this._get_items_content) { 
      this._get_items_content = this.injector.get(ShipTheory_get_items_contentService);
    }
    return this._get_items_content.run(inParams);
  }
   
   

   
 
  private _get_token: ShipTheory_get_tokenService;
  public async get_token(inParams: {  }): Promise< { token?: string, reason?: string }> {
    if(!this._get_token) { 
      this._get_token = this.injector.get(ShipTheory_get_tokenService);
    }
    return this._get_token.run(inParams);
  }
   
   

   
 
  private _is_shipment_manifestable_flow: ShipTheory_is_shipment_manifestable_flowService;
  public async is_shipment_manifestable_flow(inParams: { shipmentId: number }): Promise< { status?: string }> {
    if(!this._is_shipment_manifestable_flow) { 
      this._is_shipment_manifestable_flow = this.injector.get(ShipTheory_is_shipment_manifestable_flowService);
    }
    return this._is_shipment_manifestable_flow.run(inParams);
  }
   
   

   
}
