import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { LaborManagement_assign_user_to_task_flowService } from './LaborManagement.flow.index';
import { LaborManagement_assign_user_to_work_class_flowService } from './LaborManagement.flow.index';
import { LaborManagement_create_work_class_flowService } from './LaborManagement.flow.index';
import { LaborManagement_delete_work_class_flowService } from './LaborManagement.flow.index';
import { LaborManagement_unassign_task_flowService } from './LaborManagement.flow.index';
import { LaborManagement_unassign_user_from_work_class_flowService } from './LaborManagement.flow.index';

import { $frontendTypes } from './LaborManagement.frontend.types'

@Injectable({ providedIn: 'root' })
export class LaborManagement_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public LaborManagement: LaborManagement_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _assign_user_to_task_flow: LaborManagement_assign_user_to_task_flowService;
  public async assign_user_to_task_flow(inParams: { taskId: number, userId: string }): Promise< { reasons?: string[], taskAssignmentId?: number, reflectedTaskId?: number }> {
    if(!this._assign_user_to_task_flow) { 
      this._assign_user_to_task_flow = this.injector.get(LaborManagement_assign_user_to_task_flowService);
    }
    return this._assign_user_to_task_flow.run(inParams);
  }
   
   

   
 
  private _assign_user_to_work_class_flow: LaborManagement_assign_user_to_work_class_flowService;
  public async assign_user_to_work_class_flow(inParams: { workClassId: number, userId: string }): Promise< { reasons?: string[] }> {
    if(!this._assign_user_to_work_class_flow) { 
      this._assign_user_to_work_class_flow = this.injector.get(LaborManagement_assign_user_to_work_class_flowService);
    }
    return this._assign_user_to_work_class_flow.run(inParams);
  }
   
   

   
 
  private _create_work_class_flow: LaborManagement_create_work_class_flowService;
  public async create_work_class_flow(inParams: { lookupcode?: string, name: string, priority?: number, description?: string }): Promise< { reasons?: string[], workClassId?: number }> {
    if(!this._create_work_class_flow) { 
      this._create_work_class_flow = this.injector.get(LaborManagement_create_work_class_flowService);
    }
    return this._create_work_class_flow.run(inParams);
  }
   
   

   
 
  private _delete_work_class_flow: LaborManagement_delete_work_class_flowService;
  public async delete_work_class_flow(inParams: { workClassId: number }): Promise< { reasons?: string[], reflectedWorkClassId?: number }> {
    if(!this._delete_work_class_flow) { 
      this._delete_work_class_flow = this.injector.get(LaborManagement_delete_work_class_flowService);
    }
    return this._delete_work_class_flow.run(inParams);
  }
   
   

   
 
  private _unassign_task_flow: LaborManagement_unassign_task_flowService;
  public async unassign_task_flow(inParams: { taskId: number, taskAssignmentIds?: number[] }): Promise< { reasons?: string[], taskId?: number }> {
    if(!this._unassign_task_flow) { 
      this._unassign_task_flow = this.injector.get(LaborManagement_unassign_task_flowService);
    }
    return this._unassign_task_flow.run(inParams);
  }
   
   

   
 
  private _unassign_user_from_work_class_flow: LaborManagement_unassign_user_from_work_class_flowService;
  public async unassign_user_from_work_class_flow(inParams: { workClassId: number, userId: string }): Promise< { reasons?: string[] }> {
    if(!this._unassign_user_from_work_class_flow) { 
      this._unassign_user_from_work_class_flow = this.injector.get(LaborManagement_unassign_user_from_work_class_flowService);
    }
    return this._unassign_user_from_work_class_flow.run(inParams);
  }
   
   

   
}
