import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class ExcelInventoryImport_ds_inventory_import_staging_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { import_status?: string, full_text_search?: string, $top?: number, $skip?: number }): Promise<{ result?: { Id?: string, ImportStatus?: string, ImportCreatedDate?: string, ImportCompletedDate?: string, ImportErrorMessage?: string, Owner?: string, Project?: string, Warehouse?: string, Location?: string, LicensePlate?: string, Material?: string, Lot?: string, VendorLot?: string, Vat?: string, Packaging?: string, PackagedAmount?: string, SerialNumber?: string, NetWeight?: string, GrossWeight?: string, WeightUOM?: string, LotStatus?: string, ReasonCode?: string, ManufactureDate?: string, ExpirationDate?: string, ReceiveDate?: string, ContainerTypeName?: string, ContainerTypeWeight?: string, UDF01Name?: string, UDF01Value?: string, UDF01Entity?: string, UDF02Name?: string, UDF02Value?: string, UDF02Entity?: string, UDF03Name?: string, UDF03Value?: string, UDF03Entity?: string, UDF04Name?: string, UDF04Value?: string, UDF04Entity?: string, UDF05Name?: string, UDF05Value?: string, UDF05Entity?: string, LicensePlateStatus?: string }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/ExcelInventoryImport/datasources/ds_inventory_import_staging_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { import_status?: string, full_text_search?: string, $keys: string[] }): Promise<{ result?: { Id?: string, ImportStatus?: string, ImportCreatedDate?: string, ImportCompletedDate?: string, ImportErrorMessage?: string, Owner?: string, Project?: string, Warehouse?: string, Location?: string, LicensePlate?: string, Material?: string, Lot?: string, VendorLot?: string, Vat?: string, Packaging?: string, PackagedAmount?: string, SerialNumber?: string, NetWeight?: string, GrossWeight?: string, WeightUOM?: string, LotStatus?: string, ReasonCode?: string, ManufactureDate?: string, ExpirationDate?: string, ReceiveDate?: string, ContainerTypeName?: string, ContainerTypeWeight?: string, UDF01Name?: string, UDF01Value?: string, UDF01Entity?: string, UDF02Name?: string, UDF02Value?: string, UDF02Entity?: string, UDF03Name?: string, UDF03Value?: string, UDF03Entity?: string, UDF04Name?: string, UDF04Value?: string, UDF04Entity?: string, UDF05Name?: string, UDF05Value?: string, UDF05Entity?: string, LicensePlateStatus?: string }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelInventoryImport/datasources/ds_inventory_import_staging_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      import_status?: string, full_text_search?: string    }) {
      return false;
    }
}
