<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"
[rowCanExpand]="true"
>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="delete_selected" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="application_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.application_name.displayControl.styles.style"
          [ngClass]="row.cells.application_name.displayControl.styles.classes"
          [matTooltip]="row.cells.application_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.application_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['application_name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.application_name.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.application_name.editControl.placeholder}}"
            [ngStyle]="row.cells.application_name.editControl.styles.style"
            [ngClass]="row.cells.application_name.editControl.styles.classes"
            [matTooltip]="row.cells.application_name.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="alert_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.alert_name.displayControl.styles.style"
          [ngClass]="row.cells.alert_name.displayControl.styles.classes"
          [matTooltip]="row.cells.alert_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.alert_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['alert_name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.alert_name.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.alert_name.editControl.placeholder}}"
            [ngStyle]="row.cells.alert_name.editControl.styles.style"
            [ngClass]="row.cells.alert_name.editControl.styles.classes"
            [matTooltip]="row.cells.alert_name.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="level">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.level.displayControl.styles.style"
          [ngClass]="row.cells.level.displayControl.styles.classes"
          [matTooltip]="row.cells.level.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.level.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['level_edit']"
            matInput
            numberBox
            [format]="row.cells.level.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.level.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.level.editControl.placeholder}}"
            [ngStyle]="row.cells.level.editControl.styles.style"
            [ngClass]="row.cells.level.editControl.styles.classes"
            [matTooltip]="row.cells.level.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="enabled">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-slide-toggle data-cy="slideToggle" [formControl]="row.formGroup.controls['enabled_display']"
                  color="primary"
                  class="datex-toggle"
                  [ngStyle]="row.cells.enabled.displayControl.styles.style"
                  [ngClass]="row.cells.enabled.displayControl.styles.classes"
                  [matTooltip]="row.cells.enabled.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.enabled.displayControl.label}}</mat-slide-toggle>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-slide-toggle data-cy="slideToggle" [formControl]="row.formGroup.controls['enabled_edit']"
                  color="primary"
                  class="datex-toggle"
                  [ngStyle]="row.cells.enabled.editControl.styles.style"
                  [ngClass]="row.cells.enabled.editControl.styles.classes"
                  [matTooltip]="row.cells.enabled.editControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.enabled.editControl.label}}</mat-slide-toggle>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="to">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.to.displayControl.styles.style"
          [ngClass]="row.cells.to.displayControl.styles.classes"
          [matTooltip]="row.cells.to.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.to.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" [formControl]="row.formGroup.controls['to_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.to.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.to.editControl.placeholder}}"
            [ngStyle]="row.cells.to.editControl.styles.style"
            [ngClass]="row.cells.to.editControl.styles.classes"
            [matTooltip]="row.cells.to.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> </textarea>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="cc">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.cc.displayControl.styles.style"
          [ngClass]="row.cells.cc.displayControl.styles.classes"
          [matTooltip]="row.cells.cc.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.cc.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" [formControl]="row.formGroup.controls['cc_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.cc.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.cc.editControl.placeholder}}"
            [ngStyle]="row.cells.cc.editControl.styles.style"
            [ngClass]="row.cells.cc.editControl.styles.classes"
            [matTooltip]="row.cells.cc.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> </textarea>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="bcc">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.bcc.displayControl.styles.style"
          [ngClass]="row.cells.bcc.displayControl.styles.classes"
          [matTooltip]="row.cells.bcc.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.bcc.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" [formControl]="row.formGroup.controls['bcc_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.bcc.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.bcc.editControl.placeholder}}"
            [ngStyle]="row.cells.bcc.editControl.styles.style"
            [ngClass]="row.cells.bcc.editControl.styles.classes"
            [matTooltip]="row.cells.bcc.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> </textarea>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="subject">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.subject.displayControl.styles.style"
          [ngClass]="row.cells.subject.displayControl.styles.classes"
          [matTooltip]="row.cells.subject.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.subject.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['subject_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.subject.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.subject.editControl.placeholder}}"
            [ngStyle]="row.cells.subject.editControl.styles.style"
            [ngClass]="row.cells.subject.editControl.styles.classes"
            [matTooltip]="row.cells.subject.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="pdf">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-slide-toggle data-cy="slideToggle" [formControl]="row.formGroup.controls['pdf_display']"
                  color="primary"
                  class="datex-toggle"
                  [ngStyle]="row.cells.pdf.displayControl.styles.style"
                  [ngClass]="row.cells.pdf.displayControl.styles.classes"
                  [matTooltip]="row.cells.pdf.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.pdf.displayControl.label}}</mat-slide-toggle>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-slide-toggle data-cy="slideToggle" [formControl]="row.formGroup.controls['pdf_edit']"
                  color="primary"
                  class="datex-toggle"
                  [ngStyle]="row.cells.pdf.editControl.styles.style"
                  [ngClass]="row.cells.pdf.editControl.styles.classes"
                  [matTooltip]="row.cells.pdf.editControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.pdf.editControl.label}}</mat-slide-toggle>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.created_on.displayControl.styles.style"
          [ngClass]="row.cells.created_on.displayControl.styles.classes"
          [matTooltip]="row.cells.created_on.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.created_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_by">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.created_by.displayControl.styles.style"
          [ngClass]="row.cells.created_by.displayControl.styles.classes"
          [matTooltip]="row.cells.created_by.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.created_by.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="modified_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.modified_on.displayControl.styles.style"
          [ngClass]="row.cells.modified_on.displayControl.styles.classes"
          [matTooltip]="row.cells.modified_on.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.modified_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="modified_by">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.modified_by.displayControl.styles.style"
          [ngClass]="row.cells.modified_by.displayControl.styles.classes"
          [matTooltip]="row.cells.modified_by.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.modified_by.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>

<ng-template expandableRowDef
              let-row>
    <Notifications-alerts_filters_grid
    [application_name]="row.$rowExpand_Notifications_alerts_filters_grid_inParams_application_name"
    [alert_id]="row.$rowExpand_Notifications_alerts_filters_grid_inParams_alert_id"
    ($refreshEvent)="row.refresh()">>
  </Notifications-alerts_filters_grid>
</ng-template>

</datex-grid>
