import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_ds_get_invoice_lines_by_invoice_idService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { invoice_id?: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Description?: string, DiscountAmount?: number, FreightCharge?: number, LineCost?: number, LineNumber?: number, LinePrice?: number, LineTotal?: number, MinimumCharge?: number, ModifiedSysDateTime?: string, ModifiedSysUser?: string, NominalDiscount?: number, Notes?: string, PercentDiscount?: number, Quantity?: number, TaxAmount?: number, TaxScheduleId?: number, UnitCost?: number, UnitPrice?: number, BillingCode?: { Name?: string, AccountSplits?: { Description?: string, Sequence?: number, Value?: number, GeneralLedgerAccount?: { Name?: string } }[] } }[] }> 
  {
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_get_invoice_lines_by_invoice_id/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { invoice_id?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Description?: string, DiscountAmount?: number, FreightCharge?: number, LineCost?: number, LineNumber?: number, LinePrice?: number, LineTotal?: number, MinimumCharge?: number, ModifiedSysDateTime?: string, ModifiedSysUser?: string, NominalDiscount?: number, Notes?: string, PercentDiscount?: number, Quantity?: number, TaxAmount?: number, TaxScheduleId?: number, UnitCost?: number, UnitPrice?: number, BillingCode?: { Name?: string, AccountSplits?: { Description?: string, Sequence?: number, Value?: number, GeneralLedgerAccount?: { Name?: string } }[] } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_get_invoice_lines_by_invoice_id/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { invoice_id?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Description?: string, DiscountAmount?: number, FreightCharge?: number, LineCost?: number, LineNumber?: number, LinePrice?: number, LineTotal?: number, MinimumCharge?: number, ModifiedSysDateTime?: string, ModifiedSysUser?: string, NominalDiscount?: number, Notes?: string, PercentDiscount?: number, Quantity?: number, TaxAmount?: number, TaxScheduleId?: number, UnitCost?: number, UnitPrice?: number, BillingCode?: { Name?: string, AccountSplits?: { Description?: string, Sequence?: number, Value?: number, GeneralLedgerAccount?: { Name?: string } }[] } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_get_invoice_lines_by_invoice_id/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      invoice_id?: number    }) {
      return false;
    }
}
