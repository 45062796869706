import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



interface IFootPrintManager_warehouse_transfers_gridComponentEntity {
Id?: number, DateCreated?: string, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string } }, SourceOutboundOrder?: { Id?: number, LookupCode?: string }, TargetInboundOrder?: { Id?: number, LookupCode?: string }, SourceWarehouse?: { Id?: number, Name?: string }, TargetWarehouse?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string }}

interface IFootPrintManager_warehouse_transfers_gridComponentInParams {
  warehouseIds?: number[], statusIds?: number[]}


class FootPrintManager_warehouse_transfers_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_warehouse_transfers_gridComponent;
  entity: IFootPrintManager_warehouse_transfers_gridComponentEntity;

  vars: { canDelete?: boolean, canCancel?: boolean } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    lookupcode: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    project: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    date_created: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    outbound_order: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    source_warehouse: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    inbound_order: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    target_warehouse: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_warehouse_transfers_gridComponent, entity: IFootPrintManager_warehouse_transfers_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_warehouse_transfers_gridComponent, entity?: IFootPrintManager_warehouse_transfers_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      warehouseIds:  $grid.inParams.warehouseIds ,
      statusIds:  $grid.inParams.statusIds ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.WarehouseTransfers.ds_warehouse_transfers_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status?.Name;
    (this.cells.project.displayControl as TextModel).text = $row.entity.Project?.LookupCode;
    (this.cells.date_created.displayControl as TextModel).text = $row.entity.DateCreated?.toString();
    (this.cells.outbound_order.displayControl as TextModel).text = $row.entity.SourceOutboundOrder?.LookupCode;
    (this.cells.source_warehouse.displayControl as TextModel).text = $row.entity.SourceWarehouse?.Name;
    (this.cells.inbound_order.displayControl as TextModel).text = $row.entity.TargetInboundOrder?.LookupCode;
    (this.cells.target_warehouse.displayControl as TextModel).text = $row.entity.TargetWarehouse?.Name;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'lookupcode' : {
        this.on_lookupcode_clicked();
        break;
      }
      case 'outbound_order' : {
        this.on_outbound_order_clicked();
        break;
      }
      case 'inbound_order' : {
        this.on_inbound_order_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_lookupcode_clicked(event = null) {
    return this.on_lookupcode_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lookupcode_clickedInternal(
    $row: FootPrintManager_warehouse_transfers_gridComponentRowModel,
  $grid: FootPrintManager_warehouse_transfers_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $shell.FootPrintManager.openwarehouse_transfer_editor({ warehouseTransferId: $row.entity.Id });
  }
  on_outbound_order_clicked(event = null) {
    return this.on_outbound_order_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_outbound_order_clickedInternal(
    $row: FootPrintManager_warehouse_transfers_gridComponentRowModel,
  $grid: FootPrintManager_warehouse_transfers_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  $shell.FootPrintManager.openwarehouse_transfer_outbound_order_editor({ warehouseTransferId: $row.entity.Id, orderId: $row.entity.SourceOutboundOrder.Id });
  }
  on_inbound_order_clicked(event = null) {
    return this.on_inbound_order_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_inbound_order_clickedInternal(
    $row: FootPrintManager_warehouse_transfers_gridComponentRowModel,
  $grid: FootPrintManager_warehouse_transfers_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $shell.FootPrintManager.openwarehouse_transfer_inbound_order_editor({ warehouseTransferId: $row.entity.Id, orderId: $row.entity.TargetInboundOrder.Id });
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_warehouse_transfers_gridComponentRowModel,
  $grid: FootPrintManager_warehouse_transfers_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let stateDetails = (await $flows.WarehouseTransfers.get_warehouse_transfer_status_flow({ warehouse_transfer_id: $row.entity.Id }));
  
  $row.cells.status.displayControl.text = stateDetails?.state_description ?? $row.entity.Status.Name;
  
  // Set row variables
  $row.vars.canDelete = stateDetails.state <= 1; // Hasn't been processed
  $row.vars.canCancel = stateDetails.eligibilities.is_transfer_cancellable;
  
  // Format date
  $row.cells.date_created.displayControl.text = ($utils.date.format($row.cells.date_created.displayControl.text, `${$settings.FootPrintManager.DateFormat} ${$settings.FootPrintManager.TimeFormat.toUpperCase() === '24 HOUR' ? 'HH:mm' : 'LT'}`));
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-warehouse_transfers_grid',
  templateUrl: './FootPrintManager.warehouse_transfers_grid.component.html'
})
export class FootPrintManager_warehouse_transfers_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_warehouse_transfers_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_warehouse_transfers_gridComponentInParams = { warehouseIds: [], statusIds: [] };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Transfer', false, false, null),       status: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       project: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),       date_created: new GridHeaderModel(new HeaderStyles(null, null), 'Created on', false, false, null),       outbound_order: new GridHeaderModel(new HeaderStyles(null, null), 'Outbound order', false, false, null),       source_warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'Source warehouse', false, false, null),       inbound_order: new GridHeaderModel(new HeaderStyles(null, null), 'Inbound order', false, false, null),       target_warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'Target warehouse', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_warehouse_transfers_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('warehouseIds') set $inParams_warehouseIds(value: any) {
    this.inParams['warehouseIds'] = value;
  }
  get $inParams_warehouseIds(): any {
    return this.inParams['warehouseIds'] ;
  }
  @Input('statusIds') set $inParams_statusIds(value: any) {
    this.inParams['statusIds'] = value;
  }
  get $inParams_statusIds(): any {
    return this.inParams['statusIds'] ;
  }

  topToolbar = {
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false),
      delete_transfer: new ToolModel(new ButtonModel('delete_transfer', new ButtonStyles(null, null), false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      print: new ToolModel(new ButtonModel('print', new ButtonStyles(null, null), false, false, ' ', 'icon-ic_fluent_print_20_regular', null)
    , false),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, false, ' ', 'icon-ic_fluent_attach_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Warehouse transfers';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 10;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      warehouseIds:  $grid.inParams.warehouseIds ,
      statusIds:  $grid.inParams.statusIds ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
    const data = await this.datasources.WarehouseTransfers.ds_warehouse_transfers_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_warehouse_transfers_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_warehouse_transfers_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.delete_transfer.control.readOnly = true;
  $grid.topToolbar.cancel.control.readOnly = true;
  $grid.topToolbar.attachments.control.readOnly = true;
  
  // One row selected
  if ($grid.selectedRows.length === 1) {
      $grid.topToolbar.attachments.control.readOnly = false;
  }
  
  if ($grid.selectedRows.length > 0) {
      // Can delete
      if (!$grid.selectedRows.find(r => !r.vars.canDelete)) {
          $grid.topToolbar.delete_transfer.control.readOnly = false;
      }
  
      if (!$grid.selectedRows.find(r => !r.vars.canCancel)) {
          $grid.topToolbar.cancel.control.readOnly = false;
      }
  }
  }
  on_delete_transfer_clicked(event = null) {
    return this.on_delete_transfer_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_transfer_clickedInternal(
    $grid: FootPrintManager_warehouse_transfers_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      let isConfirmed = (await $shell.FootPrintManager.openConfirmationDialog(
          'Delete warehouse transfers',
          `Are you sure you want to delete ${$grid.selectedRows.length > 5 ?
              `the ${$grid.selectedRows.length} selected warehouse transfers` :
              `warehouse transfers ${(await $flows.Utilities.grammar_format_string_array_flow({ values: $grid.selectedRows.map(r => r.entity.LookupCode) })).formattedValue}`
          }? Once deleted, they cannot be recovered.`,
          'Confirm',
          'Cancel'
      ));
  
      if (isConfirmed) {
          var errors: string[] = [];
          let successCount = 0;
  
          for (let row of $grid.selectedRows) {
              let result = (await $flows.WarehouseTransfers.delete_warehouse_transfer_flow({ warehouseTransferId: row.entity.Id }));
  
              if ($utils.isDefined(result?.reasons)) {
                  errors.push(`Warehouse transfer ${row.entity.LookupCode} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons})).formattedValue}`);
              }
              else {
                  successCount++;
              }
          }
  
          if ($utils.isDefined(errors)) {
              if (successCount === 0) {
                  throw new Error(`Failed to delete warehouse transfers`);
              }
              else {
                  throw new Error(`Successfully deleted ${successCount} of the ${$grid.selectedRows.length} selected warehouse transfers.`);
              }
          }
  
          if (successCount > 0) {
              await $grid.refresh();
          }
      }
  }
  catch (error) {
      let targetError = error;
  
      while ($utils.isDefined(targetError?.error)) {
          targetError = targetError.error;
      }
  
      $shell.FootPrintManager.openErrorDialog('Error deleting warehouse transfers', error.message, errors);
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $grid: FootPrintManager_warehouse_transfers_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      let isConfirmed = (await $shell.FootPrintManager.openConfirmationDialog(
          'Cancel warehouse transfers',
          `Are you sure you want to cancel ${$grid.selectedRows.length > 5 ?
              `the ${$grid.selectedRows.length} selected warehouse transfers` :
              `warehouse transfers ${(await $flows.Utilities.grammar_format_string_array_flow({ values: $grid.selectedRows.map(r => r.entity.LookupCode) })).formattedValue}`
          }? Once cancelled, they will no longer be available for completion.`,
          'Confirm',
          'Cancel'
      ));
  
      if (isConfirmed) {
          var errors: string[] = [];
          let successCount = 0;
  
          for (let row of $grid.selectedRows) {
              let result = (await $flows.WarehouseTransfers.cancel_warehouse_transfer_flow({ warehouseTransferId: row.entity.Id }));
  
              if ($utils.isDefined(result?.reasons)) {
                  errors.push(`Warehouse transfer ${row.entity.LookupCode} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons})).formattedValue}`);
              }
              else {
                  successCount++;
              }
          }
  
          if ($utils.isDefined(errors)) {
              if (successCount === 0) {
                  throw new Error(`Failed to cancel warehouse transfers`);
              }
              else {
                  throw new Error(`Successfully cancelled ${successCount} of the ${$grid.selectedRows.length} selected warehouse transfers.`);
              }
          }
  
          if (successCount > 0) {
              await $grid.refresh();
          }
      }
  }
  catch (error) {
      let targetError = error;
  
      while ($utils.isDefined(targetError?.error)) {
          targetError = targetError.error;
      }
  
      $shell.FootPrintManager.openErrorDialog('Error cancelling warehouse transfers', error.message, errors);
  }
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $grid: FootPrintManager_warehouse_transfers_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootPrintManager.openInfoDialog('Not yet implemented', 'The print functionality for this screen has not yet been implemented.');
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $grid: FootPrintManager_warehouse_transfers_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($grid.selectedRows.length === 1) {
      await $shell.FootPrintManager.openentity_attachments_gridDialog({ entityType: 'WarehouseTransfer', entityKeys: [{ name: 'Id', value: $grid.selectedRows[0].entity.Id }]});
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
