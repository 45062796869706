import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SalesOrders_ds_manual_allocations_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { waveId?: number, orderId?: number }): 
  Promise<{ result: { Id?: number, ExpectedPackagedAmount?: number, OrderLineNumber?: number, WarehouseId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string }, Material?: { Id?: number, LookupCode?: string, Name?: string }, Lot?: { Id?: number, LookupCode?: string }, VendorLot?: { Id?: number, ExpirationDate?: string, ManufactureDate?: string }, ExpectedPackagedPack?: { ShortName?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_manual_allocations_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { waveId?: number, orderId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ExpectedPackagedAmount?: number, OrderLineNumber?: number, WarehouseId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string }, Material?: { Id?: number, LookupCode?: string, Name?: string }, Lot?: { Id?: number, LookupCode?: string }, VendorLot?: { Id?: number, ExpirationDate?: string, ManufactureDate?: string }, ExpectedPackagedPack?: { ShortName?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_manual_allocations_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { waveId?: number, orderId?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ExpectedPackagedAmount?: number, OrderLineNumber?: number, WarehouseId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string }, Material?: { Id?: number, LookupCode?: string, Name?: string }, Lot?: { Id?: number, LookupCode?: string }, VendorLot?: { Id?: number, ExpirationDate?: string, ManufactureDate?: string }, ExpectedPackagedPack?: { ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_manual_allocations_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      waveId?: number, orderId?: number    }) {
      return false;
    }
}
