import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Invoices_ShellService } from './Invoices.shell.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Invoices.frontend.types'
import { $frontendTypes as $types} from './Invoices.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { Invoices_freight_billing_details_editor_gridComponent } from './Invoices.freight_billing_details_editor_grid.component';
import { Invoices_freight_billing_warehouse_selector_singleComponent } from './Invoices.freight_billing_warehouse_selector_single.component'
import { Invoices_freight_billing_owner_selector_singleComponent } from './Invoices.freight_billing_owner_selector_single.component'
import { Invoices_freight_billing_projects_selector_singleComponent } from './Invoices.freight_billing_projects_selector_single.component'
import { Invoices_freight_billing_account_selector_singleComponent } from './Invoices.freight_billing_account_selector_single.component'
import { Invoices_freight_billing_address_selector_singleComponent } from './Invoices.freight_billing_address_selector_single.component'

type EntityType = { 
    header_id?: string, freight_header_lookupcode?: string, charge_type?: string, charge_amount?: number, pallet_range_from?: number, pallet_range_to?: number, created_date?: string, date_number?: number, order?: number}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Invoices_freight_billing_details_editor_gridComponent),
    forwardRef(() => Invoices_freight_billing_warehouse_selector_singleComponent),
    forwardRef(() => Invoices_freight_billing_owner_selector_singleComponent),
    forwardRef(() => Invoices_freight_billing_projects_selector_singleComponent),
    forwardRef(() => Invoices_freight_billing_account_selector_singleComponent),
    forwardRef(() => Invoices_freight_billing_address_selector_singleComponent),
  ],
  selector: 'Invoices-freight_billing_matrix_editor',
  templateUrl: './Invoices.freight_billing_matrix_editor.component.html'
})
export class Invoices_freight_billing_matrix_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { freight_header_id: string } = { freight_header_id: null };
  //#region Inputs
  @Input('freight_header_id') set $inParams_freight_header_id(v: string) {
    this.inParams.freight_header_id = v;
  }
  get $inParams_freight_header_id(): string {
    return this.inParams.freight_header_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { confirm?: boolean, on_close?: boolean, reason?: string, results?: any } = { confirm: null, on_close: null, reason: null, results: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    lookup_code: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouse_id: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    owner_id: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project_id: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    account_id: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    address_id: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    shipping_days_mon: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    shipping_days_tue: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    shipping_days_wed: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    shipping_days_thu: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    shipping_days_fri: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    shipping_days_sat: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    shipping_days_sun: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    delivery_days_mon: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    delivery_days_tue: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    delivery_days_wed: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    delivery_days_thu: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    delivery_days_fri: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    delivery_days_sat: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    delivery_days_sun: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      save_button: new ToolModel(new ButtonModel('save_button', new ButtonStyles(null, null), false, false, 'Save', 'ms-Icon ms-Icon--Save', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      delete_record: new ToolModel(new ButtonModel('delete_record', new ButtonStyles(['destructive'], null), false, false, 'Delete', 'ms-Icon ms-Icon--Delete', null)
    , false)
  };

  fields = {
    lookup_code: new FieldModel(new TextBoxModel(this.formGroup.controls['lookup_code'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Lookupcode', false, false),
    warehouse_id: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse_id'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', false, false),
    owner_id: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner_id'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Owner', false, false),
    project_id: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project_id'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
    account_id: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['account_id'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Account', false, false),
    address_id: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['address_id'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Address', false, false),
    ship_mon_label: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    shipping_days_mon: new FieldModel(new CheckBoxModel(this.formGroup.controls['shipping_days_mon'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
    ship_tue_label: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    shipping_days_tue: new FieldModel(new CheckBoxModel(this.formGroup.controls['shipping_days_tue'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
    ship_wed_label: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    shipping_days_wed: new FieldModel(new CheckBoxModel(this.formGroup.controls['shipping_days_wed'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
    ship_thu_label: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    shipping_days_thu: new FieldModel(new CheckBoxModel(this.formGroup.controls['shipping_days_thu'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
    ship_fri_label: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    shipping_days_fri: new FieldModel(new CheckBoxModel(this.formGroup.controls['shipping_days_fri'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
    ship_sat_label: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    shipping_days_sat: new FieldModel(new CheckBoxModel(this.formGroup.controls['shipping_days_sat'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
    ship_sun_label: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    shipping_days_sun: new FieldModel(new CheckBoxModel(this.formGroup.controls['shipping_days_sun'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
    delivery_mon_label: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    delivery_days_mon: new FieldModel(new CheckBoxModel(this.formGroup.controls['delivery_days_mon'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
    delivery_tue_label: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    delivery_days_tue: new FieldModel(new CheckBoxModel(this.formGroup.controls['delivery_days_tue'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
    delivery_wed_label: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    delivery_days_wed: new FieldModel(new CheckBoxModel(this.formGroup.controls['delivery_days_wed'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
    delivery_thu_label: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    delivery_days_thu: new FieldModel(new CheckBoxModel(this.formGroup.controls['delivery_days_thu'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
    delivery_fri_label: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    delivery_days_fri: new FieldModel(new CheckBoxModel(this.formGroup.controls['delivery_days_fri'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
    delivery_sat_label: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    delivery_days_sat: new FieldModel(new CheckBoxModel(this.formGroup.controls['delivery_days_sat'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
    delivery_sun_label: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    delivery_days_sun: new FieldModel(new CheckBoxModel(this.formGroup.controls['delivery_days_sun'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
  };

  fieldsets = {
  matrix_editor_header_group: new FieldsetModel('', true, false, true, false),
  shipping_days_group: new FieldsetModel('Shipping days', false, true, false, false),
  delivery_days_group: new FieldsetModel('Delivery days', false, true, false, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      freigth_charge: new TabItemModel(
        this.rootTabGroup, 
        'Freigth charge', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_freigth_charge_freight_billing_details_editor_grid_inParams_lookupcode(): string {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.freight_header_id;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_freigth_charge', { read: Invoices_freight_billing_details_editor_gridComponent }) $tabs_freigth_charge: Invoices_freight_billing_details_editor_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  get $fields_project_id_selector_inParams_owner_id(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.owner_id.control.value;
    
    return expr;
  }

  get $fields_account_id_selector_inParams_owner_id(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.owner_id.control.value;
    
    return expr;
  }

  get $fields_address_id_selector_inParams_account_id(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.account_id.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookup_code: this.fields.lookup_code.control.valueChanges
    ,
    warehouse_id: this.fields.warehouse_id.control.valueChanges
    ,
    owner_id: this.fields.owner_id.control.valueChanges
    ,
    project_id: this.fields.project_id.control.valueChanges
    ,
    account_id: this.fields.account_id.control.valueChanges
    ,
    address_id: this.fields.address_id.control.valueChanges
    ,
    shipping_days_mon: this.fields.shipping_days_mon.control.valueChanges
    ,
    shipping_days_tue: this.fields.shipping_days_tue.control.valueChanges
    ,
    shipping_days_wed: this.fields.shipping_days_wed.control.valueChanges
    ,
    shipping_days_thu: this.fields.shipping_days_thu.control.valueChanges
    ,
    shipping_days_fri: this.fields.shipping_days_fri.control.valueChanges
    ,
    shipping_days_sat: this.fields.shipping_days_sat.control.valueChanges
    ,
    shipping_days_sun: this.fields.shipping_days_sun.control.valueChanges
    ,
    delivery_days_mon: this.fields.delivery_days_mon.control.valueChanges
    ,
    delivery_days_tue: this.fields.delivery_days_tue.control.valueChanges
    ,
    delivery_days_wed: this.fields.delivery_days_wed.control.valueChanges
    ,
    delivery_days_thu: this.fields.delivery_days_thu.control.valueChanges
    ,
    delivery_days_fri: this.fields.delivery_days_fri.control.valueChanges
    ,
    delivery_days_sat: this.fields.delivery_days_sat.control.valueChanges
    ,
    delivery_days_sun: this.fields.delivery_days_sun.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Invoices_ShellService,
    private datasources: Invoices_DatasourceService,
    private flows: Invoices_FlowService,
    private reports: Invoices_ReportService,
    private localization: Invoices_LocalizationService,
    private operations: Invoices_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.freigth_charge,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.freight_header_id)) {
        this.$missingRequiredInParams.push('freight_header_id');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Freight charge';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      date_from:  null ,
      date_to:  null ,
      full_text_search:  $utils.odata.formatValue($editor.inParams.freight_header_id) 
    };

    const data = await this.datasources.Invoices.ds_freight_billing_details_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    private async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    

    await this.on_init();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_freigth_charge') {
        if (!isNil(this.$tabs_freigth_charge) && !this.tabs.freigth_charge.hidden && !this.tabs.freigth_charge.removed) {
          this.$tabs_freigth_charge.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookup_code
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .warehouse_id
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .owner_id
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_onwer_changed();
      });
    this.$formGroupFieldValidationObservables
      .project_id
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .account_id
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .address_id
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .shipping_days_mon
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .shipping_days_tue
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .shipping_days_wed
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .shipping_days_thu
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .shipping_days_fri
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .shipping_days_sat
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .shipping_days_sun
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .delivery_days_mon
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .delivery_days_tue
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .delivery_days_wed
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .delivery_days_thu
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .delivery_days_fri
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .delivery_days_sat
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .delivery_days_sun
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_close_clicked(event = null) {
    return this.on_close_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_close_clickedInternal(
    $editor: Invoices_freight_billing_matrix_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.logger.log('Invoices', 'freight_billing_matrix_editor.on_close_clicked');
  $editor.close()
  
  $editor.outParams.on_close = true;
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: Invoices_freight_billing_matrix_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
      //Enable save and cancel button
      $editor.toolbar.save_button.hidden = false;
  
      //Enable fields updates
      $editor.fields.lookup_code.control.readOnly = false;
      $editor.fields.warehouse_id.control.readOnly = false;
      $editor.fields.owner_id.control.readOnly = false;
      $editor.fields.project_id.control.readOnly = false;
      $editor.fields.account_id.control.readOnly = false;
      $editor.fields.address_id.control.readOnly = false;
  
      $editor.fields.shipping_days_mon.control.readOnly = false;
      $editor.fields.shipping_days_tue.control.readOnly = false;
      $editor.fields.shipping_days_wed.control.readOnly = false;
      $editor.fields.shipping_days_thu.control.readOnly = false;
      $editor.fields.shipping_days_fri.control.readOnly = false;
      $editor.fields.shipping_days_sat.control.readOnly = false;
      $editor.fields.shipping_days_sun.control.readOnly = false;
  
      $editor.fields.delivery_days_mon.control.readOnly = false;
      $editor.fields.delivery_days_tue.control.readOnly = false;
      $editor.fields.delivery_days_wed.control.readOnly = false;
      $editor.fields.delivery_days_thu.control.readOnly = false;
      $editor.fields.delivery_days_fri.control.readOnly = false;
      $editor.fields.delivery_days_sat.control.readOnly = false;
      $editor.fields.delivery_days_sun.control.readOnly = false;
      $editor.fields.delivery_days_sun.control.readOnly = false;
  
      var lookup_code:string = null;
      var freight_header_id:string = $editor.inParams.freight_header_id.toString().toUpperCase();
      
      let result = (await $flows.Invoices.freight_billing_header_storage({
          payload: [{
              header_id: freight_header_id
          }],
          action: `Read`
  
      }));
  
      //Retrive record from Freight Billing Header Storage for Display
      if ($utils.isDefined(result?.payload)) {
          $editor.fields.lookup_code.control.value = result.payload[0].lookup_code
          $editor.fields.warehouse_id.control.value = result.payload[0].warehouse_id
          $editor.fields.owner_id.control.value = result.payload[0].owner_id
          $editor.fields.project_id.control.value = result.payload[0].project_id
          $editor.fields.account_id.control.value = result.payload[0].account_id
          $editor.fields.address_id.control.value = result.payload[0].address_id
          $editor.fields.shipping_days_mon.control.value = result.payload[0].shipping_days_mon
          $editor.fields.shipping_days_tue.control.value = result.payload[0].shipping_days_tue
          $editor.fields.shipping_days_wed.control.value = result.payload[0].shipping_days_wed
          $editor.fields.shipping_days_thu.control.value = result.payload[0].shipping_days_thu
          $editor.fields.shipping_days_fri.control.value = result.payload[0].shipping_days_fri
          $editor.fields.shipping_days_sat.control.value = result.payload[0].shipping_days_sat
          $editor.fields.shipping_days_sun.control.value = result.payload[0].shipping_days_sun
          $editor.fields.delivery_days_mon.control.value = result.payload[0].delivery_days_mon
          $editor.fields.delivery_days_tue.control.value = result.payload[0].delivery_days_tue
          $editor.fields.delivery_days_wed.control.value = result.payload[0].delivery_days_wed
          $editor.fields.delivery_days_thu.control.value = result.payload[0].delivery_days_thu
          $editor.fields.delivery_days_fri.control.value = result.payload[0].delivery_days_fri
          $editor.fields.delivery_days_sat.control.value = result.payload[0].delivery_days_sat
          $editor.fields.delivery_days_sun.control.value = result.payload[0].delivery_days_sun
          $editor.fields.ship_mon_label.control.text = "Monday"
          $editor.fields.ship_tue_label.control.text = "Tuesday"
          $editor.fields.ship_wed_label.control.text = "Wednesday"
          $editor.fields.ship_thu_label.control.text = "Thursday"
          $editor.fields.ship_fri_label.control.text = "Friday"
          $editor.fields.ship_sat_label.control.text = "Saturday"
          $editor.fields.ship_sun_label.control.text = "Sunday"
          $editor.fields.delivery_mon_label.control.text = "Monday"
          $editor.fields.delivery_tue_label.control.text = "Tuesday"
          $editor.fields.delivery_wed_label.control.text = "Wednesday"
          $editor.fields.delivery_thu_label.control.text = "Thursday"
          $editor.fields.delivery_fri_label.control.text = "Friday"
          $editor.fields.delivery_sat_label.control.text = "Saturday"
          $editor.fields.delivery_sun_label.control.text = "Sunday"
      }
  
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $editor: Invoices_freight_billing_matrix_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.logger.log('Invoices', 'freight_billing_matrix_editor.on_confirm_clicked');
  
  $editor.outParams.confirm = true;
  
  close();
  }
  on_save_clicked(event = null) {
    return this.on_save_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_clickedInternal(
    $editor: Invoices_freight_billing_matrix_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  
  //Enable save and cancel buttons
  $editor.toolbar.save_button.control.readOnly = false;
  
  //Enable Fields update
  $editor.fields.lookup_code.control.readOnly = false;
  $editor.fields.warehouse_id.control.readOnly = false;
  $editor.fields.owner_id.control.readOnly = false;
  $editor.fields.project_id.control.readOnly = false;
  $editor.fields.account_id.control.readOnly = false;
  $editor.fields.address_id.control.readOnly = true;
  
  $editor.fields.shipping_days_mon.control.readOnly = false;
  $editor.fields.shipping_days_mon.control.readOnly = false;
  $editor.fields.shipping_days_tue.control.readOnly = false;
  $editor.fields.shipping_days_wed.control.readOnly = false;
  $editor.fields.shipping_days_thu.control.readOnly = false;
  $editor.fields.shipping_days_fri.control.readOnly = false;
  $editor.fields.shipping_days_sat.control.readOnly = false;
  $editor.fields.shipping_days_sun.control.readOnly = false;
  
  $editor.fields.delivery_days_mon.control.readOnly = false;
  $editor.fields.delivery_days_tue.control.readOnly = false;
  $editor.fields.delivery_days_wed.control.readOnly = false;
  $editor.fields.delivery_days_thu.control.readOnly = false;
  $editor.fields.delivery_days_fri.control.readOnly = false;
  $editor.fields.delivery_days_sat.control.readOnly = false;
  $editor.fields.delivery_days_sun.control.readOnly = false;
  $editor.fields.delivery_days_sun.control.readOnly = false;
  
  
  let payload: any = {};
  var freight_header_id: string = $editor.inParams.freight_header_id.toString().toUpperCase();
  // var lookup_code:string = null;
  
  //Payload entity mapping
  if ($utils.isDefined($editor.fields.lookup_code?.control.value)) {
      payload.lookup_code = $editor.fields.lookup_code?.control.value.toString().toUpperCase();
  };
  
  if ($utils.isDefined($editor.fields.warehouse_id?.control.value)) {
      payload.warehouse_id = $editor.fields.warehouse_id.control.value;
  };
  
  if ($utils.isDefined(payload.warehouse_id)) {
      let warehouseinfo = (await $datasources.Invoices.ds_freight_billing_warehouse_selector.get({ warehouse_id: payload.warehouse_id })).result;
      payload.warehouse_lookupcode = warehouseinfo[0].Name;
  };
  
  if ($utils.isDefined($editor.fields.owner_id?.control.value)) {
      payload.owner_id = $editor.fields.owner_id.control.value;
  };
  
  if ($utils.isDefined(payload.owner_id)) {
      let ownerinfo = (await $datasources.Invoices.ds_freight_billing_owner_selector.get({ owner_id: payload.owner_id })).result;
      payload.owner_lookupcode = ownerinfo[0].LookupCode;
      payload.owner_name = ownerinfo[0].Name;
  };
  
  if ($utils.isDefined($editor.fields.project_id?.control.value)) {
      payload.project_id = $editor.fields.project_id.control.value;
  };
  
  if ($utils.isDefined(payload.project_id)) {
      let projectinfo = (await $datasources.Invoices.ds_freight_billing_projects_selector.get({ project_id: payload.project_id })).result;
      payload.project_lookupcode = projectinfo[0].LookupCode;
      payload.project_name = projectinfo[0].Name;
  };
  
  if ($utils.isDefined($editor.fields.account_id?.control.value)) {
      payload.account_id = $editor.fields.account_id.control.value;
  };
  
  //Address Details Parse
  if ($utils.isDefined(payload.account_id)) {
      let accountinfo = (await $datasources.Invoices.ds_freight_billing_account_selector.get({ account_id: payload.account_id })).result;
      payload.account_lookupcode = accountinfo[0].LookupCode;
      payload.account_name = accountinfo[0].Name;
  }
  
  if ($utils.isDefined($editor.fields.address_id?.control.value)) {
      payload.address_id = $editor.fields.address_id.control.value;
  };
  
  //Address Details Parse
  if ($utils.isDefined(payload.address_id)) {
      let addressinfo = (await $datasources.Invoices.ds_freight_billing_address_selector.get({ address_id: payload.address_id })).result;
  
      if ($utils.isDefined(addressinfo[0]?.Contacts[0]?.FirstName)) {
  
          payload.destination = addressinfo[0]?.Contacts[0]?.FirstName
      }
  
      if ($utils.isDefined(addressinfo[0]?.Contacts[0]?.LastName)) {
  
          payload.destination = payload.destination + ", " + addressinfo[0]?.Contacts[0]?.LastName
      }
  
      if ($utils.isDefined(addressinfo[0]?.Line1)) {
  
          payload.destination = payload.destination + ", " + addressinfo[0]?.Line1
      }
  
      if ($utils.isDefined(addressinfo[0]?.Line2)) {
  
          payload.destination = payload.destination + ", " + addressinfo[0]?.Line2
      }
  
      if ($utils.isDefined(addressinfo[0]?.City)) {
  
          payload.destination = payload.destination + ", " + addressinfo[0]?.City
      }
  
      if ($utils.isDefined(addressinfo[0]?.State)) {
  
          payload.destination = payload.destination + ", " + addressinfo[0]?.State
      }
  
      if ($utils.isDefined(addressinfo[0]?.Country)) {
  
          payload.destination = payload.destination + ", " + addressinfo[0]?.Country
      }
  
      if ($utils.isDefined(addressinfo[0]?.PostalCode)) {
  
          payload.destination = payload.destination + ", " + addressinfo[0]?.PostalCode
      }
  };
  
  //Shipping Days Parse
  if ($utils.isDefined($editor.fields.shipping_days_mon?.control.value)) {
      payload.shipping_days_mon = $editor.fields.shipping_days_mon.control.value;
  
      if (payload.shipping_days_mon === true) {
          payload.shipping_days = "Monday"
      }
      else {
          payload.shipping_days = " "
      }
  };
  
  if ($utils.isDefined($editor.fields.shipping_days_tue?.control.value)) {
      payload.shipping_days_tue = $editor.fields.shipping_days_tue.control.value;
  
      if (payload.shipping_days_tue === true) {
  
          if (payload.shipping_days === " ") {
              payload.shipping_days = "Tuesday"
          }
          else {
              payload.shipping_days = payload.shipping_days + " / Tuesday"
          }
      }
  };
  
  if ($utils.isDefined($editor.fields.shipping_days_wed?.control.value)) {
      payload.shipping_days_wed = $editor.fields.shipping_days_wed.control.value;
  
      if (payload.shipping_days_wed === true) {
  
          if (payload.shipping_days === " ") {
              payload.shipping_days = "Wednesday"
          }
          else {
              payload.shipping_days = payload.shipping_days + " / Wednesday"
          }
      }
  };
  
  if ($utils.isDefined($editor.fields.shipping_days_thu?.control.value)) {
      payload.shipping_days_thu = $editor.fields.shipping_days_thu.control.value;
  
      if (payload.shipping_days_thu === true) {
  
          if (payload.shipping_days === " ") {
              payload.shipping_days = "Thusday"
          }
          else {
              payload.shipping_days = payload.shipping_days + " / Thusday"
          }
      }
  };
  
  if ($utils.isDefined($editor.fields.shipping_days_fri?.control.value)) {
      payload.shipping_days_fri = $editor.fields.shipping_days_fri.control.value;
  
      if (payload.shipping_days_fri === true) {
  
          if (payload.shipping_days === " ") {
              payload.shipping_days = "Friday"
          }
          else {
              payload.shipping_days = payload.shipping_days + " / Friday"
          }
      }
  };
  
  if ($utils.isDefined($editor.fields.shipping_days_sat?.control.value)) {
      payload.shipping_days_sat = $editor.fields.shipping_days_sat.control.value;
  
      if (payload.shipping_days_sat === true) {
  
          if (payload.shipping_days === " ") {
              payload.shipping_days = "Saturday"
          }
          else {
              payload.shipping_days = payload.shipping_days + " / Saturday"
          }
      }
  };
  
  if ($utils.isDefined($editor.fields.shipping_days_sun?.control.value)) {
      payload.shipping_days_sun = $editor.fields.shipping_days_sun.control.value;
  
      if (payload.shipping_days_sun === true) {
  
          if (payload.shipping_days === " ") {
              payload.shipping_days = "Sunday"
          }
          else {
              payload.shipping_days = payload.shipping_days + " / Sunday"
          }
      }
  };
  
  
  //Delivery Days Parse
  if ($utils.isDefined($editor.fields.delivery_days_mon?.control.value)) {
      payload.delivery_days_mon = $editor.fields.delivery_days_mon.control.value;
  
      if (payload.delivery_days_mon === true) {
          payload.delivery_days = "Monday"
      }
      else {
          payload.delivery_days = " "
      }
  };
  
  if ($utils.isDefined($editor.fields.delivery_days_tue?.control.value)) {
      payload.delivery_days_tue = $editor.fields.delivery_days_tue.control.value;
  
      if (payload.delivery_days_tue === true) {
  
          if (payload.delivery_days === " ") {
              payload.delivery_days = "Tuesday"
          }
          else {
              payload.delivery_days = payload.delivery_days + " / Tuesday"
          }
      }
  };
  
  if ($utils.isDefined($editor.fields.delivery_days_wed?.control.value)) {
      payload.delivery_days_wed = $editor.fields.delivery_days_wed.control.value;
  
      if (payload.delivery_days_wed === true) {
  
          if (payload.delivery_days === " ") {
              payload.delivery_days = "Wednesday"
          }
          else {
              payload.delivery_days = payload.delivery_days + " / Wednesday"
          }
      }
  };
  
  if ($utils.isDefined($editor.fields.delivery_days_thu?.control.value)) {
      payload.delivery_days_thu = $editor.fields.delivery_days_thu.control.value;
  
      if (payload.delivery_days_thu === true) {
  
          if (payload.delivery_days === " ") {
              payload.delivery_days = "Thursday"
          }
          else {
              payload.delivery_days = payload.delivery_days + " / Thursday"
          }
      }
  };
  
  if ($utils.isDefined($editor.fields.delivery_days_fri?.control.value)) {
      payload.delivery_days_fri = $editor.fields.delivery_days_fri.control.value;
  
      if (payload.delivery_days_fri === true) {
  
          if (payload.delivery_days === " ") {
              payload.delivery_days = "Friday"
          }
          else {
              payload.delivery_days = payload.delivery_days + " / Friday"
          }
      }
  };
  
  if ($utils.isDefined($editor.fields.delivery_days_sat?.control.value)) {
      payload.delivery_days_sat = $editor.fields.delivery_days_sat.control.value;
  
      if (payload.delivery_days_sat === true) {
  
          if (payload.delivery_days === " ") {
              payload.delivery_days = "Saturday"
          }
          else {
              payload.delivery_days = payload.delivery_days + " / Saturday"
          }
      }
  };
  
  if ($utils.isDefined($editor.fields.delivery_days_sun?.control.value)) {
      payload.delivery_days_sun = $editor.fields.delivery_days_sun.control.value;
  
      if (payload.delivery_days_sun === true) {
  
          if (payload.delivery_days === " ") {
              payload.delivery_days = "Sunday"
          }
          else {
              payload.delivery_days = payload.delivery_days + " / Sunday"
          }
      }
  };
  
  let lookupcode_validate = (await $flows.Invoices.freight_billing_header_storage({
      payload: [{
          lookup_code: payload.lookup_code
      }],
      action: `Read`
  }));
  
  let lookupcodeCheck = (await $flows.Invoices.freight_billing_header_storage({
      payload: [{
          header_id: freight_header_id
      }],
      action: `Read`
  }));
  
  let matrix_records_validate = (await $flows.Invoices.freight_billing_header_storage({
      payload: [{
          warehouse_id: payload.warehouse_id,
          owner_id: payload.owner_id,
          project_id: payload.project_id,
          account_id: payload.account_id,
          address_id: payload.address_id
      }],
      action: `Read`
  }));
  
  let valdationException = false;
  let missingFields = false;
  let sameFieldUpdate = false;
  let missingLookupcode = false;
  const errors: string[] = [];
  
  try {
  
      if (!$utils.isDefined(payload.lookup_code)) {
          valdationException = true;
          missingFields = true;
          missingLookupcode = true
          errors.push("Lookupcode");
      }
      if (!$utils.isDefined(payload.warehouse_id)) {
          valdationException = true;
          missingFields = true;
          errors.push("Warehouse");
      }
      if (!$utils.isDefined(payload.owner_id)) {
          valdationException = true;
          missingFields = true;
          errors.push("Owner");
      }
      if (!$utils.isDefined(payload.project_id)) {
          valdationException = true;
          missingFields = true;
          errors.push("Project");
      }
      if (!$utils.isDefined(payload.account_id)) {
          valdationException = true;
          missingFields = true;
          errors.push("Accounts");
      }
  
      if (missingFields === true) {
          const title = 'Freight matrix creation';
          let message = `Please supply field details: `;
  
          errors.forEach((error, index) => {
              if (index === errors.length - 1) {
                  message += " " + error
              } else {
                  message += " " + error + ","
              }
          })
          $shell.Utilities.openErrorDialog(title, message, null, null, null);
          valdationException = true;
      }
  
      if (lookupcode_validate.count > 0 && missingLookupcode === false) {
  
          if (lookupcodeCheck.count > 0) {
  
              if (lookupcodeCheck.payload[0].lookup_code != payload.lookup_code) {
  
                  const title = 'Freight matrix creation';
                  const message = `Lookupcode is already being used.`;
                  $shell.Utilities.openErrorDialog(title, message);
                  valdationException = true;
              }
              // if (lookupcodeCheck.payload[0].lookup_code === payload.lookup_code) {
              //     valdationException = false;
              // }
          }
      }
  
      if (lookupcodeCheck.payload[0].warehouse_id === payload.warehouse_id
          && lookupcodeCheck.payload[0].owner_id === payload.owner_id
          && lookupcodeCheck.payload[0].project_id === payload.project_id
          && lookupcodeCheck.payload[0].account_id === payload.account_id
          && lookupcodeCheck.payload[0].address_id === payload.address_id
          ) {
          sameFieldUpdate = true;
      }
  
      if (matrix_records_validate.count > 0 && sameFieldUpdate === false && missingFields === false) {
          const title = 'Freight matrix creation';
          const message = `There is an existing record for the supplied matrix details`;
          $shell.Utilities.openErrorDialog(title, message);
          valdationException = true;
      }
  
      if (valdationException != true) {
          //Update Freight Matrix Header Fields
          const result =
              await $flows.Invoices.freight_billing_header_storage({
                  payload: [{
                      header_id: $editor.inParams.freight_header_id,
                      lookup_code: payload.lookup_code,
                      warehouse_id: payload.warehouse_id,
                      warehouse_lookupcode: payload.warehouse_lookupcode,
                      owner_id: payload.owner_id,
                      owner_lookupcode: payload.owner_lookupcode,
                      owner_name: payload.owner_name,
                      project_id: payload.project_id,
                      project_lookupcode: payload.project_lookupcode,
                      project_name: payload.project_name,
                      account_id: payload.account_id,
                      account_lookupcode: payload.account_lookupcode,
                      account_name: payload.account_name,
                      address_id: payload.address_id,
                      destination: payload.destination,
                      shipping_days_mon: payload.shipping_days_mon,
                      shipping_days_tue: payload.shipping_days_tue,
                      shipping_days_wed: payload.shipping_days_wed,
                      shipping_days_thu: payload.shipping_days_thu,
                      shipping_days_fri: payload.shipping_days_fri,
                      shipping_days_sat: payload.shipping_days_sat,
                      shipping_days_sun: payload.shipping_days_sun,
                      delivery_days_mon: payload.delivery_days_mon,
                      delivery_days_tue: payload.delivery_days_tue,
                      delivery_days_wed: payload.delivery_days_wed,
                      delivery_days_thu: payload.delivery_days_thu,
                      delivery_days_fri: payload.delivery_days_fri,
                      delivery_days_sat: payload.delivery_days_sat,
                      delivery_days_sun: payload.delivery_days_sun,
                      shipping_days: payload.shipping_days,
                      delivery_days: payload.delivery_days
                  }],
                  action: "Update"
              })
          const title = `Freight matrix ${payload.lookup_code}`;
          const message = "Updated";
          $shell.Utilities.openToaster(title, message, EToasterType.Success, { timeOut: 5000, positionClass: EToasterPosition.topRight });
      }
  } catch (error) {
  
      let targetError = error;
  
      while ($utils.isDefined(targetError?.error)) {
          targetError = targetError.error
      }
  
      if (!$utils.isDefined(targetError?.message)) {
          targetError = { "message": "Uncaught exception!" }
      }
  
      if (!$utils.isDefined($editor.outParams.results)) {
          $editor.outParams.results = [{ error: "error", details: targetError.message }];
      }
  }
  
  let result = (await $flows.Invoices.freight_billing_header_storage({
      payload: [{
          header_id: freight_header_id
      }],
      action: `Read`
  }));
  
  //Refresh Editor Display after save
  if ($utils.isDefined(result?.payload)) {
      $editor.fields.lookup_code.control.value = result.payload[0].lookup_code
      $editor.fields.warehouse_id.control.value = result.payload[0].warehouse_id
      $editor.fields.owner_id.control.value = result.payload[0].owner_id
      $editor.fields.project_id.control.value = result.payload[0].project_id
      $editor.fields.account_id.control.value = result.payload[0].account_id
      $editor.fields.address_id.control.value = result.payload[0].address_id
      $editor.fields.shipping_days_mon.control.value = result.payload[0].shipping_days_mon
      $editor.fields.shipping_days_tue.control.value = result.payload[0].shipping_days_tue
      $editor.fields.shipping_days_wed.control.value = result.payload[0].shipping_days_wed
      $editor.fields.shipping_days_thu.control.value = result.payload[0].shipping_days_thu
      $editor.fields.shipping_days_fri.control.value = result.payload[0].shipping_days_fri
      $editor.fields.shipping_days_sat.control.value = result.payload[0].shipping_days_sat
      $editor.fields.shipping_days_sun.control.value = result.payload[0].shipping_days_sun
      $editor.fields.delivery_days_mon.control.value = result.payload[0].delivery_days_mon
      $editor.fields.delivery_days_tue.control.value = result.payload[0].delivery_days_tue
      $editor.fields.delivery_days_wed.control.value = result.payload[0].delivery_days_wed
      $editor.fields.delivery_days_thu.control.value = result.payload[0].delivery_days_thu
      $editor.fields.delivery_days_fri.control.value = result.payload[0].delivery_days_fri
      $editor.fields.delivery_days_sat.control.value = result.payload[0].delivery_days_sat
      $editor.fields.delivery_days_sun.control.value = result.payload[0].delivery_days_sun
      $editor.fields.ship_mon_label.control.text = "Monday"
      $editor.fields.ship_tue_label.control.text = "Tuesday"
      $editor.fields.ship_wed_label.control.text = "Wednesday"
      $editor.fields.ship_thu_label.control.text = "Thursday"
      $editor.fields.ship_fri_label.control.text = "Friday"
      $editor.fields.ship_sat_label.control.text = "Saturday"
      $editor.fields.ship_sun_label.control.text = "Sunday"
      $editor.fields.delivery_mon_label.control.text = "Monday"
      $editor.fields.delivery_tue_label.control.text = "Tuesday"
      $editor.fields.delivery_wed_label.control.text = "Wednesday"
      $editor.fields.delivery_thu_label.control.text = "Thursday"
      $editor.fields.delivery_fri_label.control.text = "Friday"
      $editor.fields.delivery_sat_label.control.text = "Saturday"
      $editor.fields.delivery_sun_label.control.text = "Sunday"
  }
  
  $editor.refresh();
  }
  on_onwer_changed(event = null) {
    return this.on_onwer_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_onwer_changedInternal(
    $editor: Invoices_freight_billing_matrix_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  const owner_id = $editor.fields.owner_id.control.value
  
  // Set Owner from Project
  if ($utils.isDefined(owner_id))
  
  if ($utils.isDefined(owner_id)) {
      const account = (await $datasources.Invoices.ds_freight_billing_account_selector.get({
          owner_id: owner_id
      })).result;
  
      if ($utils.isDefined(account)) {
          $editor.fields.account_id.control.value = account[0].Id;
      }
  
      const project = (await $datasources.Invoices.ds_freight_billing_projects_selector.get({
          owner_id: owner_id
      })).result;
     
      if ($utils.isDefined(project)) {
          $editor.fields.project_id.control.value = project[0].Id;
      }
  }
  
  }
  on_account_changed(event = null) {
    return this.on_account_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_account_changedInternal(
    $editor: Invoices_freight_billing_matrix_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.logger.log('Invoices', 'freight_billing_matrix_editor.on_account_changed');
  const account_id = $editor.fields.account_id.control.value
  
  // Set Owner from Project
  if ($utils.isDefined(account_id))
  
  if ($utils.isDefined(account_id)) {
      const address = (await $datasources.Invoices.ds_freight_billing_address_selector.get({
          account_id: account_id
      })).result;
     
      if ($utils.isDefined(address)) {
          $editor.fields.account_id.control.value = address[0].Id;
      }
  }
  
  }
  on_delete_record_clicked(event = null) {
    return this.on_delete_record_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_record_clickedInternal(
    $editor: Invoices_freight_billing_matrix_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  const freight_matrix_record = $editor.inParams.freight_header_id;
  let valdationResults: string = "False";
  
  const failures = [];
  const errors = [];
  const errorMsgList = [];
  const errorMsgListDetails = [];
  
  let matrix_header_records = (await $flows.Invoices.freight_billing_header_storage({
      payload: [{
          header_id: freight_matrix_record
      }],
      action: `Read`
  
  }));
  
  
  if ($utils.isDefined(freight_matrix_record)) {
  
      let confirm = false;
      const title = `Delete freight charge ${matrix_header_records.payload[0].lookup_code}`;
      const confirmCandidates = `Once deleted, cannot be restored.`
      const message = confirmCandidates;
      confirm = await $shell.Utilities.openConfirmationDialog(title, message, 'Delete charge');
  
      if (confirm) {
          try {
  
              let matrix_records_validate = (await $flows.Invoices.freight_billing_details_storage({
                  full_text_search: freight_matrix_record,
                  action: `Read`
              }));
  
              if ($utils.isDefined(matrix_records_validate?.payload)) {
                  valdationResults = "True"
              }
  
              if (valdationResults != "True") {
  
                  const details = await $flows.Invoices.freight_billing_header_storage({
                      payload: [{
                          header_id: freight_matrix_record
                      }],
                      action: "Delete"
                  });
              }
              else {
                  if ($utils.isDefined(matrix_records_validate)) {
                      let i: number = 0;
                      while (i < matrix_records_validate.count) {
  
                          const result = await $flows.Invoices.freight_billing_details_storage({
                              payload: [{
                                  header_id: matrix_records_validate.payload[i].header_id
                              }],
                              action: "Delete"
                          })
                          i++;
                      }
                  }
  
                  const header = await $flows.Invoices.freight_billing_header_storage({
                      payload: [{
                          header_id: freight_matrix_record
                      }],
                      action: "Delete"
                  });
              }
  
              const title = `Freigth charge ${matrix_header_records.payload[0].lookup_code}`;
              const message = "Deleted"
              $shell.Utilities.openToaster(title, message, EToasterType.Success, { timeOut: 5000, positionClass: EToasterPosition.topRight });
  
              $editor.close();
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Freigth charge ${matrix_header_records.payload[0].lookup_code} connot be deleted - ${errorMessage}`;
              errorMsgList.push(errorDescription);
              errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
          }
      }
  
  }
  
  
  }
  //#endregion private flows
  
}
