import { isEmpty, isNil, isObject } from 'lodash-es';
import { ControlModel, ValueControlModel } from './control';
import { ControlContainerStyles } from './style';

export class FieldModel<TC extends ControlModel> {
  styles: ControlContainerStyles;
  label: string;
  required: boolean;
  control: TC;
  hidden: boolean;
  removed: boolean;

  constructor(control: TC, styles: ControlContainerStyles, label: string, required: boolean, removed: boolean) {
    this.styles = isNil(styles) ? new ControlContainerStyles() : styles;
    this.label = label;
    this.control = control;
    this.required = required;
    this.removed = removed;
  }

  get invalid(): boolean {
    return this.control instanceof ValueControlModel
      ? this.control.errors && (this.isDefined(this.control.value) || this.control.touched)
      : false;
  }

  get errors(): string[] {
    return this.control instanceof ValueControlModel
      ? this.control.errorMessages
      : [];
  }

  private isDefined(value) {
    if (isObject(value)) {
      return !isEmpty(value);
    } else {
      return !isNil(value) && value !== '';
    }
  }
}