<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="save_contract" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_save_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="edit_contract" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_edit_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="activate_contract" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_activate_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="deactivate_contract" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_deactivate_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="copy_contract" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_copy_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-id" *ngIf="!fields.id.hidden && !fields.id.removed" 
                            class="field-container standard {{fields.id.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.id.styles.style"
                            [ngClass]="fields.id.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.id.label + (fields.id.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.id.label}}<span *ngIf="fields.id.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="id"
                                matInput
                                numberBox
                                [format]="fields.id.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.id.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.id.control.placeholder}}"
                                [ngStyle]="fields.id.control.styles.style"
                                [ngClass]="fields.id.control.styles.classes"
                                [matTooltip]="fields.id.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.id.invalid">
                          <ng-container *ngFor="let error of fields.id.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-name" *ngIf="!fields.name.hidden && !fields.name.removed" 
                            class="field-container standard {{fields.name.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.name.styles.style"
                            [ngClass]="fields.name.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.name.label + (fields.name.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.name.label}}<span *ngIf="fields.name.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="name"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.name.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.name.control.placeholder}}"
                                [ngStyle]="fields.name.control.styles.style"
                                [ngClass]="fields.name.control.styles.classes"
                                [matTooltip]="fields.name.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.name.invalid">
                          <ng-container *ngFor="let error of fields.name.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-owner" *ngIf="!fields.owner.hidden && !fields.owner.removed" 
                            class="field-container standard {{fields.owner.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.owner.styles.style"
                            [ngClass]="fields.owner.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.owner.label + (fields.owner.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.owner.label}}<span *ngIf="fields.owner.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="owner"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.owner.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.owner.control.placeholder}}"
                                [ngStyle]="fields.owner.control.styles.style"
                                [ngClass]="fields.owner.control.styles.classes"
                                [matTooltip]="fields.owner.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.owner.invalid">
                          <ng-container *ngFor="let error of fields.owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden && !fields.project.removed" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.project.label + (fields.project.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.project.label}}<span *ngIf="fields.project.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="project"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.project.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.project.control.placeholder}}"
                                [ngStyle]="fields.project.control.styles.style"
                                [ngClass]="fields.project.control.styles.classes"
                                [matTooltip]="fields.project.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-account" *ngIf="!fields.account.hidden && !fields.account.removed" 
                            class="field-container standard {{fields.account.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.account.styles.style"
                            [ngClass]="fields.account.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.account.label + (fields.account.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.account.label}}<span *ngIf="fields.account.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="account"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.account.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.account.control.placeholder}}"
                                [ngStyle]="fields.account.control.styles.style"
                                [ngClass]="fields.account.control.styles.classes"
                                [matTooltip]="fields.account.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.account.invalid">
                          <ng-container *ngFor="let error of fields.account.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-status" *ngIf="!fields.status.hidden && !fields.status.removed" 
                            class="field-container standard {{fields.status.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.status.label + (fields.status.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.status.label}}<span *ngIf="fields.status.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="status"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.status.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.status.control.placeholder}}"
                                [ngStyle]="fields.status.control.styles.style"
                                [ngClass]="fields.status.control.styles.classes"
                                [matTooltip]="fields.status.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-billing_terms" *ngIf="!fields.billing_terms.hidden && !fields.billing_terms.removed" 
                            class="field-container standard {{fields.billing_terms.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.billing_terms.styles.style"
                            [ngClass]="fields.billing_terms.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.billing_terms.label + (fields.billing_terms.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.billing_terms.label}}<span *ngIf="fields.billing_terms.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Invoices-invoice_terms_dd_single 
                            data-cy="selector"
                            [type]="fields.billing_terms.control.type"
                            formControlName="billing_terms"
                            (displayTextChange)="fields.billing_terms.control.displayText=$event"
                            [placeholder]="fields.billing_terms.control.placeholder"
                            [styles]="fields.billing_terms.control.styles"
                            [tooltip]="fields.billing_terms.control.tooltip"
                        >
                        </Invoices-invoice_terms_dd_single>
                        <ng-container *ngIf="fields.billing_terms.invalid">
                          <ng-container *ngFor="let error of fields.billing_terms.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden && !fieldsets.newGroup2.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup2.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-start_date" *ngIf="!fields.start_date.hidden && !fields.start_date.removed" 
                            class="field-container standard {{fields.start_date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.start_date.styles.style"
                            [ngClass]="fields.start_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.start_date.label + (fields.start_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.start_date.label}}<span *ngIf="fields.start_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="start_date"
                            [format]="fields.start_date.control.format"
                            [mode]="fields.start_date.control.mode"
                            [ngStyle]="fields.start_date.control.styles.style"
                            [ngClass]="fields.start_date.control.styles.classes"
                            [tooltip]="fields.start_date.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.start_date.invalid">
                          <ng-container *ngFor="let error of fields.start_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-end_date" *ngIf="!fields.end_date.hidden && !fields.end_date.removed" 
                            class="field-container standard {{fields.end_date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.end_date.styles.style"
                            [ngClass]="fields.end_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.end_date.label + (fields.end_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.end_date.label}}<span *ngIf="fields.end_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="end_date"
                            [format]="fields.end_date.control.format"
                            [mode]="fields.end_date.control.mode"
                            [ngStyle]="fields.end_date.control.styles.style"
                            [ngClass]="fields.end_date.control.styles.classes"
                            [tooltip]="fields.end_date.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.end_date.invalid">
                          <ng-container *ngFor="let error of fields.end_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-created_date" *ngIf="!fields.created_date.hidden && !fields.created_date.removed" 
                            class="field-container standard {{fields.created_date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.created_date.styles.style"
                            [ngClass]="fields.created_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.created_date.label + (fields.created_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.created_date.label}}<span *ngIf="fields.created_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="created_date"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.created_date.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.created_date.control.placeholder}}"
                                [ngStyle]="fields.created_date.control.styles.style"
                                [ngClass]="fields.created_date.control.styles.classes"
                                [matTooltip]="fields.created_date.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.created_date.invalid">
                          <ng-container *ngFor="let error of fields.created_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-created_by" *ngIf="!fields.created_by.hidden && !fields.created_by.removed" 
                            class="field-container standard {{fields.created_by.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.created_by.styles.style"
                            [ngClass]="fields.created_by.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.created_by.label + (fields.created_by.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.created_by.label}}<span *ngIf="fields.created_by.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="created_by"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.created_by.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.created_by.control.placeholder}}"
                                [ngStyle]="fields.created_by.control.styles.style"
                                [ngClass]="fields.created_by.control.styles.classes"
                                [matTooltip]="fields.created_by.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.created_by.invalid">
                          <ng-container *ngFor="let error of fields.created_by.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-modified_date" *ngIf="!fields.modified_date.hidden && !fields.modified_date.removed" 
                            class="field-container standard {{fields.modified_date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.modified_date.styles.style"
                            [ngClass]="fields.modified_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.modified_date.label + (fields.modified_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.modified_date.label}}<span *ngIf="fields.modified_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="modified_date"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.modified_date.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.modified_date.control.placeholder}}"
                                [ngStyle]="fields.modified_date.control.styles.style"
                                [ngClass]="fields.modified_date.control.styles.classes"
                                [matTooltip]="fields.modified_date.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.modified_date.invalid">
                          <ng-container *ngFor="let error of fields.modified_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-modified_by" *ngIf="!fields.modified_by.hidden && !fields.modified_by.removed" 
                            class="field-container standard {{fields.modified_by.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.modified_by.styles.style"
                            [ngClass]="fields.modified_by.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.modified_by.label + (fields.modified_by.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.modified_by.label}}<span *ngIf="fields.modified_by.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="modified_by"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.modified_by.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.modified_by.control.placeholder}}"
                                [ngStyle]="fields.modified_by.control.styles.style"
                                [ngClass]="fields.modified_by.control.styles.classes"
                                [matTooltip]="fields.modified_by.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.modified_by.invalid">
                          <ng-container *ngFor="let error of fields.modified_by.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup3"
                     *ngIf="!fieldsets.newGroup3.hidden && !fieldsets.newGroup3.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup3.collapsible }">
                    <div *ngIf="!fieldsets.newGroup3.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup3.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup3.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup3.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup3.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup3.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup3.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-currency" *ngIf="!fields.currency.hidden && !fields.currency.removed" 
                            class="field-container standard {{fields.currency.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.currency.styles.style"
                            [ngClass]="fields.currency.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.currency.label + (fields.currency.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.currency.label}}<span *ngIf="fields.currency.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Invoices-currencies_dd_single 
                            data-cy="selector"
                            [type]="fields.currency.control.type"
                            formControlName="currency"
                            (displayTextChange)="fields.currency.control.displayText=$event"
                            [placeholder]="fields.currency.control.placeholder"
                            [styles]="fields.currency.control.styles"
                            [tooltip]="fields.currency.control.tooltip"
                        >
                        </Invoices-currencies_dd_single>
                        <ng-container *ngIf="fields.currency.invalid">
                          <ng-container *ngFor="let error of fields.currency.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-tax" *ngIf="!fields.tax.hidden && !fields.tax.removed" 
                            class="field-container standard {{fields.tax.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.tax.styles.style"
                            [ngClass]="fields.tax.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.tax.label + (fields.tax.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.tax.label}}<span *ngIf="fields.tax.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Invoices-tax_schedules_dd_single 
                            data-cy="selector"
                            [type]="fields.tax.control.type"
                            formControlName="tax"
                            (displayTextChange)="fields.tax.control.displayText=$event"
                            [placeholder]="fields.tax.control.placeholder"
                            [styles]="fields.tax.control.styles"
                            [tooltip]="fields.tax.control.tooltip"
                        >
                        </Invoices-tax_schedules_dd_single>
                        <ng-container *ngIf="fields.tax.invalid">
                          <ng-container *ngFor="let error of fields.tax.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-renewal_type" *ngIf="!fields.renewal_type.hidden && !fields.renewal_type.removed" 
                            class="field-container standard {{fields.renewal_type.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.renewal_type.styles.style"
                            [ngClass]="fields.renewal_type.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.renewal_type.label + (fields.renewal_type.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.renewal_type.label}}<span *ngIf="fields.renewal_type.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Invoices-renewal_types_dd_single 
                            data-cy="selector"
                            [type]="fields.renewal_type.control.type"
                            formControlName="renewal_type"
                            (displayTextChange)="fields.renewal_type.control.displayText=$event"
                            [placeholder]="fields.renewal_type.control.placeholder"
                            [styles]="fields.renewal_type.control.styles"
                            [tooltip]="fields.renewal_type.control.tooltip"
                        >
                        </Invoices-renewal_types_dd_single>
                        <ng-container *ngIf="fields.renewal_type.invalid">
                          <ng-container *ngFor="let error of fields.renewal_type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-renewal_percentage" *ngIf="!fields.renewal_percentage.hidden && !fields.renewal_percentage.removed" 
                            class="field-container standard {{fields.renewal_percentage.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.renewal_percentage.styles.style"
                            [ngClass]="fields.renewal_percentage.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.renewal_percentage.label + (fields.renewal_percentage.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.renewal_percentage.label}}<span *ngIf="fields.renewal_percentage.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="renewal_percentage"
                                matInput
                                numberBox
                                [format]="fields.renewal_percentage.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.renewal_percentage.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.renewal_percentage.control.placeholder}}"
                                [ngStyle]="fields.renewal_percentage.control.styles.style"
                                [ngClass]="fields.renewal_percentage.control.styles.classes"
                                [matTooltip]="fields.renewal_percentage.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.renewal_percentage.invalid">
                          <ng-container *ngFor="let error of fields.renewal_percentage.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden && !fields.notes.removed" 
                            class="field-container double {{fields.notes.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.notes.label + (fields.notes.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.notes.label}}<span *ngIf="fields.notes.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.notes.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"
                                [matTooltip]="fields.notes.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> </textarea>
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

        <div class="widgets">
          <div *ngIf="!widgets.line_count.hidden && !widgets.line_count.removed" class="widget-container">
            <Invoices-billing_contract_lines_widget
            #$widgets_line_count 
              [contractId]="$widgets_line_count_inParams_contractId"
            ($refreshEvent)="refresh(false, false, '$widgets_line_count')"
            >
            </Invoices-billing_contract_lines_widget>
          </div>
          <div *ngIf="!widgets.contract_total.hidden && !widgets.contract_total.removed" class="widget-container">
            <Invoices-billing_contract_total_widget
            #$widgets_contract_total 
              [contractId]="$widgets_contract_total_inParams_contractId"
            ($refreshEvent)="refresh(false, false, '$widgets_contract_total')"
            >
            </Invoices-billing_contract_total_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.accessorial_lines.hasTabToShow" class="tab" data-cy="tab-accessorial_lines">
              <h2 [className]="tabs.accessorial_lines.active? 'active': ''" (click)="tabs.accessorial_lines.activate()">{{tabs.accessorial_lines.title}}</h2>
            </div>
            <div *ngIf="tabs.inbound_charges.hasTabToShow" class="tab" data-cy="tab-inbound_charges">
              <h2 [className]="tabs.inbound_charges.active? 'active': ''" (click)="tabs.inbound_charges.activate()">{{tabs.inbound_charges.title}}</h2>
            </div>
            <div *ngIf="tabs.initial_charges.hasTabToShow" class="tab" data-cy="tab-initial_charges">
              <h2 [className]="tabs.initial_charges.active? 'active': ''" (click)="tabs.initial_charges.activate()">{{tabs.initial_charges.title}}</h2>
            </div>
            <div *ngIf="tabs.outbound_charges.hasTabToShow" class="tab" data-cy="tab-outbound_charges">
              <h2 [className]="tabs.outbound_charges.active? 'active': ''" (click)="tabs.outbound_charges.activate()">{{tabs.outbound_charges.title}}</h2>
            </div>
            <div *ngIf="tabs.recurring_charges.hasTabToShow" class="tab" data-cy="tab-recurring_charges">
              <h2 [className]="tabs.recurring_charges.active? 'active': ''" (click)="tabs.recurring_charges.activate()">{{tabs.recurring_charges.title}}</h2>
            </div>
            <div *ngIf="tabs.retainier_charges.hasTabToShow" class="tab" data-cy="tab-retainier_charges">
              <h2 [className]="tabs.retainier_charges.active? 'active': ''" (click)="tabs.retainier_charges.activate()">{{tabs.retainier_charges.title}}</h2>
            </div>
            <div *ngIf="tabs.auto_invoicing_rules.hasTabToShow" class="tab" data-cy="tab-auto_invoicing_rules">
              <h2 [className]="tabs.auto_invoicing_rules.active? 'active': ''" (click)="tabs.auto_invoicing_rules.activate()">{{tabs.auto_invoicing_rules.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-billing_contract_lines_grid *ngIf="tabs.accessorial_lines.active"
              #$tabs_accessorial_lines
              [contractId]="$tabs_accessorial_lines_billing_contract_lines_grid_inParams_contractId"
              [strategyName]="$tabs_accessorial_lines_billing_contract_lines_grid_inParams_strategyName"
              [edit]="$tabs_accessorial_lines_billing_contract_lines_grid_inParams_edit"
              [hide]="$tabs_accessorial_lines_billing_contract_lines_grid_inParams_hide"
              [statusId]="$tabs_accessorial_lines_billing_contract_lines_grid_inParams_statusId"
              ($refreshEvent)="refresh(false, false, '$tabs_accessorial_lines')">
              </FootPrintManager-billing_contract_lines_grid>
              <FootPrintManager-billing_contract_lines_grid *ngIf="tabs.inbound_charges.active"
              #$tabs_inbound_charges
              [contractId]="$tabs_inbound_charges_billing_contract_lines_grid_inParams_contractId"
              [strategyName]="$tabs_inbound_charges_billing_contract_lines_grid_inParams_strategyName"
              [edit]="$tabs_inbound_charges_billing_contract_lines_grid_inParams_edit"
              [hide]="$tabs_inbound_charges_billing_contract_lines_grid_inParams_hide"
              [statusId]="$tabs_inbound_charges_billing_contract_lines_grid_inParams_statusId"
              ($refreshEvent)="refresh(false, false, '$tabs_inbound_charges')">
              </FootPrintManager-billing_contract_lines_grid>
              <FootPrintManager-billing_contract_lines_grid *ngIf="tabs.initial_charges.active"
              #$tabs_initial_charges
              [contractId]="$tabs_initial_charges_billing_contract_lines_grid_inParams_contractId"
              [strategyName]="$tabs_initial_charges_billing_contract_lines_grid_inParams_strategyName"
              [edit]="$tabs_initial_charges_billing_contract_lines_grid_inParams_edit"
              [hide]="$tabs_initial_charges_billing_contract_lines_grid_inParams_hide"
              [statusId]="$tabs_initial_charges_billing_contract_lines_grid_inParams_statusId"
              ($refreshEvent)="refresh(false, false, '$tabs_initial_charges')">
              </FootPrintManager-billing_contract_lines_grid>
              <FootPrintManager-billing_contract_lines_grid *ngIf="tabs.outbound_charges.active"
              #$tabs_outbound_charges
              [contractId]="$tabs_outbound_charges_billing_contract_lines_grid_inParams_contractId"
              [strategyName]="$tabs_outbound_charges_billing_contract_lines_grid_inParams_strategyName"
              [edit]="$tabs_outbound_charges_billing_contract_lines_grid_inParams_edit"
              [hide]="$tabs_outbound_charges_billing_contract_lines_grid_inParams_hide"
              [statusId]="$tabs_outbound_charges_billing_contract_lines_grid_inParams_statusId"
              ($refreshEvent)="refresh(false, false, '$tabs_outbound_charges')">
              </FootPrintManager-billing_contract_lines_grid>
              <FootPrintManager-billing_contract_lines_grid *ngIf="tabs.recurring_charges.active"
              #$tabs_recurring_charges
              [contractId]="$tabs_recurring_charges_billing_contract_lines_grid_inParams_contractId"
              [strategyName]="$tabs_recurring_charges_billing_contract_lines_grid_inParams_strategyName"
              [edit]="$tabs_recurring_charges_billing_contract_lines_grid_inParams_edit"
              [hide]="$tabs_recurring_charges_billing_contract_lines_grid_inParams_hide"
              [statusId]="$tabs_recurring_charges_billing_contract_lines_grid_inParams_statusId"
              ($refreshEvent)="refresh(false, false, '$tabs_recurring_charges')">
              </FootPrintManager-billing_contract_lines_grid>
              <FootPrintManager-billing_contract_lines_grid *ngIf="tabs.retainier_charges.active"
              #$tabs_retainier_charges
              [contractId]="$tabs_retainier_charges_billing_contract_lines_grid_inParams_contractId"
              [strategyName]="$tabs_retainier_charges_billing_contract_lines_grid_inParams_strategyName"
              [edit]="$tabs_retainier_charges_billing_contract_lines_grid_inParams_edit"
              [hide]="$tabs_retainier_charges_billing_contract_lines_grid_inParams_hide"
              [statusId]="$tabs_retainier_charges_billing_contract_lines_grid_inParams_statusId"
              ($refreshEvent)="refresh(false, false, '$tabs_retainier_charges')">
              </FootPrintManager-billing_contract_lines_grid>
              <Invoices-invoicing_rules_grid *ngIf="tabs.auto_invoicing_rules.active"
              #$tabs_auto_invoicing_rules
              [projectId]="$tabs_auto_invoicing_rules_invoicing_rules_grid_inParams_projectId"
              ($refreshEvent)="refresh(false, false, '$tabs_auto_invoicing_rules')">
              </Invoices-invoicing_rules_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>