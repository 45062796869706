
export class TabItemModel {
  private _hidden: boolean = false;
  removed: boolean = false;
  title: string;

  mediator: TabGroupModel;
  hasSubtabs?: boolean;
  tabs?: { [tabKey: string]: TabItemModel };

  get hidden() {
    return this._hidden;
  }

  get hasTabToShow() {
    return !this._hidden && !this.removed &&
      (!this.tabs || Object.values(this.tabs).some(x => x.hasTabToShow));
  }

  set hidden(value: boolean) {
    this._hidden = value;
    this.mediator.tabHidden(this);
  }

  get active() {
    return this.mediator.activeTab === this;
  }

  activate() {
    this.mediator.activeTab = this;
  }

  constructor(
    mediator: TabGroupModel,
    title: string,
    removed: boolean,
    hasSubtabs?: boolean,
    tabs?: { [tabKey: string]: TabItemModel }) {
    this.mediator = mediator;
    this.title = title;
    this.hasSubtabs = hasSubtabs;
    this.tabs = tabs;
    this.removed = removed;
  }

}

export class TabGroupModel {
  private _tabs: TabItemModel[];
  activeTab: TabItemModel;

  get tabs() {
    return this._tabs?.filter(x => !x.hidden && !x.removed) ?? [];
  }

  set tabs(value: TabItemModel[]) {
    this._tabs = value;
    this.setActiveTab();
  }

  tabHidden(tab: TabItemModel) {
    if ((tab.hidden || tab.removed) && this.activeTab == tab) {
      // current active tab was hidden/removed
      this.setActiveTab();
    }
  }

  get allTabsHidden(): boolean {
    return this._tabs?.every(tab => tab.hidden || tab.removed) ?? true;
  }

  private setActiveTab() {
    const visibleTab = this._tabs?.find(x => (!x.hidden && !x.removed && (!x.hasSubtabs || Object.values(x?.tabs ?? []).some(y => !y.hidden && !y.removed))));
    if (visibleTab) {
      this.activeTab = visibleTab;
    }
  }
}

