import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class LaborManagement_ds_task_assignment_editorService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { taskId: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, Priority?: number, Status?: { Id?: number, Name?: string }, OperationCode?: { Id?: number, Name?: string, WorkClassOperationCodes?: { Id?: number, WorkClass?: { Name?: string } }[] }, Material?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, Lot?: { LookupCode?: string }, Order?: { LookupCode?: string }, PickSlip?: { LookupCode?: string, Wave?: { Id?: number } }, ExpectedPackagedPack?: { Name?: string }, ExpectedSourceLocation?: { Name?: string }, ExpectedSourceLicensePlate?: { LookupCode?: string }, TaskAssignments?: { StatusId?: number, UserId?: string }[] } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.taskId)) {
      missingRequiredInParams.push('\'taskId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LaborManagement/datasources/ds_task_assignment_editor/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      taskId: number    }) {
        if(isNil(inParams.taskId)) {
          return true; 
        }
      return false;
    }
}
