import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class PurchaseOrders_ds_inbound_orders_grid_countService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], fullTextSearch?: string, fromDate?: string, toDate?: string, orderClassIds?: number[], take?: number, skip?: number, dateType?: string, reference?: string, orderIds?: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/PurchaseOrders/datasources/ds_inbound_orders_grid_count/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], fullTextSearch?: string, fromDate?: string, toDate?: string, orderClassIds?: number[], take?: number, skip?: number, dateType?: string, reference?: string, orderIds?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/PurchaseOrders/datasources/ds_inbound_orders_grid_count/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], fullTextSearch?: string, fromDate?: string, toDate?: string, orderClassIds?: number[], take?: number, skip?: number, dateType?: string, reference?: string, orderIds?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PurchaseOrders/datasources/ds_inbound_orders_grid_count/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], fullTextSearch?: string, fromDate?: string, toDate?: string, orderClassIds?: number[], take?: number, skip?: number, dateType?: string, reference?: string, orderIds?: number[]    }) {
      return false;
    }
}
