import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_receiving_label_report_multipleService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number }): Promise<{ result?: { Labels?: { OrderId?: number, OrderLookupCode?: string, OwnerReference?: string, ProjectLookupCode?: string, ProjectName?: string, OwnerLookupCode?: string, OwnerName?: string, ShipmentId?: number, ShipmentLookupCode?: string, LineNumber?: number, LotLookupCode?: string, VendorLotLookupCode?: string, MaterialLookupCode?: string, MaterialName?: string, MaterialDescription?: string, ShipToAddressFirstName?: string, ShipToAddressLastName?: string, ShipToAddressLine1?: string, ShipToAddressLine2?: string, ShipToAddressCity?: string, ShipToAddressState?: string, ShipToAddressPostalCode?: string, ShipToAddressAttentionOf?: string, VendorReference?: string }[] } }> {
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_receiving_label_report_multiple/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number    }) {
      return false;
    }
}
