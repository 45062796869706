import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Dynamics365BusinessCentral.frontend.types'

@Injectable({ providedIn: 'root' })
export class Dynamics365BusinessCentral_flow_order_so_create_so_lineService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { guid: string, p_id: number, o_id: number, shipmentid: number, orderid: number, qid: string, order_type: string, transaction_id: string }): Promise<{ out?: { val?: string[] }, out_err?: { err?: string[] }, okline?: { val?: number[] }, totalline?: { val?: number[] }, plookup?: { val?: string[] }, olookup?: { val?: string[] }, uom?: { val?: string[] }, result?: string, oln?: { val?: string[] } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.guid)) {
      missingRequiredInParams.push('\'guid\'');
    }
    if (isNil(inParams.p_id)) {
      missingRequiredInParams.push('\'p_id\'');
    }
    if (isNil(inParams.o_id)) {
      missingRequiredInParams.push('\'o_id\'');
    }
    if (isNil(inParams.shipmentid)) {
      missingRequiredInParams.push('\'shipmentid\'');
    }
    if (isNil(inParams.orderid)) {
      missingRequiredInParams.push('\'orderid\'');
    }
    if (isNil(inParams.qid)) {
      missingRequiredInParams.push('\'qid\'');
    }
    if (isNil(inParams.order_type)) {
      missingRequiredInParams.push('\'order_type\'');
    }
    if (isNil(inParams.transaction_id)) {
      missingRequiredInParams.push('\'transaction_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Dynamics365BusinessCentral/functions/flow_order_so_create_so_line`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

