import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Catalogs_ds_get_all_catalog_materials_by_project_idService } from './Catalogs.datasource.index';
import { Catalogs_ds_get_associated_materials_by_source_material_idService } from './Catalogs.datasource.index';
import { Catalogs_ds_get_material_by_catalog_id_top1Service } from './Catalogs.datasource.index';
import { Catalogs_ds_get_material_by_lookupcode_and_catalog_IdService } from './Catalogs.datasource.index';
import { Catalogs_ds_get_material_by_material_idService } from './Catalogs.datasource.index';
import { Catalogs_ds_get_material_catalog_by_catalog_idService } from './Catalogs.datasource.index';
import { Catalogs_ds_get_material_catalog_by_nameService } from './Catalogs.datasource.index';
import { Catalogs_ds_get_material_catalog_by_project_id_and_catalog_idService } from './Catalogs.datasource.index';
import { Catalogs_ds_get_material_catalog_details_by_material_idService } from './Catalogs.datasource.index';
import { Catalogs_ds_get_material_catalog_project_by_catalog_id_top1Service } from './Catalogs.datasource.index';
import { Catalogs_ds_get_material_catalogs_by_project_id_top1Service } from './Catalogs.datasource.index';
import { Catalogs_ds_get_material_count_by_project_id_and_catalog_idService } from './Catalogs.datasource.index';
import { Catalogs_ds_get_materials_by_project_idService } from './Catalogs.datasource.index';
import { Catalogs_ds_get_materials_by_project_id_with_catalogService } from './Catalogs.datasource.index';
import { Catalogs_ds_material_catalog_materials_by_project_ddService } from './Catalogs.datasource.index';
import { Catalogs_ds_material_catalog_source_material_project_subscribers_gridService } from './Catalogs.datasource.index';
import { Catalogs_ds_material_catalog_source_materials_gridService } from './Catalogs.datasource.index';
import { Catalogs_ds_material_catalogs_ddService } from './Catalogs.datasource.index';
import { Catalogs_ds_material_catalogs_project_subscribers_gridService } from './Catalogs.datasource.index';
import { Catalogs_ds_project_subscribers_ddService } from './Catalogs.datasource.index';

@Injectable({ providedIn: 'root' })
export class Catalogs_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Catalogs: Catalogs_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_all_catalog_materials_by_project_id: Catalogs_ds_get_all_catalog_materials_by_project_idService;
  public get ds_get_all_catalog_materials_by_project_id(): Catalogs_ds_get_all_catalog_materials_by_project_idService {
    if(!this._ds_get_all_catalog_materials_by_project_id) {
      this._ds_get_all_catalog_materials_by_project_id = this.injector.get(Catalogs_ds_get_all_catalog_materials_by_project_idService);
    }
    return this._ds_get_all_catalog_materials_by_project_id;
  }
  private _ds_get_associated_materials_by_source_material_id: Catalogs_ds_get_associated_materials_by_source_material_idService;
  public get ds_get_associated_materials_by_source_material_id(): Catalogs_ds_get_associated_materials_by_source_material_idService {
    if(!this._ds_get_associated_materials_by_source_material_id) {
      this._ds_get_associated_materials_by_source_material_id = this.injector.get(Catalogs_ds_get_associated_materials_by_source_material_idService);
    }
    return this._ds_get_associated_materials_by_source_material_id;
  }
  private _ds_get_material_by_catalog_id_top1: Catalogs_ds_get_material_by_catalog_id_top1Service;
  public get ds_get_material_by_catalog_id_top1(): Catalogs_ds_get_material_by_catalog_id_top1Service {
    if(!this._ds_get_material_by_catalog_id_top1) {
      this._ds_get_material_by_catalog_id_top1 = this.injector.get(Catalogs_ds_get_material_by_catalog_id_top1Service);
    }
    return this._ds_get_material_by_catalog_id_top1;
  }
  private _ds_get_material_by_lookupcode_and_catalog_Id: Catalogs_ds_get_material_by_lookupcode_and_catalog_IdService;
  public get ds_get_material_by_lookupcode_and_catalog_Id(): Catalogs_ds_get_material_by_lookupcode_and_catalog_IdService {
    if(!this._ds_get_material_by_lookupcode_and_catalog_Id) {
      this._ds_get_material_by_lookupcode_and_catalog_Id = this.injector.get(Catalogs_ds_get_material_by_lookupcode_and_catalog_IdService);
    }
    return this._ds_get_material_by_lookupcode_and_catalog_Id;
  }
  private _ds_get_material_by_material_id: Catalogs_ds_get_material_by_material_idService;
  public get ds_get_material_by_material_id(): Catalogs_ds_get_material_by_material_idService {
    if(!this._ds_get_material_by_material_id) {
      this._ds_get_material_by_material_id = this.injector.get(Catalogs_ds_get_material_by_material_idService);
    }
    return this._ds_get_material_by_material_id;
  }
  private _ds_get_material_catalog_by_catalog_id: Catalogs_ds_get_material_catalog_by_catalog_idService;
  public get ds_get_material_catalog_by_catalog_id(): Catalogs_ds_get_material_catalog_by_catalog_idService {
    if(!this._ds_get_material_catalog_by_catalog_id) {
      this._ds_get_material_catalog_by_catalog_id = this.injector.get(Catalogs_ds_get_material_catalog_by_catalog_idService);
    }
    return this._ds_get_material_catalog_by_catalog_id;
  }
  private _ds_get_material_catalog_by_name: Catalogs_ds_get_material_catalog_by_nameService;
  public get ds_get_material_catalog_by_name(): Catalogs_ds_get_material_catalog_by_nameService {
    if(!this._ds_get_material_catalog_by_name) {
      this._ds_get_material_catalog_by_name = this.injector.get(Catalogs_ds_get_material_catalog_by_nameService);
    }
    return this._ds_get_material_catalog_by_name;
  }
  private _ds_get_material_catalog_by_project_id_and_catalog_id: Catalogs_ds_get_material_catalog_by_project_id_and_catalog_idService;
  public get ds_get_material_catalog_by_project_id_and_catalog_id(): Catalogs_ds_get_material_catalog_by_project_id_and_catalog_idService {
    if(!this._ds_get_material_catalog_by_project_id_and_catalog_id) {
      this._ds_get_material_catalog_by_project_id_and_catalog_id = this.injector.get(Catalogs_ds_get_material_catalog_by_project_id_and_catalog_idService);
    }
    return this._ds_get_material_catalog_by_project_id_and_catalog_id;
  }
  private _ds_get_material_catalog_details_by_material_id: Catalogs_ds_get_material_catalog_details_by_material_idService;
  public get ds_get_material_catalog_details_by_material_id(): Catalogs_ds_get_material_catalog_details_by_material_idService {
    if(!this._ds_get_material_catalog_details_by_material_id) {
      this._ds_get_material_catalog_details_by_material_id = this.injector.get(Catalogs_ds_get_material_catalog_details_by_material_idService);
    }
    return this._ds_get_material_catalog_details_by_material_id;
  }
  private _ds_get_material_catalog_project_by_catalog_id_top1: Catalogs_ds_get_material_catalog_project_by_catalog_id_top1Service;
  public get ds_get_material_catalog_project_by_catalog_id_top1(): Catalogs_ds_get_material_catalog_project_by_catalog_id_top1Service {
    if(!this._ds_get_material_catalog_project_by_catalog_id_top1) {
      this._ds_get_material_catalog_project_by_catalog_id_top1 = this.injector.get(Catalogs_ds_get_material_catalog_project_by_catalog_id_top1Service);
    }
    return this._ds_get_material_catalog_project_by_catalog_id_top1;
  }
  private _ds_get_material_catalogs_by_project_id_top1: Catalogs_ds_get_material_catalogs_by_project_id_top1Service;
  public get ds_get_material_catalogs_by_project_id_top1(): Catalogs_ds_get_material_catalogs_by_project_id_top1Service {
    if(!this._ds_get_material_catalogs_by_project_id_top1) {
      this._ds_get_material_catalogs_by_project_id_top1 = this.injector.get(Catalogs_ds_get_material_catalogs_by_project_id_top1Service);
    }
    return this._ds_get_material_catalogs_by_project_id_top1;
  }
  private _ds_get_material_count_by_project_id_and_catalog_id: Catalogs_ds_get_material_count_by_project_id_and_catalog_idService;
  public get ds_get_material_count_by_project_id_and_catalog_id(): Catalogs_ds_get_material_count_by_project_id_and_catalog_idService {
    if(!this._ds_get_material_count_by_project_id_and_catalog_id) {
      this._ds_get_material_count_by_project_id_and_catalog_id = this.injector.get(Catalogs_ds_get_material_count_by_project_id_and_catalog_idService);
    }
    return this._ds_get_material_count_by_project_id_and_catalog_id;
  }
  private _ds_get_materials_by_project_id: Catalogs_ds_get_materials_by_project_idService;
  public get ds_get_materials_by_project_id(): Catalogs_ds_get_materials_by_project_idService {
    if(!this._ds_get_materials_by_project_id) {
      this._ds_get_materials_by_project_id = this.injector.get(Catalogs_ds_get_materials_by_project_idService);
    }
    return this._ds_get_materials_by_project_id;
  }
  private _ds_get_materials_by_project_id_with_catalog: Catalogs_ds_get_materials_by_project_id_with_catalogService;
  public get ds_get_materials_by_project_id_with_catalog(): Catalogs_ds_get_materials_by_project_id_with_catalogService {
    if(!this._ds_get_materials_by_project_id_with_catalog) {
      this._ds_get_materials_by_project_id_with_catalog = this.injector.get(Catalogs_ds_get_materials_by_project_id_with_catalogService);
    }
    return this._ds_get_materials_by_project_id_with_catalog;
  }
  private _ds_material_catalog_materials_by_project_dd: Catalogs_ds_material_catalog_materials_by_project_ddService;
  public get ds_material_catalog_materials_by_project_dd(): Catalogs_ds_material_catalog_materials_by_project_ddService {
    if(!this._ds_material_catalog_materials_by_project_dd) {
      this._ds_material_catalog_materials_by_project_dd = this.injector.get(Catalogs_ds_material_catalog_materials_by_project_ddService);
    }
    return this._ds_material_catalog_materials_by_project_dd;
  }
  private _ds_material_catalog_source_material_project_subscribers_grid: Catalogs_ds_material_catalog_source_material_project_subscribers_gridService;
  public get ds_material_catalog_source_material_project_subscribers_grid(): Catalogs_ds_material_catalog_source_material_project_subscribers_gridService {
    if(!this._ds_material_catalog_source_material_project_subscribers_grid) {
      this._ds_material_catalog_source_material_project_subscribers_grid = this.injector.get(Catalogs_ds_material_catalog_source_material_project_subscribers_gridService);
    }
    return this._ds_material_catalog_source_material_project_subscribers_grid;
  }
  private _ds_material_catalog_source_materials_grid: Catalogs_ds_material_catalog_source_materials_gridService;
  public get ds_material_catalog_source_materials_grid(): Catalogs_ds_material_catalog_source_materials_gridService {
    if(!this._ds_material_catalog_source_materials_grid) {
      this._ds_material_catalog_source_materials_grid = this.injector.get(Catalogs_ds_material_catalog_source_materials_gridService);
    }
    return this._ds_material_catalog_source_materials_grid;
  }
  private _ds_material_catalogs_dd: Catalogs_ds_material_catalogs_ddService;
  public get ds_material_catalogs_dd(): Catalogs_ds_material_catalogs_ddService {
    if(!this._ds_material_catalogs_dd) {
      this._ds_material_catalogs_dd = this.injector.get(Catalogs_ds_material_catalogs_ddService);
    }
    return this._ds_material_catalogs_dd;
  }
  private _ds_material_catalogs_project_subscribers_grid: Catalogs_ds_material_catalogs_project_subscribers_gridService;
  public get ds_material_catalogs_project_subscribers_grid(): Catalogs_ds_material_catalogs_project_subscribers_gridService {
    if(!this._ds_material_catalogs_project_subscribers_grid) {
      this._ds_material_catalogs_project_subscribers_grid = this.injector.get(Catalogs_ds_material_catalogs_project_subscribers_gridService);
    }
    return this._ds_material_catalogs_project_subscribers_grid;
  }
  private _ds_project_subscribers_dd: Catalogs_ds_project_subscribers_ddService;
  public get ds_project_subscribers_dd(): Catalogs_ds_project_subscribers_ddService {
    if(!this._ds_project_subscribers_dd) {
      this._ds_project_subscribers_dd = this.injector.get(Catalogs_ds_project_subscribers_ddService);
    }
    return this._ds_project_subscribers_dd;
  }
}

