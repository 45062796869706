import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class ShippingContainers_ds_get_shipping_containers_tasksService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { actualSourceShippingContainerId?: number, actualTargetShippingContainerId?: number, expectedSourceShippingContainerId?: number, expectedTargetShippingContainerId?: number, taskIds?: number[], dateTo?: string, dateFrom?: string, dateType?: string, warehouseIds?: number[], fullTextSearch?: string, chainHead?: number }): 
  Promise<{ result: { Id?: number, ActualInventoryAmount?: number, ActualInventoryAmountPackId?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualSourceLicensePlateId?: number, ActualSourceLocationId?: number, ActualSourceShippingContainerId?: number, ActualTargetLicensePlateId?: number, ActualTargetLocationId?: number, ActualTargetShippingContainerId?: number, ChainHead?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, CreatedSysUser?: string, Employee?: string, ExpectedInventoryAmount?: number, ExpectedInventoryAmountPackId?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, ExpectedSourceLocationId?: number, ExpectedSourceShippingContainerId?: number, ExpectedTargetLocationId?: number, ExpectedTargetShippingContainerId?: number, LotId?: number, MaterialId?: number, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, OperationCodeId?: number, OrderId?: number, ProjectId?: number, ShipmentId?: number, StartDateTime?: string, StatusId?: number, VendorLotId?: number, WarehouseId?: number, Status?: { Name?: string }, OperationCode?: { Name?: string }, Order?: { LookupCode?: string }, Shipment?: { LoadContainerId?: number, LookupCode?: string, LoadContainer?: { LookupCode?: string } }, ActualSourceShippingContainer?: { LookupCode?: string }, ActualTargetShippingContainer?: { LookupCode?: string }, ExpectedSourceShippingContainer?: { LookupCode?: string }, ExpectedTargetShippingContainer?: { LookupCode?: string }, ActualPackagedPack?: { Name?: string }, Warehouse?: { Name?: string }, ActualSourceLocation?: { Name?: string }, ActualTargetLocation?: { Name?: string }, ExpectedSourceLocation?: { Name?: string }, ExpectedTargetLocation?: { Name?: string }, Material?: { LookupCode?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, Project?: { Name?: string, OwnerId?: number, Owner?: { Name?: string } } }[] }> 
  {
    let url = `${environment.backendUrl}api/ShippingContainers/datasources/ds_get_shipping_containers_tasks/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { actualSourceShippingContainerId?: number, actualTargetShippingContainerId?: number, expectedSourceShippingContainerId?: number, expectedTargetShippingContainerId?: number, taskIds?: number[], dateTo?: string, dateFrom?: string, dateType?: string, warehouseIds?: number[], fullTextSearch?: string, chainHead?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualInventoryAmount?: number, ActualInventoryAmountPackId?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualSourceLicensePlateId?: number, ActualSourceLocationId?: number, ActualSourceShippingContainerId?: number, ActualTargetLicensePlateId?: number, ActualTargetLocationId?: number, ActualTargetShippingContainerId?: number, ChainHead?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, CreatedSysUser?: string, Employee?: string, ExpectedInventoryAmount?: number, ExpectedInventoryAmountPackId?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, ExpectedSourceLocationId?: number, ExpectedSourceShippingContainerId?: number, ExpectedTargetLocationId?: number, ExpectedTargetShippingContainerId?: number, LotId?: number, MaterialId?: number, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, OperationCodeId?: number, OrderId?: number, ProjectId?: number, ShipmentId?: number, StartDateTime?: string, StatusId?: number, VendorLotId?: number, WarehouseId?: number, Status?: { Name?: string }, OperationCode?: { Name?: string }, Order?: { LookupCode?: string }, Shipment?: { LoadContainerId?: number, LookupCode?: string, LoadContainer?: { LookupCode?: string } }, ActualSourceShippingContainer?: { LookupCode?: string }, ActualTargetShippingContainer?: { LookupCode?: string }, ExpectedSourceShippingContainer?: { LookupCode?: string }, ExpectedTargetShippingContainer?: { LookupCode?: string }, ActualPackagedPack?: { Name?: string }, Warehouse?: { Name?: string }, ActualSourceLocation?: { Name?: string }, ActualTargetLocation?: { Name?: string }, ExpectedSourceLocation?: { Name?: string }, ExpectedTargetLocation?: { Name?: string }, Material?: { LookupCode?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, Project?: { Name?: string, OwnerId?: number, Owner?: { Name?: string } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/ShippingContainers/datasources/ds_get_shipping_containers_tasks/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { actualSourceShippingContainerId?: number, actualTargetShippingContainerId?: number, expectedSourceShippingContainerId?: number, expectedTargetShippingContainerId?: number, taskIds?: number[], dateTo?: string, dateFrom?: string, dateType?: string, warehouseIds?: number[], fullTextSearch?: string, chainHead?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualInventoryAmount?: number, ActualInventoryAmountPackId?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualSourceLicensePlateId?: number, ActualSourceLocationId?: number, ActualSourceShippingContainerId?: number, ActualTargetLicensePlateId?: number, ActualTargetLocationId?: number, ActualTargetShippingContainerId?: number, ChainHead?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, CreatedSysUser?: string, Employee?: string, ExpectedInventoryAmount?: number, ExpectedInventoryAmountPackId?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, ExpectedSourceLocationId?: number, ExpectedSourceShippingContainerId?: number, ExpectedTargetLocationId?: number, ExpectedTargetShippingContainerId?: number, LotId?: number, MaterialId?: number, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, OperationCodeId?: number, OrderId?: number, ProjectId?: number, ShipmentId?: number, StartDateTime?: string, StatusId?: number, VendorLotId?: number, WarehouseId?: number, Status?: { Name?: string }, OperationCode?: { Name?: string }, Order?: { LookupCode?: string }, Shipment?: { LoadContainerId?: number, LookupCode?: string, LoadContainer?: { LookupCode?: string } }, ActualSourceShippingContainer?: { LookupCode?: string }, ActualTargetShippingContainer?: { LookupCode?: string }, ExpectedSourceShippingContainer?: { LookupCode?: string }, ExpectedTargetShippingContainer?: { LookupCode?: string }, ActualPackagedPack?: { Name?: string }, Warehouse?: { Name?: string }, ActualSourceLocation?: { Name?: string }, ActualTargetLocation?: { Name?: string }, ExpectedSourceLocation?: { Name?: string }, ExpectedTargetLocation?: { Name?: string }, Material?: { LookupCode?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, Project?: { Name?: string, OwnerId?: number, Owner?: { Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ShippingContainers/datasources/ds_get_shipping_containers_tasks/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      actualSourceShippingContainerId?: number, actualTargetShippingContainerId?: number, expectedSourceShippingContainerId?: number, expectedTargetShippingContainerId?: number, taskIds?: number[], dateTo?: string, dateFrom?: string, dateType?: string, warehouseIds?: number[], fullTextSearch?: string, chainHead?: number    }) {
      return false;
    }
}
