import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Notifications_ShellService } from './Notifications.shell.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { Notifications_LocalizationService } from './Notifications.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Notifications.frontend.types'
import { $frontendTypes as $types} from './Notifications.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { Notifications_auto_email_rule_reports_gridComponent } from './Notifications.auto_email_rule_reports_grid.component';
import { Notifications_owners_dd_singleComponent } from './Notifications.owners_dd_single.component'
import { Notifications_projects_dd_singleComponent } from './Notifications.projects_dd_single.component'
import { Notifications_auto_email_rule_contexts_dd_singleComponent } from './Notifications.auto_email_rule_contexts_dd_single.component'
import { Notifications_carriers_dd_singleComponent } from './Notifications.carriers_dd_single.component'
import { Notifications_dock_appointment_types_dd_singleComponent } from './Notifications.dock_appointment_types_dd_single.component'

type EntityType = { 
    id?: string, context?: string, filters?: string, to?: string, cc?: string, bcc?: string, subject?: string, body?: string, notes?: string, created_by?: string, created_on?: string, modified_by?: string, modified_on?: string, last_executed_on?: string, frequency?: string, projectId?: number, is_enabled?: boolean, include_attachments?: boolean, dock_appointment_carrier_id?: number, dock_appointment_type_id?: number}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Notifications_auto_email_rule_reports_gridComponent),
    forwardRef(() => Notifications_owners_dd_singleComponent),
    forwardRef(() => Notifications_projects_dd_singleComponent),
    forwardRef(() => Notifications_auto_email_rule_contexts_dd_singleComponent),
    forwardRef(() => Notifications_carriers_dd_singleComponent),
    forwardRef(() => Notifications_dock_appointment_types_dd_singleComponent),
  ],
  selector: 'Notifications-auto_email_rule_editor',
  templateUrl: './Notifications.auto_email_rule_editor.component.html'
})
export class Notifications_auto_email_rule_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { rule_id?: string, project_id?: number } = { rule_id: null, project_id: null };
  //#region Inputs
  @Input('rule_id') set $inParams_rule_id(v: string) {
    this.inParams.rule_id = v;
  }
  get $inParams_rule_id(): string {
    return this.inParams.rule_id;
  }
  @Input('project_id') set $inParams_project_id(v: number) {
    this.inParams.project_id = v;
  }
  get $inParams_project_id(): number {
    return this.inParams.project_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { entity?: string, parameter_field?: string, is_edit?: boolean, reports?: string[], schedule?: { dateOption: string, dateInterval: any, timeOption: string, timeInterval: any, timeOffset: number }, parameter_position?: number, validation_is_executing?: boolean } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    is_enabled: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    context: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    include_attachments: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    dock_appointment_carrier: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    dock_appointment_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    to: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    cc: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    bcc: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    subject: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    body: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      edit: new ToolModel(new ButtonModel('edit', new ButtonStyles(null, null), false, false, 'Edit', 'icon-ic_fluent_edit_20_regular', null)
    , false),
      save: new ToolModel(new ButtonModel('save', new ButtonStyles(['primary'], null), false, false, 'Save', 'icon-ic_fluent_save_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false),
      delete_rule: new ToolModel(new ButtonModel('delete_rule', new ButtonStyles(['destructive'], null), false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false),
      copy: new ToolModel(new ButtonModel('copy', new ButtonStyles(null, null), false, false, 'Copy', 'icon-ic_fluent_copy_20_regular', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      request_history: new ToolModel(new ButtonModel('request_history', new ButtonStyles(null, null), false, false, 'Request history', 'icon-ic_fluent_history_20_regular', null)
    , false)
  };

  fields = {
    is_enabled: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_enabled'] as DatexFormControl, null, false, 'Enabled', null)
, new ControlContainerStyles(null, null), '', false, false),
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Owner', false, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Project', true, false),
    context: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['context'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Trigger', true, false),
    include_attachments: new FieldModel(new CheckBoxModel(this.formGroup.controls['include_attachments'] as DatexFormControl, null, false, 'Include attachments', null)
, new ControlContainerStyles(null, null), '', false, false),
    set_schedule: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, false, 'Set schedule', 'icon-ic_fluent_calendar_20_regular', null)
, new ControlContainerStyles(null, null), '', false, false),
    dock_appointment_carrier: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['dock_appointment_carrier'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Dock appointment carrier', false, false),
    dock_appointment_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['dock_appointment_type'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Dock appointment type', false, false),
    to: new FieldModel(new TextBoxModel(this.formGroup.controls['to'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'To', true, false),
    use_cc: new FieldModel(new ButtonModel('', new ButtonStyles(['tertiary'], null), false, false, 'CC', '', null)
, new ControlContainerStyles(null, null), ' ', false, false),
    cc: new FieldModel(new TextBoxModel(this.formGroup.controls['cc'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'CC', false, false),
    use_bcc: new FieldModel(new ButtonModel('', new ButtonStyles(['tertiary'], null), false, false, 'BCC', '', null)
, new ControlContainerStyles(null, null), ' ', false, false),
    bcc: new FieldModel(new TextBoxModel(this.formGroup.controls['bcc'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'BCC', false, false),
    add_parameter: new FieldModel(new ButtonModel('', new ButtonStyles(['secondary'], null), false, false, 'Insert parameter', 'icon-ic_fluent_braces_variable_20_regular', null)
, new ControlContainerStyles(null, null), '', false, false),
    subject: new FieldModel(new TextBoxModel(this.formGroup.controls['subject'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Subject', true, false),
    body: new FieldModel(new TextBoxModel(this.formGroup.controls['body'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Body', false, false),
  };

  fieldsets = {
  header: new FieldsetModel('Header', true, false, true, false),
  recipients: new FieldsetModel('Recipients', false, false, true, false),
  content: new FieldsetModel('Content', false, false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      reports: new TabItemModel(
        this.rootTabGroup, 
        'Attached reports', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_reports_auto_email_rule_reports_grid_inParams_ruleId(): string {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.id;
      
      return expr;
    }
  
    get $tabs_reports_auto_email_rule_reports_grid_inParams_ruleContext(): string {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.fields.context.control.value;
      
      return expr;
    }
  
    get $tabs_reports_auto_email_rule_reports_grid_inParams_readOnly(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = !$editor.vars.is_edit;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_reports', { read: Notifications_auto_email_rule_reports_gridComponent }) $tabs_reports: Notifications_auto_email_rule_reports_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  get $fields_owner_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.owner.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    is_enabled: this.fields.is_enabled.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    owner: this.fields.owner.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    project: this.fields.project.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    context: this.fields.context.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    include_attachments: this.fields.include_attachments.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    dock_appointment_carrier: this.fields.dock_appointment_carrier.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    dock_appointment_type: this.fields.dock_appointment_type.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    to: this.fields.to.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    cc: this.fields.cc.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    bcc: this.fields.bcc.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    subject: this.fields.subject.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    body: this.fields.body.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Notifications_ShellService,
    private datasources: Notifications_DatasourceService,
    private flows: Notifications_FlowService,
    private reports: Notifications_ReportService,
    private localization: Notifications_LocalizationService,
    private operations: Notifications_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.reports,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'auto_email_rule_editor';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      rule_id:  $editor.inParams.rule_id 
    };

    const data = await this.datasources.Notifications.ds_auto_email_rule_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    private async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.is_enabled.control as CheckBoxModel).reset($editor.entity.is_enabled ?? true);
    (this.fields.project.control as SelectBoxModel).reset($editor.entity.projectId ?? $editor.inParams.project_id);
    (this.fields.context.control as SelectBoxModel).reset($editor.entity.context);
    (this.fields.include_attachments.control as CheckBoxModel).reset($editor.entity.include_attachments ?? false);
    (this.fields.dock_appointment_carrier.control as SelectBoxModel).reset($editor?.entity?.dock_appointment_carrier_id);
    (this.fields.dock_appointment_type.control as SelectBoxModel).reset($editor?.entity?.dock_appointment_type_id);
    (this.fields.to.control as TextBoxModel).reset($editor.entity.to);
    (this.fields.cc.control as TextBoxModel).reset($editor.entity.cc);
    (this.fields.bcc.control as TextBoxModel).reset($editor.entity.bcc);
    (this.fields.subject.control as TextBoxModel).reset($editor.entity.subject);
    (this.fields.body.control as TextBoxModel).reset($editor.entity.body);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_reports') {
        if (!isNil(this.$tabs_reports) && !this.tabs.reports.hidden && !this.tabs.reports.removed) {
          this.$tabs_reports.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .is_enabled
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_changed();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_changed();
      });
    this.$formGroupFieldValidationObservables
      .context
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_context_changed();
      });
    this.$formGroupFieldValidationObservables
      .include_attachments
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .dock_appointment_carrier
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .dock_appointment_type
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .to
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .cc
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .bcc
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .subject
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .body
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_owner_changed(event = null) {
    return this.on_owner_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changedInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($editor.fields.owner.control.value)) {
  
      $editor.fields.project.control.value = null;
  }
  
  // Fake to push validation flow
  let original_to = $editor.fields.to.control.value;
  $editor.fields.to.control.value = 'potato';
  $editor.fields.to.control.value = original_to;
  }
  on_project_changed(event = null) {
    return this.on_project_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changedInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  if (!$utils.isDefined($editor.fields.owner.control.value) && $utils.isDefined($editor.fields.project.control.value)) {
      let projects = (await $datasources.Notifications.ds_get_projects.get({projectIds: [$editor.fields.project.control.value]})).result;
  
      $editor.fields.owner.control.value = projects[0]?.Owner?.Id;
  }
  
  
  }
  on_schedule_clicked(event = null) {
    return this.on_schedule_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_schedule_clickedInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  let result = (await $shell.Notifications.openschedule_frequency_formDialog({ schedule: !$utils.isDefined($editor.vars.schedule) ? null : $editor.vars.schedule }, 'modal'));
  
  if ($utils.isDefined(result.schedule)) {
      $editor.vars.schedule = result.schedule;
  }
  
  await $editor.set_schedule();
  
  await $editor.set_state();
  }
  on_context_changed(event = null) {
    return this.on_context_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_context_changedInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  await $editor.set_state();
  
  if ($utils.isDefined($editor.entity.frequency) && $editor.fields.context.control.value?.trim()?.toUpperCase() !== 'SCHEDULE') {
      await $editor.clear_schedule();
  }
  
  if ($utils.isDefined($editor.inParams.rule_id)) {
      // Delete all existing reports
      let attachments = (await $datasources.Notifications.ds_auto_email_rule_reports.getList({ ruleId: $editor.inParams.rule_id })).result;
  
      for (let attachment of attachments) {
          await $flows.Notifications.delete_auto_email_rule_attachment_flow({ attachmentId: attachment.id });
      }
  }
  
  await $editor.tabs.reports.activate();
  }
  set_schedule(event = null) {
    return this.set_scheduleInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async set_scheduleInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  // Format schedule
  if ($utils.isDefined($editor.vars.schedule)) {
  
      $editor.fields.set_schedule.control.label = (await $flows.Notifications.auto_email_schedule_format_flow({
          schedule: $editor.vars.schedule
      })).scheduleString;
  
      $editor.fields.set_schedule.control.styles.clearClasses();
  } else {
      $editor.fields.set_schedule.control.label = `Please enter schedule details`;
      $editor.fields.set_schedule.control.styles.setDestructiveClass();
  }
  
  
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  if ($editor.valid) {
      if ($utils.isDefined($editor.inParams.rule_id)) {
          await update_rule();
      } else {
          await create_rule();
      }
  
      await set_schedule();
  
      await set_reports();
  
      $editor.refresh();
  }
  
  
  
  
  async function update_rule() {
      await $flows.Notifications.update_auto_email_rule_flow({
          ruleId: $editor.inParams.rule_id,
          ruleProperties: {
              context: $editor.fields.context.control.value,
              to: $editor.fields.to.control.value,
              cc: $editor.fields.cc.control.value,
              bcc: $editor.fields.bcc.control.value,
              subject: $editor.fields.subject.control.value,
              body: $editor.fields.body.control.value,
              projectId: $editor.fields.project.control.value,
              is_enabled: $editor.fields.is_enabled.control.value,
              include_attachments: $editor.fields.include_attachments.control.value,
              dock_appointment_carrier_id: $editor.fields.dock_appointment_carrier.control.value,
              dock_appointment_type_id: $editor.fields.dock_appointment_type.control.value
          }
      });
  }
  
  async function create_rule() {
      $editor.inParams.rule_id = (await $flows.Notifications.add_auto_email_rule_flow({
          ruleProperties: {
              context: $editor.fields.context.control.value,
              to: $editor.fields.to.control.value,
              cc: $editor.fields.cc.control.value,
              bcc: $editor.fields.bcc.control.value,
              subject: $editor.fields.subject.control.value,
              body: $editor.fields.body.control.value,
              projectId: $editor.fields.project.control.value,
              is_enabled: $editor.fields.is_enabled.control.value,
              include_attachments: $editor.fields.include_attachments.control.value,
              dock_appointment_carrier_id: $editor.fields.dock_appointment_carrier.control.value,
              dock_appointment_type_id: $editor.fields.dock_appointment_type.control.value
          }
      })).ruleId;
  
      $editor.entity.id = $editor.inParams.rule_id;
  }
  
  async function set_reports() {
      let reports = (await $datasources.Notifications.ds_auto_email_rule_reports.getList({ ruleId: $editor.entity.id })).result;
  
      if (!$utils.isDefined(reports)) {
          reports = [];
      }
      
      // Create
      let reports_to_create = $editor.vars.reports.filter(new_report => !reports.some(existing_report => new_report.trim().toUpperCase() === existing_report.name.trim().toUpperCase()));
      for (let report of reports_to_create) {
          let result = await $flows.Notifications.add_auto_email_rule_attachment_flow({
              ruleId: $editor.entity.id,
              properties: {
                  rule_id: $editor.entity.id,
                  type: 'REPORT',
                  name: report
              }
          });
      }
  
      await new Promise(result => setTimeout(result, 100));
  
      // Delete
      let reports_to_delete = reports.filter(existing_report => !$editor.vars.reports.some(new_report => new_report.trim().toUpperCase() === existing_report.name.trim().toUpperCase()));
  
      for (let report of reports_to_delete) {
          await $flows.Notifications.delete_auto_email_rule_attachment_flow({ attachmentId: report.id });
      }
  }
  
  async function set_schedule() {
      $editor.entity.frequency = $utils.isDefined($editor.vars.schedule) ? JSON.stringify($editor.vars.schedule) : null;
  
      await $flows.Notifications.update_auto_email_rule_flow({
          ruleId: $editor.entity.id,
          ruleProperties: {
              frequency: $editor.entity.frequency
          }
      });
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  $editor.vars.parameter_field = 'subject';
  $editor.vars.parameter_position = 0;
  $editor.vars.validation_is_executing = false;
  
  $editor.vars.is_edit = true //!$utils.isDefined($editor.inParams.rule_id);
  
  $editor.vars.reports = [];
  
  $editor.toolbar.save.control.readOnly = true;
  
  $editor.fields.cc.hidden = true;
  $editor.fields.bcc.hidden = true;
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  // Initialize field caches
  if ($utils.isDefinedTrimmed($editor.entity.frequency)) {
      $editor.vars.schedule = JSON.parse($editor.entity.frequency);
  }
  
  $editor.set_state()
  
  capture_cursor_position();
  
  
  /********************************************
   * EVENTS
  *********************************************/
  async function capture_cursor_position() {
      await new Promise(result => setTimeout(result, 500));
  
      let subject = document.querySelector('[formcontrolname="subject"]') as HTMLTextAreaElement;
  
      subject.addEventListener("click", () => {
          $editor.vars.parameter_position = subject.selectionStart;
          $editor.vars.parameter_field = 'subject';
      });
  
      subject.addEventListener("keyup", () => {
          $editor.vars.parameter_position = subject.selectionStart;
          $editor.vars.parameter_field = 'subject';
      });
  
      subject.addEventListener('keydown', create_key_down_handler(subject));
  
      let body = document.querySelector('[formcontrolname="body"]') as HTMLTextAreaElement;
  
      body.addEventListener("click", () => {
          $editor.vars.parameter_position = body.selectionStart;
          $editor.vars.parameter_field = 'body';
      });
  
      body.addEventListener("keyup", () => {
          $editor.vars.parameter_position = body.selectionStart;
          $editor.vars.parameter_field = 'body';
      });
  
      body.addEventListener('keydown', create_key_down_handler(body));
  }
  
  function get_parameters(field: string) {
      const regex = /\{[^}]*\}/g;
      let match;
      let parameters: string[] = [];
  
      while ((match = regex.exec(field)) !== null) {
          console.log(match);
          parameters.push(...match);
      }
  
      return parameters;
  }
  
  
  function replace_text(field: HTMLTextAreaElement, newText: string, cursorPosition: number) {
      // Update the value of the input element
      field.value = newText;
  
      // Create a custom input event to trigger the undo stack
      const inputEvent = new InputEvent('input', {
          bubbles: true,
          cancelable: true,
          inputType: 'insertText',
          data: newText
      });
  
      // Dispatch the input event
      field.dispatchEvent(inputEvent);
  
      // Restore the cursor position
      field.setSelectionRange(cursorPosition, cursorPosition);
  }
  
  
  function create_key_down_handler(field: HTMLTextAreaElement) {
      return function(event: KeyboardEvent) {
          handle_key_down(event, field);
      }
  }
  
  function handle_key_down(event: KeyboardEvent, field: HTMLTextAreaElement) {
      const { selectionStart, selectionEnd, value } = field;
  
      let parameter_positions = get_parameters_position_range(field.value);
  
      const is_delete = ['Backspace', 'Delete'].includes(event.key);
  
      for (let parameter_position of parameter_positions) {
          let is_parameter_affected = parameter_position.start !== -1 && parameter_position.end !== -1 &&
              parameter_position.start < selectionStart + (event.key === 'Delete' ? 1 : 0) &&
              parameter_position.end > selectionEnd - (event.key === 'Backspace' ? 1 : 0);
  
          if (is_parameter_affected && !is_delete) {
              if (!['ArrowUp','ArrowDown','ArrowLeft','ArrowRight','Home','End'].includes(event.key)) {
                  event.preventDefault();
              }
              break;
          } else if (is_parameter_affected && is_delete) {
              event.preventDefault();
              replace_text(field, field.value.slice(0, parameter_position.start) + field.value.slice(parameter_position.end), parameter_position.start);
              break;
          }
      }
  }
  
  function get_parameters_position_range(field: string) {
      const regex = /\{[^}]*\}/g;
      let match;
      const indexes: { start: number, end: number }[] = [];
  
      while ((match = regex.exec(field)) !== null) {
          indexes.push({ start: match.index, end: regex.lastIndex });
      }
  
      return indexes;
  }
  
  }
  set_state(event = null) {
    return this.set_stateInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async set_stateInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  get_entity();
  
  set_read_only_controls();
  
  if ($utils.isDefined($editor.inParams.rule_id)) {
      $editor.title = 'Edit auto-email rule';
  
      if ($utils.isDefined($editor.entity.projectId) && !$utils.isDefined($editor.fields.owner.control.value)) {
          let project = (await $datasources.Notifications.ds_get_projects.getList({ $top: 1, projectIds: [$editor.entity.projectId] })).result[0];
  
          $editor.fields.owner.control.value = project?.Owner?.Id;
          $editor.fields.project.control.value = $editor.entity.projectId;
      }
  
      $editor.tabs.reports.hidden = false;
      $editor.toolbar.delete_rule.hidden = false;
      $editor.toolbar.separator1.hidden = false;
      $editor.toolbar.request_history.hidden = false;
  
      await $editor.set_schedule();
  }
  else {
      $editor.title = 'Create auto-email rule';
  
      $editor.toolbar.delete_rule.hidden = true;
      $editor.toolbar.separator1.hidden = true;
      $editor.toolbar.request_history.hidden = true;
  
      if ($utils.isDefined($editor.inParams.project_id)) {
          let project = (await $datasources.Notifications.ds_get_projects.getList({ $top: 1, projectIds: [$editor.inParams.project_id] })).result[0];
  
          $editor.fields.owner.control.value = project?.Owner?.Id;
          $editor.fields.project.control.value = project.Id;
  
          $editor.fields.owner.hidden = true;
          $editor.fields.project.hidden = true;
      }
  
      $editor.tabs.reports.hidden = false;
  }
  
  
  $editor.fields.use_cc.hidden = $utils.isDefined($editor.entity.cc);
  $editor.fields.cc.hidden = !$utils.isDefined($editor.entity.cc);
  $editor.fields.use_bcc.hidden = $utils.isDefined($editor.entity.bcc);
  $editor.fields.bcc.hidden = !$utils.isDefined($editor.entity.bcc);
  
  if ($editor.fields.context.control.value?.trim()?.toUpperCase() === 'SCHEDULE') {
      $editor.fields.set_schedule.hidden = false;
      $editor.fields.include_attachments.hidden = true;
      $editor.fields.include_attachments.control.value = false;
      $editor.fields.dock_appointment_carrier.hidden = true;
      $editor.fields.dock_appointment_type.hidden = true;
  }
  else if ($editor.fields.context.control.value?.trim()?.toUpperCase() === 'DOCK APPOINTMENT COMPLETED') 
  {
      $editor.fields.set_schedule.hidden = true;
      $editor.fields.include_attachments.hidden = !$utils.isDefinedTrimmed($editor.fields.context.control.value);
      $editor.fields.dock_appointment_carrier.hidden = false;
      $editor.fields.dock_appointment_type.hidden = false;    
  
  }
  else {
      $editor.fields.set_schedule.hidden = true;
      $editor.fields.include_attachments.hidden = !$utils.isDefinedTrimmed($editor.fields.context.control.value);
      $editor.fields.dock_appointment_carrier.hidden = true;
      $editor.fields.dock_appointment_type.hidden = true;    
  }
  
  // Hide separators
  $editor.toolbar.separator1.hidden = $editor.toolbar.delete_rule.hidden
  $editor.toolbar.separator2.hidden = $editor.toolbar.request_history.hidden
  
  
  await $editor.set_schedule();
  /***************************************
   * FUNCTIONS
  ****************************************/
  function set_read_only_controls() {
      // Set edit control
      if ($editor.vars.is_edit) {
          if (!$utils.isDefined($editor.inParams.rule_id)) {
              $editor.toolbar.cancel.control.styles.setDestructiveClass();
              $editor.toolbar.cancel.control.label = 'Discard';
          } else {
              $editor.toolbar.cancel.control.styles.clearClasses();
              $editor.toolbar.cancel.control.label = 'Cancel';
          }
  
          $editor.toolbar.save.hidden = false;
          $editor.toolbar.cancel.hidden = false;
          $editor.toolbar.edit.hidden = true;
  
          for (let key in $editor.fields) {
              $editor.fields[key].control.readOnly = false;
          }
  
          $editor.fields.add_parameter.control.readOnly = !$utils.isAllDefined($editor.vars.entity, $editor.vars.parameter_field, $editor.vars.parameter_position);
      } else {
          $editor.toolbar.save.hidden = true;
          $editor.toolbar.cancel.hidden = true;
          $editor.toolbar.edit.hidden = false;
  
          for (let key in $editor.fields) {
              $editor.fields[key].control.readOnly = true;
          }
      }
  }
  
  function get_entity() {
      // Set entity
      let new_entity = $editor.vars.entity;
      switch ($editor.fields.context.control.value) {
          case $types.Notifications.AutoEmailRuleContexts.Schedule:
              new_entity = $types.Notifications.AutoEmailEntityTypes.Project;
  
              break;
          case $types.Notifications.AutoEmailRuleContexts.InboundOrderCompleted:
              new_entity = $types.Notifications.AutoEmailEntityTypes.Order;
              break;
          case $types.Notifications.AutoEmailRuleContexts.OutboundOrderCompleted:
              new_entity = $types.Notifications.AutoEmailEntityTypes.Order;
  
              break;
          case $types.Notifications.AutoEmailRuleContexts.InvoiceExported:
              new_entity = $types.Notifications.AutoEmailEntityTypes.Invoice;
              break;
          case $types.Notifications.AutoEmailRuleContexts.DockAppointmentCompleted:
              new_entity = $types.Notifications.AutoEmailEntityTypes.DockAppointment;
              break;
          default:
              break;
      }
  
      $editor.vars.entity = new_entity;
  
      $editor.fields.include_attachments.control.label = `Include ${$utils.isDefined($editor.vars.entity) ? $editor.vars.entity.toLowerCase() + ' ' : ''}attachments`;
  }
  }
  clear_schedule(event = null) {
    return this.clear_scheduleInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async clear_scheduleInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  $editor.entity.frequency = null;
      
  await $flows.Notifications.update_auto_email_rule_flow({ ruleId: $editor.entity.id, ruleProperties: { frequency: '' } });
  }
  on_request_history_clicked(event = null) {
    return this.on_request_history_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_request_history_clickedInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  await $shell.Notifications.openemail_requests_gridDialog({
      ruleId: $editor.entity.id
  }, 'flyout', EModalSize.Xlarge);
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  let is_confirmed = await $shell.Notifications.openConfirmationDialog('Delete auto-email rule?', `Are you sure you want to delete this rule?`, 'Delete rule', 'Cancel');
  if (!is_confirmed) {
      return;
  }
  
  
  
  let attachments = (await $datasources.Notifications.ds_auto_email_rule_reports.getList({ ruleId: $editor.entity.id })).result;
  
  for (let attachment of attachments) {
      await $flows.Notifications.delete_auto_email_rule_attachment_flow({ attachmentId: attachment.id });
  }
  
  let result = await $flows.Notifications.delete_auto_email_rule_flow({ ruleId: $editor.entity.id });
  
  $editor.close();
  
  $shell.Notifications.openToaster(
      'Success',
      'Auto-email rule has been deleted',
      EToasterType.Success,
      {
          timeOut: 4000,
          positionClass: EToasterPosition.topRight
      }
  )
  }
  on_add_parameter_clicked(event = null) {
    return this.on_add_parameter_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_add_parameter_clickedInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  
  const result = (await $shell.Notifications.openauto_email_field_parameters_by_entity_gridDialog({ entity: $editor.vars.entity }, 'modal', EModalSize.Standard));
  
  if ($utils.isDefined(result.parameters)) {
      let field_value = $editor.fields[$editor.vars.parameter_field].control.value ?? '';
  
      $editor.fields[$editor.vars.parameter_field].control.value = 
          field_value.substring(0, $editor.vars.parameter_position) + result.parameters.map(p => `{${p}}`).join('') + field_value.substring($editor.vars.parameter_position);
  }
  
  $editor.fields[$editor.vars.parameter_field].control.focus();
  
  let field = document.querySelector(`[formcontrolname="${$editor.vars.parameter_field}"]`) as HTMLTextAreaElement;
  
  if ($utils.isDefined(field)) {
      let new_position = $editor.vars.parameter_position + result.parameters.reduce((total: number, parameter) => total += parameter.length + 2, 0);
      field.setSelectionRange(new_position, new_position);
  }
  }
  on_edit_clicked(event = null) {
    return this.on_edit_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_clickedInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  $editor.vars.is_edit = true;
  
  $editor.refresh();
  }
  on_save_clicked(event = null) {
    return this.on_save_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_clickedInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  while ($editor.vars.validation_is_executing) {
      await new Promise(result => setTimeout(result, 25));
  }
  
  if (!$editor.valid) {
      return;
  }
  
  await $editor.on_save();
  
  $editor.vars.is_edit = false;
  $editor.set_state();
  }
  on_reports_changed(event = null) {
    return this.on_reports_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_reports_changedInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  $editor.vars.reports = $event.reports ?? [];
  
  await $editor.set_state();
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  $editor.vars.is_edit = false;
  
  if (!$utils.isDefined($editor.inParams.rule_id)) {
      $editor.close();
      return;
  }
  
  $editor.refresh();
  }
  on_use_cc_clicked(event = null) {
    return this.on_use_cc_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_use_cc_clickedInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  $editor.fields.use_cc.hidden = true;
  $editor.fields.cc.hidden = false;
  }
  on_use_bcc_clicked(event = null) {
    return this.on_use_bcc_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_use_bcc_clickedInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  $editor.fields.use_bcc.hidden = true;
  $editor.fields.bcc.hidden = false;
  }
  on_copy_clicked(event = null) {
    return this.on_copy_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_copy_clickedInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  await $shell.Notifications.openauto_email_rule_copy_formDialog({
      rule_id: $editor.entity.id
  },'modal');
  
  
  }
  //#endregion private flows
  //#region validation flows
  
  validate_form(fieldErrors: { is_enabled: Pick<string[], 'push'>,owner: Pick<string[], 'push'>,project: Pick<string[], 'push'>,context: Pick<string[], 'push'>,include_attachments: Pick<string[], 'push'>,dock_appointment_carrier: Pick<string[], 'push'>,dock_appointment_type: Pick<string[], 'push'>,to: Pick<string[], 'push'>,cc: Pick<string[], 'push'>,bcc: Pick<string[], 'push'>,subject: Pick<string[], 'push'>,body: Pick<string[], 'push'>, } = null) {
    const emptyResult = { is_enabled:[],owner:[],project:[],context:[],include_attachments:[],dock_appointment_carrier:[],dock_appointment_type:[],to:[],cc:[],bcc:[],subject:[],body:[], };
    if (!this.initialized){
      return Promise.resolve(emptyResult);
    }
    return this.validate_formInternal(
      this,
      { fieldErrors: fieldErrors ?? emptyResult },
      this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      );
  }
  async validate_formInternal(
    $editor: Notifications_auto_email_rule_editorComponent,
    $validation:{
      fieldErrors: { is_enabled: Pick<string[], 'push'>,owner: Pick<string[], 'push'>,project: Pick<string[], 'push'>,context: Pick<string[], 'push'>,include_attachments: Pick<string[], 'push'>,dock_appointment_carrier: Pick<string[], 'push'>,dock_appointment_type: Pick<string[], 'push'>,to: Pick<string[], 'push'>,cc: Pick<string[], 'push'>,bcc: Pick<string[], 'push'>,subject: Pick<string[], 'push'>,body: Pick<string[], 'push'>, }
    },
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
  ) {
    $editor.vars.validation_is_executing = true;
  try {
      check_required_fields();
  
      $editor.fields.add_parameter.control.readOnly = !$utils.isAllDefined($editor.vars.entity, $editor.vars.parameter_field, $editor.vars.parameter_position);
  
      let promises: any[] = [];
      promises.push(checkInvalidEmails('To', $editor.fields.to.control.value));
      promises.push(checkInvalidEmails('CC', $editor.fields.cc.control.value));
      promises.push(checkInvalidEmails('BCC', $editor.fields.bcc.control.value));
  
      promises.push(checkForRepeatedEmails('BCC', $editor.fields.cc.control.value, 'CC', $editor.fields.bcc.control.value));
      promises.push(checkForRepeatedEmails('BCC', $editor.fields.to.control.value, 'To', $editor.fields.bcc.control.value));
      promises.push(checkForRepeatedEmails('CC', $editor.fields.to.control.value, 'To', $editor.fields.cc.control.value));
  
      // Check for purely repeated emails
      promises.push(checkForDuplicateEmails('BCC', $editor.fields.bcc.control.value));
      promises.push(checkForDuplicateEmails('CC', $editor.fields.cc.control.value));
      promises.push(checkForDuplicateEmails('To', $editor.fields.to.control.value));
  
      if ($utils.isDefined($editor.vars.entity)) {
          promises.push(validate_parameterized_fields());
      }
  
      await Promise.all(promises);
  
      let has_field_errors = false;
      for (let key in $validation.fieldErrors) {
          if ($utils.isDefined($validation.fieldErrors[key])) {
              has_field_errors = true;
              break;
          }
      }
  
      $editor.toolbar.save.control.readOnly = has_field_errors;
  } catch (error) {
      let target_error = error;
      while ($utils.isDefined(target_error.error)) {
          target_error = target_error.error;
      }
  
      $shell.Notifications.openToaster(`Error validating editor`, target_error.message, EToasterType.Error, { disableTimeOut: true, tapToDismiss: true });
  }
  
  $editor.vars.validation_is_executing = false;
  
  /*****************************************************
   * FUNCTIONS
  ******************************************************/
  function check_required_fields() {
      if ($utils.isDefined($editor.fields.context.control.value) && $editor.fields.context.control.value.trim().toUpperCase() === 'SCHEDULE' && !$utils.isDefinedTrimmed($editor.vars.schedule)) {
          $editor.fields.set_schedule.control.styles.setDestructiveClass();
          $editor.fields.set_schedule.control.label = `Please enter schedule details`;
          $validation.fieldErrors.context.push('Missing schedule details');
      }
  }
  
  /*** Check email formatting ***/
  async function checkInvalidEmails(fieldName: string, emailString: string) {
      if ($utils.isDefinedTrimmed(emailString)) {
          let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          let invalidEmails: string[] = [];
  
          let emails = (await $flows.Notifications.parse_emails_flow({ email_string: emailString })).emails;
  
          for (let email of emails) {
              if ($utils.isDefinedTrimmed(email) && !regex.test(email)) {
                  invalidEmails.push(email);
              }
          }
  
          if ($utils.isDefined(invalidEmails)) { $validation.fieldErrors[fieldName.toLowerCase()].push(`Invalid email${invalidEmails.length > 1 ? 's' : ''}: ${invalidEmails.join(', ')}`); }
      }
  }
  
  // Check if any identical email addresses exist in the to/cc/bcc fields
  async function checkForRepeatedEmails(fieldName1: string, emailString1: string, fieldName2: string, emailString2: string) {
      if ($utils.isDefined(emailString1) && $utils.isDefined(emailString2)) {
          let list1 = (await $flows.Notifications.parse_emails_flow({ email_string: emailString1 })).emails;
          let list2 = (await $flows.Notifications.parse_emails_flow({ email_string: emailString2 })).emails;
  
          let duplicatedEmails: string[] = [];
  
          for (const email of list1) {
              if (list2.includes(email)) {
                  duplicatedEmails.push(email);
              }
          }
  
          if ($utils.isDefined(duplicatedEmails)) { $validation.fieldErrors[fieldName1.toLowerCase()].push(`Email${duplicatedEmails.length > 1 ? 's' : ''} already exists in '${fieldName2}' field: ${duplicatedEmails.join(', ')}`); }
      }
  }
  
  async function checkForDuplicateEmails(fieldName: string, emailString: string) {
      if ($utils.isDefinedTrimmed(emailString)) {
          let emails = (await $flows.Notifications.parse_emails_flow({ email_string: emailString })).emails;
  
          let duplicateEmails: string[] = [];
          let uniqueValues = new Set<string>();
  
          for (const email of emails) {
              if (uniqueValues.has(email)) {
                  if (!duplicateEmails.includes(email)) {
                      duplicateEmails.push(email);
                  }
              }
              else {
                  uniqueValues.add(email);
              }
          }
  
          if ($utils.isDefined(duplicateEmails)) { $validation.fieldErrors[fieldName.toLowerCase()].push(`Contains email${duplicateEmails.length > 1 ? 's' : ''} multiple times: ${duplicateEmails.join(', ')}`); }
      }
  }
  
  
  
  async function validate_parameterized_fields() {
      let supported_entities = (await $datasources.Notifications.ds_get_auto_email_field_parameters_by_context.getList({ entity: $editor.vars.entity })).result;
  
      let parameterized_fields = [
          'subject',
          'body'
      ];
  
      for (let field_key of parameterized_fields) {
          if ($utils.isDefined($editor.fields[field_key].control.value)) {
              let parameter_keys: string[] = [];
              if ($utils.isDefined($editor.fields[field_key].control.value)) {
                  const regex = /{([^}]+)}/g;
  
                  let field_parameters = [...$editor.fields[field_key].control.value.matchAll(regex)].map(match => match[1]);
  
                  parameter_keys.push(...field_parameters.filter(p => !parameter_keys.includes(p)));
              }
  
              let invalid_parameters: string[] = [];
              for (let key of parameter_keys) {
                  if (!supported_entities.some(e => e.parameter === key)) {
                      invalid_parameters.push(key);
                  }
              }
  
              if ($utils.isDefined(invalid_parameters)) {
                  $validation.fieldErrors[field_key].push(`Invalid parameter${invalid_parameters.length > 1 ? 's' : ''}: ${(await $flows.Utilities.grammar_format_string_array_flow({ values: invalid_parameters })).formattedValue}`);
              }
          }
      }
  }
    return $validation.fieldErrors as { is_enabled:[],owner:[],project:[],context:[],include_attachments:[],dock_appointment_carrier:[],dock_appointment_type:[],to:[],cc:[],bcc:[],subject:[],body:[], };
  }
  //#endregion validation flows
  
}
