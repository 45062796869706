import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



interface IFootPrintManager_transload_orders_gridComponentEntity {
Id?: number, CreatedSysDateTime?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredWarehouseId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Id?: number, Name?: string }, OrderClass?: { LookupCode?: string }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, PreferredWarehouse?: { Id?: number, Name?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierService?: { CarrierId?: number }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { BillOfLading?: string, ConveyanceId?: number, ConveyenceReference?: string, LookupCode?: string, Notes?: string, ReferenceNumber?: string, ExpectedWarehouse?: { Name?: string }, Conveyance?: { LookupCode?: string } } }[], Addresses?: { AttentionOf?: string, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[], TransLoadsForInboundOrder?: { Id?: number, OutboundOrderId?: number, OutboundOrder?: { Id?: number, OrderStatusId?: number, Status?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { ConveyanceId?: number, ConveyenceReference?: string, Conveyance?: { LookupCode?: string } } }[] } }[], total_received?: { TotalCount?: number, ExpectedCount?: number, OrderId?: number }, orderline_count?: { ExpectedCount?: number, OrderId?: number }, calculated_total_items?: string}

interface IFootPrintManager_transload_orders_gridComponentInParams {
  ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], materialIds?: number[]}


class FootPrintManager_transload_orders_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_transload_orders_gridComponent;
  entity: IFootPrintManager_transload_orders_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    lookupcode: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    orderclass: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    owner: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    project: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    warehouse: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    total_items: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    created_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    vendor_reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    owner_reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    inbound_conveyance: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    inbound_conveyance_reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_transload_orders_gridComponent, entity: IFootPrintManager_transload_orders_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_transload_orders_gridComponent, entity?: IFootPrintManager_transload_orders_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      warehouseId:  $grid.inParams.warehouseId ,
      statusIds:  $grid.inParams.statusIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      createdFrom:  $grid.filters.created_from.control.value ,
      createdTo:  $grid.filters.created_to.control.value ,
      materialIds:  $grid.inParams.materialIds ,
    };
    const data = await this.datasources.FootPrintManager.ds_transload_orders_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status?.Name;
    (this.cells.orderclass.displayControl as TextModel).text = $row.entity.OrderClass?.LookupCode;
    (this.cells.owner.displayControl as TextModel).text = $row.entity.Project?.Owner?.LookupCode;
    (this.cells.warehouse.displayControl as TextModel).text = $utils.isDefined($row.entity.PreferredWarehouse?.Name) ? $row.entity.PreferredWarehouse?.Name : $row.entity.ShipmentOrderLookups[0]?.Shipment?.ExpectedWarehouse?.Name;
    (this.cells.total_items.displayControl as TextModel).text = $row.entity.calculated_total_items;
    (this.cells.vendor_reference.displayControl as TextModel).text = $row.entity.VendorReference;
    (this.cells.owner_reference.displayControl as TextModel).text = $row.entity.OwnerReference;
    (this.cells.inbound_conveyance.displayControl as TextModel).text = $row.entity.ShipmentOrderLookups[0]?.Shipment?.Conveyance?.LookupCode;
    (this.cells.inbound_conveyance_reference.displayControl as TextModel).text = $row.entity.ShipmentOrderLookups[0]?.Shipment?.ConveyenceReference;
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'lookupcode' : {
        this.on_lookupcode_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_lookupcode_clicked(event = null) {
    return this.on_lookupcode_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lookupcode_clickedInternal(
    $row: FootPrintManager_transload_orders_gridComponentRowModel,
  $grid: FootPrintManager_transload_orders_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  $shell.FootPrintManager.opentransload_order_editor({ orderId: $row.entity.Id });
  
  
  
  
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_transload_orders_gridComponentRowModel,
  $grid: FootPrintManager_transload_orders_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  // Set Date/Time Formats
  const format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  // Apply date format
  if ($utils.isDefined($row.entity.CreatedSysDateTime)) {
      $row.cells.created_date.displayControl.text = $utils.date.format($row.entity.CreatedSysDateTime, format);
  };
  
  
  // Apply project column formating
  if ($row.entity.Project.LookupCode === $row.entity.Project.Name) {
      $row.cells.project.displayControl.text = $row.entity.Project.LookupCode
  } else {
      $row.cells.project.displayControl.text = $row.entity.Project.LookupCode + ' ' + $row.entity.Project.Name
  }
  
  // Set custom status when the inbound or outbound order are in a processing status
  const inboundOrderStatus = $row.entity.OrderStatusId;
  const outboundOrderStatus = $row.entity.TransLoadsForInboundOrder[0]?.OutboundOrder?.OrderStatusId;
  
  if (inboundOrderStatus === 2 || outboundOrderStatus === 2) {
  
  
      if ($utils.isDefined($row.entity.TransLoadsForInboundOrder[0]?.Id)) {
          // Set custom status
          let transload_state = await $flows.TransloadOrders.get_transload_order_state({ transloadId: $row.entity.TransLoadsForInboundOrder[0]?.Id });
  
          switch (transload_state.state) {
  
              case 2: {
                  $row.cells.status.displayControl.text = 'Ready to receive'
                  break;
              }
              case 3: {
                  $row.cells.status.displayControl.text = 'Receiving in progress'
                  break;
              }
              case 4: {
                  $row.cells.status.displayControl.text = 'Ready to pick'
                  break;
              }
              case 5: {
                  $row.cells.status.displayControl.text = 'Ready to load'
                  break;
              }
              case 6: {
                  $row.cells.status.displayControl.text = 'Ready to complete'
                  break;
              }
          }
      }
  
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-transload_orders_grid',
  templateUrl: './FootPrintManager.transload_orders_grid.component.html'
})
export class FootPrintManager_transload_orders_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_transload_orders_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_transload_orders_gridComponentInParams = { ownerId: null, projectId: null, warehouseId: [], statusIds: [], materialIds: [] };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Order', false, false, null),       status: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       orderclass: new GridHeaderModel(new HeaderStyles(null, null), 'Order class', false, false, null),       owner: new GridHeaderModel(new HeaderStyles(null, null), 'Owner', false, false, null),       project: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'Warehouse', false, false, null),       total_items: new GridHeaderModel(new HeaderStyles(null, null), 'Total items', false, false, null),       created_date: new GridHeaderModel(new HeaderStyles(null, null), 'Created', false, false, null),       vendor_reference: new GridHeaderModel(new HeaderStyles(null, null), 'Vendor reference', false, false, null),       owner_reference: new GridHeaderModel(new HeaderStyles(null, null), 'Owner reference', false, false, null),       inbound_conveyance: new GridHeaderModel(new HeaderStyles(null, null), 'Conveyance', false, false, null),       inbound_conveyance_reference: new GridHeaderModel(new HeaderStyles(null, null), 'Conveyance reference', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_transload_orders_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }
  @Input('statusIds') set $inParams_statusIds(value: any) {
    this.inParams['statusIds'] = value;
  }
  get $inParams_statusIds(): any {
    return this.inParams['statusIds'] ;
  }
  @Input('materialIds') set $inParams_materialIds(value: any) {
    this.inParams['materialIds'] = value;
  }
  get $inParams_materialIds(): any {
    return this.inParams['materialIds'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    created_from: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    created_to: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    created_from: new FieldModel(new DateBoxModel(this.formGroup.controls['created_from'] as DatexFormControl, null, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'Created from', false, false),
    created_to: new FieldModel(new DateBoxModel(this.formGroup.controls['created_to'] as DatexFormControl, null, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'Created to', false, false),
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Transload orders';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 50;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      warehouseId:  $grid.inParams.warehouseId ,
      statusIds:  $grid.inParams.statusIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      createdFrom:  $grid.filters.created_from.control.value ,
      createdTo:  $grid.filters.created_to.control.value ,
      materialIds:  $grid.inParams.materialIds ,
    };
    try {
    const data = await this.datasources.FootPrintManager.ds_transload_orders_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_transload_orders_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_transload_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  // Initialize date format
  var dateFormat = $settings.FootPrintManager.DateFormat;
  
  $grid.filters.created_from.control.format = dateFormat;
  $grid.filters.created_to.control.format = dateFormat;
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
