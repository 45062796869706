<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template 
                toolbarToolDef="refresh_dropdowns" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_refresh_dropdowns($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="save" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-http_configuration"
                     *ngIf="!filtersets.http_configuration.hidden && !filtersets.http_configuration.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.http_configuration.collapsible }">
                    <div *ngIf="!filtersets.http_configuration.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.http_configuration.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.http_configuration.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.http_configuration.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.http_configuration.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.http_configuration.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.http_configuration.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-http_endpoint" *ngIf="!filters.http_endpoint.hidden && !filters.http_endpoint.removed" 
                                  class="field-container double {{filters.http_endpoint.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.http_endpoint.styles.style"
                                  [ngClass]="filters.http_endpoint.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.http_endpoint.label + (filters.http_endpoint.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.http_endpoint.label}}<span *ngIf="filters.http_endpoint.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="http_endpoint"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.http_endpoint.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.http_endpoint.control.placeholder}}"
                                      [ngStyle]="filters.http_endpoint.control.styles.style"
                                      [ngClass]="filters.http_endpoint.control.styles.classes"
                                      [matTooltip]="filters.http_endpoint.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.http_endpoint.invalid">
                                <ng-container *ngFor="let error of filters.http_endpoint.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-http_port" *ngIf="!filters.http_port.hidden && !filters.http_port.removed" 
                                  class="field-container double {{filters.http_port.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.http_port.styles.style"
                                  [ngClass]="filters.http_port.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.http_port.label + (filters.http_port.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.http_port.label}}<span *ngIf="filters.http_port.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="http_port"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.http_port.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.http_port.control.placeholder}}"
                                      [ngStyle]="filters.http_port.control.styles.style"
                                      [ngClass]="filters.http_port.control.styles.classes"
                                      [matTooltip]="filters.http_port.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.http_port.invalid">
                                <ng-container *ngFor="let error of filters.http_port.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-http_username" *ngIf="!filters.http_username.hidden && !filters.http_username.removed" 
                                  class="field-container double {{filters.http_username.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.http_username.styles.style"
                                  [ngClass]="filters.http_username.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.http_username.label + (filters.http_username.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.http_username.label}}<span *ngIf="filters.http_username.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="http_username"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.http_username.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.http_username.control.placeholder}}"
                                      [ngStyle]="filters.http_username.control.styles.style"
                                      [ngClass]="filters.http_username.control.styles.classes"
                                      [matTooltip]="filters.http_username.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.http_username.invalid">
                                <ng-container *ngFor="let error of filters.http_username.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-http_password" *ngIf="!filters.http_password.hidden && !filters.http_password.removed" 
                                  class="field-container double {{filters.http_password.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.http_password.styles.style"
                                  [ngClass]="filters.http_password.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.http_password.label + (filters.http_password.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.http_password.label}}<span *ngIf="filters.http_password.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="http_password"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.http_password.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.http_password.control.placeholder}}"
                                      [ngStyle]="filters.http_password.control.styles.style"
                                      [ngClass]="filters.http_password.control.styles.classes"
                                      [matTooltip]="filters.http_password.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.http_password.invalid">
                                <ng-container *ngFor="let error of filters.http_password.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                <div data-cy="fieldset-id-grp_sftp_endpoint"
                     *ngIf="!filtersets.grp_sftp_endpoint.hidden && !filtersets.grp_sftp_endpoint.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.grp_sftp_endpoint.collapsible }">
                    <div *ngIf="!filtersets.grp_sftp_endpoint.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.grp_sftp_endpoint.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.grp_sftp_endpoint.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.grp_sftp_endpoint.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.grp_sftp_endpoint.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.grp_sftp_endpoint.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.grp_sftp_endpoint.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-sftp_endpoint" *ngIf="!filters.sftp_endpoint.hidden && !filters.sftp_endpoint.removed" 
                                  class="field-container double {{filters.sftp_endpoint.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.sftp_endpoint.styles.style"
                                  [ngClass]="filters.sftp_endpoint.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.sftp_endpoint.label + (filters.sftp_endpoint.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.sftp_endpoint.label}}<span *ngIf="filters.sftp_endpoint.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="sftp_endpoint"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.sftp_endpoint.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.sftp_endpoint.control.placeholder}}"
                                      [ngStyle]="filters.sftp_endpoint.control.styles.style"
                                      [ngClass]="filters.sftp_endpoint.control.styles.classes"
                                      [matTooltip]="filters.sftp_endpoint.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.sftp_endpoint.invalid">
                                <ng-container *ngFor="let error of filters.sftp_endpoint.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-sftp_port" *ngIf="!filters.sftp_port.hidden && !filters.sftp_port.removed" 
                                  class="field-container double {{filters.sftp_port.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.sftp_port.styles.style"
                                  [ngClass]="filters.sftp_port.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.sftp_port.label + (filters.sftp_port.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.sftp_port.label}}<span *ngIf="filters.sftp_port.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="sftp_port"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.sftp_port.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.sftp_port.control.placeholder}}"
                                      [ngStyle]="filters.sftp_port.control.styles.style"
                                      [ngClass]="filters.sftp_port.control.styles.classes"
                                      [matTooltip]="filters.sftp_port.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.sftp_port.invalid">
                                <ng-container *ngFor="let error of filters.sftp_port.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-sftp_username" *ngIf="!filters.sftp_username.hidden && !filters.sftp_username.removed" 
                                  class="field-container double {{filters.sftp_username.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.sftp_username.styles.style"
                                  [ngClass]="filters.sftp_username.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.sftp_username.label + (filters.sftp_username.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.sftp_username.label}}<span *ngIf="filters.sftp_username.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="sftp_username"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.sftp_username.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.sftp_username.control.placeholder}}"
                                      [ngStyle]="filters.sftp_username.control.styles.style"
                                      [ngClass]="filters.sftp_username.control.styles.classes"
                                      [matTooltip]="filters.sftp_username.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.sftp_username.invalid">
                                <ng-container *ngFor="let error of filters.sftp_username.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-sftp_password" *ngIf="!filters.sftp_password.hidden && !filters.sftp_password.removed" 
                                  class="field-container double {{filters.sftp_password.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.sftp_password.styles.style"
                                  [ngClass]="filters.sftp_password.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.sftp_password.label + (filters.sftp_password.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.sftp_password.label}}<span *ngIf="filters.sftp_password.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="sftp_password"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.sftp_password.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.sftp_password.control.placeholder}}"
                                      [ngStyle]="filters.sftp_password.control.styles.style"
                                      [ngClass]="filters.sftp_password.control.styles.classes"
                                      [matTooltip]="filters.sftp_password.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.sftp_password.invalid">
                                <ng-container *ngFor="let error of filters.sftp_password.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-sftp_working_folder" *ngIf="!filters.sftp_working_folder.hidden && !filters.sftp_working_folder.removed" 
                                  class="field-container double {{filters.sftp_working_folder.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.sftp_working_folder.styles.style"
                                  [ngClass]="filters.sftp_working_folder.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.sftp_working_folder.label + (filters.sftp_working_folder.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.sftp_working_folder.label}}<span *ngIf="filters.sftp_working_folder.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="sftp_working_folder"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.sftp_working_folder.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.sftp_working_folder.control.placeholder}}"
                                      [ngStyle]="filters.sftp_working_folder.control.styles.style"
                                      [ngClass]="filters.sftp_working_folder.control.styles.classes"
                                      [matTooltip]="filters.sftp_working_folder.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.sftp_working_folder.invalid">
                                <ng-container *ngFor="let error of filters.sftp_working_folder.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-sftp_archive_folder" *ngIf="!filters.sftp_archive_folder.hidden && !filters.sftp_archive_folder.removed" 
                                  class="field-container double {{filters.sftp_archive_folder.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.sftp_archive_folder.styles.style"
                                  [ngClass]="filters.sftp_archive_folder.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.sftp_archive_folder.label + (filters.sftp_archive_folder.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.sftp_archive_folder.label}}<span *ngIf="filters.sftp_archive_folder.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="sftp_archive_folder"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.sftp_archive_folder.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.sftp_archive_folder.control.placeholder}}"
                                      [ngStyle]="filters.sftp_archive_folder.control.styles.style"
                                      [ngClass]="filters.sftp_archive_folder.control.styles.classes"
                                      [matTooltip]="filters.sftp_archive_folder.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.sftp_archive_folder.invalid">
                                <ng-container *ngFor="let error of filters.sftp_archive_folder.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-sftp_error_folder" *ngIf="!filters.sftp_error_folder.hidden && !filters.sftp_error_folder.removed" 
                                  class="field-container double {{filters.sftp_error_folder.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.sftp_error_folder.styles.style"
                                  [ngClass]="filters.sftp_error_folder.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.sftp_error_folder.label + (filters.sftp_error_folder.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.sftp_error_folder.label}}<span *ngIf="filters.sftp_error_folder.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="sftp_error_folder"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.sftp_error_folder.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.sftp_error_folder.control.placeholder}}"
                                      [ngStyle]="filters.sftp_error_folder.control.styles.style"
                                      [ngClass]="filters.sftp_error_folder.control.styles.classes"
                                      [matTooltip]="filters.sftp_error_folder.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.sftp_error_folder.invalid">
                                <ng-container *ngFor="let error of filters.sftp_error_folder.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.projects_configuration.hasTabToShow" class="tab" data-cy="tab-projects_configuration">
              <h2 [className]="tabs.projects_configuration.active? 'active': ''" (click)="tabs.projects_configuration.activate()">{{tabs.projects_configuration.title}}</h2>
            </div>
          </div>
        
              <Ultraship-ultraship_project_configurations_grid *ngIf="tabs.projects_configuration.active"
              #$tabs_projects_configuration
              [application_name]="$tabs_projects_configuration_ultraship_project_configurations_grid_inParams_application_name"
              ($refreshEvent)="refresh(false, false, '$tabs_projects_configuration')">
              </Ultraship-ultraship_project_configurations_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>