import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Dynamics365BusinessCentral.frontend.types'

@Injectable({ providedIn: 'root' })
export class Dynamics365BusinessCentral_flow_material_queue_writeService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { input?: $frontendTypes.Dynamics365BusinessCentral.material_queue[], criteria?: { application_name?: string } }): Promise<{ output?: { q_id?: string }[], success?: boolean, error?: any[] }> 
  {
    let url = `${environment.backendUrl}api/Dynamics365BusinessCentral/functions/flow_material_queue_write`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

