import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles
} from './models/control';
import { Styles, ControlContainerStyles, CardStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { DatexFormControl } from './models/datex-form-control';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-dock_appointment_card',
  templateUrl: './FootPrintManager.dock_appointment_card.component.html'
})
export class FootPrintManager_dock_appointment_cardComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  styles = new CardStyles(['creation','border-left']);

  inParams: { dockAppointment: { lookupCode?: string, carrier?: string, referenceNumber?: string, status?: string, id?: number, typeId?: number, warehouseId?: number, orderId?: number } } = { dockAppointment: { lookupCode: null, carrier: null, referenceNumber: null, status: null, id: null, typeId: null, warehouseId: null, orderId: null } };
  //#region Inputs
  @Input('dockAppointment') set $inParams_dockAppointment(v: { lookupCode?: string, carrier?: string, referenceNumber?: string, status?: string, id?: number, typeId?: number, warehouseId?: number, orderId?: number }) {
    this.inParams.dockAppointment = v;
  }
  get $inParams_dockAppointment(): { lookupCode?: string, carrier?: string, referenceNumber?: string, status?: string, id?: number, typeId?: number, warehouseId?: number, orderId?: number } {
    return this.inParams.dockAppointment;
  }
  //#endregion Inputs

  //#region Outputs
  //#endregion
  //#region Variables
  vars: { icon?: string, iconText?: string, warehouseId?: number } = { };
  //#endregion
  //#region Events
  @Output()
  openEditorClick = new EventEmitter<number>();
  
  events = {
    openEditorClick: this.openEditorClick,
  }
  //#endregion

  @Output()
  $refreshEvent = new EventEmitter();


  formGroupContent: FormGroup = new FormGroup({
    scheduled_arrival: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    checked_in_time: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    completed_on: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    cancelled_on: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });


  content = {
    fields: {
        scheduled_arrival: new FieldModel(new DateBoxModel(this.formGroupContent.controls['scheduled_arrival'] as DatexFormControl, null, true, '', 'date', null)
, new ControlContainerStyles(null, null), 'Scheduled arrival', false, false),
        checked_in_time: new FieldModel(new DateBoxModel(this.formGroupContent.controls['checked_in_time'] as DatexFormControl, null, true, '', 'date', null)
, new ControlContainerStyles(null, null), 'Checked in on', false, false),
        completed_on: new FieldModel(new DateBoxModel(this.formGroupContent.controls['completed_on'] as DatexFormControl, null, true, '', 'date', null)
, new ControlContainerStyles(null, null), 'Completed on', false, false),
        cancelled_on: new FieldModel(new DateBoxModel(this.formGroupContent.controls['cancelled_on'] as DatexFormControl, null, true, '', 'date', null)
, new ControlContainerStyles(null, null), 'Cancelled on', false, false),
        project: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Project', false, false),
        order: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Order', false, false),
        progress: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Progress', false, false),
        carrier: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Carrier', false, false),
    },
    fieldsets: {
  newGroup1: new FieldsetModel('', true, false, true, false),
}
  }


  
  //#region content fields inParams
  //#endregion content fields inParams



  get headerTitle() {
    const $card = this;
    const $utils = this.utils;
    const expr = $card.inParams.dockAppointment.lookupCode + ' ' + $card.inParams.dockAppointment.status;
    return expr;
  }


  get headerIcon() {
    const $card = this;
    const $utils = this.utils;
    const expr = $card.vars.icon;
    return expr;
  }

  get headerIconText() {
    const $card = this;
    const $utils = this.utils;
    const expr = $card.vars.iconText;
    return expr;
  }

  get headerIconSize() {
      return "";
  }

  get headerIconColor() {
      return "";
  }

  get headerIconTransparency() {
      return "";
  }


  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super(); 
    this.$subscribeFormControlValueChanges();

  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.dockAppointment)) {
        this.$missingRequiredInParams.push('dockAppointment');
      }
  }

  initialized = false;

  async $init() {

    const $card = this;
    const $utils = this.utils;



    await this.on_init();

    this.initialized = true;

  }

  private $subscribeFormControlValueChanges() {

  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $card: FootPrintManager_dock_appointment_cardComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Initialize
  
  
  // Set Date/Time Formats
  $card.content.fields.scheduled_arrival.control.format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  $card.content.fields.checked_in_time.control.format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  $card.content.fields.cancelled_on.control.format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  $card.content.fields.completed_on.control.format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  
  
  var orderDetails = '';
  
  // Gather dock appointment status
  const dockAppt = (await $datasources.DockAppointments.ds_get_dock_appointment_by_appointmentId.get({
      appointmentId: $card.inParams.dockAppointment.id
  })).result;
  if ($utils.isDefined(dockAppt)) {
  
      var project = dockAppt.ScheduledProject?.LookupCode;
      var carrier = dockAppt.ScheduledCarrier?.Name;
      var checkedInOn = dockAppt.convertedCheckInOn?.convertedDate;
      var scheduledArrival = dockAppt.convertedScheduledArrival?.convertedDate;
      var completedOn = dockAppt.convertedCompletedOn?.convertedDate;
      var cancelledOn = dockAppt.convertedCancelledOn?.convertedDate;
      var typeId = dockAppt.TypeId;
  
      const dockType = (await $datasources.DockAppointments.ds_dock_appointment_type_by_typeId.get({typeId: typeId})).result;
      var typeName = dockType.Name;
  
      const dockStatus = dockAppt.StatusId
      if (dockStatus == 0) {
          // Hidden
          $card.content.fields.checked_in_time.hidden = true;
          $card.content.fields.cancelled_on.hidden = true;
          $card.content.fields.completed_on.hidden = true;
  
          // Visible
          $card.content.fields.scheduled_arrival.hidden = false;
  
  
          // Data
          $card.content.fields.scheduled_arrival.control.value = scheduledArrival;
  
      }
      // Checked In, Assigned, In Process
      else if (dockStatus == 1 || dockStatus == 2 || dockStatus == 3) {
  
          // Visible
          $card.content.fields.scheduled_arrival.hidden = false;
          $card.content.fields.checked_in_time.hidden = false;
          $card.content.fields.scheduled_arrival.control.value = scheduledArrival;
          $card.content.fields.checked_in_time.control.value = checkedInOn;
  
          // Hidden
          $card.content.fields.cancelled_on.hidden = true;
          $card.content.fields.completed_on.hidden = true;
  
      }
      // Completed
      else if (dockStatus == 4) {
  
          // Visible
          $card.content.fields.completed_on.hidden = false;
          $card.content.fields.completed_on.control.value = completedOn;
          $card.content.fields.checked_in_time.hidden = false;
          $card.content.fields.checked_in_time.control.value = checkedInOn;
  
          // Hidden
          $card.content.fields.cancelled_on.hidden = true;
          $card.content.fields.scheduled_arrival.hidden = true;
  
  
  
      }
      // Cancelled
      else {
  
          // Visible
          $card.content.fields.cancelled_on.hidden = false;
          $card.content.fields.cancelled_on.control.value = cancelledOn;
  
          // Hidden
          $card.content.fields.completed_on.hidden = true;
          $card.content.fields.scheduled_arrival.hidden = true;
          $card.content.fields.checked_in_time.hidden = true;
  
      }
  
      // Project Field
  
      if ($utils.isDefined(dockAppt.ScheduledProject?.LookupCode)) {
  
          $card.content.fields.project.hidden = false;
          $card.content.fields.project.control.text = project;
  
      }
      else {
  
          $card.content.fields.project.hidden = true;
  
      }
  
      // Carrier Field
  
      if ($utils.isDefined(dockAppt.ScheduledCarrier?.Name)) {
  
          $card.content.fields.carrier.hidden = false;
          $card.content.fields.carrier.control.text = carrier;
  
      }
      else {
  
          $card.content.fields.carrier.hidden = true;
  
      }
  
  
  
  
  
  }
  
  // Handle inbound or outbound order logic
  if ($utils.isDefined($card.inParams.dockAppointment.orderId)) {
  
      const order = (await $datasources.DockAppointments.ds_get_order_by_orderId.get({
          orderId: $card.inParams.dockAppointment.orderId
      })).result;
  
      if ($utils.isDefined(order)) {
  
          orderDetails = order.LookupCode + ' ' + order.Status?.Name;
          $card.content.fields.order.control.text = orderDetails;
  
      }
  
      if (typeId === 1) {
          const progressRecv = (await $datasources.DockAppointments.ds_order_total_received.get({
              orderId: $card.inParams.dockAppointment.orderId
          })).result;
  
          if ($utils.isDefined(progressRecv)) {
  
              const totalOrder = $utils.isDefined(progressRecv?.TotalOrder) ? progressRecv.TotalOrder : 0;
              const totalReceived = progressRecv.TotalReceived;
              if ($utils.isAllDefined(totalReceived, totalOrder) && totalReceived > 0) {
                  const progressPercentage = ((totalReceived / totalOrder) * 100).toFixed(2);
                  $card.content.fields.progress.control.text = `Received ${progressPercentage}%`;
              }
              else {
                  $card.content.fields.progress.control.text = 'Received 0%'
              }
  
          }
          else {
  
              $card.content.fields.progress.control.text = 'Received 0%'
  
          }
  
      } else if (typeId === 2) {
          const progressPick = (await $datasources.DockAppointments.ds_order_total_picked.get({
              orderId: $card.inParams.dockAppointment.orderId
          })).result;
  
          if ($utils.isDefined(progressPick)) {
  
              const totalOrder = $utils.isDefined(progressPick?.TotalOrder) ? progressPick.TotalOrder : 0;
              const totalPicked = progressPick?.TotalPicked;
              if ($utils.isAllDefined(totalPicked, totalOrder) && totalPicked > 0) {
                  const progressPercentage = ((totalPicked / totalOrder) * 100).toFixed(2);
                  $card.content.fields.progress.control.text = `Picked ${progressPercentage}%`;
              }
              else {
                  $card.content.fields.progress.control.text = 'Picked 0%'
              }
  
          }
          else {
  
              $card.content.fields.progress.control.text = 'Picked 0%'
  
          }
      }
  
  }
  else {
  
      $card.content.fields.order.hidden = true;
      $card.content.fields.progress.hidden = true;
  }
  
  
  
  
  if ($utils.isDefined(typeId)) {
      if (typeId === 1) {
          $card.styles.setCreationClass();
          $card.vars.icon = 'ArrowTallDownRight';
          $card.vars.iconText = 'In';
      } else if (typeId === 2) {
          $card.styles.setAttentionClass();
          $card.vars.icon = 'ArrowTallUpRight';
          $card.vars.iconText = 'Out';
      } else {
  
          $card.vars.iconText = typeName;
  
      }
  
  }
  
  
  
  
  
  
  }
  on_open_editor_clicked(event = null) {
    return this.on_open_editor_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_open_editor_clickedInternal(
    $card: FootPrintManager_dock_appointment_cardComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  await $shell.FootPrintManager.opendock_appointment_editorDialog({
        dockAppointmentId: $card.inParams.dockAppointment.id
      }, 'modal');
      
  ($card as any).refresh();
  
  
  }
  //#endregion private flows

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);

    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }


}
