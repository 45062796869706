import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class DockAppointments_ds_dock_appointments_selection_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { locationIds?: number[], fromDate?: string, typeIds?: number[], carrierId?: number, statusIds?: number[], toDate?: string, fullTextSearch?: string, scheduledProjectId?: number, warehouseId: number, excludedDockAppointmentIds?: number[] }): 
  Promise<{ result: { Id?: number, CheckedInBy?: string, CheckedInOn?: string, LookupCode?: string, ReferenceNumber?: string, ScheduledArrival?: string, ScheduledCarrierId?: number, ScheduledDeparture?: string, ScheduledOwnerId?: number, ScheduledProjectId?: number, StatusId?: number, TypeId?: number, WarehouseId?: number, ScheduledLocation?: { Id?: number, Name?: string }, ScheduledCarrier?: { Id?: number, Name?: string }, Status?: { Name?: string }, ScheduledProject?: { LookupCode?: string }, AssignedLocation?: { Id?: number, Name?: string }, ScheduledOwner?: { Id?: number, LookupCode?: string }, Warehouse?: { Id?: number, Name?: string, TimeZoneId?: string }, convertedScheduledArrival?: { id?: number, convertedDate?: string }, convertedScheduledDeparture?: { id?: number, convertedDate?: string }, convertedCheckInDate?: { id?: number, convertedDate?: string }, dockAppointmentType?: { Id?: number, Name?: string, Options?: string, SystemDefined?: boolean, SystemLabel?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_dock_appointments_selection_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { locationIds?: number[], fromDate?: string, typeIds?: number[], carrierId?: number, statusIds?: number[], toDate?: string, fullTextSearch?: string, scheduledProjectId?: number, warehouseId: number, excludedDockAppointmentIds?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CheckedInBy?: string, CheckedInOn?: string, LookupCode?: string, ReferenceNumber?: string, ScheduledArrival?: string, ScheduledCarrierId?: number, ScheduledDeparture?: string, ScheduledOwnerId?: number, ScheduledProjectId?: number, StatusId?: number, TypeId?: number, WarehouseId?: number, ScheduledLocation?: { Id?: number, Name?: string }, ScheduledCarrier?: { Id?: number, Name?: string }, Status?: { Name?: string }, ScheduledProject?: { LookupCode?: string }, AssignedLocation?: { Id?: number, Name?: string }, ScheduledOwner?: { Id?: number, LookupCode?: string }, Warehouse?: { Id?: number, Name?: string, TimeZoneId?: string }, convertedScheduledArrival?: { id?: number, convertedDate?: string }, convertedScheduledDeparture?: { id?: number, convertedDate?: string }, convertedCheckInDate?: { id?: number, convertedDate?: string }, dockAppointmentType?: { Id?: number, Name?: string, Options?: string, SystemDefined?: boolean, SystemLabel?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_dock_appointments_selection_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { locationIds?: number[], fromDate?: string, typeIds?: number[], carrierId?: number, statusIds?: number[], toDate?: string, fullTextSearch?: string, scheduledProjectId?: number, warehouseId: number, excludedDockAppointmentIds?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, CheckedInBy?: string, CheckedInOn?: string, LookupCode?: string, ReferenceNumber?: string, ScheduledArrival?: string, ScheduledCarrierId?: number, ScheduledDeparture?: string, ScheduledOwnerId?: number, ScheduledProjectId?: number, StatusId?: number, TypeId?: number, WarehouseId?: number, ScheduledLocation?: { Id?: number, Name?: string }, ScheduledCarrier?: { Id?: number, Name?: string }, Status?: { Name?: string }, ScheduledProject?: { LookupCode?: string }, AssignedLocation?: { Id?: number, Name?: string }, ScheduledOwner?: { Id?: number, LookupCode?: string }, Warehouse?: { Id?: number, Name?: string, TimeZoneId?: string }, convertedScheduledArrival?: { id?: number, convertedDate?: string }, convertedScheduledDeparture?: { id?: number, convertedDate?: string }, convertedCheckInDate?: { id?: number, convertedDate?: string }, dockAppointmentType?: { Id?: number, Name?: string, Options?: string, SystemDefined?: boolean, SystemLabel?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_dock_appointments_selection_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      locationIds?: number[], fromDate?: string, typeIds?: number[], carrierId?: number, statusIds?: number[], toDate?: string, fullTextSearch?: string, scheduledProjectId?: number, warehouseId: number, excludedDockAppointmentIds?: number[]    }) {
        if(isNil(inParams.warehouseId)) {
          return true; 
        }
      return false;
    }
}
