import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



interface IFootPrintManager_assigned_orders_on_wave_gridComponentEntity {
Id?: number, ActualWarehouseId?: number, CarrierId?: number, CarrierServiceTypeId?: number, Cartonized?: boolean, CreatedSysDateTime?: string, CreatedSysUser?: string, DateNeeded?: string, ExpectedWarehouseId?: number, LookupCode?: string, WaveId?: number, Tasks?: { Id?: number, OperationCodeId?: number, PickSlipId?: number, StatusId?: number, HardAllocations?: { Id?: number, Details?: { Id?: number }[] }[] }[], OrderLookups?: { OrderId?: number, ShipmentId?: number, Order?: { Id?: number, LookupCode?: string, OrderStatusId?: number, OwnerReference?: string, VendorReference?: string, Status?: { Name?: string } } }[], Carrier?: { Id?: number, Name?: string, ShortName?: string }, CarrierServiceType?: { Id?: number, Name?: string, ShortName?: string }, ActualWarehouse?: { Id?: number, Name?: string }, ExpectedWarehouse?: { Id?: number, Name?: string }, Wave?: { Id?: number, StatusId?: number, Status?: { Name?: string } }}

interface IFootPrintManager_assigned_orders_on_wave_gridComponentInParams {
  waveId: number, waveStatusId: number}

interface IFootPrintManager_assigned_orders_on_wave_gridComponentOutParams {
  refresh?: boolean}

class FootPrintManager_assigned_orders_on_wave_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_assigned_orders_on_wave_gridComponent;
  entity: IFootPrintManager_assigned_orders_on_wave_gridComponentEntity;

  vars: { stateId?: number } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    order_code: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    total_items: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    LookupCode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    CreatedSysDateTime: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null,  'datetime', 'l, LT', null)
,
null
      ),
    Carrier_Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    CarrierServiceType_Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    date_needed: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null,  'datetime', 'l, LT', null)
,
null
      ),
    order_reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    vendor_reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    warehouse: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_assigned_orders_on_wave_gridComponent, entity: IFootPrintManager_assigned_orders_on_wave_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_assigned_orders_on_wave_gridComponent, entity?: IFootPrintManager_assigned_orders_on_wave_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      waveId:  $grid.inParams.waveId ,
    };
    const data = await this.datasources.Waves.ds_get_shipments_by_waveId.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.order_code.displayControl as TextModel).text = $row.entity?.OrderLookups[0]?.Order?.LookupCode;
    (this.cells.status.displayControl as TextModel).text = $row.entity.OrderLookups[0]?.Order?.Status?.Name;
    (this.cells.LookupCode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.CreatedSysDateTime.displayControl as TextModel).text = $row.entity.CreatedSysDateTime?.toString();
    (this.cells.Carrier_Name.displayControl as TextModel).text = $row.entity.Carrier?.Name;
    (this.cells.CarrierServiceType_Name.displayControl as TextModel).text = $row.entity.CarrierServiceType?.Name;
    (this.cells.date_needed.displayControl as TextModel).text = $row.entity.DateNeeded;
    (this.cells.order_reference.displayControl as TextModel).text = $row.entity?.OrderLookups[0]?.Order?.OwnerReference;
    (this.cells.vendor_reference.displayControl as TextModel).text = $row.entity?.OrderLookups[0]?.Order?.VendorReference;
    (this.cells.warehouse.displayControl as TextModel).text = (($row.entity?.ActualWarehouse?.Name === null) ? $row.entity?.ExpectedWarehouse?.Name : $row.entity?.ActualWarehouse?.Name);

  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'order_code' : {
        this.on_order_code_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_order_code_clicked(event = null) {
    return this.on_order_code_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_order_code_clickedInternal(
    $row: FootPrintManager_assigned_orders_on_wave_gridComponentRowModel,
  $grid: FootPrintManager_assigned_orders_on_wave_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Check order class type
  const order = (await $datasources.SalesOrders.ds_get_order_by_orderId.get({ orderId: $row.entity.OrderLookups[0]?.Order?.Id })).result;
  if ($utils.isDefined(order)) {
      const orderClassTypeId = order.OrderClass.OrderClassTypeId;
      if (orderClassTypeId == 5) {
          $shell.FootPrintManager.opensales_order_editor({ orderId: $row.entity.OrderLookups[0]?.Order?.Id });
      }
      else {
          throw new Error('Editor for selected order class type under development!')
      }
  }
  
  else {
      throw new Error('Unable to access order data.')
  }
  
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-assigned_orders_on_wave_grid',
  templateUrl: './FootPrintManager.assigned_orders_on_wave_grid.component.html'
})
export class FootPrintManager_assigned_orders_on_wave_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_assigned_orders_on_wave_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_assigned_orders_on_wave_gridComponentInParams = { waveId: null, waveStatusId: null };

  outParams: IFootPrintManager_assigned_orders_on_wave_gridComponentOutParams = { refresh: null };

  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ refresh?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     order_code: new GridHeaderModel(new HeaderStyles(null, null), 'Order code', false, false, null),       status: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       total_items: new GridHeaderModel(new HeaderStyles(null, null), 'Total items', false, false, null),       LookupCode: new GridHeaderModel(new HeaderStyles(null, null), 'Shipment code', false, false, null),       CreatedSysDateTime: new GridHeaderModel(new HeaderStyles(null, null), 'Create date', false, false, null),       Carrier_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Carrier name', false, false, null),       CarrierServiceType_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Carrier service name', false, false, null),       date_needed: new GridHeaderModel(new HeaderStyles(null, null), 'Date needed', false, false, null),       order_reference: new GridHeaderModel(new HeaderStyles(null, null), 'Order reference', false, false, null),       vendor_reference: new GridHeaderModel(new HeaderStyles(null, null), 'Vendor reference', false, false, null),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'Warehouse', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_assigned_orders_on_wave_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('waveId') set $inParams_waveId(value: any) {
    this.inParams['waveId'] = value;
  }
  get $inParams_waveId(): any {
    return this.inParams['waveId'] ;
  }
  @Input('waveStatusId') set $inParams_waveStatusId(value: any) {
    this.inParams['waveStatusId'] = value;
  }
  get $inParams_waveStatusId(): any {
    return this.inParams['waveStatusId'] ;
  }

  topToolbar = {
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, false, 'Complete', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      update: new ToolModel(new ButtonModel('update', new ButtonStyles(null, null), false, false, 'Update', 'icon-ic_fluent_edit_20_regular', null)
    , false),
      manifest_print: new ToolModel(new ButtonModel('manifest_print', new ButtonStyles(null, null), false, false, 'Manifest', 'icon-ic_fluent_document_print_20_regular', null)
    , false),
      auto_pick: new ToolModel(new ButtonModel('auto_pick', new ButtonStyles(null, null), false, false, 'Auto pick', 'icon-datex-Pick', null)
    , false),
      auto_load: new ToolModel(new ButtonModel('auto_load', new ButtonStyles(null, null), false, false, 'Auto load', 'icon-datex-Load', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      remove_from_wave: new ToolModel(new ButtonModel('remove_from_wave', new ButtonStyles(null, null), false, false, 'Remove from wave', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Assigned orders';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.waveId)) {
        this.$missingRequiredInParams.push('waveId');
      }
      if(isNil(this.inParams.waveStatusId)) {
        this.$missingRequiredInParams.push('waveStatusId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 50;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      waveId:  $grid.inParams.waveId ,
    };
    try {
    const data = await this.datasources.Waves.ds_get_shipments_by_waveId.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_assigned_orders_on_wave_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_remove_from_wave_clicked(event = null) {
    return this.on_remove_from_wave_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_remove_from_wave_clickedInternal(
    $grid: FootPrintManager_assigned_orders_on_wave_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let shipmentIds: number[] = [];
  for (let row of $grid.selectedRows) {
      shipmentIds.push(row.entity.Id)
  }
  
  if ($utils.isDefined(shipmentIds)) {
      for (let shipment of shipmentIds) {
          await $flows.Utilities.crud_update_flow({
              entitySet: 'Shipments',
              id: shipment,
              entity: { WaveId: null }
          })
      }
  } else {
      throw Error(`Unable to remove shipments ${shipmentIds.join(' ,')} from wave`)
  }
  $grid.refresh();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_assigned_orders_on_wave_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.remove_from_wave.control.readOnly = true;
  
  
  // Initialize Manifesting module
  var shippingKey = $settings.FootPrintManager.EasyPostKey;
  
  if (shippingKey == 'N/A'){
  
      $grid.topToolbar.manifest_print.hidden = true;
  }
  
  if (await $operations.FootPrintManager.Disable_SalesOrders_AutoPicking.isAuthorized()){
      $grid.topToolbar.auto_pick.hidden = true;
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_assigned_orders_on_wave_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  //Initialize all buttons to read only true
  $grid.topToolbar.complete.control.readOnly = true;
  $grid.topToolbar.update.control.readOnly = true;
  $grid.topToolbar.manifest_print.control.readOnly = true;
  $grid.topToolbar.auto_pick.control.readOnly = true;
  $grid.topToolbar.auto_load.control.readOnly = true;
  $grid.topToolbar.remove_from_wave.control.readOnly = true;
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  // Single row only
  if (selectedRowsCount == 1) {
  
      // placeholder
  }
  
  // One or more rows selected
  if (selectedRowsCount > 0) {
      const selectedRows = $grid.selectedRows;
  
      setButtonsByOrderStatusNames($grid.selectedRows.filter(row => $utils.isDefined(row.vars.stateId)).map(row => row.vars.stateId));
  
      $grid.topToolbar.update.control.readOnly = false;
      $grid.topToolbar.manifest_print.control.readOnly = false;
      $grid.topToolbar.remove_from_wave.control.readOnly = false;
  }
  else {
  
      $grid.topToolbar.complete.control.readOnly = true;
      $grid.topToolbar.update.control.readOnly = true;
      $grid.topToolbar.manifest_print.control.readOnly = true;
      $grid.topToolbar.auto_pick.control.readOnly = true;
      $grid.topToolbar.auto_load.control.readOnly = true;
      $grid.topToolbar.remove_from_wave.control.readOnly = true;
  
  }
  /****************************************
   * FUNCTIONS
  *****************************************/
  
  
  function setButtonsByOrderStatusNames(statusNames: number[]) {
  
   
      if (statusNames.indexOf(2) > -1 || statusNames.indexOf(3) > -1 || statusNames.indexOf(4) > -1) {
         
      }
      if (statusNames.indexOf(5) > -1 || statusNames.indexOf(6) > -1) {
          $grid.topToolbar.auto_pick.control.readOnly = false;
      
      }
      if (statusNames.indexOf(7) > -1) {
          $grid.topToolbar.auto_load.control.readOnly = false;
         
          $grid.topToolbar.complete.control.readOnly = false;
      }
      if (statusNames.indexOf(8) > -1) {
          $grid.topToolbar.complete.control.readOnly = false;
      }
    
  }
  
  }
  on_update_clicked(event = null) {
    return this.on_update_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_update_clickedInternal(
    $grid: FootPrintManager_assigned_orders_on_wave_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Order Update Error', 'No orders selected.');
      return;
  } else {
  
      const dialogResult = await $shell.FootPrintManager.openoutbound_orders_update_formDialog({
          orders: selectedRows.map(row => ({shipmentId: row.entity.Id, orderId: row.entity.OrderLookups[0]?.OrderId})) });
      const userConfirmed = dialogResult.confirm;
  
  
      if (userConfirmed) {
          
          await $grid.refresh();
      }
  
  }
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootPrintManager_assigned_orders_on_wave_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const outbound_orders = $grid.rows.map(row => row.entity.OrderLookups[0]?.OrderId);
  const outbound_shipments = $grid.rows.map(row => row.entity.Id)
  
  if (outbound_orders.length > 0) {
  
      const orders_tasks = (await $datasources.SalesOrders.ds_get_orders_aggregated_tasks.get({ orderIds: outbound_orders, statusIds: [1, 4], operationCodeIds: [8, 23, 29] })).result;
      const shipments_tasks = (await $datasources.SalesOrders.ds_get_shipments_aggregated_tasks.get({ shipmentIds: outbound_shipments, statusIds: [2], operationCodeIds: [24] })).result;
      const shipments_licenseplates = (await $datasources.SalesOrders.ds_get_shipments_aggregated_licenseplates.get({ shipmentIds: outbound_shipments })).result;
      const orders_picked = (await $datasources.SalesOrders.ds_get_order_total_picked_units_by_orderIds.get({ orderIds: outbound_orders })).result;
      const orders_total = (await $datasources.SalesOrders.ds_get_order_total_units_by_orderIds.get({ orderIds: outbound_orders })).result;
      const dock_appointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderIds.get({ orderIds: outbound_orders })).result;
      const order_addresses = (await $flows.Addresses.get_addresses_single_string_by_orderIds({ orderIds: outbound_orders }));
  
      const manual_allocation_tasks = orders_tasks.filter(t => ((t.StatusId === 1 || t.StatusId === 4) && (t.OperationCodeId === 23)))
      const loading_tasks = shipments_tasks.filter(t => ((t.StatusId === 2) && (t.OperationCodeId === 24)))
      const released_pick_tasks = orders_tasks.filter(t => ((t.StatusId === 1 || t.StatusId === 4) && (t.OperationCodeId === 8 || t.OperationCodeId === 29)))
  
      const picked_array = orders_picked.map(row => row.ShipmentLine.OrderId);
      const total_array = orders_total.map(row => row.OrderId);
      const dock_array = dock_appointments.map(row => row.ItemEntityId);
      const manual_array = manual_allocation_tasks.map(row => row.OrderId);
      const released_array = released_pick_tasks.map(row => row.OrderId);
      const address_array = order_addresses.Addresses.map(row => row.OrderId);
      const loading_array = loading_tasks.map(row => row.ShipmentId);
      const licenseplates_array = shipments_licenseplates.map(row => row.ShipmentId);
  
      for (let row of $grid.rows) {
  
          let picked_total = 0;
          let expected_total = 0;
          let released_total = 0;
          let manual_total = 0;
          let loading_total = 0;
          let licenseplates_total = 0;
          let orderIndex = -1;
  
          let orderId = row.entity.OrderLookups[0]?.OrderId;
          let shipmentId = row.entity.Id ?? 0;
          let orderStatusId = row.entity.OrderLookups[0]?.Order?.OrderStatusId;
  
          //Get the total received inventory
          orderIndex = picked_array.indexOf(orderId)
          if (orderIndex >= 0) {
              picked_total = orders_picked[orderIndex].TotalUnitsPicked;
          };
  
          //Get the total expected inventory
          orderIndex = total_array.indexOf(orderId)
          if (orderIndex >= 0) {
              expected_total = orders_total[orderIndex].TotalUnits;
          };
  
          //Get the total released inventory
          orderIndex = released_array.indexOf(orderId)
          if (orderIndex >= 0) {
              released_total = released_pick_tasks[orderIndex].ExpectedPackagedAmount;
          };
  
          //Get the total manual allocations
          orderIndex = manual_array.indexOf(orderId)
          if (orderIndex >= 0) {
              manual_total = manual_allocation_tasks[orderIndex].ExpectedPackagedAmount;
          };
  
          //Get the total loading
          orderIndex = loading_array.indexOf(shipmentId)
          if (orderIndex >= 0) {
              loading_total = loading_tasks[orderIndex].RecordCount;
          };
  
          //Get the total licenseplates
          orderIndex = licenseplates_array.indexOf(shipmentId)
          if (orderIndex >= 0) {
              licenseplates_total = shipments_licenseplates[orderIndex].RecordCount;
          };
  
          // Check Status
          let has_picked = false;
          let has_pending = false;
          let has_manual = false;
  
          if (picked_total > 0) {
              has_picked = true;
          };
  
          if (manual_total > 0) {
              has_manual = true;
          };
  
          if (released_total > 0 || manual_total > 0) {
              has_pending = true;
          };
  
          //Set the total items
          if (expected_total > 0) {
              if (orderStatusId === 1 && expected_total > 0) {
                  row.cells.total_items.displayControl.text = `${expected_total}`
              } else {
                  row.cells.total_items.displayControl.text = `${picked_total} of ${expected_total}`
              };
          };
  
  
          let waveId = 0;
          let waveStatusId = 0;
  
  
          if ($utils.isDefined(row.entity.Id)) {
  
  
              //Set the shipment
              shipmentId = row.entity.Id;
  
              //Set the wave
              if ($utils.isDefined(row.entity.WaveId)) {
                  waveId = row.entity.WaveId
                  waveStatusId = row.entity.Wave.StatusId
              };
  
          };
  
          let order_state = 0;
  
          if (orderStatusId === 1) {
              order_state = 1;
          }
          else if (orderStatusId === 2) {
  
              //Replaces $flows.SalesOrders.get_sales_order_state
  
              // 2 Can Create Wave
              // 3 Can Process Wave
              // 4 Can Release Wave
              // 5 Ready to Pick
              // 6 Picking in progress
              // 7 Ready to load
              // 8 Ready to complete
              // 9 Ready to cancel
  
              if (orderStatusId === 2 && waveId === 0) {
                  // Can Create Wave
                  order_state = 2;
              } else if (orderStatusId === 2 && waveId > 0 && waveStatusId === 1) {
                  // Can Process Wave
                  order_state = 3;
  
              } else if (orderStatusId === 2 && waveId > 0 && waveStatusId === 2) {
                  // Can Release Wave
                  order_state = 4;
              } else {
  
                  if (has_pending && !has_picked) {
                      // All pick tasks in a released status
                      order_state = 5;
                  } else if (has_pending && has_picked) {
                      // One or more pick tasks completed and one or more pick tasks released
                      order_state = 6;
                  } else if (!has_pending && !has_picked && waveId > 0 && (waveStatusId === 4 || waveStatusId === 5)) {
                      //No pending, no picked, wave completed or cancelled.
                      order_state = 9;
                  } else if (!has_pending && !has_picked && expected_total === 0) {
                      //No pending, no picked, no expected quantity
                      order_state = 9;
                  } else if (!has_pending && has_picked) {
  
                      // No released pick tasks exist and one or more completed picking tasks
  
                      if (loading_total === 0) {
                          order_state = 7;
                      }
                      else if (loading_total === licenseplates_total) {
                          order_state = 8;
                      }
                      else if (licenseplates_total === 0) {
                          order_state = 9;
                      }
                      else {
                          order_state = 7;
                      }
  
                  };
              };
          };
  
          var waveDetails = '';
          waveDetails = row.entity.WaveId?.toString() + ' ' + row.entity.Wave?.Status?.Name;
  
          if ($utils.isDefined(order_state)) {
  
              switch (order_state) {
  
                  case 2: {
                      row.cells.status.displayControl.text = 'Ready to create wave'
                      break;
                  };
                  case 3: {
                      row.cells.status.displayControl.text = 'Ready to process wave ' + waveDetails
                      break;
                  };
                  case 4: {
                      row.cells.status.displayControl.text = 'Ready to release wave ' + waveDetails
                      break;
                  };
                  case 5: {
                      row.cells.status.displayControl.text = 'Ready to pick wave ' + waveDetails
                      break;
                  };
                  case 6: {
                      row.cells.status.displayControl.text = 'Picking in progress wave ' + waveDetails
                      break;
                  };
                  case 7: {
                      row.cells.status.displayControl.text = 'Ready to load'
                      break;
                  };
                  case 8: {
                      if (has_manual) {
                          row.cells.status.displayControl.text = 'Contains manual allocations wave ' + waveDetails
  
                      } else {
                          row.cells.status.displayControl.text = 'Ready to complete'
                      };
                      break;
                  };
                  case 9: {
                      if (has_manual) {
                          row.cells.status.displayControl.text = 'Contains manual allocations wave ' + waveDetails
  
                      } else {
                          row.cells.status.displayControl.text = 'Ready to cancel'
                      };
                      break;
                  };
              };
          } else {
              if (has_manual) {
                  row.cells.status.displayControl.text = 'Manual allocations wave ' + waveDetails
              };
          };
          // Set row level variable
          row.vars.stateId = order_state;
  
  
  
      };
  
  
  }
  }
  on_manifest_print_clicked(event = null) {
    return this.on_manifest_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manifest_print_clickedInternal(
    $grid: FootPrintManager_assigned_orders_on_wave_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Manifesting Error', 'No orders selected.');
      return;
  }
  
  
  else {
  
   
  
      var shipmentIds = $grid.selectedRows.map(s => s.entity.Id);
      $shell.Manifesting.openmanifesting_hub({ shipmentIds: shipmentIds })
  
  
  }
  
  
  }
  on_auto_pick_clicked(event = null) {
    return this.on_auto_pick_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_auto_pick_clickedInternal(
    $grid: FootPrintManager_assigned_orders_on_wave_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Order Auto Pick Error', 'No orders selected.');
      return;
  } else {
  
      // Extract the warehouse from the first record selected. Preventing auto picking across multiple warehouses.
      var warehouseId = selectedRows[0].entity.ActualWarehouseId;
      if (!$utils.isDefined(warehouseId)) {
  
          $shell.FootPrintManager.openErrorDialog('Order Auto Pick Error', 'Unable to determine preferred warehouse from orders selected');
          return;
      }
      var different = false;
  
      for (const row of selectedRows) {
  
          if (!$utils.isDefined(row.entity.ActualWarehouseId)) {
  
              $shell.FootPrintManager.openErrorDialog('Order Auto Pick Error', 'Unable to determine preferred warehouse from orders selected');
              return;
          }
  
          if (warehouseId !== row.entity.ActualWarehouseId) {
              different = true;
              break;
          }
  
  
  
      }
      if (different) {
          $shell.FootPrintManager.openErrorDialog('Order Auto Pick Error', 'Selected orders are from different warehouses, auto picking not allowed.');
          return;
      }
  
      const dialogResult = await $shell.FootPrintManager.openauto_pick_options_formDialog({ warehouse_id: warehouseId });
      var userConfirmed = dialogResult.confirm;
      var autoLoadConfirmed = dialogResult.auto_load;
      var loadLocation = dialogResult.load_location;
      let license_plate_lookup = dialogResult.license_plate_lookup;
  
      if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
          return;
      }
  
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              orderId: row.entity.OrderLookups[0].OrderId,
              shipmentId: row.entity.Id
          };
          const result = await $flows.SalesOrders.is_sales_order_pickable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Order ${row.entity.LookupCode} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Order auto pick errors';
          const errorMessage = `Order(s) ${failures.map(c => c.entity.LookupCode).join(',')} cannot be auto picked`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Order(s) ${candidates.map(c => c.entity.LookupCode).join(',')} - Once auto picked, any changes must be coordinated through the unpicking function.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some order(s) cannot be auto picked';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Auto pick the following order(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const CandidateSuccess = [];
              const CandidateFailures = [];
              const CandidateLoadSuccess = [];
              const CandidateLoadFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const result = await $flows.SalesOrders.auto_pick_sales_order_flow({
                          orderId: candidate.entity.OrderLookups[0].OrderId,
                          shipmentId: candidate.entity.Id,
                          license_plate_lookup: license_plate_lookup
                      });
                      const reason = result.reason;
                      if ($utils.isDefined(reason)) {
                          CandidateFailures.push(candidate);
                          errorMsgList.push(`Order ${candidate.entity.LookupCode} - ${reason}`);
                      } else {
                          CandidateSuccess.push(candidate);
                      }
  
                      // Handle Auto Load Request
                      if ($utils.isAllDefined(userConfirmed, autoLoadConfirmed) && userConfirmed === true && autoLoadConfirmed === true) {
  
                          const flowLoadParams = {
                              orderId: candidate.entity.OrderLookups[0].OrderId,
                              shipmentId: candidate.entity.Id,
                              loadLocation: loadLocation
                          }
                          const resultLoad = await $flows.SalesOrders.auto_load_sales_order_flow(flowLoadParams);
                          const reasonLoad = resultLoad.reason;
  
                          if ($utils.isDefined(reasonLoad)) {
                              CandidateLoadFailures.push(candidate);
                              errorMsgList.push(`Order ${candidate.entity.LookupCode} - ${reason}`);
                          } else {
                              CandidateLoadSuccess.push(candidate);
                          }
  
  
  
                      }
  
  
                  } catch (error) {
                      CandidateFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Order ${candidate.entity.LookupCode} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (CandidateSuccess.length === candidates.length) {
                  const title = 'All order(s) auto picked';
                  const message = `Order(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} auto picked`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  $grid.outParams.refresh = true;
                  $grid.events.outParamsChange.emit();
                  await $grid.refresh();
              } else {
                  // all failures
                  if (CandidateFailures.length === candidates.length) {
                      const title = 'All order(s) failed to auto pick';
                      const message = `Order(s) ${CandidateFailures.map(c => c.entity.LookupCode).join(',')} could not be auto picked`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some order(s) could not be auto picked';
                      const success = `Order(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} were auto picked.`;
                      const errors = `The following order(s) could not be auto picked: ${CandidateFailures.map(c => c.entity.LookupCode).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      $grid.outParams.refresh = true;
                      $grid.events.outParamsChange.emit();
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_auto_load_clicked(event = null) {
    return this.on_auto_load_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_auto_load_clickedInternal(
    $grid: FootPrintManager_assigned_orders_on_wave_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Order Auto Load Error', 'No orders selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              orderId: row.entity.OrderLookups[0]?.OrderId,
              shipmentId: row.entity.Id
          };
          const result = await $flows.SalesOrders.is_sales_order_loadable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Order ${row.entity.LookupCode} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Order auto load errors';
          const errorMessage = `Order(s) ${failures.map(c => c.entity.LookupCode).join(',')} cannot be auto loaded`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Order(s) ${candidates.map(c => c.entity.LookupCode).join(',')} - Once auto loaded, any changes must be coordinated through the unpicking function.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some order(s) cannot be auto loaded';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Auto load the following order(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const CandidateSuccess = [];
              const CandidateFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams = {
                          orderId: candidate.entity.OrderLookups[0]?.OrderId,
                          shipmentId: candidate.entity.Id
                      }
                      const result = await $flows.SalesOrders.auto_load_sales_order_flow(flowParams);
                      const reason = result.reason;
                      if ($utils.isDefined(reason)) {
                          CandidateFailures.push(candidate);
                          errorMsgList.push(`Order ${candidate.entity.LookupCode} - ${reason}`);
                      } else {
                          CandidateSuccess.push(candidate);
                      }
  
  
                  } catch (error) {
                      CandidateFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Order ${candidate.entity.LookupCode} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (CandidateSuccess.length === candidates.length) {
                  const title = 'All order(s) auto loaded';
                  const message = `Order(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} auto loaded`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  $grid.outParams.refresh = true;
                  $grid.events.outParamsChange.emit();
                  await $grid.refresh();
              } else {
                  // all failures
                  if (CandidateFailures.length === candidates.length) {
                      const title = 'All order(s) failed to auto load';
                      const message = `Order(s) ${CandidateFailures.map(c => c.entity.LookupCode).join(',')} could not be auto loaded`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some order(s) could not be auto loaded';
                      const success = `Order(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} were auto loaded.`;
                      const errors = `The following order(s) could not be auto loaded: ${CandidateFailures.map(c => c.entity.LookupCode).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      $grid.outParams.refresh = true;
                      $grid.events.outParamsChange.emit();
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_complete_clicked(event = null) {
    return this.on_complete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_clickedInternal(
    $grid: FootPrintManager_assigned_orders_on_wave_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Order Complete Error', 'No orders selected.');
      return;
  } else {
  
  
      // Extract the warehouse from the first record selected. Preventing auto picking across multiple warehouses.
      var warehouseId = selectedRows[0].entity.ActualWarehouseId;
      if (!$utils.isDefined(warehouseId)) {
  
          $shell.FootPrintManager.openErrorDialog('Order Completion Error', 'Unable to determine preferred warehouse from orders selected');
          return;
      }
      var different = false;
  
      for (const row of selectedRows) {
  
          if (!$utils.isDefined(row.entity.ActualWarehouseId)) {
  
              $shell.FootPrintManager.openErrorDialog('Order Completion Error', 'Unable to determine preferred warehouse from orders selected');
              return;
          }
  
          if (warehouseId !== row.entity.ActualWarehouseId) {
              different = true;
              break;
          }
  
  
  
      }
      if (different) {
          $shell.FootPrintManager.openErrorDialog('Order Completion Error', 'Selected orders are from different warehouses, bulk completion not allowed.');
          return;
      }
  
      const dialogResult = await $shell.FootPrintManager.openorder_completion_options_formDialog({ warehouseId: warehouseId });
      var userConfirmed = dialogResult.confirm;
      var autoLoadConfirmed = dialogResult.autoLoad;
      var loadLocation = dialogResult.loadLocation;
  
      var autoLoad = false;
      if ($utils.isDefined(userConfirmed) && userConfirmed === true) {
  
          if ($utils.isDefined(autoLoadConfirmed) && autoLoadConfirmed === true) {
              autoLoad = true;
          }
  
      }
      else {
          return;
      }
  
  
  
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
  
          // If auto load true attempt to auto load then validate that order is completeable
          if (autoLoad) {
              const result = await $flows.SalesOrders.auto_load_sales_order_flow({
                  orderId: row.entity.OrderLookups[0]?.OrderId,
                  shipmentId: row.entity.Id,
                  loadLocation: loadLocation
              });
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  failures.push(row);
              }
              else {
  
  
                  const flowParams = {
                      orderId: row.entity.OrderLookups[0]?.OrderId
                  };
                  const result = await $flows.SalesOrders.is_sales_order_completable_flow(flowParams);
                  const reason = result.reason;
                  if ($utils.isDefined(reason)) {
                      failures.push(row);
                      errors.push(`Order ${row.entity.LookupCode} - ${reason}`);
                  } else {
                      candidates.push(row);
                  }
  
  
  
              }
          }
          // Else check if order is completable
          else {
  
              const flowParams = {
                  orderId: row.entity.OrderLookups[0]?.OrderId
              };
              const result = await $flows.SalesOrders.is_sales_order_completable_flow(flowParams);
              const reason = result.reason;
              if ($utils.isDefined(reason)) {
                  failures.push(row);
                  errors.push(`Order ${row.entity.LookupCode} - ${reason}`);
              } else {
                  candidates.push(row);
              }
  
  
  
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Order complete errors';
          const errorMessage = `Order(s) ${failures.map(c => c.entity.LookupCode).join(',')} cannot be completed`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Order(s) ${candidates.map(c => c.entity.LookupCode).join(',')} - Once completed, the order cannot be changed.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some order(s) cannot be completed';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Complete the following order(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const CandidateSuccess = [];
              const CandidateFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams = {
                          orderId: candidate.entity.OrderLookups[0]?.OrderId
                      }
                      const result = await $flows.SalesOrders.complete_sales_order_flow(flowParams);
                      const reason = result.reason;
                      if ($utils.isDefined(reason)) {
                          CandidateFailures.push(candidate);
                          errorMsgList.push(`Order ${candidate.entity.LookupCode} - ${reason}`);
                      } else {
                          CandidateSuccess.push(candidate);
                      }
  
  
                  } catch (error) {
                      CandidateFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Order ${candidate.entity.LookupCode} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (CandidateSuccess.length === candidates.length) {
                  const title = 'All order(s) completed';
                  const message = `Order(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} completed`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  $grid.outParams.refresh = true;
                  $grid.events.outParamsChange.emit();
                  await $grid.refresh();
              } else {
                  // all failures
                  if (CandidateFailures.length === candidates.length) {
                      const title = 'All order(s) failed to complete';
                      const message = `Order(s) ${CandidateFailures.map(c => c.entity.LookupCode).join(',')} could not be completed`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some order(s) could not be completed';
                      const success = `Order(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} were completed.`;
                      const errors = `The following order(s) could not be completed: ${CandidateFailures.map(c => c.entity.LookupCode).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      $grid.outParams.refresh = true;
                      $grid.events.outParamsChange.emit();
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
