import { Subject } from 'rxjs';
import { ButtonModel, ControlModel } from './control';

// TODO: To discuss if we want to do the same as field for tools
export class ToolModel<TC extends ControlModel> {
  control: TC;
  private _hidden: boolean;
  hiddenChange: Subject<void> = new Subject<void>();
  removed: boolean = false;

  constructor(control: TC, removed: boolean = false) {
    this.control = control;
    this.removed = removed;
  }

  get hidden(): boolean {
    return this._hidden;
  }

  set hidden(value: boolean) {
    this._hidden = value;
    this.hiddenChange.next();  // Emit event when hidden changes
  }

  get hasButtonsToShow() {
    const buttons = (<any>this.control)?.buttons;
    return !this.removed && (!buttons || Object.values<ButtonModel>(buttons).some(x => !x.removed));
  }
}