<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.messages.hasTabToShow" class="tab" data-cy="tab-messages">
              <h2 [className]="tabs.messages.active? 'active': ''" (click)="tabs.messages.activate()">{{tabs.messages.title}}</h2>
            </div>
            <div *ngIf="tabs.logs.hasTabToShow" class="tab" data-cy="tab-logs">
              <h2 [className]="tabs.logs.active? 'active': ''" (click)="tabs.logs.activate()">{{tabs.logs.title}}</h2>
            </div>
          </div>
        
              <Utilities-messages_grid *ngIf="tabs.messages.active"
              #$tabs_messages
              [transaction_id]="$tabs_messages_messages_grid_inParams_transaction_id"
              [column_names]="$tabs_messages_messages_grid_inParams_column_names"
              [filter_values]="$tabs_messages_messages_grid_inParams_filter_values"
              ($refreshEvent)="refresh(false, false, '$tabs_messages')">
              </Utilities-messages_grid>
              <Utilities-logs_grid *ngIf="tabs.logs.active"
              #$tabs_logs
              [transaction_id]="$tabs_logs_logs_grid_inParams_transaction_id"
              [column_names]="$tabs_logs_logs_grid_inParams_column_names"
              [filter_values]="$tabs_logs_logs_grid_inParams_filter_values"
              ($refreshEvent)="refresh(false, false, '$tabs_logs')">
              </Utilities-logs_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>