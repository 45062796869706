import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Notifications_ds_auto_email_rulesService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { projectId?: number, context?: string, $top?: number, $skip?: number }): Promise<{ result?: { id?: string, context?: string, filters?: string, to?: string, cc?: string, bcc?: string, subject?: string, body?: string, notes?: string, created_by?: string, created_on?: string, modified_by?: string, modified_on?: string, last_executed_on?: string, frequency?: string, projectId?: number, is_enabled?: boolean, include_attachments?: boolean, dock_appointment_carrier_id?: number, dock_appointment_type_id?: number }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/Notifications/datasources/ds_auto_email_rules/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { projectId?: number, context?: string, $keys: string[] }): Promise<{ result?: { id?: string, context?: string, filters?: string, to?: string, cc?: string, bcc?: string, subject?: string, body?: string, notes?: string, created_by?: string, created_on?: string, modified_by?: string, modified_on?: string, last_executed_on?: string, frequency?: string, projectId?: number, is_enabled?: boolean, include_attachments?: boolean, dock_appointment_carrier_id?: number, dock_appointment_type_id?: number }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Notifications/datasources/ds_auto_email_rules/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      projectId?: number, context?: string    }) {
      return false;
    }
}
