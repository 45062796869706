import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_ds_get_order_id_by_invoiceidService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { invoice_id?: number }): 
  Promise<{ result: { BillingTaskId?: number, ActualAmount?: number, ActualUOM?: number, ApplicableMinimumCharge?: number, ApplicableRate?: number, InvoiceLineId?: number, MaturityDate?: string, Notes?: string, Total?: number, BillingTask?: { Amount?: number, BillingCodeId?: number, CreatedSysDateTime?: string, LicensePlateId?: number, LocationId?: number, LotId?: number, MaterialId?: number, Notes?: string, OperationCodeId?: number, OrderId?: number, OrderLineNumber?: number, PackagingId?: number, ProjectId?: number, ShipmentId?: number, ShipmentLineId?: number, UomId?: number, VendorLotId?: number, WarehouseId?: number, WorkOrderId?: number, BillingCode?: { Name?: string }, OperationCode?: { Name?: string }, Packaging?: { ShortName?: string }, Warehouse?: { Name?: string }, Project?: { LookupCode?: string }, Order?: { OwnerReference?: string } } } }> 
  {
    let url = `${environment.backendUrl}api/app/datasources/ds_get_order_id_by_invoiceid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      invoice_id?: number    }) {
      return false;
    }
}
