import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Dynamics365BusinessCentral_ds_flow_orders_detailService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { $top?: number, $skip?: number }): Promise<{ result?: { id?: string, number?: string, orderDate?: string, postingDate?: string, vendorId?: string, vendorNumber?: string, vendorName?: string, payToName?: string, payToVendorId?: string, payToVendorNumber?: string, shipToName?: string, shipToContact?: string, buyFromAddressLine1?: string, buyFromAddressLine2?: string, buyFromCity?: string, buyFromCountry?: string, buyFromState?: string, buyFromPostCode?: string, payToAddressLine1?: string, payToAddressLine2?: string, payToCity?: string, payToCountry?: string, payToState?: string, payToPostCode?: string, shipToAddressLine1?: string, shipToAddressLine2?: string, shipToCity?: string, shipToCountry?: string, shipToState?: string, shipToPostCode?: string, currencyCode?: string, pricesIncludeTax?: string, paymentTermsId?: string, shipmentMethodId?: string, purchaser?: string, requestedReceiptDate?: string, discountAmount?: string, discountAppliedBeforeTax?: string, totalAmountExcludingTax?: string, totalTaxAmount?: string, totalAmountIncludingTax?: string, fullyReceived?: string, status?: string, lastModifiedDateTime?: string, operation?: string }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/Dynamics365BusinessCentral/datasources/ds_flow_orders_detail/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { $keys: string[] }): Promise<{ result?: { id?: string, number?: string, orderDate?: string, postingDate?: string, vendorId?: string, vendorNumber?: string, vendorName?: string, payToName?: string, payToVendorId?: string, payToVendorNumber?: string, shipToName?: string, shipToContact?: string, buyFromAddressLine1?: string, buyFromAddressLine2?: string, buyFromCity?: string, buyFromCountry?: string, buyFromState?: string, buyFromPostCode?: string, payToAddressLine1?: string, payToAddressLine2?: string, payToCity?: string, payToCountry?: string, payToState?: string, payToPostCode?: string, shipToAddressLine1?: string, shipToAddressLine2?: string, shipToCity?: string, shipToCountry?: string, shipToState?: string, shipToPostCode?: string, currencyCode?: string, pricesIncludeTax?: string, paymentTermsId?: string, shipmentMethodId?: string, purchaser?: string, requestedReceiptDate?: string, discountAmount?: string, discountAppliedBeforeTax?: string, totalAmountExcludingTax?: string, totalTaxAmount?: string, totalAmountIncludingTax?: string, fullyReceived?: string, status?: string, lastModifiedDateTime?: string, operation?: string }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Dynamics365BusinessCentral/datasources/ds_flow_orders_detail/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      }) {
      return false;
    }
}
