import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Inventory_ds_container_types_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { fullTextSearch?: string, typeIds?: number[], active: boolean, containerTypesCategoryIds?: number[], ownerId?: number, projectId?: number, materialId?: number }): 
  Promise<{ result: { Id?: number, Active?: boolean, CatchAll?: boolean, ContainerTypeCategories?: number, Description?: string, DimensionUomId?: number, Height?: number, InnerHeight?: number, InnerLength?: number, InnerWidth?: number, Length?: number, Name?: string, VolumeCapacity?: number, VolumeUomId?: number, Weight?: number, WeightCapacity?: number, WeightUomId?: number, Width?: number, DimensionUom?: { Short_name?: string }, VolumeUom?: { Short_name?: string }, WeightUom?: { Short_name?: string }, Style?: { Id?: number, Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.active)) {
      missingRequiredInParams.push('\'active\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_container_types_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { fullTextSearch?: string, typeIds?: number[], active: boolean, containerTypesCategoryIds?: number[], ownerId?: number, projectId?: number, materialId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, Active?: boolean, CatchAll?: boolean, ContainerTypeCategories?: number, Description?: string, DimensionUomId?: number, Height?: number, InnerHeight?: number, InnerLength?: number, InnerWidth?: number, Length?: number, Name?: string, VolumeCapacity?: number, VolumeUomId?: number, Weight?: number, WeightCapacity?: number, WeightUomId?: number, Width?: number, DimensionUom?: { Short_name?: string }, VolumeUom?: { Short_name?: string }, WeightUom?: { Short_name?: string }, Style?: { Id?: number, Name?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.active)) {
      missingRequiredInParams.push('\'active\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_container_types_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { fullTextSearch?: string, typeIds?: number[], active: boolean, containerTypesCategoryIds?: number[], ownerId?: number, projectId?: number, materialId?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, Active?: boolean, CatchAll?: boolean, ContainerTypeCategories?: number, Description?: string, DimensionUomId?: number, Height?: number, InnerHeight?: number, InnerLength?: number, InnerWidth?: number, Length?: number, Name?: string, VolumeCapacity?: number, VolumeUomId?: number, Weight?: number, WeightCapacity?: number, WeightUomId?: number, Width?: number, DimensionUom?: { Short_name?: string }, VolumeUom?: { Short_name?: string }, WeightUom?: { Short_name?: string }, Style?: { Id?: number, Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.active)) {
      missingRequiredInParams.push('\'active\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_container_types_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      fullTextSearch?: string, typeIds?: number[], active: boolean, containerTypesCategoryIds?: number[], ownerId?: number, projectId?: number, materialId?: number    }) {
        if(isNil(inParams.active)) {
          return true; 
        }
      return false;
    }
}
