import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Dynamics365BusinessCentral.frontend.types'

@Injectable({ providedIn: 'root' })
export class Dynamics365BusinessCentral_flow_order_po_update_wl_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: {  }): Promise<void> 
  {
    let url = `${environment.backendUrl}api/Dynamics365BusinessCentral/functions/flow_order_po_update_wl_grid`;
    
    const options = {
    }
    
    const body = null;
    
    return this.utils.http.post(url, body, options);
    
  }

}

