import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Notifications_ds_get_auto_email_eligible_invoicesService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { projectId?: number, from_date?: string, $top?: number, $skip?: number }): Promise<{ result?: { invoiceId?: number, lookupCode?: string }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/Notifications/datasources/ds_get_auto_email_eligible_invoices/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { projectId?: number, from_date?: string, $keys: number[] }): Promise<{ result?: { invoiceId?: number, lookupCode?: string }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Notifications/datasources/ds_get_auto_email_eligible_invoices/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      projectId?: number, from_date?: string    }) {
      return false;
    }
}
