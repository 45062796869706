<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="order_type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.order_type.displayControl.styles.style"
          [ngClass]="row.cells.order_type.displayControl.styles.classes"
          [matTooltip]="row.cells.order_type.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.order_type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="operation">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.operation.displayControl.styles.style"
          [ngClass]="row.cells.operation.displayControl.styles.classes"
          [matTooltip]="row.cells.operation.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.operation.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="state">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.state.displayControl.styles.style"
          [ngClass]="row.cells.state.displayControl.styles.classes"
          [matTooltip]="row.cells.state.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.state.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="insert_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.insert_date.displayControl.styles.style"
          [ngClass]="row.cells.insert_date.displayControl.styles.classes"
          [matTooltip]="row.cells.insert_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.insert_date.displayControl.text | formatText : row.cells.insert_date.displayControl.formatType : row.cells.insert_date.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="error">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.error.displayControl.styles.style"
          [ngClass]="row.cells.error.displayControl.styles.classes"
          [matTooltip]="row.cells.error.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.error.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="pld">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.pld.displayControl.styles.style"
          [ngClass]="row.cells.pld.displayControl.styles.classes"
          [matTooltip]="row.cells.pld.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.pld.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
