import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Ultraship_http_configuration_add_formComponent } from './Ultraship.http_configuration_add_form.component';
import { Ultraship_project_configuration_add_formComponent } from './Ultraship.project_configuration_add_form.component';
import { Ultraship_sftp_configuration_add_formComponent } from './Ultraship.sftp_configuration_add_form.component';
import { Ultraship_ultraship_library_homeComponent } from './Ultraship.ultraship_library_home.component';
import { Ultraship_ultraship_http_configurations_gridComponent } from './Ultraship.ultraship_http_configurations_grid.component';
import { Ultraship_ultraship_messages_gridComponent } from './Ultraship.ultraship_messages_grid.component';
import { Ultraship_ultraship_project_configurations_gridComponent } from './Ultraship.ultraship_project_configurations_grid.component';
import { Ultraship_ultraship_sftp_configurations_gridComponent } from './Ultraship.ultraship_sftp_configurations_grid.component';
import { Ultraship_ultraship_configuration_hubComponent } from './Ultraship.ultraship_configuration_hub.component';
import { Ultraship_ultraship_hubComponent } from './Ultraship.ultraship_hub.component';
import { Ultraship_http_configuration_dd_singleComponent } from './Ultraship.http_configuration_dd_single.component';
import { Ultraship_owners_dd_singleComponent } from './Ultraship.owners_dd_single.component';
import { Ultraship_projects_configuration_dd_singleComponent } from './Ultraship.projects_configuration_dd_single.component';
import { Ultraship_projects_dd_singleComponent } from './Ultraship.projects_dd_single.component';
import { Ultraship_sftp_configuration_dd_singleComponent } from './Ultraship.sftp_configuration_dd_single.component';
import { Ultraship_http_configuration_dd_multiComponent } from './Ultraship.http_configuration_dd_multi.component';
import { Ultraship_owners_dd_multiComponent } from './Ultraship.owners_dd_multi.component';
import { Ultraship_projects_configuration_dd_multiComponent } from './Ultraship.projects_configuration_dd_multi.component';
import { Ultraship_projects_dd_multiComponent } from './Ultraship.projects_dd_multi.component';
import { Ultraship_sftp_configuration_dd_multiComponent } from './Ultraship.sftp_configuration_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Ultraship_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Ultraship: Ultraship_ShellService = this;

  public openhttp_configuration_add_form(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Http Configuration Creation Form',
        referenceName: 'Ultraship_http_configuration_add_form',
        component: Ultraship_http_configuration_add_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openhttp_configuration_add_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ projectId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Ultraship_http_configuration_add_formComponent,
      'Http Configuration Creation Form',
      mode,
      dialogSize
    )
  }
  public openproject_configuration_add_form(inParams:{ application_name?: string, environment?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Project Configuration Creation Form',
        referenceName: 'Ultraship_project_configuration_add_form',
        component: Ultraship_project_configuration_add_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openproject_configuration_add_formDialog(
    inParams:{ application_name?: string, environment?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ projectId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Ultraship_project_configuration_add_formComponent,
      'Project Configuration Creation Form',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensftp_configuration_add_form(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'SFTP Configuration Creation Form ',
        referenceName: 'Ultraship_sftp_configuration_add_form',
        component: Ultraship_sftp_configuration_add_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opensftp_configuration_add_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ projectId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Ultraship_sftp_configuration_add_formComponent,
      'SFTP Configuration Creation Form ',
      mode,
      dialogSize
    )
  }
  public openultraship_library_home(replaceCurrentView?: boolean) {
    this.logger.log('Ultraship', 'ultraship_library_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'ultraship_library_home',
        referenceName: 'Ultraship_ultraship_library_home',
        component: Ultraship_ultraship_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openultraship_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Ultraship', 'ultraship_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Ultraship_ultraship_library_homeComponent,
      'ultraship_library_home',
      mode,
      dialogSize
    )
  }
  public openultraship_http_configurations_grid(inParams:{ application_name?: string, user_name?: string, column_names?: { string_1?: string, string_2?: string, string_3?: string }, filter_values?: { string_1?: string, string_2?: string, string_3?: string }, name?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'ultraship_http_configurations_grid',
        referenceName: 'Ultraship_ultraship_http_configurations_grid',
        component: Ultraship_ultraship_http_configurations_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openultraship_http_configurations_gridDialog(
    inParams:{ application_name?: string, user_name?: string, column_names?: { string_1?: string, string_2?: string, string_3?: string }, filter_values?: { string_1?: string, string_2?: string, string_3?: string }, name?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Ultraship_ultraship_http_configurations_gridComponent,
      'ultraship_http_configurations_grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openultraship_messages_grid(inParams:{ application_name?: string, transaction_id?: string, grid_title?: string, column_names?: { number_1?: string, number_2?: string, number_3?: string, number_4?: string, number_5?: string, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string }, filter_values?: { number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string } }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'ultraship_messages_grid',
        referenceName: 'Ultraship_ultraship_messages_grid',
        component: Ultraship_ultraship_messages_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openultraship_messages_gridDialog(
    inParams:{ application_name?: string, transaction_id?: string, grid_title?: string, column_names?: { number_1?: string, number_2?: string, number_3?: string, number_4?: string, number_5?: string, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string }, filter_values?: { number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string } }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Ultraship_ultraship_messages_gridComponent,
      'ultraship_messages_grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openultraship_project_configurations_grid(inParams:{ application_name?: string, user_name?: string, column_names?: { string_1?: string, string_2?: string, string_3?: string }, filter_values?: { string_1?: string, string_2?: string, string_3?: string }, name?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'ultraship_project_configurations_grid',
        referenceName: 'Ultraship_ultraship_project_configurations_grid',
        component: Ultraship_ultraship_project_configurations_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openultraship_project_configurations_gridDialog(
    inParams:{ application_name?: string, user_name?: string, column_names?: { string_1?: string, string_2?: string, string_3?: string }, filter_values?: { string_1?: string, string_2?: string, string_3?: string }, name?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Ultraship_ultraship_project_configurations_gridComponent,
      'ultraship_project_configurations_grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openultraship_sftp_configurations_grid(inParams:{ application_name?: string, user_name?: string, column_names?: { string_1?: string, string_2?: string, string_3?: string }, filter_values?: { string_1?: string, string_2?: string, string_3?: string }, name?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'ultraship_sftp_configurations_grid',
        referenceName: 'Ultraship_ultraship_sftp_configurations_grid',
        component: Ultraship_ultraship_sftp_configurations_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openultraship_sftp_configurations_gridDialog(
    inParams:{ application_name?: string, user_name?: string, column_names?: { string_1?: string, string_2?: string, string_3?: string }, filter_values?: { string_1?: string, string_2?: string, string_3?: string }, name?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Ultraship_ultraship_sftp_configurations_gridComponent,
      'ultraship_sftp_configurations_grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openultraship_configuration_hub(inParams:{ application_name?: string, column_names?: any, filter_values?: any, user_name?: string }, replaceCurrentView?: boolean) {
    this.logger.log('Ultraship', 'ultraship_configuration_hub');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'ultraship_configuration_hub',
        referenceName: 'Ultraship_ultraship_configuration_hub',
        component: Ultraship_ultraship_configuration_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openultraship_configuration_hubDialog(
    inParams:{ application_name?: string, column_names?: any, filter_values?: any, user_name?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Ultraship', 'ultraship_configuration_hub');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Ultraship_ultraship_configuration_hubComponent,
      'ultraship_configuration_hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openultraship_hub(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'ultraship_hub',
        referenceName: 'Ultraship_ultraship_hub',
        component: Ultraship_ultraship_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openultraship_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Ultraship_ultraship_hubComponent,
      'ultraship_hub',
      mode,
      dialogSize
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Ultraship_http_configuration_add_form') {
      const title = 'Http Configuration Creation Form';
      const component = Ultraship_http_configuration_add_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Ultraship_project_configuration_add_form') {
      const title = 'Project Configuration Creation Form';
      const component = Ultraship_project_configuration_add_formComponent;
      const inParams:{ application_name?: string, environment?: string } = { application_name: null, environment: null };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }
      if (!isNil(params.get('environment'))) {
        const paramValueString = params.get('environment');
        // TODO: date
        inParams.environment = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Ultraship_sftp_configuration_add_form') {
      const title = 'SFTP Configuration Creation Form ';
      const component = Ultraship_sftp_configuration_add_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Ultraship_ultraship_library_home') {
      this.logger.log('Ultraship', 'ultraship_library_home');
      const title = 'ultraship_library_home';
      const component = Ultraship_ultraship_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Ultraship_ultraship_http_configurations_grid') {
      const title = 'ultraship_http_configurations_grid';
      const component = Ultraship_ultraship_http_configurations_gridComponent;
      const inParams:{ application_name?: string, user_name?: string, column_names?: { string_1?: string, string_2?: string, string_3?: string }, filter_values?: { string_1?: string, string_2?: string, string_3?: string }, name?: string } = { application_name: null, user_name: null, column_names: { string_1: null, string_2: null, string_3: null }, filter_values: { string_1: null, string_2: null, string_3: null }, name: null };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }
      if (!isNil(params.get('user_name'))) {
        const paramValueString = params.get('user_name');
        // TODO: date
        inParams.user_name = paramValueString;
              }
      if (!isNil(params.get('column_names'))) {
        const paramValueString = params.get('column_names');
        inParams.column_names = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('filter_values'))) {
        const paramValueString = params.get('filter_values');
        inParams.filter_values = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('name'))) {
        const paramValueString = params.get('name');
        // TODO: date
        inParams.name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Ultraship_ultraship_messages_grid') {
      const title = 'ultraship_messages_grid';
      const component = Ultraship_ultraship_messages_gridComponent;
      const inParams:{ application_name?: string, transaction_id?: string, grid_title?: string, column_names?: { number_1?: string, number_2?: string, number_3?: string, number_4?: string, number_5?: string, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string }, filter_values?: { number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string } } = { application_name: null, transaction_id: null, grid_title: null, column_names: { number_1: null, number_2: null, number_3: null, number_4: null, number_5: null, string_1: null, string_2: null, string_3: null, string_4: null, string_5: null }, filter_values: { number_1: null, number_2: null, number_3: null, number_4: null, number_5: null, string_1: null, string_2: null, string_3: null, string_4: null, string_5: null } };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('grid_title'))) {
        const paramValueString = params.get('grid_title');
        // TODO: date
        inParams.grid_title = paramValueString;
              }
      if (!isNil(params.get('column_names'))) {
        const paramValueString = params.get('column_names');
        inParams.column_names = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('filter_values'))) {
        const paramValueString = params.get('filter_values');
        inParams.filter_values = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Ultraship_ultraship_project_configurations_grid') {
      const title = 'ultraship_project_configurations_grid';
      const component = Ultraship_ultraship_project_configurations_gridComponent;
      const inParams:{ application_name?: string, user_name?: string, column_names?: { string_1?: string, string_2?: string, string_3?: string }, filter_values?: { string_1?: string, string_2?: string, string_3?: string }, name?: string } = { application_name: null, user_name: null, column_names: { string_1: null, string_2: null, string_3: null }, filter_values: { string_1: null, string_2: null, string_3: null }, name: null };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }
      if (!isNil(params.get('user_name'))) {
        const paramValueString = params.get('user_name');
        // TODO: date
        inParams.user_name = paramValueString;
              }
      if (!isNil(params.get('column_names'))) {
        const paramValueString = params.get('column_names');
        inParams.column_names = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('filter_values'))) {
        const paramValueString = params.get('filter_values');
        inParams.filter_values = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('name'))) {
        const paramValueString = params.get('name');
        // TODO: date
        inParams.name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Ultraship_ultraship_sftp_configurations_grid') {
      const title = 'ultraship_sftp_configurations_grid';
      const component = Ultraship_ultraship_sftp_configurations_gridComponent;
      const inParams:{ application_name?: string, user_name?: string, column_names?: { string_1?: string, string_2?: string, string_3?: string }, filter_values?: { string_1?: string, string_2?: string, string_3?: string }, name?: string } = { application_name: null, user_name: null, column_names: { string_1: null, string_2: null, string_3: null }, filter_values: { string_1: null, string_2: null, string_3: null }, name: null };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }
      if (!isNil(params.get('user_name'))) {
        const paramValueString = params.get('user_name');
        // TODO: date
        inParams.user_name = paramValueString;
              }
      if (!isNil(params.get('column_names'))) {
        const paramValueString = params.get('column_names');
        inParams.column_names = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('filter_values'))) {
        const paramValueString = params.get('filter_values');
        inParams.filter_values = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('name'))) {
        const paramValueString = params.get('name');
        // TODO: date
        inParams.name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Ultraship_ultraship_configuration_hub') {
      this.logger.log('Ultraship', 'ultraship_configuration_hub');
      const title = 'ultraship_configuration_hub';
      const component = Ultraship_ultraship_configuration_hubComponent;
      const inParams:{ application_name?: string, column_names?: any, filter_values?: any, user_name?: string } = { application_name: null, column_names: null, filter_values: null, user_name: null };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }
      if (!isNil(params.get('column_names'))) {
        const paramValueString = params.get('column_names');
        inParams.column_names = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('filter_values'))) {
        const paramValueString = params.get('filter_values');
        inParams.filter_values = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('user_name'))) {
        const paramValueString = params.get('user_name');
        // TODO: date
        inParams.user_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Ultraship_ultraship_hub') {
      const title = 'ultraship_hub';
      const component = Ultraship_ultraship_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Ultraship_http_configuration_dd_single') {
      this.logger.log('Ultraship', 'http_configuration_dd_single');
      const title = 'http_configuration_dd';
      const component = Ultraship_http_configuration_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Ultraship_owners_dd_single') {
      const title = 'Owners dropdown';
      const component = Ultraship_owners_dd_singleComponent;
      const inParams:{ statusId?: number, projectId?: number } = { statusId: null, projectId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Ultraship_projects_configuration_dd_single') {
      this.logger.log('Ultraship', 'projects_configuration_dd_single');
      const title = 'projects_configuration_dd';
      const component = Ultraship_projects_configuration_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Ultraship_projects_dd_single') {
      const title = 'Projects dropdown';
      const component = Ultraship_projects_dd_singleComponent;
      const inParams:{ statusId?: number, ownerId?: number, excludedProjectIds?: number[] } = { statusId: null, ownerId: null, excludedProjectIds: [] };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('excludedProjectIds'))) {
        const paramValueString = params.get('excludedProjectIds');
        inParams.excludedProjectIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Ultraship_sftp_configuration_dd_single') {
      this.logger.log('Ultraship', 'sftp_configuration_dd_single');
      const title = 'sftp_configuration_dd';
      const component = Ultraship_sftp_configuration_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Ultraship_http_configuration_dd_multi') {
      this.logger.log('Ultraship', 'http_configuration_dd_multi');
      const title = 'http_configuration_dd';
      const component = Ultraship_http_configuration_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Ultraship_owners_dd_multi') {
      const title = 'Owners dropdown';
      const component = Ultraship_owners_dd_multiComponent;
      const inParams:{ statusId?: number, projectId?: number } = { statusId: null, projectId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Ultraship_projects_configuration_dd_multi') {
      this.logger.log('Ultraship', 'projects_configuration_dd_multi');
      const title = 'projects_configuration_dd';
      const component = Ultraship_projects_configuration_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Ultraship_projects_dd_multi') {
      const title = 'Projects dropdown';
      const component = Ultraship_projects_dd_multiComponent;
      const inParams:{ statusId?: number, ownerId?: number, excludedProjectIds?: number[] } = { statusId: null, ownerId: null, excludedProjectIds: [] };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('excludedProjectIds'))) {
        const paramValueString = params.get('excludedProjectIds');
        inParams.excludedProjectIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Ultraship_sftp_configuration_dd_multi') {
      this.logger.log('Ultraship', 'sftp_configuration_dd_multi');
      const title = 'sftp_configuration_dd';
      const component = Ultraship_sftp_configuration_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
