import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Inventory_ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId_detailedService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { licenseplateId: number, lotId: number, packagingId: number }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, Amount?: number, FirstTimeLotReceivedOnLicensePlateDate?: string, PackagedAmount?: number, Lot?: { LookupCode?: string, VendorLot?: { Id?: number, LookupCode?: string }, Material?: { Id?: number, LookupCode?: string } }, LicensePlate?: { LookupCode?: string }, Packaged?: { Name?: string, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.licenseplateId)) {
      missingRequiredInParams.push('\'licenseplateId\'');
    }
    if (isNil(inParams.lotId)) {
      missingRequiredInParams.push('\'lotId\'');
    }
    if (isNil(inParams.packagingId)) {
      missingRequiredInParams.push('\'packagingId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId_detailed/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { licenseplateId: number, lotId: number, packagingId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, Amount?: number, FirstTimeLotReceivedOnLicensePlateDate?: string, PackagedAmount?: number, Lot?: { LookupCode?: string, VendorLot?: { Id?: number, LookupCode?: string }, Material?: { Id?: number, LookupCode?: string } }, LicensePlate?: { LookupCode?: string }, Packaged?: { Name?: string, ShortName?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.licenseplateId)) {
      missingRequiredInParams.push('\'licenseplateId\'');
    }
    if (isNil(inParams.lotId)) {
      missingRequiredInParams.push('\'lotId\'');
    }
    if (isNil(inParams.packagingId)) {
      missingRequiredInParams.push('\'packagingId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId_detailed/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { licenseplateId: number, lotId: number, packagingId: number, $keys: { LicensePlateId?: number, LotId?: number, PackagedId?: number }[] }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, Amount?: number, FirstTimeLotReceivedOnLicensePlateDate?: string, PackagedAmount?: number, Lot?: { LookupCode?: string, VendorLot?: { Id?: number, LookupCode?: string }, Material?: { Id?: number, LookupCode?: string } }, LicensePlate?: { LookupCode?: string }, Packaged?: { Name?: string, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.licenseplateId)) {
      missingRequiredInParams.push('\'licenseplateId\'');
    }
    if (isNil(inParams.lotId)) {
      missingRequiredInParams.push('\'lotId\'');
    }
    if (isNil(inParams.packagingId)) {
      missingRequiredInParams.push('\'packagingId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId_detailed/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      licenseplateId: number, lotId: number, packagingId: number    }) {
        if(isNil(inParams.licenseplateId)) {
          return true; 
        }
        if(isNil(inParams.lotId)) {
          return true; 
        }
        if(isNil(inParams.packagingId)) {
          return true; 
        }
      return false;
    }
}
