<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template 
                toolbarToolDef="logs" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_logs_button_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="configurations" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button 
                        data-cy="splitbutton"
                        class="datex-button splitbutton" 
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matMenuTriggerFor]="dropdownMenuOne"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip">
                  <div class="button-label">
                    <div *ngIf="tool.control.icon"
                          class="button-icon">
                      <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                    <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                    <div class="button-icon splitbutton-drop-icon">
                      <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                    </div>
                  </div>
                </button>
                <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                  <div data-cy="splitbutton-options">
                    <div *ngIf="!tool.control.buttons.projects.removed" 
                        mat-menu-item 
                        class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.projects.readOnly"
                              [ngStyle]="tool.control.buttons.projects.styles.style"
                              [ngClass]="tool.control.buttons.projects.styles.classes"
                              [matTooltip]="tool.control.buttons.projects.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_projects_button_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.projects.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.projects.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.projects.label}}</div>
                        </div>
                      </button>
                    </div>
                    <div *ngIf="!tool.control.buttons.http.removed" 
                        mat-menu-item 
                        class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.http.readOnly"
                              [ngStyle]="tool.control.buttons.http.styles.style"
                              [ngClass]="tool.control.buttons.http.styles.classes"
                              [matTooltip]="tool.control.buttons.http.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_http_button_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.http.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.http.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.http.label}}</div>
                        </div>
                      </button>
                    </div>
                    <div *ngIf="!tool.control.buttons.sftp.removed" 
                        mat-menu-item 
                        class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.sftp.readOnly"
                              [ngStyle]="tool.control.buttons.sftp.styles.style"
                              [ngClass]="tool.control.buttons.sftp.styles.classes"
                              [matTooltip]="tool.control.buttons.sftp.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_sftp_button_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.sftp.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.sftp.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.sftp.label}}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </mat-menu>
                </ng-container>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.transactions.hasTabToShow" class="tab" data-cy="tab-transactions">
              <h2 [className]="tabs.transactions.active? 'active': ''" (click)="tabs.transactions.activate()">{{tabs.transactions.title}}</h2>
            </div>
          </div>
        
              <Ultraship-ultraship_messages_grid *ngIf="tabs.transactions.active"
              #$tabs_transactions
              [application_name]="$tabs_transactions_ultraship_messages_grid_inParams_application_name"
              ($refreshEvent)="refresh(false, false, '$tabs_transactions')">
              </Ultraship-ultraship_messages_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>