import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_pick_waves_gridComponent } from './FootPrintManager.pick_waves_grid.component';
import { app_custom_wave_creation_gridComponent } from './app.custom_wave_creation_grid.component';
import { Waves_pick_waves_widgetComponent } from './Waves.pick_waves_widget.component';
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Locations_warehouses_dd_multiComponent } from './Locations.warehouses_dd_multi.component'
import { Waves_wave_statuses_dd_multiComponent } from './Waves.wave_statuses_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_pick_waves_gridComponent),
    forwardRef(() => app_custom_wave_creation_gridComponent),
    forwardRef(() => Waves_pick_waves_widgetComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Locations_warehouses_dd_multiComponent),
    forwardRef(() => Waves_wave_statuses_dd_multiComponent),
  ],
  selector: 'FootPrintManager-waves_hub',
  templateUrl: './FootPrintManager.waves_hub.component.html'
})
export class FootPrintManager_waves_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      create_new_wave: new ToolModel(new ButtonModel('create_new_wave', new ButtonStyles(null, null), false, false, 'Create new wave', 'icon-ic_fluent_add_20_regular', null)
    , false)
  };

  actionbar = {
  };

 filters = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Owner', false, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', false, false),
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Status', false, false),
  };


  filtersets = {
  newGroup1: new FieldsetModel('Filters', false, true, false, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      pick_waves: new TabItemModel(
        this.rootTabGroup, 
        'Pick waves', 
        false,
        ),
      create_wave: new TabItemModel(
        this.rootTabGroup, 
        'Orders', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    cacheValueFor_$tabs_pick_waves_pick_waves_grid_inParams_ownerId: number[];
    get $tabs_pick_waves_pick_waves_grid_inParams_ownerId(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_pick_waves_pick_waves_grid_inParams_ownerId, expr)) {
        this.cacheValueFor_$tabs_pick_waves_pick_waves_grid_inParams_ownerId = expr;
      }
      return this.cacheValueFor_$tabs_pick_waves_pick_waves_grid_inParams_ownerId;
    }
  
    cacheValueFor_$tabs_pick_waves_pick_waves_grid_inParams_projectId: number[];
    get $tabs_pick_waves_pick_waves_grid_inParams_projectId(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_pick_waves_pick_waves_grid_inParams_projectId, expr)) {
        this.cacheValueFor_$tabs_pick_waves_pick_waves_grid_inParams_projectId = expr;
      }
      return this.cacheValueFor_$tabs_pick_waves_pick_waves_grid_inParams_projectId;
    }
  
    cacheValueFor_$tabs_pick_waves_pick_waves_grid_inParams_warehouseId: number[];
    get $tabs_pick_waves_pick_waves_grid_inParams_warehouseId(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_pick_waves_pick_waves_grid_inParams_warehouseId, expr)) {
        this.cacheValueFor_$tabs_pick_waves_pick_waves_grid_inParams_warehouseId = expr;
      }
      return this.cacheValueFor_$tabs_pick_waves_pick_waves_grid_inParams_warehouseId;
    }
  
    cacheValueFor_$tabs_pick_waves_pick_waves_grid_inParams_statusIds: number[];
    get $tabs_pick_waves_pick_waves_grid_inParams_statusIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.status.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_pick_waves_pick_waves_grid_inParams_statusIds, expr)) {
        this.cacheValueFor_$tabs_pick_waves_pick_waves_grid_inParams_statusIds = expr;
      }
      return this.cacheValueFor_$tabs_pick_waves_pick_waves_grid_inParams_statusIds;
    }
  
    get $tabs_create_wave_custom_wave_creation_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $tabs_create_wave_custom_wave_creation_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_create_wave_custom_wave_creation_grid_inParams_warehouseIds: number[];
    get $tabs_create_wave_custom_wave_creation_grid_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_create_wave_custom_wave_creation_grid_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_create_wave_custom_wave_creation_grid_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_create_wave_custom_wave_creation_grid_inParams_warehouseIds;
    }
  
    get $tabs_create_wave_custom_wave_creation_grid_inParams_showCreate(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_pick_waves', { read: FootPrintManager_pick_waves_gridComponent }) $tabs_pick_waves: FootPrintManager_pick_waves_gridComponent;
      @ViewChild('$tabs_create_wave', { read: app_custom_wave_creation_gridComponent }) $tabs_create_wave: app_custom_wave_creation_gridComponent;
    //#endregion tabs children
    widgets = {
      wave_count_by_status: new WidgetModel(false),
    };
  
    //#region widgets inParams
    get $widgets_wave_count_by_status_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    get $widgets_wave_count_by_status_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $widgets_wave_count_by_status_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    cacheValueFor_$widgets_wave_count_by_status_inParams_statusIds: number[];
    get $widgets_wave_count_by_status_inParams_statusIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.status.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_wave_count_by_status_inParams_statusIds, expr)) {
        this.cacheValueFor_$widgets_wave_count_by_status_inParams_statusIds = expr;
      }
      return this.cacheValueFor_$widgets_wave_count_by_status_inParams_statusIds;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_wave_count_by_status', { read:  Waves_pick_waves_widgetComponent }) $widgets_wave_count_by_status: Waves_pick_waves_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_owner_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.project.control.value;
    
    return expr;
  }



  get $fields_project_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_project_selector_inParams_ownerId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.owner.control.value;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Waves hub`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Your landing page to view waves.`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: app_ShellService,
  private datasources: app_DatasourceService,
  private flows: app_FlowService,
  private reports: app_ReportService,
  private localization: app_LocalizationService,
  private operations: app_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.pick_waves,
      this.tabs.create_wave,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Waves hub';
    
    const $hub = this;
    const $utils = this.utils;

    (this.filters.status.control as SelectBoxModel).reset([1,2,3]);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['owner']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_changed();
      });
    this.formGroup
      .controls['project']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_changed();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_wave_count_by_status') {
      if (!isNil(this.$widgets_wave_count_by_status) && !this.widgets.wave_count_by_status.hidden) {
        this.$widgets_wave_count_by_status.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_pick_waves') {
      if (!isNil(this.$tabs_pick_waves) && !this.tabs.pick_waves.hidden) {
        this.$tabs_pick_waves.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_create_wave') {
      if (!isNil(this.$tabs_create_wave) && !this.tabs.create_wave.hidden) {
        this.$tabs_create_wave.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_owner_changed(event = null) {
    return this.on_owner_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changedInternal(
    $hub: FootPrintManager_waves_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $hub.filters.project.control.value = null;
  }
  on_project_changed(event = null) {
    return this.on_project_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changedInternal(
    $hub: FootPrintManager_waves_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const projectId = $hub.filters.project.control.value
  
  
  // Set Owner from Project
  if ($utils.isDefined(projectId) && !$utils.isDefined($hub.filters.owner.control.value)) {
      const project = (await $datasources.Materials.ds_get_project_by_projectId.get({
          projectId: projectId
      })).result;
     
      if ($utils.isDefined(project)) {
  
          $hub.filters.owner.control.value = project[0].OwnerId;
  
      }
  
  }
  
  }
  on_create_new_wave_clicked(event = null) {
    return this.on_create_new_wave_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_new_wave_clickedInternal(
    $hub: FootPrintManager_waves_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  let waveId: number;
  let createWave;
  
  try {
      if ($utils.isDefined($hub.filters.warehouse.control.value) || $hub.filters.warehouse.control.value != null) {
          if ($hub.filters.warehouse.control.value.length != 1) {
              //$shell.Waves.openErrorDialog(`Error creating wave`, `Please select a single warehouse from the dropdown. Wave cannot be associated with more than 1 warehouse.`)
              throw new Error(`Please select a single warehouse from the dropdown. Wave cannot be associated with more than 1 warehouse.`)
          } else {
              createWave = (await $flows.Waves.create_new_wave_flow({ warehouseId: $hub.filters.warehouse.control.value[0] }))
              if ($utils.isDefined(createWave.reason)) {
                  //$shell.Waves.openErrorDialog(`Error creating wave`, createWave.reason)
                  throw new Error(createWave.reason)
              } else (
                  $hub.refresh()
              )
          }
      } else {
          //$shell.Waves.openErrorDialog(`Error creating wave`, `Please select a warehouse from the dropdown before creating a new wave.`)
          throw new Error(`Please select a warehouse from the dropdown before creating a new wave.`)
      }
      if ($utils.isDefined(createWave.waveId)) {
          let confirmWave = (await $shell.FootPrintManager.openwave_editorDialog({
              waveId: createWave.waveId,
              projectId: $hub.filters.project.control.value,
              ownerId: $hub.filters.owner.control.value,
              warehouseId: $hub.filters.warehouse.control.value[0],
              showCreate: false
          }))
  
          if (confirmWave.confirm) {
              $shell.FootPrintManager.opensingle_wave_hub({waveId: createWave.waveId})
              $hub.refresh();
          }
      }
  }
  catch (error) {
      var errorMessage = $utils.isDefined(error?.error?.error?.message) ? error?.error?.error?.message : error?.message;
      if ($utils.isDefined(errorMessage)) {
          $shell.Waves.openErrorDialog(`Error creating wave`, errorMessage);
      }
  }
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_waves_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  if ((await $operations.FootPrintManager.Enable_Locations_DefaultWarehouseByActivity.isAuthorized())) {
  
      let username = (await $flows.Utilities.get_username_flow({})).userName;
  
      let defaultWarehouseResult = (await $datasources.Locations.ds_get_default_warehouse_by_user.get({ userName: username })).result;
  
      $hub.filters.warehouse.control.value = $utils.isDefined(defaultWarehouseResult?.warehouseId) ? [defaultWarehouseResult.warehouseId] : null;
  
  }
  }
  //#endregion private flows
}
