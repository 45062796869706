<div class="card-container">
  <mat-card class="datex-card card"
            data-cy="card" 
            [ngStyle]="styles.style" 
            [ngClass]="styles.classes">
    <ng-container
                  *ngIf="initialized && !$hasMissingRequiredInParams">



      <mat-card-title-group class="card-header">
        <div class="card-title-container">
          <ng-content></ng-content>
          <mat-card-title data-cy="card-title" class="card-title card-title-link" (click)="on_open_editor_clicked()">{{headerTitle}}</mat-card-title>
        </div>

      </mat-card-title-group>


      <mat-card-content data-cy="card-content" class="card-content" >
        <div [formGroup]="formGroupContent"
             class="formdata">
                <div data-cy="fieldset-formGroupContent-id-newGroup1"
                     *ngIf="!content.fieldsets.newGroup1.hidden && !content.fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': content.fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!content.fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="content.fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{content.fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="content.fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !content.fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': content.fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="content.fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-active" *ngIf="!content.fields.active.hidden && !content.fields.active.removed" 
                            class="field-container standard {{content.fields.active.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.active.styles.style"
                            [ngClass]="content.fields.active.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.active.label + (content.fields.active.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.active.label}}<span *ngIf="content.fields.active.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="active"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="content.fields.active.control.styles.style"
                                      [ngClass]="content.fields.active.control.styles.classes"
                                      [matTooltip]="content.fields.active.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{content.fields.active.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="content.fields.active.invalid">
                          <ng-container *ngFor="let error of content.fields.active.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-current_appointment" *ngIf="!content.fields.current_appointment.hidden && !content.fields.current_appointment.removed" 
                            class="field-container double {{content.fields.current_appointment.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.current_appointment.styles.style"
                            [ngClass]="content.fields.current_appointment.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.current_appointment.label + (content.fields.current_appointment.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.current_appointment.label}}<span *ngIf="content.fields.current_appointment.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="content.fields.current_appointment.control.styles.style"
                              [ngClass]="content.fields.current_appointment.control.styles.classes"
                              [matTooltip]="content.fields.current_appointment.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{content.fields.current_appointment.control.text }}</div>
                        <ng-container *ngIf="content.fields.current_appointment.invalid">
                          <ng-container *ngFor="let error of content.fields.current_appointment.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-upcoming_appointment" *ngIf="!content.fields.upcoming_appointment.hidden && !content.fields.upcoming_appointment.removed" 
                            class="field-container double {{content.fields.upcoming_appointment.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.upcoming_appointment.styles.style"
                            [ngClass]="content.fields.upcoming_appointment.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.upcoming_appointment.label + (content.fields.upcoming_appointment.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.upcoming_appointment.label}}<span *ngIf="content.fields.upcoming_appointment.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="content.fields.upcoming_appointment.control.styles.style"
                              [ngClass]="content.fields.upcoming_appointment.control.styles.classes"
                              [matTooltip]="content.fields.upcoming_appointment.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{content.fields.upcoming_appointment.control.text }}</div>
                        <ng-container *ngIf="content.fields.upcoming_appointment.invalid">
                          <ng-container *ngFor="let error of content.fields.upcoming_appointment.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
      </mat-card-content>

      <mat-card-actions data-cy="card-actions" class="card-actions">
          <app-toolbar [toolbar]="actionbar">
              <ng-template 
                toolbarToolDef="create_appointment" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_create_appointment_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
          </app-toolbar>
      </mat-card-actions>


    </ng-container>

    <div *ngIf="$hasMissingRequiredInParams"
        class="missing-params">
      <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
    </div>
  </mat-card>
  <div class="card-gripper"></div>
</div>