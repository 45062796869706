<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="delete_record" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-statuses" *ngIf="!filters.statuses.hidden && !filters.statuses.removed" 
            class="field-container standard {{filters.statuses.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.statuses.styles.style"
            [ngClass]="filters.statuses.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.statuses.label + (filters.statuses.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.statuses.label}}<span *ngIf="filters.statuses.required" class="required-asterisk">*</span>
          </label>
        </div>
        <FootPrintApiManager-statuses_dd_multi 
            data-cy="selector-multi"
            [type]="filters.statuses.control.type"
            formControlName="statuses"
            (displayTextChange)="filters.statuses.control.displayText=$event"
            [placeholder]="filters.statuses.control.placeholder"
            [styles]="filters.statuses.control.styles"
            [tooltip]="filters.statuses.control.tooltip"
        >
        </FootPrintApiManager-statuses_dd_multi>
        <ng-container *ngIf="filters.statuses.invalid">
          <ng-container *ngFor="let error of filters.statuses.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.id.displayControl.styles.style"
          [ngClass]="row.cells.id.displayControl.styles.classes"
          [matTooltip]="row.cells.id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="integration_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.integration_name.displayControl.styles.style"
          [ngClass]="row.cells.integration_name.displayControl.styles.classes"
          [matTooltip]="row.cells.integration_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.integration_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="environment">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.environment.displayControl.styles.style"
          [ngClass]="row.cells.environment.displayControl.styles.classes"
          [matTooltip]="row.cells.environment.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.environment.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="sender">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.sender.displayControl.styles.style"
          [ngClass]="row.cells.sender.displayControl.styles.classes"
          [matTooltip]="row.cells.sender.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.sender.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="receiver">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.receiver.displayControl.styles.style"
          [ngClass]="row.cells.receiver.displayControl.styles.classes"
          [matTooltip]="row.cells.receiver.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.receiver.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ingress_type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ingress_type.displayControl.styles.style"
          [ngClass]="row.cells.ingress_type.displayControl.styles.classes"
          [matTooltip]="row.cells.ingress_type.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ingress_type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="translation_status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.translation_status.displayControl.styles.style"
          [ngClass]="row.cells.translation_status.displayControl.styles.classes"
          [matTooltip]="row.cells.translation_status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.translation_status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="import_status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.import_status.displayControl.styles.style"
          [ngClass]="row.cells.import_status.displayControl.styles.classes"
          [matTooltip]="row.cells.import_status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.import_status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="order_status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.order_status.displayControl.styles.style"
          [ngClass]="row.cells.order_status.displayControl.styles.classes"
          [matTooltip]="row.cells.order_status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.order_status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.reference.displayControl.styles.style"
          [ngClass]="row.cells.reference.displayControl.styles.classes"
          [matTooltip]="row.cells.reference.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="export_status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.export_status.displayControl.styles.style"
          [ngClass]="row.cells.export_status.displayControl.styles.classes"
          [matTooltip]="row.cells.export_status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.export_status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="send_status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.send_status.displayControl.styles.style"
          [ngClass]="row.cells.send_status.displayControl.styles.classes"
          [matTooltip]="row.cells.send_status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.send_status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="egress_type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.egress_type.displayControl.styles.style"
          [ngClass]="row.cells.egress_type.displayControl.styles.classes"
          [matTooltip]="row.cells.egress_type.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.egress_type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="date_time">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.date_time.displayControl.styles.style"
          [ngClass]="row.cells.date_time.displayControl.styles.classes"
          [matTooltip]="row.cells.date_time.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.date_time.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="transaction_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.transaction_id.displayControl.styles.style"
          [ngClass]="row.cells.transaction_id.displayControl.styles.classes"
          [matTooltip]="row.cells.transaction_id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.transaction_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
