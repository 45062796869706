import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Ultraship_ShellService } from './Ultraship.shell.service';
import { Ultraship_OperationService } from './Ultraship.operation.service';
import { Ultraship_DatasourceService } from './Ultraship.datasource.index';
import { Ultraship_FlowService } from './Ultraship.flow.index';
import { Ultraship_ReportService } from './Ultraship.report.index';
import { Ultraship_LocalizationService } from './Ultraship.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Ultraship.frontend.types'
import { $frontendTypes as $types} from './Ultraship.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Ultraship-http_configuration_add_form',
  templateUrl: './Ultraship.http_configuration_add_form.component.html'
})
export class Ultraship_http_configuration_add_formComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { projectId?: number } = { projectId: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ projectId?: number }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    http_endpoint: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    http_port: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    http_username: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    http_password: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      Save: new ToolModel(new ButtonModel('Save', new ButtonStyles(['primary'], null), false, false, 'Save', 'icon-ic_fluent_save_20_regular', null)
    , false),
      discard: new ToolModel(new ButtonModel('discard', new ButtonStyles(['secondary'], null), false, false, 'Discard', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  fields = {
    http_endpoint: new FieldModel(new TextBoxModel(this.formGroup.controls['http_endpoint'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Endpoint', true, false),
    http_port: new FieldModel(new TextBoxModel(this.formGroup.controls['http_port'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Port', true, false),
    http_username: new FieldModel(new TextBoxModel(this.formGroup.controls['http_username'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Username', true, false),
    http_password: new FieldModel(new TextBoxModel(this.formGroup.controls['http_password'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Password', true, false),
  };

  fieldsets = {
  httpConfigurationGroup: new FieldsetModel('HTTP Endpoint', false, false, true, false),
  http_credentials: new FieldsetModel('HTTP Credentials', false, false, true, false),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    http_endpoint: this.fields.http_endpoint.control.valueChanges
    ,
    http_port: this.fields.http_port.control.valueChanges
    ,
    http_username: this.fields.http_username.control.valueChanges
    ,
    http_password: this.fields.http_password.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Ultraship_ShellService,
private datasources: Ultraship_DatasourceService,
private flows: Ultraship_FlowService,
private reports: Ultraship_ReportService,
private localization: Ultraship_LocalizationService,
private operations: Ultraship_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Http Configuration Creation Form';
  
    const $form = this;
    const $utils = this.utils;

    
    
    
    


    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .http_endpoint
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .http_port
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .http_username
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .http_password
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: Ultraship_http_configuration_add_formComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.logger.log('Ultraship', 'http_configuration_add_form.on_init');
  
  }
  on_discard_clicked(event = null) {
    return this.on_discard_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_discard_clickedInternal(
    $form: Ultraship_http_configuration_add_formComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
  $form.close();
  }
  on_save_clicked(event = null) {
    return this.on_save_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_clickedInternal(
    $form: Ultraship_http_configuration_add_formComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
  let instanceInfo = await $flows.Ultraship.get_instance_information({})
  let applicationName = instanceInfo.application_name
  let environment = instanceInfo.environment
  
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.http_endpoint.control.value,
      $form.fields.http_port.control.value,
      $form.fields.http_username.control.value,
      $form.fields.http_password.control.value,
  );
  
  if (!allRequiredFieldHaveValue) {
      return;
  }
  
      // Check there are configurations for the application
      var applicationConfigs = await $flows.Utilities.configurations_get({
          criteria: {
              application_name: applicationName
          }
      });
  
      if ($utils.isDefined(applicationConfigs)) {
  
          const matchList = applicationConfigs.output.filter(p => 
              p.string_1 === instanceInfo.environment
              && p.string_2 === $types.Ultraship.ConfigurationType.HTTP)
  
          if ($utils.isDefined(matchList) && matchList.length > 0) {
              $shell.Utilities.openConfirmationDialog("HTTP Configuration", `Transaction is cancelled because the configuration exists.`)
              return;
          }
  
          // HTTP Endpoint
          await $flows.Utilities.configurations_write({
              input: [{
                  application_name: applicationName,
                  name: $types.Ultraship.Configuration.ultraship_http_endpoint,
                  value: $form.fields.http_endpoint.control.value,
                  string_1: instanceInfo.environment,
                  string_2: $types.Ultraship.ConfigurationType.HTTP
              }],
              criteria: { application_name: applicationName }
          });
  
          // HTTP Port
          await $flows.Utilities.configurations_write({
              input: [{
                  application_name: applicationName,
                  name: $types.Ultraship.Configuration.ultraship_http_port,
                  value: $form.fields.http_port.control.value,
                  string_1: instanceInfo.environment,
                  string_2: $types.Ultraship.ConfigurationType.HTTP
              }],
              criteria: { application_name: applicationName }
          });
  
          // HTTP Username
          await $flows.Utilities.configurations_write({
              input: [{
                  application_name: applicationName,
                  name: $types.Ultraship.Configuration.ultraship_http_username,
                  value: $form.fields.http_username.control.value,
                  string_1: instanceInfo.environment,
                  string_2: $types.Ultraship.ConfigurationType.HTTP
              }],
              criteria: { application_name: applicationName }
          });
  
          // HTTP Password
          await $flows.Utilities.configurations_write({
              input: [{
                  application_name: applicationName,
                  name: $types.Ultraship.Configuration.ultraship_http_password,
                  value: $form.fields.http_password.control.value,
                  string_1: instanceInfo.environment,
                  string_2: $types.Ultraship.ConfigurationType.HTTP
              }],
              criteria: { application_name: applicationName }
          });
  
  
          $shell.Utilities.openConfirmationDialog("HTTP Configuration", `Successfully added the HTTP configuration.`)
  
          $form.fields.http_endpoint.control.value = null;
          $form.fields.http_port.control.value = null;
          $form.fields.http_username.control.value = null;
          $form.fields.http_password.control.value = null;
  
      } // End Configurations check
  
  
  
  }
  //#endregion private flows
}
