<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-Document360"
                     *ngIf="!fieldsets.Document360.hidden && !fieldsets.Document360.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.Document360.collapsible }">
                    <div *ngIf="!fieldsets.Document360.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.Document360.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.Document360.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.Document360.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.Document360.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.Document360.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.Document360.expanded"
                         class="fieldsetsGroup">
                    </div>
                  </div>
                </div>
              </div>
      </ng-container>

    </div>
  </div>
</div>