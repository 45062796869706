<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.order_attachments.hasTabToShow" class="tab" data-cy="tab-order_attachments">
              <h2 [className]="tabs.order_attachments.active? 'active': ''" (click)="tabs.order_attachments.activate()">{{tabs.order_attachments.title}}</h2>
            </div>
            <div *ngIf="tabs.shipment_attachments.hasTabToShow" class="tab" data-cy="tab-shipment_attachments">
              <h2 [className]="tabs.shipment_attachments.active? 'active': ''" (click)="tabs.shipment_attachments.activate()">{{tabs.shipment_attachments.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-entity_attachments_grid *ngIf="tabs.order_attachments.active"
              #$tabs_order_attachments
              [entityType]="$tabs_order_attachments_entity_attachments_grid_inParams_entityType"
              [entityKeys]="$tabs_order_attachments_entity_attachments_grid_inParams_entityKeys"
              ($refreshEvent)="refresh(false, false, '$tabs_order_attachments')">
              </FootPrintManager-entity_attachments_grid>
              <FootPrintManager-entity_attachments_grid *ngIf="tabs.shipment_attachments.active"
              #$tabs_shipment_attachments
              [entityType]="$tabs_shipment_attachments_entity_attachments_grid_inParams_entityType"
              [entityKeys]="$tabs_shipment_attachments_entity_attachments_grid_inParams_entityKeys"
              ($refreshEvent)="refresh(false, false, '$tabs_shipment_attachments')">
              </FootPrintManager-entity_attachments_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>