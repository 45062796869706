import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class LoadContainers_ds_orders_by_load_container_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { take?: number, skip?: number, loadContainerId: number, fullTextSearch?: string }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, FulfillmentDate?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Id?: number, Name?: string }, OrderClass?: { LookupCode?: string, OrderClassTypeId?: number, OrderTypeId?: number }, Project?: { LookupCode?: string, Name?: string, OwnerId?: number, Owner?: { LookupCode?: string, Name?: string } }, Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, PreferredWarehouse?: { Id?: number, Name?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierServiceType?: { Id?: number, Name?: string }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { ContainerSize?: string, ExpectedDate?: string, LoadContainerSequence?: number, LookupCode?: string, SealId?: string, StatusId?: number, TrackingIdentifier?: string, WaveId?: number, Wave?: { StatusId?: number, Status?: { Name?: string } } } }[], Addresses?: { AttentionOf?: string, Country?: string, FirstName?: string, LastName?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[] }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.loadContainerId)) {
      missingRequiredInParams.push('\'loadContainerId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LoadContainers/datasources/ds_orders_by_load_container_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { take?: number, skip?: number, loadContainerId: number, fullTextSearch?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, FulfillmentDate?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Id?: number, Name?: string }, OrderClass?: { LookupCode?: string, OrderClassTypeId?: number, OrderTypeId?: number }, Project?: { LookupCode?: string, Name?: string, OwnerId?: number, Owner?: { LookupCode?: string, Name?: string } }, Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, PreferredWarehouse?: { Id?: number, Name?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierServiceType?: { Id?: number, Name?: string }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { ContainerSize?: string, ExpectedDate?: string, LoadContainerSequence?: number, LookupCode?: string, SealId?: string, StatusId?: number, TrackingIdentifier?: string, WaveId?: number, Wave?: { StatusId?: number, Status?: { Name?: string } } } }[], Addresses?: { AttentionOf?: string, Country?: string, FirstName?: string, LastName?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[] }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.loadContainerId)) {
      missingRequiredInParams.push('\'loadContainerId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LoadContainers/datasources/ds_orders_by_load_container_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { take?: number, skip?: number, loadContainerId: number, fullTextSearch?: string, $keys: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, FulfillmentDate?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Id?: number, Name?: string }, OrderClass?: { LookupCode?: string, OrderClassTypeId?: number, OrderTypeId?: number }, Project?: { LookupCode?: string, Name?: string, OwnerId?: number, Owner?: { LookupCode?: string, Name?: string } }, Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, PreferredWarehouse?: { Id?: number, Name?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierServiceType?: { Id?: number, Name?: string }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { ContainerSize?: string, ExpectedDate?: string, LoadContainerSequence?: number, LookupCode?: string, SealId?: string, StatusId?: number, TrackingIdentifier?: string, WaveId?: number, Wave?: { StatusId?: number, Status?: { Name?: string } } } }[], Addresses?: { AttentionOf?: string, Country?: string, FirstName?: string, LastName?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.loadContainerId)) {
      missingRequiredInParams.push('\'loadContainerId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LoadContainers/datasources/ds_orders_by_load_container_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      take?: number, skip?: number, loadContainerId: number, fullTextSearch?: string    }) {
        if(isNil(inParams.loadContainerId)) {
          return true; 
        }
      return false;
    }
}
