import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Inventory_ds_get_lot_by_lotIdsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { lot_ids: number[] }): 
  Promise<{ result: { Id?: number, LastReceiveDate?: string, LookupCode?: string, MaterialId?: number, Notes?: string, ReceiveDate?: string, VendorLotId?: number, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Status?: { Id?: number, Name?: string }, Material?: { LookupCode?: string, MixingRuleId?: number, ProjectId?: number, Project?: { LookupCode?: string, OwnerId?: number }, StorageCategoryRule?: { Id?: number, Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.lot_ids)) {
      missingRequiredInParams.push('\'lot_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_get_lot_by_lotIds/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { lot_ids: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, LastReceiveDate?: string, LookupCode?: string, MaterialId?: number, Notes?: string, ReceiveDate?: string, VendorLotId?: number, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Status?: { Id?: number, Name?: string }, Material?: { LookupCode?: string, MixingRuleId?: number, ProjectId?: number, Project?: { LookupCode?: string, OwnerId?: number }, StorageCategoryRule?: { Id?: number, Name?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.lot_ids)) {
      missingRequiredInParams.push('\'lot_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_get_lot_by_lotIds/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { lot_ids: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, LastReceiveDate?: string, LookupCode?: string, MaterialId?: number, Notes?: string, ReceiveDate?: string, VendorLotId?: number, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Status?: { Id?: number, Name?: string }, Material?: { LookupCode?: string, MixingRuleId?: number, ProjectId?: number, Project?: { LookupCode?: string, OwnerId?: number }, StorageCategoryRule?: { Id?: number, Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.lot_ids)) {
      missingRequiredInParams.push('\'lot_ids\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_get_lot_by_lotIds/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      lot_ids: number[]    }) {
        if(isNil(inParams.lot_ids)) {
          return true; 
        }
      return false;
    }
}
