import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Lots_lot_date_types_singleComponent } from './Lots.lot_date_types_single.component';
import { Lots_lot_statuses_dd_singleComponent } from './Lots.lot_statuses_dd_single.component';
import { Lots_lots_dd_singleComponent } from './Lots.lots_dd_single.component';
import { Lots_reason_codes_singleComponent } from './Lots.reason_codes_single.component';
import { Lots_vendorlots_dd_singleComponent } from './Lots.vendorlots_dd_single.component';
import { Lots_lot_date_types_multiComponent } from './Lots.lot_date_types_multi.component';
import { Lots_lot_statuses_dd_multiComponent } from './Lots.lot_statuses_dd_multi.component';
import { Lots_lots_dd_multiComponent } from './Lots.lots_dd_multi.component';
import { Lots_reason_codes_multiComponent } from './Lots.reason_codes_multi.component';
import { Lots_vendorlots_dd_multiComponent } from './Lots.vendorlots_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Lots_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Lots: Lots_ShellService = this;


  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Lots_lot_date_types_single') {
      const title = 'Lot Date Types';
      const component = Lots_lot_date_types_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Lots_lot_statuses_dd_single') {
      const title = 'Lot Status';
      const component = Lots_lot_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Lots_lots_dd_single') {
      const title = 'Lots dropdown';
      const component = Lots_lots_dd_singleComponent;
      const inParams:{ materialId: number, vendorLotId?: number, lotLookupCode?: string, vendorLotLookupCode?: string } = { materialId: null, vendorLotId: null, lotLookupCode: null, vendorLotLookupCode: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('vendorLotId'))) {
        const paramValueString = params.get('vendorLotId');
        inParams.vendorLotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotLookupCode'))) {
        const paramValueString = params.get('lotLookupCode');
        // TODO: date
        inParams.lotLookupCode = paramValueString;
              }
      if (!isNil(params.get('vendorLotLookupCode'))) {
        const paramValueString = params.get('vendorLotLookupCode');
        // TODO: date
        inParams.vendorLotLookupCode = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Lots_reason_codes_single') {
      const title = 'reason_codes';
      const component = Lots_reason_codes_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Lots_vendorlots_dd_single') {
      const title = 'Vendor Lots dropdown';
      const component = Lots_vendorlots_dd_singleComponent;
      const inParams:{ materialId: number, lotId?: number, vendorLotLookupCode?: string } = { materialId: null, lotId: null, vendorLotLookupCode: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('vendorLotLookupCode'))) {
        const paramValueString = params.get('vendorLotLookupCode');
        // TODO: date
        inParams.vendorLotLookupCode = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Lots_lot_date_types_multi') {
      const title = 'Lot Date Types';
      const component = Lots_lot_date_types_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Lots_lot_statuses_dd_multi') {
      const title = 'Lot Status';
      const component = Lots_lot_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Lots_lots_dd_multi') {
      const title = 'Lots dropdown';
      const component = Lots_lots_dd_multiComponent;
      const inParams:{ materialId: number, vendorLotId?: number, lotLookupCode?: string, vendorLotLookupCode?: string } = { materialId: null, vendorLotId: null, lotLookupCode: null, vendorLotLookupCode: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('vendorLotId'))) {
        const paramValueString = params.get('vendorLotId');
        inParams.vendorLotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotLookupCode'))) {
        const paramValueString = params.get('lotLookupCode');
        // TODO: date
        inParams.lotLookupCode = paramValueString;
              }
      if (!isNil(params.get('vendorLotLookupCode'))) {
        const paramValueString = params.get('vendorLotLookupCode');
        // TODO: date
        inParams.vendorLotLookupCode = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Lots_reason_codes_multi') {
      const title = 'reason_codes';
      const component = Lots_reason_codes_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Lots_vendorlots_dd_multi') {
      const title = 'Vendor Lots dropdown';
      const component = Lots_vendorlots_dd_multiComponent;
      const inParams:{ materialId: number, lotId?: number, vendorLotLookupCode?: string } = { materialId: null, lotId: null, vendorLotLookupCode: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('vendorLotLookupCode'))) {
        const paramValueString = params.get('vendorLotLookupCode');
        // TODO: date
        inParams.vendorLotLookupCode = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
