import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_shipping_containers_to_verify_gridComponent } from './FootPrintManager.shipping_containers_to_verify_grid.component';
import { PackVerification_shipping_containers_to_verify_widgetComponent } from './PackVerification.shipping_containers_to_verify_widget.component';
import { PackVerification_shipping_containers_failed_verification_widgetComponent } from './PackVerification.shipping_containers_failed_verification_widget.component';
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { PackVerification_verification_locations_dd_singleComponent } from './PackVerification.verification_locations_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_shipping_containers_to_verify_gridComponent),
    forwardRef(() => PackVerification_shipping_containers_to_verify_widgetComponent),
    forwardRef(() => PackVerification_shipping_containers_failed_verification_widgetComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => PackVerification_verification_locations_dd_singleComponent),
  ],
  selector: 'FootPrintManager-pack_verification_hub',
  templateUrl: './FootPrintManager.pack_verification_hub.component.html'
})
export class FootPrintManager_pack_verification_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { defaultWarehouseId?: number } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    location: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    container_code: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      manage: new ToolModel(new SplitButtonModel<{ manage_configurations: ButtonModel }>(
        'manage',
        new ButtonStyles(null, null),
        false,
        'Manage',
        'icon-ic_fluent_settings_20_regular',
        null,
        [
          new ButtonModel('manage_configurations', new ButtonStyles(null, null), false, false, 'Configurations', '')
        ])
    , false)
  };

  actionbar = {
  };

 filters = {
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', true, false),
    location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['location'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Verification location', true, false),
    container_code: new FieldModel(new TextBoxModel(this.formGroup.controls['container_code'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Container code', false, false),
  };


  filtersets = {
  newGroup1: new FieldsetModel('', true, false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      containers_to_verify: new TabItemModel(
        this.rootTabGroup, 
        'Containers to verify', 
        false,
        ),
      failed_containers: new TabItemModel(
        this.rootTabGroup, 
        'Failed verification', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_containers_to_verify_shipping_containers_to_verify_grid_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    get $tabs_containers_to_verify_shipping_containers_to_verify_grid_inParams_locationId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.location.control.value;
      
      return expr;
    }
  
    get $tabs_containers_to_verify_shipping_containers_to_verify_grid_inParams_shippingContainerCode(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.container_code.control.value;
      
      return expr;
    }
  
    get $tabs_containers_to_verify_shipping_containers_to_verify_grid_inParams_isFailed(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = false;
      
      return expr;
    }
  
    get $tabs_failed_containers_shipping_containers_to_verify_grid_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    get $tabs_failed_containers_shipping_containers_to_verify_grid_inParams_locationId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.location.control.value;
      
      return expr;
    }
  
    get $tabs_failed_containers_shipping_containers_to_verify_grid_inParams_shippingContainerCode(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.container_code.control.value;
      
      return expr;
    }
  
    get $tabs_failed_containers_shipping_containers_to_verify_grid_inParams_isFailed(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_containers_to_verify', { read: FootPrintManager_shipping_containers_to_verify_gridComponent }) $tabs_containers_to_verify: FootPrintManager_shipping_containers_to_verify_gridComponent;
      @ViewChild('$tabs_failed_containers', { read: FootPrintManager_shipping_containers_to_verify_gridComponent }) $tabs_failed_containers: FootPrintManager_shipping_containers_to_verify_gridComponent;
    //#endregion tabs children
    widgets = {
      shipping_containers_to_verify: new WidgetModel(false),
      shipping_containers_failed_verification: new WidgetModel(false),
    };
  
    //#region widgets inParams
    get $widgets_shipping_containers_to_verify_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    get $widgets_shipping_containers_to_verify_inParams_locationId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = null;
      
      return expr;
    }
  
    get $widgets_shipping_containers_failed_verification_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    get $widgets_shipping_containers_failed_verification_inParams_locationId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.location.control.value;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_shipping_containers_to_verify', { read:  PackVerification_shipping_containers_to_verify_widgetComponent }) $widgets_shipping_containers_to_verify: PackVerification_shipping_containers_to_verify_widgetComponent;
      @ViewChild('$widgets_shipping_containers_failed_verification', { read:  PackVerification_shipping_containers_failed_verification_widgetComponent }) $widgets_shipping_containers_failed_verification: PackVerification_shipping_containers_failed_verification_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  get $fields_location_selector_inParams_warehouseId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.warehouse.control.value;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Pack verification hub`;
  }


  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.containers_to_verify,
      this.tabs.failed_containers,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Pack verification hub';
    
    const $hub = this;
    const $utils = this.utils;

    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['warehouse']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_warehouse_changed();
      });
    this.formGroup
      .controls['location']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_verification_location_changed();
      });
    this.formGroup
      .controls['container_code']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_container_code_changed();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_shipping_containers_to_verify') {
      if (!isNil(this.$widgets_shipping_containers_to_verify) && !this.widgets.shipping_containers_to_verify.hidden) {
        this.$widgets_shipping_containers_to_verify.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_shipping_containers_failed_verification') {
      if (!isNil(this.$widgets_shipping_containers_failed_verification) && !this.widgets.shipping_containers_failed_verification.hidden) {
        this.$widgets_shipping_containers_failed_verification.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_containers_to_verify') {
      if (!isNil(this.$tabs_containers_to_verify) && !this.tabs.containers_to_verify.hidden) {
        this.$tabs_containers_to_verify.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_failed_containers') {
      if (!isNil(this.$tabs_failed_containers) && !this.tabs.failed_containers.hidden) {
        this.$tabs_failed_containers.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_pack_verification_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $flows.PackVerification.initialize_pack_verification_flow({});
  
  // Set default warehouse
  let username = (await $flows.Utilities.get_username_flow({})).userName;
  let defaultWarehouseResult = (await $datasources.Locations.ds_get_default_warehouse_by_user.get({ userName: username })).result;
  $hub.filters.warehouse.control.value = $utils.isDefined(defaultWarehouseResult?.warehouseId) ? defaultWarehouseResult.warehouseId : null;
  
  if (!$utils.isDefined($hub.filters.location.control.value)) {
      $hub.filters.container_code.hidden = true;
  } else {
      $hub.filters.container_code.hidden = false;
      $hub.filters.container_code.control.focus();
  }
  }
  on_container_code_changed(event = null) {
    return this.on_container_code_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_container_code_changedInternal(
    $hub: FootPrintManager_pack_verification_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      if ($utils.isDefined($hub.filters.container_code.control.value)) {
          let isBlind = true;
  
          let container = (await $datasources.PackVerification.ds_get_shippingcontainer_by_shippingContainerCode.get({ shippingContainerCode: $hub.filters.container_code.control.value })).result;
  
          if (!$utils.isDefined(container)) { throw new Error(`Container '${$hub.filters.container_code.control.value}' does not exist.`) }
  
          let startTasks = (await $datasources.PackVerification.ds_get_start_pack_verification_tasks_by_shippingContainerId.getList({ shippingContainerId: container.Id })).result;
  
          let failedStartTask = startTasks.find(task => task.Status.Id === 3 && task.Undone)
  
          if ($utils.isDefined(failedStartTask)) {
              isBlind = false;
          }
  
          if ($utils.isDefined(container)) {
              let targetLocation = $hub.filters.location.control.value;
  
              let locationValidation = (await $flows.PackVerification.is_pack_verification_location_valid_flow({
                  shippingContainerId: container.Id,
                  locationId: targetLocation
              }));
  
              if ($utils.isDefined(locationValidation.reasons)) {
                  throw new Error('Location is not valid, ' + (await $flows.Utilities.grammar_format_string_array_flow({ values: locationValidation.reasons })).formattedValue);
              }
  
              let startTaskId: number;
  
              if ($utils.isDefined(startTasks)) {
                  startTasks.sort((a, b) => a.Id - b.Id); //Sort ascending
                  startTaskId = startTasks.find(t => t.Status.Id === 1)?.Id;
              }
  
              await $shell.FootPrintManager.openpack_verification_wizardDialog({
                  shippingContainerId: container.Id,
                  startVerificationTaskId: startTaskId,
                  targetLocationId: $hub.filters.location.control.value,
                  isBlind: isBlind
              });
          }
          else {
              if (container.TasksForExpectedTargetShippingContainer.find(t => t.OperationCodeId === 8 && (t.StatusId === 1 || t.StatusId === 4))) {
                  await $shell.FootPrintManager.openErrorDialog('Shipping container not ready for verification', `Shipping container ${$hub.filters.container_code.control.value} still has released picking tasks and is not ready to be verified.`);
              }
          }
  
          $hub.filters.container_code.control.value = null;
          $hub.filters.container_code.control.focus();
      }
  }
  catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error finding shipping container', error.message);
  }
  }
  on_verification_location_changed(event = null) {
    return this.on_verification_location_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_verification_location_changedInternal(
    $hub: FootPrintManager_pack_verification_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if(!$utils.isDefined($hub.filters.location.control.value)){
      $hub.filters.container_code.hidden = true;
  } else {
      $hub.filters.container_code.hidden = false;
      $hub.filters.container_code.control.focus();
  }
  
  
  
  }
  set_focus(event = null) {
    return this.set_focusInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async set_focusInternal(
    $hub: FootPrintManager_pack_verification_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $hub.filters.container_code.control.focus();
  }
  on_manage_configurations_clicked(event = null) {
    return this.on_manage_configurations_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manage_configurations_clickedInternal(
    $hub: FootPrintManager_pack_verification_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.Utilities.openconfigurations_gridDialog({ application_name: 'PackVerification'}, 'flyout', EModalSize.Xlarge);
  }
  on_warehouse_changed(event = null) {
    return this.on_warehouse_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_warehouse_changedInternal(
    $hub: FootPrintManager_pack_verification_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $hub.filters.location.control.value = null;
  
  $hub.on_verification_location_changed();
  }
  //#endregion private flows
}
