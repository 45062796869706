import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_entity_attachments_gridComponent } from './FootPrintManager.entity_attachments_grid.component';
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_entity_attachments_gridComponent),
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
  ],
  selector: 'FootPrintManager-material_attachments_hub',
  templateUrl: './FootPrintManager.material_attachments_hub.component.html'
})
export class FootPrintManager_material_attachments_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { materialId: number, packagingId?: number } = { materialId: null, packagingId: null };
  //#region Inputs
  @Input('materialId') set $inParams_materialId(v: number) {
    this.inParams.materialId = v;
  }
  get $inParams_materialId(): number {
    return this.inParams.materialId;
  }
  @Input('packagingId') set $inParams_packagingId(v: number) {
    this.inParams.packagingId = v;
  }
  get $inParams_packagingId(): number {
    return this.inParams.packagingId;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
    packaging: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
  };

  actionbar = {
  };

 filters = {
    packaging: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['packaging'] as DatexFormControl, 
  ESelectBoxType.chips, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Packaging', false, false),
  };


  filtersets = {
  filters: new FieldsetModel('', true, false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      material_attachments: new TabItemModel(
        this.rootTabGroup, 
        'Material', 
        false,
        ),
      material_packagings_attachments: new TabItemModel(
        this.rootTabGroup, 
        'Packagings', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_material_attachments_entity_attachments_grid_inParams_entityType(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = 'Material';
      
      return expr;
    }
  
    cacheValueFor_$tabs_material_attachments_entity_attachments_grid_inParams_entityKeys: { name?: string, value?: any }[];
    get $tabs_material_attachments_entity_attachments_grid_inParams_entityKeys(): { name?: string, value?: any }[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = [{name: 'Id', value: $hub.inParams.materialId}];
      
      if(!isEqual(this.cacheValueFor_$tabs_material_attachments_entity_attachments_grid_inParams_entityKeys, expr)) {
        this.cacheValueFor_$tabs_material_attachments_entity_attachments_grid_inParams_entityKeys = expr;
      }
      return this.cacheValueFor_$tabs_material_attachments_entity_attachments_grid_inParams_entityKeys;
    }
  
    get $tabs_material_packagings_attachments_entity_attachments_grid_inParams_entityType(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = 'MaterialsPackagingsLookup';
      
      return expr;
    }
  
    cacheValueFor_$tabs_material_packagings_attachments_entity_attachments_grid_inParams_entityKeys: { name?: string, value?: any }[];
    get $tabs_material_packagings_attachments_entity_attachments_grid_inParams_entityKeys(): { name?: string, value?: any }[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = [{name: 'MaterialId', value: $hub.inParams.materialId},{name: 'PackagingId', value: $hub.filters.packaging.control.value}];
      
      if(!isEqual(this.cacheValueFor_$tabs_material_packagings_attachments_entity_attachments_grid_inParams_entityKeys, expr)) {
        this.cacheValueFor_$tabs_material_packagings_attachments_entity_attachments_grid_inParams_entityKeys = expr;
      }
      return this.cacheValueFor_$tabs_material_packagings_attachments_entity_attachments_grid_inParams_entityKeys;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_material_attachments', { read: FootPrintManager_entity_attachments_gridComponent }) $tabs_material_attachments: FootPrintManager_entity_attachments_gridComponent;
      @ViewChild('$tabs_material_packagings_attachments', { read: FootPrintManager_entity_attachments_gridComponent }) $tabs_material_packagings_attachments: FootPrintManager_entity_attachments_gridComponent;
    //#endregion tabs children

  //#region filters inParams
  get $fields_packaging_selector_inParams_materialId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.inParams.materialId;
    
    return expr;
  }



  //#endregion filters inParams



  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.material_attachments,
      this.tabs.material_packagings_attachments,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.materialId)) {
        this.$missingRequiredInParams.push('materialId');
      }
  }



  initialized = false;

  async $init() {
    this.title = 'Material attachments';
    
    const $hub = this;
    const $utils = this.utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_material_attachments') {
      if (!isNil(this.$tabs_material_attachments) && !this.tabs.material_attachments.hidden) {
        this.$tabs_material_attachments.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_material_packagings_attachments') {
      if (!isNil(this.$tabs_material_packagings_attachments) && !this.tabs.material_packagings_attachments.hidden) {
        this.$tabs_material_packagings_attachments.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_material_tab_activated(event = null) {
    return this.on_material_tab_activatedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_tab_activatedInternal(
    $hub: FootPrintManager_material_attachments_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $hub.filters.packaging.hidden = true;
  }
  on_material_packagings_tab_activated(event = null) {
    return this.on_material_packagings_tab_activatedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_packagings_tab_activatedInternal(
    $hub: FootPrintManager_material_attachments_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $hub.filters.packaging.hidden = false;
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_material_attachments_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      // Get the material passed in
      let material = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: $hub.inParams.materialId })).result[0];
  
      if (!$utils.isDefined(material)) { throw new Error(`Material with ID '${$hub.inParams.materialId}' does not exist.`); }
  
      // Get the packagings
      let packagings = (await $datasources.Materials.ds_get_material_packagings_by_materialId.get({ materialId: material.Id })).result;
  
      if (!$utils.isDefined(packagings)) { throw new Error(`No packagings exist for material '${material.LookupCode}'.`); }
  
      // Set packaging if passed in, otherwise default to base
      let packagingId = packagings.find(p => p.IsBasePackaging)?.PackagingId;
  
      if ($utils.isDefined($hub.inParams.packagingId)) {
          packagingId = packagings.find(p => p.PackagingId === $hub.inParams.packagingId)?.PackagingId;
  
          if (!$utils.isDefined(packagingId)) { throw new Error(`Provided packaging ID '${$hub.inParams.packagingId}' does not exist for material '${material.LookupCode}'.`) }
      }
  
      $hub.filters.packaging.control.value = packagingId;
  }
  catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error opening material attachments', error.message);
  }
  }
  //#endregion private flows
}
