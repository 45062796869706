import { Inject, Injectable, Injector }from '@angular/core';

import { BarcodeParsing_DatasourceService } from './BarcodeParsing.datasource.index';
import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { Addresses_DatasourceService } from './Addresses.datasource.index';
import { Attachments_DatasourceService } from './Attachments.datasource.index';
import { Carriers_DatasourceService } from './Carriers.datasource.index';
import { EntityImport_DatasourceService } from './EntityImport.datasource.index';
import { Equipment_DatasourceService } from './Equipment.datasource.index';
import { ExcelMaterialImport_DatasourceService } from './ExcelMaterialImport.datasource.index';
import { ExcelProjectImport_DatasourceService } from './ExcelProjectImport.datasource.index';
import { Inspections_DatasourceService } from './Inspections.datasource.index';
import { Instructions_DatasourceService } from './Instructions.datasource.index';
import { InventoryTransfers_DatasourceService } from './InventoryTransfers.datasource.index';
import { LaborManagement_DatasourceService } from './LaborManagement.datasource.index';
import { LoadContainers_DatasourceService } from './LoadContainers.datasource.index';
import { PackVerification_DatasourceService } from './PackVerification.datasource.index';
import { PalletTransactions_DatasourceService } from './PalletTransactions.datasource.index';
import { Replenishments_DatasourceService } from './Replenishments.datasource.index';
import { Returns_DatasourceService } from './Returns.datasource.index';
import { Surveys_DatasourceService } from './Surveys.datasource.index';
import { TemperatureReadings_DatasourceService } from './TemperatureReadings.datasource.index';
import { TransloadOrders_DatasourceService } from './TransloadOrders.datasource.index';
import { WorkOrders_DatasourceService } from './WorkOrders.datasource.index';
import { Lots_DatasourceService } from './Lots.datasource.index';
import { ExcelLocationImport_DatasourceService } from './ExcelLocationImport.datasource.index';
import { SerialNumbers_DatasourceService } from './SerialNumbers.datasource.index';
import { InventoryCounts_DatasourceService } from './InventoryCounts.datasource.index';
import { AsnOrders_DatasourceService } from './AsnOrders.datasource.index';
import { Owners_DatasourceService } from './Owners.datasource.index';
import { Discussions_DatasourceService } from './Discussions.datasource.index';
import { WarehouseTransfers_DatasourceService } from './WarehouseTransfers.datasource.index';
import { ExcelOrderImport_DatasourceService } from './ExcelOrderImport.datasource.index';
import { Document360_DatasourceService } from './Document360.datasource.index';
import { Usersnap_DatasourceService } from './Usersnap.datasource.index';
import { BarTender_DatasourceService } from './BarTender.datasource.index';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { ShippingContainers_DatasourceService } from './ShippingContainers.datasource.index';
import { Inventory_DatasourceService } from './Inventory.datasource.index';
import { Waves_DatasourceService } from './Waves.datasource.index';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Materials_DatasourceService } from './Materials.datasource.index';
import { Locations_DatasourceService } from './Locations.datasource.index';
import { Catalogs_DatasourceService } from './Catalogs.datasource.index';
import { ExcelInventoryImport_DatasourceService } from './ExcelInventoryImport.datasource.index';
import { DockAppointments_DatasourceService } from './DockAppointments.datasource.index';
import { Cartonization_DatasourceService } from './Cartonization.datasource.index';
import { PrintNode_DatasourceService } from './PrintNode.datasource.index';
import { Manifesting_DatasourceService } from './Manifesting.datasource.index';
import { PurchaseOrders_DatasourceService } from './PurchaseOrders.datasource.index';
import { SalesOrders_DatasourceService } from './SalesOrders.datasource.index';

import { FootPrintManager_ds_activity_gridService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_entity_user_defined_field_values_gridService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_field_selector_gridService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_get_integration_info_by_order_idService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_get_order_by_order_idService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_get_reason_code_by_idService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_get_task_detailsService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_get_warehouse_viz_modelService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_inbound_orders_details_gridService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_inbound_orders_gridService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_invoice_types_ddService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_material_catalogs_by_project_gridService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_material_catalogs_gridService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_material_components_gridService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_material_kit_editorService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_material_kits_gridService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_material_substitutions_editorService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_material_substitutions_gridService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_operation_types_ddService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_orderclasses_inbound_ddService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_print_order_labels_options_ddService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_reason_code_parent_entities_ddService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_reason_codes_ddService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_reason_codes_gridService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_return_serial_numbers_selector_gridService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_sales_order_line_components_gridService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_sales_order_line_kit_gridService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_sales_order_line_substitutions_gridService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_support_tests_gridService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_transload_order_editorService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_transload_order_lines_gridService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_transload_order_receiving_gridService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_transload_orders_gridService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_transload_outbound_shipments_gridService } from './FootPrintManager.datasource.index';
import { FootPrintManager_ds_transload_received_inventory_gridService } from './FootPrintManager.datasource.index';

@Injectable({ providedIn: 'root' })
export class FootPrintManager_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.BarcodeParsing = this.injector.get(BarcodeParsing_DatasourceService);
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.Addresses = this.injector.get(Addresses_DatasourceService);
    this.Attachments = this.injector.get(Attachments_DatasourceService);
    this.Carriers = this.injector.get(Carriers_DatasourceService);
    this.EntityImport = this.injector.get(EntityImport_DatasourceService);
    this.Equipment = this.injector.get(Equipment_DatasourceService);
    this.ExcelMaterialImport = this.injector.get(ExcelMaterialImport_DatasourceService);
    this.ExcelProjectImport = this.injector.get(ExcelProjectImport_DatasourceService);
    this.Inspections = this.injector.get(Inspections_DatasourceService);
    this.Instructions = this.injector.get(Instructions_DatasourceService);
    this.InventoryTransfers = this.injector.get(InventoryTransfers_DatasourceService);
    this.LaborManagement = this.injector.get(LaborManagement_DatasourceService);
    this.LoadContainers = this.injector.get(LoadContainers_DatasourceService);
    this.PackVerification = this.injector.get(PackVerification_DatasourceService);
    this.PalletTransactions = this.injector.get(PalletTransactions_DatasourceService);
    this.Replenishments = this.injector.get(Replenishments_DatasourceService);
    this.Returns = this.injector.get(Returns_DatasourceService);
    this.Surveys = this.injector.get(Surveys_DatasourceService);
    this.TemperatureReadings = this.injector.get(TemperatureReadings_DatasourceService);
    this.TransloadOrders = this.injector.get(TransloadOrders_DatasourceService);
    this.WorkOrders = this.injector.get(WorkOrders_DatasourceService);
    this.Lots = this.injector.get(Lots_DatasourceService);
    this.ExcelLocationImport = this.injector.get(ExcelLocationImport_DatasourceService);
    this.SerialNumbers = this.injector.get(SerialNumbers_DatasourceService);
    this.InventoryCounts = this.injector.get(InventoryCounts_DatasourceService);
    this.AsnOrders = this.injector.get(AsnOrders_DatasourceService);
    this.Owners = this.injector.get(Owners_DatasourceService);
    this.Discussions = this.injector.get(Discussions_DatasourceService);
    this.WarehouseTransfers = this.injector.get(WarehouseTransfers_DatasourceService);
    this.ExcelOrderImport = this.injector.get(ExcelOrderImport_DatasourceService);
    this.Document360 = this.injector.get(Document360_DatasourceService);
    this.Usersnap = this.injector.get(Usersnap_DatasourceService);
    this.BarTender = this.injector.get(BarTender_DatasourceService);
    this.Notifications = this.injector.get(Notifications_DatasourceService);
    this.ShippingContainers = this.injector.get(ShippingContainers_DatasourceService);
    this.Inventory = this.injector.get(Inventory_DatasourceService);
    this.Waves = this.injector.get(Waves_DatasourceService);
    this.Invoices = this.injector.get(Invoices_DatasourceService);
    this.Materials = this.injector.get(Materials_DatasourceService);
    this.Locations = this.injector.get(Locations_DatasourceService);
    this.Catalogs = this.injector.get(Catalogs_DatasourceService);
    this.ExcelInventoryImport = this.injector.get(ExcelInventoryImport_DatasourceService);
    this.DockAppointments = this.injector.get(DockAppointments_DatasourceService);
    this.Cartonization = this.injector.get(Cartonization_DatasourceService);
    this.PrintNode = this.injector.get(PrintNode_DatasourceService);
    this.Manifesting = this.injector.get(Manifesting_DatasourceService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_DatasourceService);
    this.SalesOrders = this.injector.get(SalesOrders_DatasourceService);
  }

    public BarcodeParsing: BarcodeParsing_DatasourceService;
    public Utilities: Utilities_DatasourceService;
    public Addresses: Addresses_DatasourceService;
    public Attachments: Attachments_DatasourceService;
    public Carriers: Carriers_DatasourceService;
    public EntityImport: EntityImport_DatasourceService;
    public Equipment: Equipment_DatasourceService;
    public ExcelMaterialImport: ExcelMaterialImport_DatasourceService;
    public ExcelProjectImport: ExcelProjectImport_DatasourceService;
    public Inspections: Inspections_DatasourceService;
    public Instructions: Instructions_DatasourceService;
    public InventoryTransfers: InventoryTransfers_DatasourceService;
    public LaborManagement: LaborManagement_DatasourceService;
    public LoadContainers: LoadContainers_DatasourceService;
    public PackVerification: PackVerification_DatasourceService;
    public PalletTransactions: PalletTransactions_DatasourceService;
    public Replenishments: Replenishments_DatasourceService;
    public Returns: Returns_DatasourceService;
    public Surveys: Surveys_DatasourceService;
    public TemperatureReadings: TemperatureReadings_DatasourceService;
    public TransloadOrders: TransloadOrders_DatasourceService;
    public WorkOrders: WorkOrders_DatasourceService;
    public Lots: Lots_DatasourceService;
    public ExcelLocationImport: ExcelLocationImport_DatasourceService;
    public SerialNumbers: SerialNumbers_DatasourceService;
    public InventoryCounts: InventoryCounts_DatasourceService;
    public AsnOrders: AsnOrders_DatasourceService;
    public Owners: Owners_DatasourceService;
    public Discussions: Discussions_DatasourceService;
    public WarehouseTransfers: WarehouseTransfers_DatasourceService;
    public ExcelOrderImport: ExcelOrderImport_DatasourceService;
    public Document360: Document360_DatasourceService;
    public Usersnap: Usersnap_DatasourceService;
    public BarTender: BarTender_DatasourceService;
    public Notifications: Notifications_DatasourceService;
    public ShippingContainers: ShippingContainers_DatasourceService;
    public Inventory: Inventory_DatasourceService;
    public Waves: Waves_DatasourceService;
    public Invoices: Invoices_DatasourceService;
    public Materials: Materials_DatasourceService;
    public Locations: Locations_DatasourceService;
    public Catalogs: Catalogs_DatasourceService;
    public ExcelInventoryImport: ExcelInventoryImport_DatasourceService;
    public DockAppointments: DockAppointments_DatasourceService;
    public Cartonization: Cartonization_DatasourceService;
    public PrintNode: PrintNode_DatasourceService;
    public Manifesting: Manifesting_DatasourceService;
    public PurchaseOrders: PurchaseOrders_DatasourceService;
    public SalesOrders: SalesOrders_DatasourceService;
  public FootPrintManager: FootPrintManager_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_activity_grid: FootPrintManager_ds_activity_gridService;
  public get ds_activity_grid(): FootPrintManager_ds_activity_gridService {
    if(!this._ds_activity_grid) {
      this._ds_activity_grid = this.injector.get(FootPrintManager_ds_activity_gridService);
    }
    return this._ds_activity_grid;
  }
  private _ds_entity_user_defined_field_values_grid: FootPrintManager_ds_entity_user_defined_field_values_gridService;
  public get ds_entity_user_defined_field_values_grid(): FootPrintManager_ds_entity_user_defined_field_values_gridService {
    if(!this._ds_entity_user_defined_field_values_grid) {
      this._ds_entity_user_defined_field_values_grid = this.injector.get(FootPrintManager_ds_entity_user_defined_field_values_gridService);
    }
    return this._ds_entity_user_defined_field_values_grid;
  }
  private _ds_field_selector_grid: FootPrintManager_ds_field_selector_gridService;
  public get ds_field_selector_grid(): FootPrintManager_ds_field_selector_gridService {
    if(!this._ds_field_selector_grid) {
      this._ds_field_selector_grid = this.injector.get(FootPrintManager_ds_field_selector_gridService);
    }
    return this._ds_field_selector_grid;
  }
  private _ds_get_integration_info_by_order_id: FootPrintManager_ds_get_integration_info_by_order_idService;
  public get ds_get_integration_info_by_order_id(): FootPrintManager_ds_get_integration_info_by_order_idService {
    if(!this._ds_get_integration_info_by_order_id) {
      this._ds_get_integration_info_by_order_id = this.injector.get(FootPrintManager_ds_get_integration_info_by_order_idService);
    }
    return this._ds_get_integration_info_by_order_id;
  }
  private _ds_get_order_by_order_id: FootPrintManager_ds_get_order_by_order_idService;
  public get ds_get_order_by_order_id(): FootPrintManager_ds_get_order_by_order_idService {
    if(!this._ds_get_order_by_order_id) {
      this._ds_get_order_by_order_id = this.injector.get(FootPrintManager_ds_get_order_by_order_idService);
    }
    return this._ds_get_order_by_order_id;
  }
  private _ds_get_reason_code_by_id: FootPrintManager_ds_get_reason_code_by_idService;
  public get ds_get_reason_code_by_id(): FootPrintManager_ds_get_reason_code_by_idService {
    if(!this._ds_get_reason_code_by_id) {
      this._ds_get_reason_code_by_id = this.injector.get(FootPrintManager_ds_get_reason_code_by_idService);
    }
    return this._ds_get_reason_code_by_id;
  }
  private _ds_get_task_details: FootPrintManager_ds_get_task_detailsService;
  public get ds_get_task_details(): FootPrintManager_ds_get_task_detailsService {
    if(!this._ds_get_task_details) {
      this._ds_get_task_details = this.injector.get(FootPrintManager_ds_get_task_detailsService);
    }
    return this._ds_get_task_details;
  }
  private _ds_get_warehouse_viz_model: FootPrintManager_ds_get_warehouse_viz_modelService;
  public get ds_get_warehouse_viz_model(): FootPrintManager_ds_get_warehouse_viz_modelService {
    if(!this._ds_get_warehouse_viz_model) {
      this._ds_get_warehouse_viz_model = this.injector.get(FootPrintManager_ds_get_warehouse_viz_modelService);
    }
    return this._ds_get_warehouse_viz_model;
  }
  private _ds_inbound_orders_details_grid: FootPrintManager_ds_inbound_orders_details_gridService;
  public get ds_inbound_orders_details_grid(): FootPrintManager_ds_inbound_orders_details_gridService {
    if(!this._ds_inbound_orders_details_grid) {
      this._ds_inbound_orders_details_grid = this.injector.get(FootPrintManager_ds_inbound_orders_details_gridService);
    }
    return this._ds_inbound_orders_details_grid;
  }
  private _ds_inbound_orders_grid: FootPrintManager_ds_inbound_orders_gridService;
  public get ds_inbound_orders_grid(): FootPrintManager_ds_inbound_orders_gridService {
    if(!this._ds_inbound_orders_grid) {
      this._ds_inbound_orders_grid = this.injector.get(FootPrintManager_ds_inbound_orders_gridService);
    }
    return this._ds_inbound_orders_grid;
  }
  private _ds_invoice_types_dd: FootPrintManager_ds_invoice_types_ddService;
  public get ds_invoice_types_dd(): FootPrintManager_ds_invoice_types_ddService {
    if(!this._ds_invoice_types_dd) {
      this._ds_invoice_types_dd = this.injector.get(FootPrintManager_ds_invoice_types_ddService);
    }
    return this._ds_invoice_types_dd;
  }
  private _ds_material_catalogs_by_project_grid: FootPrintManager_ds_material_catalogs_by_project_gridService;
  public get ds_material_catalogs_by_project_grid(): FootPrintManager_ds_material_catalogs_by_project_gridService {
    if(!this._ds_material_catalogs_by_project_grid) {
      this._ds_material_catalogs_by_project_grid = this.injector.get(FootPrintManager_ds_material_catalogs_by_project_gridService);
    }
    return this._ds_material_catalogs_by_project_grid;
  }
  private _ds_material_catalogs_grid: FootPrintManager_ds_material_catalogs_gridService;
  public get ds_material_catalogs_grid(): FootPrintManager_ds_material_catalogs_gridService {
    if(!this._ds_material_catalogs_grid) {
      this._ds_material_catalogs_grid = this.injector.get(FootPrintManager_ds_material_catalogs_gridService);
    }
    return this._ds_material_catalogs_grid;
  }
  private _ds_material_components_grid: FootPrintManager_ds_material_components_gridService;
  public get ds_material_components_grid(): FootPrintManager_ds_material_components_gridService {
    if(!this._ds_material_components_grid) {
      this._ds_material_components_grid = this.injector.get(FootPrintManager_ds_material_components_gridService);
    }
    return this._ds_material_components_grid;
  }
  private _ds_material_kit_editor: FootPrintManager_ds_material_kit_editorService;
  public get ds_material_kit_editor(): FootPrintManager_ds_material_kit_editorService {
    if(!this._ds_material_kit_editor) {
      this._ds_material_kit_editor = this.injector.get(FootPrintManager_ds_material_kit_editorService);
    }
    return this._ds_material_kit_editor;
  }
  private _ds_material_kits_grid: FootPrintManager_ds_material_kits_gridService;
  public get ds_material_kits_grid(): FootPrintManager_ds_material_kits_gridService {
    if(!this._ds_material_kits_grid) {
      this._ds_material_kits_grid = this.injector.get(FootPrintManager_ds_material_kits_gridService);
    }
    return this._ds_material_kits_grid;
  }
  private _ds_material_substitutions_editor: FootPrintManager_ds_material_substitutions_editorService;
  public get ds_material_substitutions_editor(): FootPrintManager_ds_material_substitutions_editorService {
    if(!this._ds_material_substitutions_editor) {
      this._ds_material_substitutions_editor = this.injector.get(FootPrintManager_ds_material_substitutions_editorService);
    }
    return this._ds_material_substitutions_editor;
  }
  private _ds_material_substitutions_grid: FootPrintManager_ds_material_substitutions_gridService;
  public get ds_material_substitutions_grid(): FootPrintManager_ds_material_substitutions_gridService {
    if(!this._ds_material_substitutions_grid) {
      this._ds_material_substitutions_grid = this.injector.get(FootPrintManager_ds_material_substitutions_gridService);
    }
    return this._ds_material_substitutions_grid;
  }
  private _ds_operation_types_dd: FootPrintManager_ds_operation_types_ddService;
  public get ds_operation_types_dd(): FootPrintManager_ds_operation_types_ddService {
    if(!this._ds_operation_types_dd) {
      this._ds_operation_types_dd = this.injector.get(FootPrintManager_ds_operation_types_ddService);
    }
    return this._ds_operation_types_dd;
  }
  private _ds_orderclasses_inbound_dd: FootPrintManager_ds_orderclasses_inbound_ddService;
  public get ds_orderclasses_inbound_dd(): FootPrintManager_ds_orderclasses_inbound_ddService {
    if(!this._ds_orderclasses_inbound_dd) {
      this._ds_orderclasses_inbound_dd = this.injector.get(FootPrintManager_ds_orderclasses_inbound_ddService);
    }
    return this._ds_orderclasses_inbound_dd;
  }
  private _ds_print_order_labels_options_dd: FootPrintManager_ds_print_order_labels_options_ddService;
  public get ds_print_order_labels_options_dd(): FootPrintManager_ds_print_order_labels_options_ddService {
    if(!this._ds_print_order_labels_options_dd) {
      this._ds_print_order_labels_options_dd = this.injector.get(FootPrintManager_ds_print_order_labels_options_ddService);
    }
    return this._ds_print_order_labels_options_dd;
  }
  private _ds_reason_code_parent_entities_dd: FootPrintManager_ds_reason_code_parent_entities_ddService;
  public get ds_reason_code_parent_entities_dd(): FootPrintManager_ds_reason_code_parent_entities_ddService {
    if(!this._ds_reason_code_parent_entities_dd) {
      this._ds_reason_code_parent_entities_dd = this.injector.get(FootPrintManager_ds_reason_code_parent_entities_ddService);
    }
    return this._ds_reason_code_parent_entities_dd;
  }
  private _ds_reason_codes_dd: FootPrintManager_ds_reason_codes_ddService;
  public get ds_reason_codes_dd(): FootPrintManager_ds_reason_codes_ddService {
    if(!this._ds_reason_codes_dd) {
      this._ds_reason_codes_dd = this.injector.get(FootPrintManager_ds_reason_codes_ddService);
    }
    return this._ds_reason_codes_dd;
  }
  private _ds_reason_codes_grid: FootPrintManager_ds_reason_codes_gridService;
  public get ds_reason_codes_grid(): FootPrintManager_ds_reason_codes_gridService {
    if(!this._ds_reason_codes_grid) {
      this._ds_reason_codes_grid = this.injector.get(FootPrintManager_ds_reason_codes_gridService);
    }
    return this._ds_reason_codes_grid;
  }
  private _ds_return_serial_numbers_selector_grid: FootPrintManager_ds_return_serial_numbers_selector_gridService;
  public get ds_return_serial_numbers_selector_grid(): FootPrintManager_ds_return_serial_numbers_selector_gridService {
    if(!this._ds_return_serial_numbers_selector_grid) {
      this._ds_return_serial_numbers_selector_grid = this.injector.get(FootPrintManager_ds_return_serial_numbers_selector_gridService);
    }
    return this._ds_return_serial_numbers_selector_grid;
  }
  private _ds_sales_order_line_components_grid: FootPrintManager_ds_sales_order_line_components_gridService;
  public get ds_sales_order_line_components_grid(): FootPrintManager_ds_sales_order_line_components_gridService {
    if(!this._ds_sales_order_line_components_grid) {
      this._ds_sales_order_line_components_grid = this.injector.get(FootPrintManager_ds_sales_order_line_components_gridService);
    }
    return this._ds_sales_order_line_components_grid;
  }
  private _ds_sales_order_line_kit_grid: FootPrintManager_ds_sales_order_line_kit_gridService;
  public get ds_sales_order_line_kit_grid(): FootPrintManager_ds_sales_order_line_kit_gridService {
    if(!this._ds_sales_order_line_kit_grid) {
      this._ds_sales_order_line_kit_grid = this.injector.get(FootPrintManager_ds_sales_order_line_kit_gridService);
    }
    return this._ds_sales_order_line_kit_grid;
  }
  private _ds_sales_order_line_substitutions_grid: FootPrintManager_ds_sales_order_line_substitutions_gridService;
  public get ds_sales_order_line_substitutions_grid(): FootPrintManager_ds_sales_order_line_substitutions_gridService {
    if(!this._ds_sales_order_line_substitutions_grid) {
      this._ds_sales_order_line_substitutions_grid = this.injector.get(FootPrintManager_ds_sales_order_line_substitutions_gridService);
    }
    return this._ds_sales_order_line_substitutions_grid;
  }
  private _ds_support_tests_grid: FootPrintManager_ds_support_tests_gridService;
  public get ds_support_tests_grid(): FootPrintManager_ds_support_tests_gridService {
    if(!this._ds_support_tests_grid) {
      this._ds_support_tests_grid = this.injector.get(FootPrintManager_ds_support_tests_gridService);
    }
    return this._ds_support_tests_grid;
  }
  private _ds_transload_order_editor: FootPrintManager_ds_transload_order_editorService;
  public get ds_transload_order_editor(): FootPrintManager_ds_transload_order_editorService {
    if(!this._ds_transload_order_editor) {
      this._ds_transload_order_editor = this.injector.get(FootPrintManager_ds_transload_order_editorService);
    }
    return this._ds_transload_order_editor;
  }
  private _ds_transload_order_lines_grid: FootPrintManager_ds_transload_order_lines_gridService;
  public get ds_transload_order_lines_grid(): FootPrintManager_ds_transload_order_lines_gridService {
    if(!this._ds_transload_order_lines_grid) {
      this._ds_transload_order_lines_grid = this.injector.get(FootPrintManager_ds_transload_order_lines_gridService);
    }
    return this._ds_transload_order_lines_grid;
  }
  private _ds_transload_order_receiving_grid: FootPrintManager_ds_transload_order_receiving_gridService;
  public get ds_transload_order_receiving_grid(): FootPrintManager_ds_transload_order_receiving_gridService {
    if(!this._ds_transload_order_receiving_grid) {
      this._ds_transload_order_receiving_grid = this.injector.get(FootPrintManager_ds_transload_order_receiving_gridService);
    }
    return this._ds_transload_order_receiving_grid;
  }
  private _ds_transload_orders_grid: FootPrintManager_ds_transload_orders_gridService;
  public get ds_transload_orders_grid(): FootPrintManager_ds_transload_orders_gridService {
    if(!this._ds_transload_orders_grid) {
      this._ds_transload_orders_grid = this.injector.get(FootPrintManager_ds_transload_orders_gridService);
    }
    return this._ds_transload_orders_grid;
  }
  private _ds_transload_outbound_shipments_grid: FootPrintManager_ds_transload_outbound_shipments_gridService;
  public get ds_transload_outbound_shipments_grid(): FootPrintManager_ds_transload_outbound_shipments_gridService {
    if(!this._ds_transload_outbound_shipments_grid) {
      this._ds_transload_outbound_shipments_grid = this.injector.get(FootPrintManager_ds_transload_outbound_shipments_gridService);
    }
    return this._ds_transload_outbound_shipments_grid;
  }
  private _ds_transload_received_inventory_grid: FootPrintManager_ds_transload_received_inventory_gridService;
  public get ds_transload_received_inventory_grid(): FootPrintManager_ds_transload_received_inventory_gridService {
    if(!this._ds_transload_received_inventory_grid) {
      this._ds_transload_received_inventory_grid = this.injector.get(FootPrintManager_ds_transload_received_inventory_gridService);
    }
    return this._ds_transload_received_inventory_grid;
  }
}

