import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { PurchaseOrders_purchase_order_date_types_dd_singleComponent } from './PurchaseOrders.purchase_order_date_types_dd_single.component'


interface IFootPrintManager_inbound_orders_gridComponentEntity {
Id?: number, CreatedSysDateTime?: string, FulfillmentDate?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Id?: number, Name?: string }, OrderClass?: { LookupCode?: string, OrderClassTypeId?: number }, Project?: { LookupCode?: string, Name?: string, OwnerId?: number, Owner?: { LookupCode?: string, Name?: string } }, Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, PreferredWarehouse?: { Id?: number, Name?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierServiceType?: { Id?: number, Name?: string }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { ExpectedWarehouseId?: number, LookupCode?: string, ExpectedWarehouse?: { Name?: string }, Carrier?: { Id?: number, Name?: string }, CarrierServiceType?: { Id?: number, Name?: string } } }[], Addresses?: { AttentionOf?: string, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[]}

interface IFootPrintManager_inbound_orders_gridComponentInParams {
  ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], orderClassIds?: number[], reference?: string, materialIds?: number[]}


class FootPrintManager_inbound_orders_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_inbound_orders_gridComponent;
  entity: IFootPrintManager_inbound_orders_gridComponentEntity;

  vars: { hasCompletedReceivingTasks?: boolean, hasRemainingReceivingTasks?: boolean, dockAppointmentId?: number } = { };

  options: { on_row_edit_order: ButtonModel, print_receiving_report: ButtonModel } = {
    on_row_edit_order: new ButtonModel('on_row_edit_order', new ButtonStyles(null, null), false, false, 'Edit order', '', null)
,print_receiving_report: new ButtonModel('print_receiving_report', new ButtonStyles(null, null), false, false, 'Print receiving report', '', null)

  }

 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    lookupcode: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    orderclass: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    project: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    total_items: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    appointment: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    created_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    fulfillment_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    expected_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    vendor_reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    warehouse: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    carrier: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    ship_from: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    validation_result: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    validation_notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_inbound_orders_gridComponent, entity: IFootPrintManager_inbound_orders_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_inbound_orders_gridComponent, entity?: IFootPrintManager_inbound_orders_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      warehouseId:  $grid.inParams.warehouseId ,
      statusIds:  $grid.inParams.statusIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      fromDate:  $grid.filters.from_date.control.value ,
      toDate:  $grid.filters.to_date.control.value ,
      orderClassIds:  $grid.inParams.orderClassIds ,
      take:  null ,
      skip:  null ,
      dateType:  $grid.filters.date_type.control.value ,
      reference:  $grid.inParams.reference ,
      orderIds:  null ,
      materialIds:  $grid.inParams.materialIds ,
    };
    const data = await this.datasources.PurchaseOrders.ds_inbound_orders_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status?.Name;
    (this.cells.orderclass.displayControl as TextModel).text = $row.entity.OrderClass?.LookupCode;
    (this.cells.vendor_reference.displayControl as TextModel).text = $row.entity.VendorReference;
    (this.cells.reference.displayControl as TextModel).text = $row.entity.OwnerReference;
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'lookupcode' : {
        this.on_lookupcode_clicked();
        break;
      }
      case 'appointment' : {
        this.on_appointment_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_lookupcode_clicked(event = null) {
    return this.on_lookupcode_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lookupcode_clickedInternal(
    $row: FootPrintManager_inbound_orders_gridComponentRowModel,
  $grid: FootPrintManager_inbound_orders_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  // Check order class type
  const order = (await $datasources.PurchaseOrders.ds_get_order_by_orderId.get({ orderId: $row.entity.Id })).result;
  if ($utils.isDefined(order)) {
      const orderClassTypeId = order.OrderClass.OrderClassTypeId;
      if (orderClassTypeId == 1) {
          $shell.FootPrintManager.openpurchase_order_editor({ orderId: $row.entity.Id });
      }
      else if(orderClassTypeId == 12) {
          $shell.FootPrintManager.openasn_order_editor({ order_id: $row.entity.Id });
      }
      else {
          throw new Error('Editor for selected order class type under development!')
      }
  
  }
  
  
  
  
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_inbound_orders_gridComponentRowModel,
  $grid: FootPrintManager_inbound_orders_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Set Date/Time Formats
  const format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  if ($utils.isDefined($row.entity.RequestedDeliveryDate)) {
      $row.cells.expected_date.displayControl.text = $utils.date.format($row.entity.RequestedDeliveryDate, format);
  };
  if ($utils.isDefined($row.entity.CreatedSysDateTime)) {
      $row.cells.created_date.displayControl.text = $utils.date.format($row.entity.CreatedSysDateTime, format);
  };
  if ($utils.isDefined($row.entity.FulfillmentDate)) {
      $row.cells.fulfillment_date.displayControl.text = $utils.date.format($row.entity.FulfillmentDate, format);
  };
  
  let shipment_carrier: string;
  let shipment_service: string;
  let shipment_warehouse: string;
  let ShipmentOrderLookups = $row.entity.ShipmentOrderLookups;
  
  if ($utils.isDefined(ShipmentOrderLookups)) {
      let Shipment = ShipmentOrderLookups[0].Shipment;
      shipment_carrier = Shipment.Carrier?.Name;
      shipment_service = Shipment.CarrierServiceType?.Name;
      shipment_warehouse = Shipment.ExpectedWarehouse?.Name;
  }
  
  //Set Carrier cell display control text
  var carrier = $utils.isDefined(shipment_carrier) ? shipment_carrier : 
                $utils.isDefined($row.entity.PreferredCarrier?.Name) ? $row.entity.PreferredCarrier?.Name : '';
  var service = $utils.isDefined(shipment_service) ? shipment_service : 
                $utils.isDefined($row.entity.PreferredCarrierServiceType?.Name) ? $row.entity.PreferredCarrierServiceType?.Name : '';
  var warehouse = $utils.isDefined(shipment_warehouse) ? shipment_warehouse :
                $utils.isDefined($row.entity.PreferredWarehouse?.Name) ? $row.entity.PreferredWarehouse?.Name : '';
  
  var carrierDetails = carrier.trim() + ' ' + service.trim();
  $row.cells.carrier.displayControl.text = carrierDetails;
  
  $row.cells.warehouse.displayControl.text = warehouse.trim();
  
  // Apply custom project string logic
  if ($row.entity.Project?.LookupCode === $row.entity.Project?.Name) {
      $row.cells.project.displayControl.text = $row.entity.Project.LookupCode
  } else {
      $row.cells.project.displayControl.text = $row.entity.Project.LookupCode + ' ' + $row.entity.Project.Name
  }
  
  
  
  
  
  
  }
  on_appointment_clicked(event = null) {
    return this.on_appointment_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_appointment_clickedInternal(
    $row: FootPrintManager_inbound_orders_gridComponentRowModel,
  $grid: FootPrintManager_inbound_orders_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  if ($utils.isDefined($row.vars.dockAppointmentId)) {
  
      if ($utils.isDefined($row.entity.PreferredWarehouseId) || $utils.isDefined($row.entity.ShipmentOrderLookups[0]?.Shipment?.ExpectedWarehouseId)) {
          const dialogResult = await $shell.FootPrintManager.opendock_appointment_editorDialog({
              dockAppointmentId: $row.vars.dockAppointmentId
          });
          if ($utils.isDefined(dialogResult)) {
              const userConfirm = dialogResult.confirm;
              if (userConfirm) {
                  $grid.refresh();
              }
          }
  
      }
      else {
          throw new Error('Unable to determined preferred warehouse from the selected order.')
      }
  
  }
  else {
      
      // Check if the selected order is in a rejected status and throw an error.
      if ($row.entity.OrderStatusId == 4096) {
  
          throw new Error('Selected order is currently in a rejected status, a dock appointment cannot be created against rejected orders.')
      }
  
      if ($utils.isDefined($row.entity.PreferredWarehouseId) || $utils.isDefined($row.entity.ShipmentOrderLookups[0]?.Shipment?.ExpectedWarehouseId)) {
          const nextAppointmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'DockAppointment' })).nextId;
  
          const dialogResult = await $shell.FootPrintManager.opendock_appointment_creation_formDialog({
  
              warehouseId: [$row.entity.PreferredWarehouseId] ?? [$row.entity.ShipmentOrderLookups[0]?.Shipment?.ExpectedWarehouseId],
              lookupcode: nextAppointmentId.toString(),
              scheduledArrival: $utils.isDefined($row.entity.RequestedDeliveryDate) ? $row.entity.RequestedDeliveryDate : $utils.date.now(),
              scheduledDeparture: $utils.isDefined($row.entity.RequestedDeliveryDate) ? $utils.date.add(1, 'hour', $row.entity.RequestedDeliveryDate) : $utils.date.add(1, 'hour', $utils.date.now()),
              typeId: 1,
              ownerId: $row.entity.Project.OwnerId,
              projectId: $row.entity.ProjectId,
              carrierId: $row.entity.PreferredCarrierId,
              orderId: $row.entity.Id
          });
  
          if ($utils.isDefined(dialogResult)) {
              const userConfirm = dialogResult.confirm;
              if (userConfirm) {
                  $grid.refresh();
              }
          }
      }
  
      else {
          throw new Error('Unable to determined preferred warehouse from the selected order.')
      }
  
  }
  }
  on_row_edit_order(event = null) {
    return this.on_row_edit_orderInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_edit_orderInternal(
    $row: FootPrintManager_inbound_orders_gridComponentRowModel,
  $grid: FootPrintManager_inbound_orders_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  // Check order class type
  const order = (await $datasources.PurchaseOrders.ds_get_order_by_orderId.get({ orderId: $row.entity.Id })).result;
  if ($utils.isDefined(order)) {
      const orderClassTypeId = order.OrderClass.OrderClassTypeId;
      if (orderClassTypeId == 1) {
          $shell.FootPrintManager.openpurchase_order_editor({ orderId: $row.entity.Id });
      }
      else if(orderClassTypeId == 12) {
          $shell.FootPrintManager.openasn_order_editor({ order_id: $row.entity.Id });
      }
      else {
          throw new Error('Editor for selected order class type under development!')
      }
  
  }
  
  
  
  
  }
  on_row_print_receiving_report(event = null) {
    return this.on_row_print_receiving_reportInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_print_receiving_reportInternal(
    $row: FootPrintManager_inbound_orders_gridComponentRowModel,
  $grid: FootPrintManager_inbound_orders_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
      await $shell.PurchaseOrders.openreceiving_reportDialog({ orderId: $row.entity.Id });
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => PurchaseOrders_purchase_order_date_types_dd_singleComponent),
  ],
  selector: 'FootPrintManager-inbound_orders_grid',
  templateUrl: './FootPrintManager.inbound_orders_grid.component.html'
})
export class FootPrintManager_inbound_orders_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_inbound_orders_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_inbound_orders_gridComponentInParams = { ownerId: null, projectId: null, warehouseId: [], statusIds: [], orderClassIds: [], reference: null, materialIds: [] };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Order', false, false, null),       status: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       orderclass: new GridHeaderModel(new HeaderStyles(null, null), 'Order class', false, false, null),       project: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),       total_items: new GridHeaderModel(new HeaderStyles(null, null), 'Total items', false, false, null),       appointment: new GridHeaderModel(new HeaderStyles(null, null), 'Appointment', false, false, null),       created_date: new GridHeaderModel(new HeaderStyles(null, null), 'Created', false, false, null),       fulfillment_date: new GridHeaderModel(new HeaderStyles(null, null), 'Fulfillment', false, false, null),       expected_date: new GridHeaderModel(new HeaderStyles(null, null), 'Expected', false, false, null),       vendor_reference: new GridHeaderModel(new HeaderStyles(null, null), 'Vendor reference', false, false, null),       reference: new GridHeaderModel(new HeaderStyles(null, null), 'Owner reference', false, false, null),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'Warehouse', false, false, null),       carrier: new GridHeaderModel(new HeaderStyles(null, null), 'Carrier', false, false, null),       ship_from: new GridHeaderModel(new HeaderStyles(null, null), 'Ship from', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),       validation_result: new GridHeaderModel(new HeaderStyles(null, null), 'Validation result', false, false, null),       validation_notes: new GridHeaderModel(new HeaderStyles(null, null), 'Validation notes', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_inbound_orders_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }
  @Input('statusIds') set $inParams_statusIds(value: any) {
    this.inParams['statusIds'] = value;
  }
  get $inParams_statusIds(): any {
    return this.inParams['statusIds'] ;
  }
  @Input('orderClassIds') set $inParams_orderClassIds(value: any) {
    this.inParams['orderClassIds'] = value;
  }
  get $inParams_orderClassIds(): any {
    return this.inParams['orderClassIds'] ;
  }
  @Input('reference') set $inParams_reference(value: any) {
    this.inParams['reference'] = value;
  }
  get $inParams_reference(): any {
    return this.inParams['reference'] ;
  }
  @Input('materialIds') set $inParams_materialIds(value: any) {
    this.inParams['materialIds'] = value;
  }
  get $inParams_materialIds(): any {
    return this.inParams['materialIds'] ;
  }

  topToolbar = {
      update: new ToolModel(new ButtonModel('update', new ButtonStyles(null, null), false, false, 'Update', 'icon-ic_fluent_edit_20_regular', null)
    , false),
      refresh: new ToolModel(new ButtonModel('refresh', new ButtonStyles(null, null), false, false, 'Refresh', 'icon-ic_fluent_arrow_clockwise_20_regular', null)
    , false),
      validate: new ToolModel(new ButtonModel('validate', new ButtonStyles(null, null), false, false, 'Validate', 'icon-ic_fluent_tasks_app_20_regular', null)
    , false),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      process: new ToolModel(new ButtonModel('process', new ButtonStyles(null, null), false, false, 'Process', 'icon-ic_fluent_arrow_clockwise_20_regular', null)
    , false),
      auto_receive: new ToolModel(new ButtonModel('auto_receive', new ButtonStyles(null, null), false, false, 'Auto receive', 'icon-datex-Receive', null)
    , false),
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, false, 'Complete', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false),
      revert: new ToolModel(new ButtonModel('revert', new ButtonStyles(null, null), false, false, 'Revert', 'icon-ic_fluent_square_hint_arrow_back_20_filled', null)
    , false),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      print: new ToolModel(new ButtonModel('print', new ButtonStyles(null, null), false, false, ' ', 'icon-ic_fluent_print_20_regular', null)
    , false),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, false, ' ', 'icon-ic_fluent_attach_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    from_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    to_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    from_date: new FieldModel(new DateBoxModel(this.formGroup.controls['from_date'] as DatexFormControl, null, false, 'l, LT', 'datetime', null)
, new ControlContainerStyles(null, null), 'From date', false, false),
    to_date: new FieldModel(new DateBoxModel(this.formGroup.controls['to_date'] as DatexFormControl, null, false, 'l, LT', 'datetime', null)
, new ControlContainerStyles(null, null), 'To date', false, false),
    date_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['date_type'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Date type', false, false),
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Inbound orders';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 50;

    (this.filters.date_type.control as SelectBoxModel).reset('CreatedSysDateTime');

    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      warehouseId:  $grid.inParams.warehouseId ,
      statusIds:  $grid.inParams.statusIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      fromDate:  $grid.filters.from_date.control.value ,
      toDate:  $grid.filters.to_date.control.value ,
      orderClassIds:  $grid.inParams.orderClassIds ,
      take:  null ,
      skip:  null ,
      dateType:  $grid.filters.date_type.control.value ,
      reference:  $grid.inParams.reference ,
      orderIds:  null ,
      materialIds:  $grid.inParams.materialIds ,
    };
    try {
    const data = await this.datasources.PurchaseOrders.ds_inbound_orders_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_inbound_orders_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_inbound_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  
  // One or more rows selected
  if (selectedRowsCount > 0) {
      if (selectedRowsCount === 1) {
          $grid.topToolbar.attachments.control.readOnly = false;
      }
  
      $grid.topToolbar.process.control.readOnly = true;
      $grid.topToolbar.cancel.control.readOnly = true;
      $grid.topToolbar.on_delete.control.readOnly = true;
      $grid.topToolbar.complete.control.readOnly = true;
      $grid.topToolbar.update.control.readOnly = false;
      $grid.topToolbar.print.control.readOnly = true;
  
      // Validation
      $grid.topToolbar.validate.control.readOnly = !(await checkAllValidatable());
  
      // Revert
      $grid.topToolbar.revert.control.readOnly = !(await checkAllRevertable());
  
      // Completion
      $grid.topToolbar.complete.control.readOnly = !(await checkAllCompletable());
  
      // AutoReceiving
      $grid.topToolbar.auto_receive.control.readOnly = !(await checkAllAutoReceivable());
  
      // Cancellation
      $grid.topToolbar.cancel.control.readOnly = !(await checkAllCancellable());
  
      // Processing
      $grid.topToolbar.process.control.readOnly = !(await checkAllProcessable());
  
      // Deletion
      $grid.topToolbar.on_delete.control.readOnly = !(await checkAllDeletable());
  
  
  }
  else {
      $grid.topToolbar.process.control.readOnly = true;
      $grid.topToolbar.cancel.control.readOnly = true;
      $grid.topToolbar.on_delete.control.readOnly = true;
      $grid.topToolbar.complete.control.readOnly = true;
      $grid.topToolbar.update.control.readOnly = true;
      $grid.topToolbar.auto_receive.control.readOnly = true;
      $grid.topToolbar.validate.control.readOnly = true;
      $grid.topToolbar.revert.control.readOnly = true;
      $grid.topToolbar.print.control.readOnly = false;
      $grid.topToolbar.attachments.control.readOnly = true;
  }
  
  
  /**************************************************** 
   * FUNCTIONS
  *****************************************************/
  async function checkAllRevertable() {
      let allow = $grid.selectedRows.length > 0;
  
      for (let row of $grid.selectedRows) {
          for (let shipmentOrderLookup of row.entity.ShipmentOrderLookups) {
              if ($utils.isDefined(shipmentOrderLookup.ShipmentId)) {
                  if (row.entity.OrderClass.OrderClassTypeId === 1) {
                      if ($utils.isDefined((await $flows.PurchaseOrders.is_purchase_order_revertable_flow({ orderId: row.entity.Id, shipmentId: shipmentOrderLookup.ShipmentId })).reasons)) {
                          return false;
                      }
                  }
                  else if (row.entity.OrderClass.OrderClassTypeId === 12) {
                      if ($utils.isDefined((await $flows.AsnOrders.is_asn_order_revertable_flow({
                          orderId: row.entity.Id,
                          useValidation: false,
                          optimalInputs: {
                              statusId: row.entity.OrderStatusId,
                              hasCompletedReceivingTasks: row.vars.hasCompletedReceivingTasks
                          }
                      })).reasons)) {
                          return false;
                      }
                  }
              }
              else {
                  return false;
              }
          }
      }
  
      return allow;
  }
  
  async function checkAllValidatable() {
      let allow = $grid.selectedRows.length > 0;
  
      for (let row of $grid.selectedRows) {
          for (let shipmentOrderLookup of row.entity.ShipmentOrderLookups) {
              if ($utils.isDefined(shipmentOrderLookup.ShipmentId)) {
                  // Purchase Order
                  if (row.entity.OrderClass.OrderClassTypeId === 1) {
                      // TODO: Add validation to Purchase Orders
                      // if ($utils.isDefined((await $flows.PurchaseOrders.is_purchase_order_validatable_flow({ orderId: row.entity.Id, shipmentId: shipmentOrderLookup.ShipmentId })).reason)) {
                      return false;
                      // }
                  }
                  // ASN Order
                  else if (row.entity.OrderClass.OrderClassTypeId === 12) {
                      if ($utils.isDefined((await $flows.AsnOrders.is_asn_order_validatable_flow({
                          orderId: row.entity.Id,
                          optimalInputs: {
                              statusId: row.entity.OrderStatusId,
                              hasCompletedReceivingTasks: row.vars.hasCompletedReceivingTasks
                          }
                      })).reasons)) {
                          return false;
                      }
                  }
              }
              else {
                  return false;
              }
          }
      }
  
      return allow;
  }
  
  async function checkAllCompletable() {
      let allow = $grid.selectedRows.length > 0;
  
      for (let row of $grid.selectedRows) {
          for (let shipmentOrderLookup of row.entity.ShipmentOrderLookups) {
              if ($utils.isDefined(shipmentOrderLookup.ShipmentId)) {
                  // Purchase Order
                  if (row.entity.OrderClass.OrderClassTypeId === 1) {
                      if ($utils.isDefined((await $flows.PurchaseOrders.is_purchase_order_completable_flow({ orderId: row.entity.Id })).reasons)) {
                          return false;
                      }
                  }
                  // ASN Order
                  else if (row.entity.OrderClass.OrderClassTypeId === 12) {
                      if ($utils.isDefined((await $flows.AsnOrders.is_asn_order_completable_flow({
                          orderId: row.entity.Id,
                          optimalInputs: {
                              statusId: row.entity.OrderStatusId,
                              hasCompletedReceivingTasks: row.vars.hasCompletedReceivingTasks,
                              checkForPlannedReceivingTasks: true
                          }
                      })).reasons)) {
                          return false;
                      }
                  }
              }
              else {
                  return false;
              }
          }
      }
  
      return allow;
  }
  
  async function checkAllAutoReceivable() {
      let allow = $grid.selectedRows.length > 0;
  
      for (let row of $grid.selectedRows) {
          for (let shipmentOrderLookup of row.entity.ShipmentOrderLookups) {
              if ($utils.isDefined(shipmentOrderLookup.ShipmentId)) {
                  // Purchase Order
                  if (row.entity.OrderClass.OrderClassTypeId === 1) {
                      return false;
                  }
                  // ASN Order
                  else if (row.entity.OrderClass.OrderClassTypeId === 12) {
                      if ($utils.isDefined((await $flows.AsnOrders.is_asn_order_auto_receivable_flow({
                          orderId: row.entity.Id,
                          optimalInputs: {
                              statusId: row.entity.OrderStatusId,
                              hasPlannedReceivingTasks: row.vars.hasRemainingReceivingTasks
                          }
                      })).reasons)) {
                          return false;
                      }
                  }
              }
              else {
                  return false;
              }
          }
      }
  
      return allow;
  }
  
  async function checkAllDeletable() {
      let allow = $grid.selectedRows.length > 0;
  
      for (let row of $grid.selectedRows) {
          if ($utils.isAllDefined(row.entity.Id, row.entity.OrderStatusId)) {
              // Purchase Order
              if (row.entity.OrderClass.OrderClassTypeId === 1) {
                  if ($utils.isDefined((await $flows.PurchaseOrders.is_purchase_order_deletable_flow({ orderId: row.entity.Id })).reasons)) {
                      return false;
                  }
              }
              // ASN Order
              else if (row.entity.OrderClass.OrderClassTypeId === 12) {
                  let results = (await $flows.AsnOrders.is_asn_order_deletable_flow({
                      orderId: row.entity.Id,
                      optimalInputs: {
                          statusId: row.entity.OrderStatusId,
                          hasCompletedReceivingTasks: row.vars.hasCompletedReceivingTasks
                      }
                  }));
  
                  if ($utils.isDefined(results.reasons)) {
                      return false;
                  }
              }
          }
          else {
              return false;
          }
      }
  
      return allow;
  }
  
  async function checkAllCancellable() {
      let allow = $grid.selectedRows.length > 0;
  
      for (let row of $grid.selectedRows) {
          if ($utils.isDefined(row.entity.ShipmentOrderLookups)) {
              // Purchase Order
              if (row.entity.OrderClass.OrderClassTypeId === 1) {
                  if ($utils.isDefined((await $flows.PurchaseOrders.is_purchase_order_cancelable_flow({
                      orderId: row.entity.Id,
                      shipmentId: row.entity.ShipmentOrderLookups[0].ShipmentId
                  })).reasons)) {
                      return false;
                  }
              }
              // ASN Order
              else if (row.entity.OrderClass.OrderClassTypeId === 12) {
                  if ($utils.isDefined((await $flows.AsnOrders.is_asn_order_cancelable_flow({
                      orderId: row.entity.Id,
                      optimalInputs: {
                          statusId: row.entity.OrderStatusId,
                          hasCompletedReceivingTasks: row.vars.hasCompletedReceivingTasks
                      }
                  })).reasons)) {
                      return false;
                  }
              }
          }
          else {
              return false;
          }
      }
  
      return allow;
  }
  
  async function checkAllProcessable() {
      let allow = $grid.selectedRows.length > 0;
  
      for (let row of $grid.selectedRows) {
          if ($utils.isDefined(row.entity.ShipmentOrderLookups)) {
              // Purchase Order
              if (row.entity.OrderClass.OrderClassTypeId === 1) {
                  if ($utils.isDefined((await $flows.PurchaseOrders.is_purchase_order_processable_flow({ orderId: row.entity.Id })).reasons)) {
                      return false;
                  }
              }
              // ASN Order
              else if (row.entity.OrderClass.OrderClassTypeId === 12) {
                  if ($utils.isDefined((await $flows.AsnOrders.is_asn_order_processable_flow({ orderId: row.entity.Id })).reasons)) {
                      return false;
                  }
              }
          }
          else {
              return false;
          }
      }
  
      return allow;
  }
  }
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $grid: FootPrintManager_inbound_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Error processing orders', 'No orders selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              orderId: row.entity.Id
          };
  
          if (row.entity.OrderClass.OrderClassTypeId === 1) {
              var confirmResult = await $flows.PurchaseOrders.is_purchase_order_processable_flow(flowParams);
          }
          else if (row.entity.OrderClass.OrderClassTypeId === 12) {
              confirmResult = await $flows.AsnOrders.is_asn_order_processable_flow({ orderId: row.entity.Id });
          }
  
          const reasons = confirmResult.reasons;
          if ($utils.isDefined(reasons)) {
              failures.push(row);
              errors.push(`Order ${row.entity.LookupCode} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Error processing orders';
          const errorMessage = `Order(s) ${failures.map(c => c.entity.LookupCode).join(',')} cannot be processed`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Order(s) ${candidates.map(c => c.entity.LookupCode).join(',')} - Once processed, the order can be received.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some order(s) cannot be processed';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Process the following order(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const candidateSuccess = [];
              const candidateFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams = {
                          orderId: candidate.entity.Id
                      }
  
                      let reasons: string[];
                      if (candidate.entity.OrderClass.OrderClassTypeId === 1) {
                          var result = await $flows.PurchaseOrders.process_purchase_order_flow(flowParams);
                          if ($utils.isDefined(result.reason)) {
                              reasons = [result.reason];
                          }
                      }
                      else if (candidate.entity.OrderClass.OrderClassTypeId === 12) {
                          reasons = (await $flows.AsnOrders.process_asn_order_flow({ orderId: candidate.entity.Id })).reasons;
                      }
  
                      if ($utils.isDefined(reasons)) {
                          candidateFailures.push(candidate);
                          errorMsgList.push(`Order ${candidate.entity.LookupCode} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue}`);
                      } else {
                          candidateSuccess.push(candidate);
                      }
  
                  } catch (error) {
                      candidateFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Order ${candidate.entity.LookupCode} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (candidateSuccess.length === candidates.length) {
                  const title = 'All order(s) processed';
                  const message = `Order(s) ${candidateSuccess.map(c => c.entity.LookupCode).join(',')} processed`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (candidateFailures.length === candidates.length) {
                      const title = 'All order(s) failed to process';
                      const message = `Order(s) ${candidateFailures.map(c => c.entity.LookupCode).join(',')} could not be processed`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some order(s) could not be processed';
                      const success = `Order(s) ${candidateSuccess.map(c => c.entity.LookupCode).join(',')} were processed.`;
                      const errors = `The following order(s) could not be processed: ${candidateFailures.map(c => c.entity.LookupCode).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $grid: FootPrintManager_inbound_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Error cancelling orders', 'No orders selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
  
          if (row.entity.OrderClass.OrderClassTypeId === 1) {
              const flowParams = {
                  orderId: row.entity.Id,
                  shipmentId: row.entity.ShipmentOrderLookups[0].ShipmentId
              };
              var confirmResult = await $flows.PurchaseOrders.is_purchase_order_cancelable_flow(flowParams);
          }
          const reasons = confirmResult?.reasons;
          if ($utils.isDefined(reasons)) {
              failures.push(row);
              errors.push(`Order ${row.entity.LookupCode} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Error cancelling orders';
          const errorMessage = `Order(s) ${failures.map(c => c.entity.LookupCode).join(', ')} cannot be cancelled`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Order(s) ${candidates.map(c => c.entity.LookupCode).join(', ')} - Once cancelled, the order cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some order(s) cannot be cancelled';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Cancel the following order(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const CandidateSuccess = [];
              const CandidateFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      let reasons: string[];
                      if (candidate.entity.OrderClass.OrderClassTypeId === 1) {
                          const flowParams = {
                              orderId: candidate.entity.Id
                          }
                          let result = await $flows.PurchaseOrders.cancel_purchase_order_flow(flowParams);
                          if ($utils.isDefined(result.reasons)) {
                              reasons = result.reasons;
                          }
                      }
                      else if (candidate.entity.OrderClass.OrderClassTypeId === 12) {
                          let result = await $flows.AsnOrders.cancel_asn_order_flow({orderId: candidate.entity.Id});
                          reasons = result.reasons
                      }
                      
                      if ($utils.isDefined(reasons)) {
                          CandidateFailures.push(candidate);
                          errorMsgList.push(`Order ${candidate.entity.LookupCode} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue}`);
                      } else {
                          CandidateSuccess.push(candidate);
                      }
  
  
                  } catch (error) {
                      CandidateFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Order ${candidate.entity.LookupCode} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (CandidateSuccess.length === candidates.length) {
                  const title = 'All order(s) cancelled';
                  const message = `Order(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} cancelled`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (CandidateFailures.length === candidates.length) {
                      const title = 'All order(s) failed to cancel';
                      const message = `Order(s) ${CandidateFailures.map(c => c.entity.LookupCode).join(',')} could not be cancelled`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some order(s) could not be cancelled';
                      const success = `Order(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} were cancelled.`;
                      const errors = `The following order(s) could not be cancelled: ${CandidateFailures.map(c => c.entity.LookupCode).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_complete_clicked(event = null) {
    return this.on_complete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_clickedInternal(
    $grid: FootPrintManager_inbound_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Error completing orders', 'No orders selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              orderId: row.entity.Id
          };
          if (row.entity.OrderClass.OrderClassTypeId === 1) {
              var confirmResult = await $flows.PurchaseOrders.is_purchase_order_completable_flow(flowParams);
          }
          const reasons = confirmResult?.reasons;
          if ($utils.isDefined(reasons)) {
              failures.push(row);
              errors.push(`Order ${row.entity.LookupCode} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Error completing orders';
          const errorMessage = `Order(s) ${failures.map(c => c.entity.LookupCode).join(', ')} cannot be completed`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Order(s) ${candidates.map(c => c.entity.LookupCode).join(',')} - Once completed, the order cannot be changed.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some order(s) cannot be completed';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Complete the following order(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const CandidateSuccess = [];
              const CandidateFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      let reasons: string[];
                      if (candidate.entity.OrderClass.OrderClassTypeId === 1) {
                          const flowParams = {
                              orderId: candidate.entity.Id
                          }
                          let result = await $flows.PurchaseOrders.complete_purchase_order_flow(flowParams);
                          reasons = result.reasons;
                      }
                      else if (candidate.entity.OrderClass.OrderClassTypeId === 12) {
                          let result = await $flows.AsnOrders.complete_asn_order_flow({orderId: candidate.entity.Id});
                          reasons = result.reasons;
                      }
                      if ($utils.isDefined(reasons)) {
                          CandidateFailures.push(candidate);
                          errorMsgList.push(`Order ${candidate.entity.LookupCode} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue}`);
                      } else {
                          CandidateSuccess.push(candidate);
                      }
  
                  } catch (error) {
                      CandidateFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Order ${candidate.entity.LookupCode} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (CandidateSuccess.length === candidates.length) {
                  const title = 'All order(s) completed';
                  const message = `Order(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} completed`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (CandidateFailures.length === candidates.length) {
                      const title = 'All order(s) failed to complete';
                      const message = `Order(s) ${CandidateFailures.map(c => c.entity.LookupCode).join(',')} could not be completed`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some order(s) could not be completed';
                      const success = `Order(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} were completed.`;
                      const errors = `The following order(s) could not be completed: ${CandidateFailures.map(c => c.entity.LookupCode).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintManager_inbound_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Error deleting orders', 'No orders selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          if (row.entity.OrderClass.OrderClassTypeId === 1) {
              const flowParams = {
                  orderId: row.entity.Id
              };
              var confirmResult = await $flows.PurchaseOrders.is_purchase_order_deletable_flow(flowParams);
          }
          const reasons = confirmResult?.reasons;
          if ($utils.isDefined(reasons)) {
              failures.push(row);
              errors.push(`Order ${row.entity.LookupCode} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Error deleting orders';
          const errorMessage = `Order(s) ${failures.map(c => c.entity.LookupCode).join(', ')} cannot be deleted`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Order(s) ${candidates.map(c => c.entity.LookupCode).join(', ')} - Once deleted, the order cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some order(s) cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Delete the following order(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const CandidateSuccess = [];
              const CandidateFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      let reasons: string[];
                      if (candidate.entity.OrderClass.OrderClassTypeId === 1) {
                          const flowParams = {
                              orderId: candidate.entity.Id
                          }
                          let reason = (await $flows.PurchaseOrders.delete_purchase_order_flow(flowParams)).reason;
                          if ($utils.isDefined(reason)) {
                              reasons = [reason];
                          }
                      }
                      else if (candidate.entity.OrderClass.OrderClassTypeId === 12) {
                          reasons = (await $flows.AsnOrders.delete_asn_order_flow({ orderId: candidate.entity.Id })).reasons;
                      }
                      
                      if ($utils.isDefined(reasons)) {
                          CandidateFailures.push(candidate);
                          errorMsgList.push(`Order ${candidate.entity.LookupCode} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue}`);
                      } else {
                          CandidateSuccess.push(candidate);
                      }
  
                  } catch (error) {
                      CandidateFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Order ${candidate.entity.LookupCode} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (CandidateSuccess.length === candidates.length) {
                  const title = 'All order(s) deleted';
                  const message = `Order(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} deleted`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (CandidateFailures.length === candidates.length) {
                      const title = 'All order(s) failed to delete';
                      const message = `Order(s) ${CandidateFailures.map(c => c.entity.LookupCode).join(',')} could not be deleted`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some order(s) could not be deleted';
                      const success = `Order(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} were deleted.`;
                      const errors = `The following order(s) could not be deleted: ${CandidateFailures.map(c => c.entity.LookupCode).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_update_clicked(event = null) {
    return this.on_update_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_update_clickedInternal(
    $grid: FootPrintManager_inbound_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Order Update Error', 'No orders selected.');
      return;
  } else {
  
      const dialogResult = await $shell.FootPrintManager.openinbound_orders_update_formDialog();
      const userConfirmed = dialogResult.confirm;
      const carrierId = dialogResult.carrierId;
      const reference = dialogResult.reference;
      const expectedDate = dialogResult.expectedDate;
  
      let payload: any = {};
      let shipmentpayload: any = {};
  
      if (userConfirmed) {
          for (const row of selectedRows) {
  
              if ($utils.isDefined(carrierId)) {
                  payload.PreferredCarrierId = carrierId;
                  shipmentpayload.CarrierId = carrierId;
              }
              if (expectedDate) {
                  payload.RequestedDeliveryDate = expectedDate;
              }
               if (reference) {
                  payload.OwnerReference = reference;
              }
  
  
              await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: row.entity.Id, entity: payload });
              await $flows.Utilities.crud_update_flow({ entitySet: 'Shipments', id: row.entity.ShipmentOrderLookups[0].ShipmentId, entity: shipmentpayload });
          }
          await $grid.refresh();
      }
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_inbound_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Hide Validation Components
  
  $grid.topToolbar.validate.hidden = true;
  $grid.topToolbar.validate.control.readOnly = true;
  
  $grid.headers.validation_notes.name = null;
  $grid.headers.validation_result.name = null;
  
  
  
  if (await $operations.FootPrintManager.Disable_AsnOrders_AutoReceiving.isAuthorized()){
      $grid.topToolbar.auto_receive.hidden = true;
  }
  
  $grid.topToolbar.cancel.hidden = (await $operations.FootPrintManager.Disable_InboundOrders_Cancellation.isAuthorized());
  $grid.topToolbar.complete.hidden = (await $operations.FootPrintManager.Disable_InboundOrders_Completion.isAuthorized());
  $grid.topToolbar.on_delete.hidden= (await $operations.FootPrintManager.Disable_InboundOrders_Deletion.isAuthorized());
  $grid.topToolbar.process.hidden = (await $operations.FootPrintManager.Disable_InboundOrders_Processing.isAuthorized());
  $grid.topToolbar.revert.hidden =(await $operations.FootPrintManager.Disable_InboundOrders_Reverting.isAuthorized());
  $grid.topToolbar.update.hidden =(await $operations.FootPrintManager.Disable_Inbound_Order_Editing.isAuthorized());
  }
  on_auto_receive_clicked(event = null) {
    return this.on_auto_receive_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_auto_receive_clickedInternal(
    $grid: FootPrintManager_inbound_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let result = (await $flows.AsnOrders.auto_receive_asn_orders({orderIds: $grid.selectedRows.filter(r => r.entity.OrderClass.OrderClassTypeId === 12).map(r => r.entity.Id)}));
  
  let message = '';
  let header = '';
  
  if (result.confirmedLicensePlates > 0 && result.confirmedPackagedAmount > 0) {
      header = 'Auto Receive Complete';
      message += `Successfully received ${result.confirmedLicensePlates} license plates along with ${result.confirmedPackagedAmount} packaged units of inventory. `;
  }
  if ($utils.isDefined(result.reasons)) {
      if (header == '') { header = 'Auto Receive Failed'; }
      else { header += ' with exceptions'; }
      
      message += (await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons})).formattedValue;
  }
  
  await $shell.FootPrintManager.openInfoDialog(header, message);
  
  $grid.refresh();
  }
  on_validate_clicked(event = null) {
    return this.on_validate_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_validate_clickedInternal(
    $grid: FootPrintManager_inbound_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  var message: string;
  var errors: string;
  let ordersPassed: string[] = [];
  let ordersFailed: string[] = [];
  
  for (let row of $grid.selectedRows) {
      if (row.entity.OrderClass.OrderClassTypeId === 1) { // PurchaseOrder
          var reason = (await $flows.PurchaseOrders.validate_order_flow({ orderId: row.entity.Id })).reason;
      }
      else if (row.entity.OrderClass.OrderClassTypeId === 12) { // Inbound ASN
          reason = (await $flows.AsnOrders.validate_order_flow({ orderId: row.entity.Id })).reason;
      }
  
      if ($utils.isDefined(reason)) {
          ordersFailed.push(row.entity.LookupCode);
          addError(reason);
      }
      else {
          ordersPassed.push(row.entity.LookupCode);
      }
  
      await row.refresh();
  }
  
  // Display results to user
  if ($grid.selectedRows.length === 1) {
      message = `Order ${$grid.selectedRows[0].entity.LookupCode} has ${$utils.isDefined(errors) ? 'failed' : 'passed'} validation.`;
  }
  else {
      message = `${ordersPassed.length} out of ${$grid.selectedRows.length} orders have passed validation`;
  }
  
  $shell.FootPrintManager.openInfoDialog('Validation Complete', message);
  await $grid.refresh();
  
  /************************************
   * FUNCTIONS
  *************************************/
  function addError(errorMessage: string) {
      if (!$utils.isDefined(errorMessage)) {
          errors = errorMessage;
      }
      else {
          errors += ' & ' + errorMessage;
      }
  }
  }
  on_revert_clicked(event = null) {
    return this.on_revert_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_revert_clickedInternal(
    $grid: FootPrintManager_inbound_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  var message: string;
  var errors: string;
  let ordersPassed: string[] = [];
  let ordersFailed: string[] = [];
  
  for (let row of $grid.selectedRows) {
      let reasons: string[];
      if (row.entity.OrderClass.OrderClassTypeId === 1) {
          let reason = (await $flows.PurchaseOrders.revert_purchase_order_flow({ orderId: row.entity.Id, shipmentId: row.entity.ShipmentOrderLookups[0]?.ShipmentId })).reason;
          if ($utils.isDefined(reason)) {
              reasons = [reason];
          }
      }
      else {
          reasons = (await $flows.AsnOrders.revert_asn_order_flow({ orderId: row.entity.Id, useValidation: false})).reasons;
      }
  
      if ($utils.isDefined(reasons)) {
          ordersFailed.push(row.entity.LookupCode);
          reasons.forEach(r => addError(r));
      }
      else {
          ordersPassed.push(row.entity.LookupCode);
      }
  
      await row.refresh();
  }
  
  // Display results to user
  if ($grid.selectedRows.length === 1) {
      message = `Order ${$grid.selectedRows[0].entity.LookupCode} has ${$utils.isDefined(errors) ? 'failed to revert' : 'successfully reverted'}.`;
  }
  else {
      message = `${ordersPassed.length} out of ${$grid.selectedRows.length} orders have been reverted`;
  }
  
  $shell.FootPrintManager.openInfoDialog('Reversion Complete', message);
  await $grid.refresh();
  /************************************
   * FUNCTIONS
  *************************************/
  function addError(errorMessage: string) {
      if (!$utils.isDefined(errorMessage)) {
          errors = errorMessage;
      }
      else {
          errors += ' & ' + errorMessage;
      }
  }
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $grid: FootPrintManager_inbound_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const created_from = $grid.filters.from_date.control.value;
  const created_to = $grid.filters.to_date.control.value;
  
  const ownerId = $grid.inParams.ownerId;
  const projectId = $grid.inParams.projectId;
  const warehouseId = $grid.inParams.warehouseId;
  
  $shell.PurchaseOrders.openopen_putaway_reportDialog({
      startDate: created_from,
      endDate: created_to,
      ownerId: ownerId,
      projectId: projectId,
      warehouseId: warehouseId
  })
  
  
  }
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: FootPrintManager_inbound_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const exportLimit = $settings.FootPrintManager.ExcelExportLimit
  
  let exportAsDetails = await $shell.FootPrintManager.openConfirmationDialog('Choose Export', 'Export Details or Summary?', 'Details', 'Summary');
  if (!$utils.isDefined(exportAsDetails)) { return; }
  
  //Initialize
  var wb = $event;
  var transactions = [];
  var totalRecords: number;
  var exportType: string = "Summary";
  
  let dateFormat = $settings.FootPrintManager.DateFormat ?? "MM/dd/yyyy";
  
  // Get Selected Rows only. 
  if ($grid.selectedRows.length > 0) {
      var orderIds = [...new Set($grid.selectedRows.map(o => o.entity.Id))]
      totalRecords = $grid.selectedRows.length;
  } else {
      if (!exportAsDetails) {
          totalRecords = (await $datasources.PurchaseOrders.ds_inbound_orders_grid_count.get({
              ownerId: $grid.inParams.ownerId,
              projectId: $grid.inParams.projectId,
              warehouseId: $grid.inParams.warehouseId,
              statusIds: $grid.inParams.statusIds,
              fromDate: $grid.filters.from_date.control.value,
              toDate: $grid.filters.to_date.control.value,
              dateType: $grid.filters.date_type.control.value,
              fullTextSearch: $grid.fullTextSearch,
              reference: $grid.inParams.reference
          })).totalCount;
      } else {
          exportType = "Details"
          totalRecords = (await $datasources.PurchaseOrders.ds_inbound_orders_details_grid_count.get({
              ownerId: $grid.inParams.ownerId,
              projectId: $grid.inParams.projectId,
              warehouseId: $grid.inParams.warehouseId,
              statusIds: $grid.inParams.statusIds,
              fromDate: $grid.filters.from_date.control.value,
              toDate: $grid.filters.to_date.control.value,
              dateType: $grid.filters.date_type.control.value,
              fullTextSearch: $grid.fullTextSearch,
              reference: $grid.inParams.reference
          })).totalCount;
      }
  }
  if (totalRecords > exportLimit) {
      (await $shell.Inventory.openInfoDialog(`Export limitation of ${exportLimit} exceeded`, `Excel will only export the first ${exportLimit} records`));
  }
  transactions = (await $flows.PurchaseOrders.excel_export_flow({
      ownerId: $grid.inParams.ownerId,
      projectId: $grid.inParams.projectId,
      warehouseIds: $grid.inParams.warehouseId,
      statusIds: $grid.inParams.statusIds,
      fromDate: $grid.filters.from_date.control.value,
      toDate: $grid.filters.to_date.control.value,
      dateType: $grid.filters.date_type.control.value,
      fullTextSearch: $grid.fullTextSearch,
      orderIds: orderIds,
      exportLimit: exportLimit,
      reference: $grid.inParams.reference,
      exportType: exportType,
      totalRecords: totalRecords,
      dateFormat: dateFormat
  })).transactions;
  
  
  var ws = $utils.excel.json_to_sheet(transactions);
  
  $utils.excel.book_append_sheet(wb, ws, "InboundOrdersExport");
  
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootPrintManager_inbound_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 09/14/2023
  
  const inbound_orders = $grid.rows.map(row => row.entity.Id);
  
  const purchase_orders = $grid.rows.filter(o => o.entity.OrderClass.OrderClassTypeId !== 12).map(o => { return { "orderId": o.entity.Id, "additionalData": [{ "lookupCode": o.entity.LookupCode }] } });
  const asn_orders = $grid.rows.filter(o => o.entity.OrderClass.OrderClassTypeId === 12).map(o => { return { "orderId": o.entity.Id, "additionalData": [{ "lookupCode": o.entity.LookupCode }] } });
  
  let order_lookupCodes: { orderId?: number, formattedLookupCode?: string }[];
  
  if (inbound_orders.length > 0) {
  
      const order_tasks = (await $datasources.PurchaseOrders.ds_get_orders_aggregated_tasks.get({ orderIds: inbound_orders, operationCodeIds: [2] })).result;
      const order_properties = (await $datasources.PurchaseOrders.ds_inbound_orders_grid_properties.get({ orderIds: inbound_orders })).result;
      const expected_inbound = (await $datasources.PurchaseOrders.ds_get_orders_aggregated_expected.get({ orderIds: inbound_orders })).result;
      const order_addresses = (await $flows.Addresses.get_addresses_single_string_by_orderIds({ orderIds: inbound_orders }));
  
      // Get formatted lookupcode
      if ($utils.isDefined(purchase_orders)) { // PurchaseOrder
          if (purchase_orders.length > 0) {
              let formatted_lookup = (await $flows.PurchaseOrders.format_order_lookupcode_flow_multiple({
                  orders: purchase_orders,
                  context: 'inbound_orders_grid'
              }));
              if ($utils.isDefined(formatted_lookup)) {
                  if ($utils.isDefined(formatted_lookup.orders)) {
                      if (formatted_lookup.orders.length > 0) {
                          order_lookupCodes = formatted_lookup.orders;
                      };
                  };
              };
          };
      };
  
      if ($utils.isDefined(asn_orders)) { // Inbound ASN
          if (asn_orders.length > 0) {
              let formatted_lookup = (await $flows.AsnOrders.format_order_lookupcode_flow_multiple({
                  orders: asn_orders,
                  context: 'inbound_orders_grid'
              }));
              if ($utils.isDefined(formatted_lookup)) {
                  if ($utils.isDefined(formatted_lookup.orders)) {
                      if (formatted_lookup.orders.length > 0) {
                          if ($utils.isDefined(order_lookupCodes)) {
                              order_lookupCodes = order_lookupCodes.concat(formatted_lookup.orders);
                          } else {
                              order_lookupCodes = formatted_lookup.orders;
                          };
                      };
                  };
              };
          };
      };
  
      const receiving_tasks = order_tasks.filter(t => (t.StatusId === 2 && t.ActualAmount > 0))
      const pending_tasks = order_tasks.filter(t => ((t.StatusId === 4 || t.StatusId === 1) && t.ExpectedAmount > 0))
  
      const properties_array = order_properties.map(row => row.Id);
      const receiving_array = receiving_tasks.map(row => row.OrderId);
      const pending_array = pending_tasks.map(row => row.OrderId);
      const expected_array = expected_inbound.map(row => row.OrderId);
      const address_array = order_addresses.Addresses.map(row => row.OrderId);
      const lookup_array = order_lookupCodes?.map(row => row.orderId);
  
      for (let row of $grid.rows) {
  
          let received_total = 0;
          let expected_total = 0;
          let orderIndex = -1;
  
          let orderId = row.entity.Id;
          let order_status_id = row.entity.OrderStatusId;
  
          //Get the lookupCode
          if ($utils.isDefined(lookup_array)) {
              orderIndex = lookup_array.indexOf(orderId)
              if (orderIndex >= 0) {
                  row.cells.lookupcode.displayControl.text = order_lookupCodes[orderIndex].formattedLookupCode;
              };
          };
  
          //Get the total received inventory
          orderIndex = receiving_array.indexOf(orderId)
          if (orderIndex >= 0) {
              received_total = receiving_tasks[orderIndex].ActualAmount;
          };
  
          //Get the total expected inventory
          orderIndex = expected_array.indexOf(orderId)
          if (orderIndex >= 0) {
              expected_total = expected_inbound[orderIndex].Amount;
          };
  
          //Created
          if (row.entity.Status.Id === 1 && expected_total > 0) {
              row.cells.total_items.displayControl.text = `${expected_total}`
          };
  
          //Canceled
          if (row.entity.Status.Id === 8 && received_total > 0) {
              row.cells.total_items.displayControl.text = `${received_total}`
          };
  
          //Processing or Completed
          if ((row.entity.Status.Id === 2 || row.entity.Status.Id === 4) && received_total >= 0 && expected_total > 0) {
              row.cells.total_items.displayControl.text = `${received_total} of ${expected_total}`
          };
  
          // Check tasks
  
          let has_receiving = false;
          let has_pending = false;
  
          orderIndex = receiving_array.indexOf(orderId)
          if (orderIndex >= 0) {
              has_receiving = true;
          };
  
          orderIndex = pending_array.indexOf(orderId)
          if (orderIndex >= 0) {
              has_pending = true;
          };
  
          row.vars.hasCompletedReceivingTasks = has_receiving;
          row.vars.hasRemainingReceivingTasks = has_pending;
  
          if (order_status_id === 2) {
  
              let order_state = 0;
  
              // All receiving tasks in a planned status
              if (has_pending && !has_receiving) {
                  order_state = 2;
              }
              // One or more receiving tasks completed and one or more receiving tasks released
              else if (has_pending && has_receiving) {
                  order_state = 3;
              }
  
              // No more released receiving tasks where the expected packaged amount gt 0 and at least one completed receiving task
              else if (!has_pending && has_receiving) {
                  order_state = 4;
              };
  
              if ($utils.isDefined(order_state)) {
                  switch (order_state) {
                      case 2: {
                          row.cells.status.displayControl.text = 'Ready to receive'
                          break;
                      }
                      case 3: {
                          row.cells.status.displayControl.text = 'Receiving in progress'
                          break;
                      }
                      case 4: {
                          row.cells.status.displayControl.text = 'Ready to complete'
                          break;
                      };
                  };
              };
          };
  
          //Get additional properties
          orderIndex = properties_array.indexOf(orderId)
          if (orderIndex >= 0) {
  
              if ($utils.isDefined(order_properties[orderIndex].ValidationResults)) {
                  row.cells.validation_notes.displayControl.text = `${order_properties[orderIndex].ValidationResults[0]?.Notes}`;
                  row.cells.validation_result.displayControl.text = `${order_properties[orderIndex].ValidationResults[0]?.ValidationExecutionResult?.DisplayName}`;
              };
  
              // Apply appointment text logic
              if ($utils.isDefined(order_properties[orderIndex].DockAppointmentsOrdersLookup)) {
  
                  if ($utils.isDefined(order_properties[orderIndex].DockAppointmentsOrdersLookup[0]?.DockAppointmentId)) {
  
                      let dock_appointment_id = order_properties[orderIndex].DockAppointmentsOrdersLookup[0]?.DockAppointmentId;
                      let dock_appointment = order_properties[orderIndex].DockAppointmentsOrdersLookup[0]?.DockAppointment;
  
                      let scheduled_arrival = dock_appointment?.ScheduledArrival;
                      let warehouseId = $utils.isDefinedTrimmed(dock_appointment?.WarehouseId) ? dock_appointment?.WarehouseId : order_properties[orderIndex].PreferredWarehouseId;
                      let scheduled_location = $utils.isDefinedTrimmed(dock_appointment?.ScheduledLocation?.ShortName) ? dock_appointment?.ScheduledLocation?.ShortName : dock_appointment?.ScheduledLocation?.Name;
                      let assigned_location = $utils.isDefinedTrimmed(dock_appointment?.AssignedLocation?.ShortName) ? dock_appointment?.AssignedLocation?.ShortName : dock_appointment?.AssignedLocation?.Name;
                      let dock_door = $utils.isDefinedTrimmed(assigned_location) ? assigned_location : ($utils.isDefinedTrimmed(scheduled_location) ? scheduled_location : 'Unassigned');
  
                      if ($utils.isDefined(scheduled_arrival) && $utils.isDefined(warehouseId)) {
  
                          scheduled_arrival = (await $datasources.DockAppointments.ds_get_current_date_from_utc.get({ utcDate: scheduled_arrival, warehouseId: warehouseId })).result.convertedDate;
  
                      };
  
                      row.vars.dockAppointmentId = dock_appointment_id;
  
                      row.cells.appointment.displayControl.text = (await $flows.DockAppointments.get_formatted_dock_appointment({
                          dockAppointmentId: dock_appointment_id,
                          optimalInputs: {
                              scheduledLocationName: dock_door,
                              assignedLocationName: dock_door,
                              statusId: dock_appointment?.StatusId,
                              lookupCode: dock_appointment?.LookupCode,
                              scheduledArrival: scheduled_arrival
                          }
                      })).formattedDockAppointment;
                  };
              }
              else {
                  row.cells.appointment.displayControl.text = '  +'
              };
          };
  
          //Set the Ship_From
          orderIndex = address_array.indexOf(orderId)
          if (orderIndex >= 0) {
  
              let address = order_addresses.Addresses[orderIndex]
  
              if ($utils.isDefined(address)) {
  
                  let ship_from = address.Address;
  
                  if (!ship_from.includes('ord-')) {
                      row.cells.ship_from.displayControl.text = ship_from
                  }
                  else {
                      row.cells.ship_from.displayControl.text = '';
                  };
              };
          };
      };
  
      // Hide Validation Columns
  
      $grid.headers.validation_result.hidden = true;
      $grid.headers.validation_notes.hidden = true;
  
  };
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $grid: FootPrintManager_inbound_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($grid.selectedRows.length === 1) {
      await $shell.FootPrintManager.openorders_attachments_hubDialog({orderId: $grid.selectedRows[0].entity.Id, shipmentId: $grid.selectedRows[0].entity.ShipmentOrderLookups[0].ShipmentId});
  }
  }
  on_refresh(event = null) {
    return this.on_refreshInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refreshInternal(
    $grid: FootPrintManager_inbound_orders_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $grid.refresh();
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_flow(wb);

    writeExcelFile(wb, 'FootPrintManager_inbound_orders_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
