import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class AsnOrders_ds_get_license_plates_by_idsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { license_plate_ids: number[] }): 
  Promise<{ result: { Id?: number, TypeId?: number, TasksForActualSourceLicensePlate?: { OperationCodeId?: number, StatusId?: number }[], TasksForActualTargetLicensePlate?: { OperationCodeId?: number, StatusId?: number }[], Shipment?: { Id?: number, Status?: { Id?: number, Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.license_plate_ids)) {
      missingRequiredInParams.push('\'license_plate_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/AsnOrders/datasources/ds_get_license_plates_by_ids/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { license_plate_ids: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, TypeId?: number, TasksForActualSourceLicensePlate?: { OperationCodeId?: number, StatusId?: number }[], TasksForActualTargetLicensePlate?: { OperationCodeId?: number, StatusId?: number }[], Shipment?: { Id?: number, Status?: { Id?: number, Name?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.license_plate_ids)) {
      missingRequiredInParams.push('\'license_plate_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/AsnOrders/datasources/ds_get_license_plates_by_ids/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { license_plate_ids: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, TypeId?: number, TasksForActualSourceLicensePlate?: { OperationCodeId?: number, StatusId?: number }[], TasksForActualTargetLicensePlate?: { OperationCodeId?: number, StatusId?: number }[], Shipment?: { Id?: number, Status?: { Id?: number, Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.license_plate_ids)) {
      missingRequiredInParams.push('\'license_plate_ids\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/AsnOrders/datasources/ds_get_license_plates_by_ids/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      license_plate_ids: number[]    }) {
        if(isNil(inParams.license_plate_ids)) {
          return true; 
        }
      return false;
    }
}
