import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Returns_ds_customer_return_shipping_containers_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number, fullTextSearch?: string }): 
  Promise<{ result: { Id?: number, CompletedDateTime?: string, ActualTargetShippingContainer?: { Id?: number, LookupCode?: string, TrackingNumber?: string, ParentShippingContainer?: { Id?: number, LookupCode?: string } }, ActualTargetLocation?: { Id?: number, Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Returns/datasources/ds_customer_return_shipping_containers_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, fullTextSearch?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CompletedDateTime?: string, ActualTargetShippingContainer?: { Id?: number, LookupCode?: string, TrackingNumber?: string, ParentShippingContainer?: { Id?: number, LookupCode?: string } }, ActualTargetLocation?: { Id?: number, Name?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Returns/datasources/ds_customer_return_shipping_containers_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, fullTextSearch?: string, $keys: number[] }): 
  Promise<{ result: { Id?: number, CompletedDateTime?: string, ActualTargetShippingContainer?: { Id?: number, LookupCode?: string, TrackingNumber?: string, ParentShippingContainer?: { Id?: number, LookupCode?: string } }, ActualTargetLocation?: { Id?: number, Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Returns/datasources/ds_customer_return_shipping_containers_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId: number, fullTextSearch?: string    }) {
        if(isNil(inParams.orderId)) {
          return true; 
        }
      return false;
    }
}
