<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="save_and_close" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_save_and_close($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="save_and_new" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_save_and_new($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-temperature_1" *ngIf="!fields.temperature_1.hidden && !fields.temperature_1.removed" 
                            class="field-container standard {{fields.temperature_1.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.temperature_1.styles.style"
                            [ngClass]="fields.temperature_1.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.temperature_1.label + (fields.temperature_1.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.temperature_1.label}}<span *ngIf="fields.temperature_1.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="temperature_1"
                                matInput
                                numberBox
                                [format]="fields.temperature_1.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.temperature_1.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.temperature_1.control.placeholder}}"
                                [ngStyle]="fields.temperature_1.control.styles.style"
                                [ngClass]="fields.temperature_1.control.styles.classes"
                                [matTooltip]="fields.temperature_1.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.temperature_1.invalid">
                          <ng-container *ngFor="let error of fields.temperature_1.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-temperature_measurement_unit_1" *ngIf="!fields.temperature_measurement_unit_1.hidden && !fields.temperature_measurement_unit_1.removed" 
                            class="field-container standard {{fields.temperature_measurement_unit_1.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.temperature_measurement_unit_1.styles.style"
                            [ngClass]="fields.temperature_measurement_unit_1.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.temperature_measurement_unit_1.label + (fields.temperature_measurement_unit_1.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.temperature_measurement_unit_1.label}}<span *ngIf="fields.temperature_measurement_unit_1.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <TemperatureReadings-temperature_measurement_units_single 
                            data-cy="selector"
                            [type]="fields.temperature_measurement_unit_1.control.type"
                            formControlName="temperature_measurement_unit_1"
                            (displayTextChange)="fields.temperature_measurement_unit_1.control.displayText=$event"
                            [placeholder]="fields.temperature_measurement_unit_1.control.placeholder"
                            [styles]="fields.temperature_measurement_unit_1.control.styles"
                            [tooltip]="fields.temperature_measurement_unit_1.control.tooltip"
                        >
                        </TemperatureReadings-temperature_measurement_units_single>
                        <ng-container *ngIf="fields.temperature_measurement_unit_1.invalid">
                          <ng-container *ngFor="let error of fields.temperature_measurement_unit_1.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-temperature_2" *ngIf="!fields.temperature_2.hidden && !fields.temperature_2.removed" 
                            class="field-container standard {{fields.temperature_2.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.temperature_2.styles.style"
                            [ngClass]="fields.temperature_2.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.temperature_2.label + (fields.temperature_2.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.temperature_2.label}}<span *ngIf="fields.temperature_2.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="temperature_2"
                                matInput
                                numberBox
                                [format]="fields.temperature_2.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.temperature_2.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.temperature_2.control.placeholder}}"
                                [ngStyle]="fields.temperature_2.control.styles.style"
                                [ngClass]="fields.temperature_2.control.styles.classes"
                                [matTooltip]="fields.temperature_2.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.temperature_2.invalid">
                          <ng-container *ngFor="let error of fields.temperature_2.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-temperature_measurement_unit_2" *ngIf="!fields.temperature_measurement_unit_2.hidden && !fields.temperature_measurement_unit_2.removed" 
                            class="field-container standard {{fields.temperature_measurement_unit_2.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.temperature_measurement_unit_2.styles.style"
                            [ngClass]="fields.temperature_measurement_unit_2.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.temperature_measurement_unit_2.label + (fields.temperature_measurement_unit_2.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.temperature_measurement_unit_2.label}}<span *ngIf="fields.temperature_measurement_unit_2.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <TemperatureReadings-temperature_measurement_units_single 
                            data-cy="selector"
                            [type]="fields.temperature_measurement_unit_2.control.type"
                            formControlName="temperature_measurement_unit_2"
                            (displayTextChange)="fields.temperature_measurement_unit_2.control.displayText=$event"
                            [placeholder]="fields.temperature_measurement_unit_2.control.placeholder"
                            [styles]="fields.temperature_measurement_unit_2.control.styles"
                            [tooltip]="fields.temperature_measurement_unit_2.control.tooltip"
                        >
                        </TemperatureReadings-temperature_measurement_units_single>
                        <ng-container *ngIf="fields.temperature_measurement_unit_2.invalid">
                          <ng-container *ngFor="let error of fields.temperature_measurement_unit_2.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-temperature_3" *ngIf="!fields.temperature_3.hidden && !fields.temperature_3.removed" 
                            class="field-container standard {{fields.temperature_3.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.temperature_3.styles.style"
                            [ngClass]="fields.temperature_3.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.temperature_3.label + (fields.temperature_3.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.temperature_3.label}}<span *ngIf="fields.temperature_3.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="temperature_3"
                                matInput
                                numberBox
                                [format]="fields.temperature_3.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.temperature_3.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.temperature_3.control.placeholder}}"
                                [ngStyle]="fields.temperature_3.control.styles.style"
                                [ngClass]="fields.temperature_3.control.styles.classes"
                                [matTooltip]="fields.temperature_3.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.temperature_3.invalid">
                          <ng-container *ngFor="let error of fields.temperature_3.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-temperature_measurement_unit_3" *ngIf="!fields.temperature_measurement_unit_3.hidden && !fields.temperature_measurement_unit_3.removed" 
                            class="field-container standard {{fields.temperature_measurement_unit_3.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.temperature_measurement_unit_3.styles.style"
                            [ngClass]="fields.temperature_measurement_unit_3.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.temperature_measurement_unit_3.label + (fields.temperature_measurement_unit_3.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.temperature_measurement_unit_3.label}}<span *ngIf="fields.temperature_measurement_unit_3.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <TemperatureReadings-temperature_measurement_units_single 
                            data-cy="selector"
                            [type]="fields.temperature_measurement_unit_3.control.type"
                            formControlName="temperature_measurement_unit_3"
                            (displayTextChange)="fields.temperature_measurement_unit_3.control.displayText=$event"
                            [placeholder]="fields.temperature_measurement_unit_3.control.placeholder"
                            [styles]="fields.temperature_measurement_unit_3.control.styles"
                            [tooltip]="fields.temperature_measurement_unit_3.control.tooltip"
                        >
                        </TemperatureReadings-temperature_measurement_units_single>
                        <ng-container *ngIf="fields.temperature_measurement_unit_3.invalid">
                          <ng-container *ngFor="let error of fields.temperature_measurement_unit_3.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-reading_point" *ngIf="!fields.reading_point.hidden && !fields.reading_point.removed" 
                            class="field-container standard {{fields.reading_point.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.reading_point.styles.style"
                            [ngClass]="fields.reading_point.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.reading_point.label + (fields.reading_point.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.reading_point.label}}<span *ngIf="fields.reading_point.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <TemperatureReadings-temperature_reading_points_single 
                            data-cy="selector"
                            [type]="fields.reading_point.control.type"
                            formControlName="reading_point"
                            (displayTextChange)="fields.reading_point.control.displayText=$event"
                            [placeholder]="fields.reading_point.control.placeholder"
                            [styles]="fields.reading_point.control.styles"
                            [tooltip]="fields.reading_point.control.tooltip"
                        >
                        </TemperatureReadings-temperature_reading_points_single>
                        <ng-container *ngIf="fields.reading_point.invalid">
                          <ng-container *ngFor="let error of fields.reading_point.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-reading_type" *ngIf="!fields.reading_type.hidden && !fields.reading_type.removed" 
                            class="field-container standard {{fields.reading_type.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.reading_type.styles.style"
                            [ngClass]="fields.reading_type.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.reading_type.label + (fields.reading_type.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.reading_type.label}}<span *ngIf="fields.reading_type.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <TemperatureReadings-temperature_reading_types_single 
                            data-cy="selector"
                            [type]="fields.reading_type.control.type"
                            formControlName="reading_type"
                            (displayTextChange)="fields.reading_type.control.displayText=$event"
                            [placeholder]="fields.reading_type.control.placeholder"
                            [styles]="fields.reading_type.control.styles"
                            [tooltip]="fields.reading_type.control.tooltip"
                        >
                        </TemperatureReadings-temperature_reading_types_single>
                        <ng-container *ngIf="fields.reading_type.invalid">
                          <ng-container *ngFor="let error of fields.reading_type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden && !fields.notes.removed" 
                            class="field-container full {{fields.notes.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.notes.label + (fields.notes.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.notes.label}}<span *ngIf="fields.notes.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.notes.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"
                                [matTooltip]="fields.notes.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> </textarea>
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-shipment" *ngIf="!fields.shipment.hidden && !fields.shipment.removed" 
                            class="field-container full {{fields.shipment.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.shipment.styles.style"
                            [ngClass]="fields.shipment.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.shipment.label + (fields.shipment.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.shipment.label}}<span *ngIf="fields.shipment.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="shipment"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.shipment.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.shipment.control.placeholder}}"
                                [ngStyle]="fields.shipment.control.styles.style"
                                [ngClass]="fields.shipment.control.styles.classes"
                                [matTooltip]="fields.shipment.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.shipment.invalid">
                          <ng-container *ngFor="let error of fields.shipment.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-shipment_line" *ngIf="!fields.shipment_line.hidden && !fields.shipment_line.removed" 
                            class="field-container full {{fields.shipment_line.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.shipment_line.styles.style"
                            [ngClass]="fields.shipment_line.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.shipment_line.label + (fields.shipment_line.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.shipment_line.label}}<span *ngIf="fields.shipment_line.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <TemperatureReadings-shipment_lines_single 
                            data-cy="selector"
                            [type]="fields.shipment_line.control.type"
                            formControlName="shipment_line"
                            (displayTextChange)="fields.shipment_line.control.displayText=$event"
                            [placeholder]="fields.shipment_line.control.placeholder"
                            [styles]="fields.shipment_line.control.styles"
                            [tooltip]="fields.shipment_line.control.tooltip"
                          [shipmentId]="$fields_shipment_line_selector_inParams_shipmentId"
                        >
                        </TemperatureReadings-shipment_lines_single>
                        <ng-container *ngIf="fields.shipment_line.invalid">
                          <ng-container *ngFor="let error of fields.shipment_line.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-license_plate" *ngIf="!fields.license_plate.hidden && !fields.license_plate.removed" 
                            class="field-container full {{fields.license_plate.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.license_plate.styles.style"
                            [ngClass]="fields.license_plate.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.license_plate.label + (fields.license_plate.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.license_plate.label}}<span *ngIf="fields.license_plate.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="license_plate"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.license_plate.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.license_plate.control.placeholder}}"
                                [ngStyle]="fields.license_plate.control.styles.style"
                                [ngClass]="fields.license_plate.control.styles.classes"
                                [matTooltip]="fields.license_plate.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.license_plate.invalid">
                          <ng-container *ngFor="let error of fields.license_plate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-load_container" *ngIf="!fields.load_container.hidden && !fields.load_container.removed" 
                            class="field-container full {{fields.load_container.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.load_container.styles.style"
                            [ngClass]="fields.load_container.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.load_container.label + (fields.load_container.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.load_container.label}}<span *ngIf="fields.load_container.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="load_container"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.load_container.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.load_container.control.placeholder}}"
                                [ngStyle]="fields.load_container.control.styles.style"
                                [ngClass]="fields.load_container.control.styles.classes"
                                [matTooltip]="fields.load_container.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.load_container.invalid">
                          <ng-container *ngFor="let error of fields.load_container.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
      </ng-container>

    </div>
  </div>
</div>