<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template 
                toolbarToolDef="runner" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_run($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="refresh_dropdowns" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_refresh($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-integration"
                     *ngIf="!filtersets.integration.hidden && !filtersets.integration.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.integration.collapsible }">
                    <div *ngIf="!filtersets.integration.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.integration.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.integration.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.integration.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.integration.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.integration.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.integration.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-integration_name" *ngIf="!filters.integration_name.hidden && !filters.integration_name.removed" 
                                  class="field-container full {{filters.integration_name.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.integration_name.styles.style"
                                  [ngClass]="filters.integration_name.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.integration_name.label + (filters.integration_name.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.integration_name.label}}<span *ngIf="filters.integration_name.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <FootPrintApiManager-integrations_dd_single 
                                  data-cy="selector"
                                  [type]="filters.integration_name.control.type"
                                  formControlName="integration_name"
                                  (displayTextChange)="filters.integration_name.control.displayText=$event"
                                  [placeholder]="filters.integration_name.control.placeholder"
                                  [styles]="filters.integration_name.control.styles"
                                  [tooltip]="filters.integration_name.control.tooltip"
                              >
                              </FootPrintApiManager-integrations_dd_single>
                              <ng-container *ngIf="filters.integration_name.invalid">
                                <ng-container *ngFor="let error of filters.integration_name.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.message_statuses.hidden && !widgets.message_statuses.removed" class="widget-container">
            <FootPrintApiManager-message_statuses_widget
            #$widgets_message_statuses 
              [integration_name]="$widgets_message_statuses_inParams_integration_name"
            ($refreshEvent)="refresh(false, false, '$widgets_message_statuses')"
            >
            </FootPrintApiManager-message_statuses_widget>
          </div>
          <div *ngIf="!widgets.in_out_totals.hidden && !widgets.in_out_totals.removed" class="widget-container">
            <FootPrintApiManager-control_partners_directions_widget
            #$widgets_in_out_totals 
              [integration_name]="$widgets_in_out_totals_inParams_integration_name"
            ($refreshEvent)="refresh(false, false, '$widgets_in_out_totals')"
            >
            </FootPrintApiManager-control_partners_directions_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.lifecycle.hasTabToShow" class="tab" data-cy="tab-lifecycle">
              <h2 [className]="tabs.lifecycle.active? 'active': ''" (click)="tabs.lifecycle.activate()">{{tabs.lifecycle.title}}</h2>
            </div>
            <div *ngIf="tabs.messages.hasTabToShow" class="tab" data-cy="tab-messages">
              <h2 [className]="tabs.messages.active? 'active': ''" (click)="tabs.messages.activate()">{{tabs.messages.title}}</h2>
            </div>
            <div *ngIf="tabs.logs.hasTabToShow" class="tab" data-cy="tab-logs">
              <h2 [className]="tabs.logs.active? 'active': ''" (click)="tabs.logs.activate()">{{tabs.logs.title}}</h2>
            </div>
            <div *ngIf="tabs.configurations.hasTabToShow" class="tab" data-cy="tab-configurations">
              <h2 [className]="tabs.configurations.active? 'active': ''" (click)="tabs.configurations.activate()">{{tabs.configurations.title}}</h2>
            </div>
            <div *ngIf="tabs.control.hasTabToShow" class="tab" data-cy="tab-control">
              <h2 [className]="tabs.control.active? 'active': ''" (click)="tabs.control.activate()">{{tabs.control.title}}</h2>
            </div>
            <div *ngIf="tabs.alerts.hasTabToShow" class="tab" data-cy="tab-alerts">
              <h2 [className]="tabs.alerts.active? 'active': ''" (click)="tabs.alerts.activate()">{{tabs.alerts.title}}</h2>
            </div>
            <div *ngIf="tabs.integrations.hasTabToShow" class="tab" data-cy="tab-integrations">
              <h2 [className]="tabs.integrations.active? 'active': ''" (click)="tabs.integrations.activate()">{{tabs.integrations.title}}</h2>
            </div>
            <div *ngIf="tabs.processes.hasTabToShow" class="tab" data-cy="tab-processes">
              <h2 [className]="tabs.processes.active? 'active': ''" (click)="tabs.processes.activate()">{{tabs.processes.title}}</h2>
            </div>
            <div *ngIf="tabs.statuses.hasTabToShow" class="tab" data-cy="tab-statuses">
              <h2 [className]="tabs.statuses.active? 'active': ''" (click)="tabs.statuses.activate()">{{tabs.statuses.title}}</h2>
            </div>
            <div *ngIf="tabs.transaction_types.hasTabToShow" class="tab" data-cy="tab-transaction_types">
              <h2 [className]="tabs.transaction_types.active? 'active': ''" (click)="tabs.transaction_types.activate()">{{tabs.transaction_types.title}}</h2>
            </div>
          </div>
        
              <FootPrintApiManager-lifecycle_grid *ngIf="tabs.lifecycle.active"
              #$tabs_lifecycle
              [integration_name]="$tabs_lifecycle_lifecycle_grid_inParams_integration_name"
              ($refreshEvent)="refresh(false, false, '$tabs_lifecycle')">
              </FootPrintApiManager-lifecycle_grid>
              <FootPrintApiManager-messages_grid *ngIf="tabs.messages.active"
              #$tabs_messages
              [integration_name]="$tabs_messages_messages_grid_inParams_integration_name"
              ($refreshEvent)="refresh(false, false, '$tabs_messages')">
              </FootPrintApiManager-messages_grid>
              <FootPrintApiManager-logs_grid *ngIf="tabs.logs.active"
              #$tabs_logs
              [integration_name]="$tabs_logs_logs_grid_inParams_integration_name"
              ($refreshEvent)="refresh(false, false, '$tabs_logs')">
              </FootPrintApiManager-logs_grid>
              <FootPrintApiManager-configurations_grid *ngIf="tabs.configurations.active"
              #$tabs_configurations
              [integration_name]="$tabs_configurations_configurations_grid_inParams_integration_name"
              ($refreshEvent)="refresh(false, false, '$tabs_configurations')">
              </FootPrintApiManager-configurations_grid>
              <FootPrintApiManager-control_grid *ngIf="tabs.control.active"
              #$tabs_control
              [integration_name]="$tabs_control_control_grid_inParams_integration_name"
              ($refreshEvent)="refresh(false, false, '$tabs_control')">
              </FootPrintApiManager-control_grid>
              <FootPrintApiManager-alerts_grid *ngIf="tabs.alerts.active"
              #$tabs_alerts
              [integration_name]="$tabs_alerts_alerts_grid_inParams_integration_name"
              ($refreshEvent)="refresh(false, false, '$tabs_alerts')">
              </FootPrintApiManager-alerts_grid>
              <FootPrintApiManager-integrations_grid *ngIf="tabs.integrations.active"
              #$tabs_integrations
              ($refreshEvent)="refresh(false, false, '$tabs_integrations')">
              </FootPrintApiManager-integrations_grid>
              <FootPrintApiManager-processes_grid *ngIf="tabs.processes.active"
              #$tabs_processes
              ($refreshEvent)="refresh(false, false, '$tabs_processes')">
              </FootPrintApiManager-processes_grid>
              <FootPrintApiManager-statuses_grid *ngIf="tabs.statuses.active"
              #$tabs_statuses
              ($refreshEvent)="refresh(false, false, '$tabs_statuses')">
              </FootPrintApiManager-statuses_grid>
              <FootPrintApiManager-transaction_types_grid *ngIf="tabs.transaction_types.active"
              #$tabs_transaction_types
              ($refreshEvent)="refresh(false, false, '$tabs_transaction_types')">
              </FootPrintApiManager-transaction_types_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>