<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams"  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"


>
    <ng-container topToolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-lot_status" *ngIf="!filters.lot_status.hidden && !filters.lot_status.removed" 
            class="field-container standard {{filters.lot_status.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.lot_status.styles.style"
            [ngClass]="filters.lot_status.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.lot_status.label + (filters.lot_status.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.lot_status.label}}<span *ngIf="filters.lot_status.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Lots-lot_statuses_dd_single 
            data-cy="selector"
            [type]="filters.lot_status.control.type"
            formControlName="lot_status"
            (displayTextChange)="filters.lot_status.control.displayText=$event"
            [placeholder]="filters.lot_status.control.placeholder"
            [styles]="filters.lot_status.control.styles"
            [tooltip]="filters.lot_status.control.tooltip"
        >
        </Lots-lot_statuses_dd_single>
        <ng-container *ngIf="filters.lot_status.invalid">
          <ng-container *ngFor="let error of filters.lot_status.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-license_plate_status" *ngIf="!filters.license_plate_status.hidden && !filters.license_plate_status.removed" 
            class="field-container standard {{filters.license_plate_status.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.license_plate_status.styles.style"
            [ngClass]="filters.license_plate_status.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.license_plate_status.label + (filters.license_plate_status.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.license_plate_status.label}}<span *ngIf="filters.license_plate_status.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Locations-licenseplate_statuses_dd_single 
            data-cy="selector"
            [type]="filters.license_plate_status.control.type"
            formControlName="license_plate_status"
            (displayTextChange)="filters.license_plate_status.control.displayText=$event"
            [placeholder]="filters.license_plate_status.control.placeholder"
            [styles]="filters.license_plate_status.control.styles"
            [tooltip]="filters.license_plate_status.control.tooltip"
        >
        </Locations-licenseplate_statuses_dd_single>
        <ng-container *ngIf="filters.license_plate_status.invalid">
          <ng-container *ngFor="let error of filters.license_plate_status.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.quantity.displayControl.styles.style"
          [ngClass]="row.cells.quantity.displayControl.styles.classes"
          [matTooltip]="row.cells.quantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['quantity_edit']"
            matInput
            numberBox
            [format]="row.cells.quantity.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.quantity.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.quantity.editControl.placeholder}}"
            [ngStyle]="row.cells.quantity.editControl.styles.style"
            [ngClass]="row.cells.quantity.editControl.styles.classes"
            [matTooltip]="row.cells.quantity.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material_lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.material_lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.material_lookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.material_lookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.material_lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="on_hand">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.on_hand.displayControl.styles.style"
          [ngClass]="row.cells.on_hand.displayControl.styles.classes"
          [matTooltip]="row.cells.on_hand.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.on_hand.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="available">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.available.displayControl.styles.style"
          [ngClass]="row.cells.available.displayControl.styles.classes"
          [matTooltip]="row.cells.available.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.available.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.location.displayControl.styles.style"
          [ngClass]="row.cells.location.displayControl.styles.classes"
          [matTooltip]="row.cells.location.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="licenseplate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.licenseplate.displayControl.styles.style"
          [ngClass]="row.cells.licenseplate.displayControl.styles.classes"
          [matTooltip]="row.cells.licenseplate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.licenseplate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="licenseplate_status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.licenseplate_status.displayControl.styles.style"
          [ngClass]="row.cells.licenseplate_status.displayControl.styles.classes"
          [matTooltip]="row.cells.licenseplate_status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.licenseplate_status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="location_status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.location_status.displayControl.styles.style"
          [ngClass]="row.cells.location_status.displayControl.styles.classes"
          [matTooltip]="row.cells.location_status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.location_status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="eligible_for_allocation">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['eligible_for_allocation_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.eligible_for_allocation.displayControl.styles.style"
                  [ngClass]="row.cells.eligible_for_allocation.displayControl.styles.classes"
                  [matTooltip]="row.cells.eligible_for_allocation.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.eligible_for_allocation.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="global_allocation_sequence">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.global_allocation_sequence.displayControl.styles.style"
          [ngClass]="row.cells.global_allocation_sequence.displayControl.styles.classes"
          [matTooltip]="row.cells.global_allocation_sequence.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.global_allocation_sequence.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes"
          [matTooltip]="row.cells.lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendorlot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.vendorlot.displayControl.styles.style"
          [ngClass]="row.cells.vendorlot.displayControl.styles.classes"
          [matTooltip]="row.cells.vendorlot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.vendorlot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="expiration_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.expiration_date.displayControl.styles.style"
          [ngClass]="row.cells.expiration_date.displayControl.styles.classes"
          [matTooltip]="row.cells.expiration_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.expiration_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="manufacture_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.manufacture_date.displayControl.styles.style"
          [ngClass]="row.cells.manufacture_date.displayControl.styles.classes"
          [matTooltip]="row.cells.manufacture_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.manufacture_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material_description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.material_description.displayControl.styles.style"
          [ngClass]="row.cells.material_description.displayControl.styles.classes"
          [matTooltip]="row.cells.material_description.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.material_description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
