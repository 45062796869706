import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Locations_location_type_dd_singleComponent } from './Locations.location_type_dd_single.component'
import { Locations_zones_dd_singleComponent } from './Locations.zones_dd_single.component'
import { Locations_location_statuses_dd_singleComponent } from './Locations.location_statuses_dd_single.component'
import { Locations_yn_dd_singleComponent } from './Locations.yn_dd_single.component'
import { Locations_measurement_units_dd_singleComponent } from './Locations.measurement_units_dd_single.component'


interface IFootPrintManager_locations_gridComponentEntity {
Id?: number, AllocationSequence?: number, ChildFootprint?: number, ChildStackHeight?: number, DimensionUomId?: number, EligibleForAllocation?: boolean, Height?: number, IsLoose?: boolean, Length?: number, Name?: string, ParentId?: number, PickSequence?: number, PutAwaySequence?: number, StatusId?: number, TypeId?: number, WarehouseId?: number, WeightCapacity?: number, WeightUomId?: number, Width?: number, Warehouse?: { Name?: string }, Type?: { Name?: string }, ParentLocationContainer?: { Name?: string }, Status?: { Name?: string }, DimensionsUom?: { Short_name?: string }, WeightUom?: { Short_name?: string }}

interface IFootPrintManager_locations_gridComponentInParams {
  warehouseId: number}


class FootPrintManager_locations_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_locations_gridComponent;
  entity: IFootPrintManager_locations_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    type_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    parent_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    status_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    eligible_for_allocation_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_loose_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    length_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    width_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    height_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    dimensions_uom_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    weight_capacity_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    weight_uom_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    child_footprint_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    child_stack_height_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    allocation_sequence_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    pick_sequence_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    putaway_sequence_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['name_edit'] as DatexFormControl, null, true, '', null)
      ),
    type: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['type_edit'] as DatexFormControl, 
        null, null,
        true, 
        '', null)
      ),
    parent: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['parent_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '', null)
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['status_edit'] as DatexFormControl, 
        null, null,
        false, 
        '', null)
      ),
    eligible_for_allocation: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['eligible_for_allocation_edit'] as DatexFormControl, 
        null, null,
        false, 
        '', null)
      ),
    is_loose: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['is_loose_edit'] as DatexFormControl, 
        null, null,
        true, 
        '', null)
      ),
    length: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['length_edit'] as DatexFormControl, null, false, '0.00', '', null)
      ),
    width: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['width_edit'] as DatexFormControl, null, false, '0.00', '', null)
      ),
    height: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['height_edit'] as DatexFormControl, null, false, '0.00', '', null)
      ),
    dimensions_uom: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['dimensions_uom_edit'] as DatexFormControl, 
        null, null,
        false, 
        '', null)
      ),
    weight_capacity: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['weight_capacity_edit'] as DatexFormControl, null, false, '0.00', '', null)
      ),
    weight_uom: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['weight_uom_edit'] as DatexFormControl, 
        null, null,
        false, 
        '', null)
      ),
    child_footprint: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['child_footprint_edit'] as DatexFormControl, null, false, '0', '', null)
      ),
    child_stack_height: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['child_stack_height_edit'] as DatexFormControl, null, false, '0', '', null)
      ),
    allocation_sequence: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['allocation_sequence_edit'] as DatexFormControl, null, false, '0', '', null)
      ),
    pick_sequence: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['pick_sequence_edit'] as DatexFormControl, null, false, '0', '', null)
      ),
    putaway_sequence: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['putaway_sequence_edit'] as DatexFormControl, null, false, '0', '', null)
      ),
  }

  get $fields_parent_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.warehouseId;
    
    return expr;
  }
  get $fields_dimensions_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = 1;
    
    return expr;
  }
  get $fields_weight_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = 3;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_locations_gridComponent, entity: IFootPrintManager_locations_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_locations_gridComponent, entity?: IFootPrintManager_locations_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      warehouseId:  $grid.inParams.warehouseId ,
      fullTextSearch:  $grid.fullTextSearch ,
      take:  null ,
      skip:  null ,
    };
    const data = await this.datasources.Locations.ds_locations_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.name.displayControl as TextModel).text = $row.entity?.Name;
    (this.cells.name.editControl as TextBoxModel).reset($row.entity?.Name);
    (this.cells.type.displayControl as TextModel).text = $row.entity.Type?.Name;
    (this.cells.type.editControl as SelectBoxModel).reset($row.entity.TypeId);
    (this.cells.parent.displayControl as TextModel).text = $row.entity.ParentLocationContainer?.Name;
    (this.cells.parent.editControl as SelectBoxModel).reset($row.entity.ParentId);
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status?.Name;
    (this.cells.status.editControl as SelectBoxModel).reset($row.entity.StatusId);
    (this.cells.eligible_for_allocation.displayControl as TextModel).text = ($row.entity.EligibleForAllocation == false ? 'N' : 'Y');
    (this.cells.eligible_for_allocation.editControl as SelectBoxModel).reset(($row.entity.EligibleForAllocation == false ? 'N' : 'Y'));
    (this.cells.is_loose.displayControl as TextModel).text = ($row.entity.IsLoose ? 'Y' : 'N');
    (this.cells.is_loose.editControl as SelectBoxModel).reset(($row.entity.IsLoose ? 'Y' : 'N'));
    (this.cells.length.displayControl as TextModel).text = $row.entity.Length?.toString();
    (this.cells.length.editControl as NumberBoxModel).reset($row.entity.Length);
    (this.cells.width.displayControl as TextModel).text = $row.entity.Width?.toString();
    (this.cells.width.editControl as NumberBoxModel).reset($row.entity.Width);
    (this.cells.height.displayControl as TextModel).text = $row.entity.Height?.toString();
    (this.cells.height.editControl as NumberBoxModel).reset($row.entity.Height);
    (this.cells.dimensions_uom.displayControl as TextModel).text = $row.entity.DimensionsUom?.Short_name;
    (this.cells.dimensions_uom.editControl as SelectBoxModel).reset($row.entity.DimensionUomId);
    (this.cells.weight_capacity.displayControl as TextModel).text = $row.entity.WeightCapacity?.toString();
    (this.cells.weight_capacity.editControl as NumberBoxModel).reset($row.entity.WeightCapacity);
    (this.cells.weight_uom.displayControl as TextModel).text = $row.entity.WeightUom?.Short_name;
    (this.cells.weight_uom.editControl as SelectBoxModel).reset($row.entity.WeightUomId);
    (this.cells.child_footprint.displayControl as TextModel).text = $row.entity.ChildFootprint?.toString();
    (this.cells.child_footprint.editControl as NumberBoxModel).reset($row.entity.ChildFootprint);
    (this.cells.child_stack_height.displayControl as TextModel).text = $row.entity.ChildStackHeight?.toString();
    (this.cells.child_stack_height.editControl as NumberBoxModel).reset($row.entity.ChildStackHeight);
    (this.cells.allocation_sequence.displayControl as TextModel).text = $row.entity.AllocationSequence?.toString();
    (this.cells.allocation_sequence.editControl as NumberBoxModel).reset($row.entity.AllocationSequence);
    (this.cells.pick_sequence.displayControl as TextModel).text = $row.entity.PickSequence?.toString();
    (this.cells.pick_sequence.editControl as NumberBoxModel).reset($row.entity.PickSequence);
    (this.cells.putaway_sequence.displayControl as TextModel).text = $row.entity.PutAwaySequence?.toString();
    (this.cells.putaway_sequence.editControl as NumberBoxModel).reset($row.entity.PutAwaySequence);

  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'type' : {
        this.on_save_existing_row();
        break;
      }
    }
  }

  //#region private flows
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootPrintManager_locations_gridComponentRowModel,
  $grid: FootPrintManager_locations_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Unlock fields
  $row.cells.type.editControl.readOnly = false;
  $row.cells.name.editControl.readOnly = false;
  $row.cells.is_loose.editControl.readOnly = false;
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootPrintManager_locations_gridComponentRowModel,
  $grid: FootPrintManager_locations_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Check required fields
  const allRequiredFieldsHaveValue = $utils.isAllDefined(
      $row.cells.parent.editControl.value,
      $row.cells.name.editControl.value,
      $row.cells.type.editControl.value
  );
  
  var errors = '';
  
  if (allRequiredFieldsHaveValue === false) {
      $shell.Locations.openErrorDialog('Save', 'Missing required fields');
      throw new Error('Missing required fields'); // To prevent display mode
  }
  else {
      try {
          var result = (await $flows.Locations.create_location_flow({
              warehouseId: $grid.inParams.warehouseId,
              parentId: $row.cells.parent.editControl.value,
              statusId: $row.cells.status.editControl.value,
              name: $row.cells.name.editControl.value,
              typeId: $row.cells.type.editControl.value,
              isLoose: $row.cells.is_loose.editControl.value == 'Y' ? true : false,
              eligibleForAllocation: $row.cells.eligible_for_allocation.editControl.value == 'Y' ? true : false,
              globalAllocationSequence: $row.cells.allocation_sequence.editControl.value,
              globalPickSequence: $row.cells.pick_sequence.editControl.value,
              globalPutAwaySequence: $row.cells.putaway_sequence.editControl.value,
              allocationSequence: $row.cells.allocation_sequence.editControl.value,
              pickSequence: $row.cells.pick_sequence.editControl.value,
              putAwaySequence: $row.cells.putaway_sequence.editControl.value,
              childFootprint: $row.cells.child_footprint.editControl.value,
              childStackHeight: $row.cells.child_stack_height.editControl.value,
              length: $row.cells.length.editControl.value,
              width: $row.cells.width.editControl.value,
              height: $row.cells.height.editControl.value,
              dimensionUomId: $row.cells.dimensions_uom.editControl.value,
              weightCapacity: $row.cells.weight_capacity.editControl.value,
              weightUomId: $row.cells.weight_uom.editControl.value,
              skipValidationRules: true,
          }));
          if (result.reasons.length) {
              throw new Error(result.reasons.join(''));
          }
  
          $row.refresh();
  
          // Lock fields
          $row.cells.type.editControl.readOnly = true;
          $row.cells.name.editControl.readOnly = true;
          $row.cells.parent.editControl.readOnly = true;
          $row.cells.is_loose.editControl.readOnly = true;
      }
      catch (error) {
          $shell.Locations.openErrorDialog('Save', 'Error on save');
          throw error; // To prevent display mode
      }
  }
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_locations_gridComponentRowModel,
  $grid: FootPrintManager_locations_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Construct payload with any values that have changed
  const payload : any = {};
  
  // General Properties
  if ($row.cells.name.editControl.isChanged) 
  {
      payload.Name = $row.cells.name.editControl.value;
  }
  if ($row.cells.status.editControl.isChanged) 
  {
      payload.StatusId = $row.cells.status.editControl.value;
  }
  if ($row.cells.parent.editControl.isChanged && $row.entity.ParentId) 
  {
      payload.ParentId = $row.cells.parent.editControl.value ?? $row.entity.ParentId;
  }
  if ($row.cells.eligible_for_allocation.editControl.isChanged) 
  {
      payload.EligibleForAllocation = $row.cells.eligible_for_allocation.editControl.value == 'N' ? false : true;
  }
  if ($row.cells.child_footprint.editControl.isChanged) 
  {
      payload.ChildFootprint = $row.cells.child_footprint.editControl.value;
  }
  if ($row.cells.child_stack_height.editControl.isChanged) 
  {
      payload.ChildStackHeight = $row.cells.child_stack_height.editControl.value;
  }
  // Dimensions
  if ($row.cells.length.editControl.isChanged) 
  {
      payload.Length = $row.cells.length.editControl.value;
  }
  if ($row.cells.width.editControl.isChanged) 
  {
      payload.Width = $row.cells.width.editControl.value;
  }
  if ($row.cells.height.editControl.isChanged) 
  {
      payload.Height = $row.cells.height.editControl.value;
  }
  if ($row.cells.dimensions_uom.editControl.isChanged) 
  {
      payload.DimensionUomId = $row.cells.dimensions_uom.editControl.value;
  }
  // Weight
  if ($row.cells.weight_capacity.editControl.isChanged) 
  {
      payload.WeightCapacity = $row.cells.weight_capacity.editControl.value;
  }
  if ($row.cells.weight_uom.editControl.isChanged) 
  {
      payload.WeightUomId = $row.cells.weight_uom.editControl.value;
  }
  // Sequences
  if ($row.cells.allocation_sequence.editControl.isChanged) 
  {
      payload.AllocationSequence = $row.cells.allocation_sequence.editControl.value;
  }
  if ($row.cells.pick_sequence.editControl.isChanged) 
  {
      payload.PickSequence = $row.cells.pick_sequence.editControl.value;
  }
  if ($row.cells.putaway_sequence.editControl.isChanged) 
  {
      payload.PutAwaySequence = $row.cells.putaway_sequence.editControl.value;
  }
  
  if (!$utils.isDefined(payload)) { return; }
  
  // Make API call
  try 
  {
      (await $flows.Utilities.crud_update_flow({
          entitySet: 'LocationContainers',
          id: $row.entity.Id,
          entity: payload
      }));
      
      await $row.refresh();
  
      // Lock fields
      $row.cells.type.editControl.readOnly = true;
      $row.cells.name.editControl.readOnly = true;
      $row.cells.is_loose.editControl.readOnly = true;
  }
  catch (error)
  {
      $shell.Locations.showErrorDetails('Save', 'Error on save.', error);
      throw error; // To prevent display mode
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Locations_location_type_dd_singleComponent),
    forwardRef(() => Locations_zones_dd_singleComponent),
    forwardRef(() => Locations_location_statuses_dd_singleComponent),
    forwardRef(() => Locations_yn_dd_singleComponent),
    forwardRef(() => Locations_measurement_units_dd_singleComponent),
  ],
  selector: 'FootPrintManager-locations_grid',
  templateUrl: './FootPrintManager.locations_grid.component.html'
})
export class FootPrintManager_locations_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_locations_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_locations_gridComponentInParams = { warehouseId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     name: new GridHeaderModel(new HeaderStyles(null, null), 'Name', true, false, null),       type: new GridHeaderModel(new HeaderStyles(null, null), 'Type', true, false, null),       parent: new GridHeaderModel(new HeaderStyles(null, null), 'Parent', false, false, null),       status: new GridHeaderModel(new HeaderStyles(null, null), 'Status', true, false, null),       eligible_for_allocation: new GridHeaderModel(new HeaderStyles(null, null), 'Eligible for allocation', false, false, null),       is_loose: new GridHeaderModel(new HeaderStyles(null, null), 'Is loose', false, false, null),       length: new GridHeaderModel(new HeaderStyles(null, null), 'Length', false, false, null),       width: new GridHeaderModel(new HeaderStyles(null, null), 'Width', false, false, null),       height: new GridHeaderModel(new HeaderStyles(null, null), 'Height', false, false, null),       dimensions_uom: new GridHeaderModel(new HeaderStyles(null, null), 'Dimensions UOM', false, false, null),       weight_capacity: new GridHeaderModel(new HeaderStyles(null, null), 'Weight capacity', false, false, null),       weight_uom: new GridHeaderModel(new HeaderStyles(null, null), 'Weight UOM', false, false, null),       child_footprint: new GridHeaderModel(new HeaderStyles(null, null), 'Pallet high', false, false, null),       child_stack_height: new GridHeaderModel(new HeaderStyles(null, null), 'Pallet tie', false, false, null),       allocation_sequence: new GridHeaderModel(new HeaderStyles(null, null), 'Allocation sequence', false, false, null),       pick_sequence: new GridHeaderModel(new HeaderStyles(null, null), 'Pick sequence', false, false, null),       putaway_sequence: new GridHeaderModel(new HeaderStyles(null, null), 'Putaway sequence', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_locations_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Locations';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.warehouseId)) {
        this.$missingRequiredInParams.push('warehouseId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 100;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      warehouseId:  $grid.inParams.warehouseId ,
      fullTextSearch:  $grid.fullTextSearch ,
      take:  null ,
      skip:  null ,
    };
    try {
    const data = await this.datasources.Locations.ds_locations_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_locations_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  async addRow(entity?: IFootPrintManager_locations_gridComponentEntity) {
    const row = new FootPrintManager_locations_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: FootPrintManager_locations_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  // Initialize
  var wb = $event;
  var transactions = [];
  const batchSize = 5000;
  
  var allData: any[] = [];
  
  try {
      do {
          var response = (await $datasources.Locations.ds_locations_grid.get({
              warehouseId: $grid.inParams.warehouseId,
              fullTextSearch: $grid.fullTextSearch,
              take: batchSize,
              skip: allData.length
          }));
  
          allData = allData.concat(response.result);
  
      } while (allData.length < response.totalCount)
  
      // Compile all data into JSON
      for (let record of allData) {
  
  
          transactions.push({
  
              "Name": record.Name,
              "Type": record.Type?.Name,
              "Parent": record.ParentLocationContainer?.Name,
              "Status": record.Status?.Name,
              "Eligible For Allocation": (record.EligibleForAllocation == false ? 'N' : 'Y'),
              "Is Loose": (record.IsLoose ? 'Y' : 'N'),
              "Length": record.Length?.toString(),
              "Width": record.Width?.toString(),
              "Height": record.Height?.toString(),
              "Dimensions Uom": record.DimensionsUom?.Short_name,
              "Weight Capacity": record.WeightCapacity?.toString(),
              "Weight Uom": record.WeightUom?.Short_name,
              "Pallet High": record.ChildFootprint?.toString(),
              "Pallet Tie": record.ChildStackHeight?.toString(),
              "Allocation Sequence": record.AllocationSequence?.toString(),
              "Pick Sequence": record.PickSequence?.toString(),
              "Putaway Sequence": record.PutAwaySequence?.toString()
          });
      }
  
      var ws = $utils.excel.json_to_sheet(transactions);
      $utils.excel.book_append_sheet(wb, ws, "Locations");
  
  
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      throw new Error(errorMessage);
  }
  
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_flow(wb);

    writeExcelFile(wb, 'FootPrintManager_locations_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
