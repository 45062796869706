import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class ExcelOrderImport_ds_get_orderaddress_by_type_and_orderIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { typeId: number, orderId: number }): 
  Promise<{ result: { Id?: number, City?: string, Line1?: string, Line2?: string, Name?: string, Orderid?: number, PostalCode?: string, State?: string, TypeId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.typeId)) {
      missingRequiredInParams.push('\'typeId\'');
    }
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelOrderImport/datasources/ds_get_orderaddress_by_type_and_orderId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { typeId: number, orderId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, City?: string, Line1?: string, Line2?: string, Name?: string, Orderid?: number, PostalCode?: string, State?: string, TypeId?: number }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.typeId)) {
      missingRequiredInParams.push('\'typeId\'');
    }
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelOrderImport/datasources/ds_get_orderaddress_by_type_and_orderId/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { typeId: number, orderId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, City?: string, Line1?: string, Line2?: string, Name?: string, Orderid?: number, PostalCode?: string, State?: string, TypeId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.typeId)) {
      missingRequiredInParams.push('\'typeId\'');
    }
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelOrderImport/datasources/ds_get_orderaddress_by_type_and_orderId/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      typeId: number, orderId: number    }) {
        if(isNil(inParams.typeId)) {
          return true; 
        }
        if(isNil(inParams.orderId)) {
          return true; 
        }
      return false;
    }
}
