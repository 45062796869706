import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Waves_ds_get_wave_by_wave_idService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { waveId: number }): 
  Promise<{ result: { Id?: number, BatchPick?: boolean, Description?: string, Notes?: string, ReleaseDate?: string, StagingLocationId?: number, Status?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }, Priority?: { Id?: number, Description?: string, Name?: string, Value?: number } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.waveId)) {
      missingRequiredInParams.push('\'waveId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Waves/datasources/ds_get_wave_by_wave_id/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      waveId: number    }) {
        if(isNil(inParams.waveId)) {
          return true; 
        }
      return false;
    }
}
