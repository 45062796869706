import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_ds_find_material_packagingsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { searchProperties: { packagingId?: number, packagingName?: string, materialId?: number, packagingShortName?: string }[] }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, BasePackagingQuantity?: number, Packaging?: { Name?: string, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.searchProperties)) {
      missingRequiredInParams.push('\'searchProperties\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_find_material_packagings/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { searchProperties: { packagingId?: number, packagingName?: string, materialId?: number, packagingShortName?: string }[], $top?: number, $skip?: number }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, BasePackagingQuantity?: number, Packaging?: { Name?: string, ShortName?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.searchProperties)) {
      missingRequiredInParams.push('\'searchProperties\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_find_material_packagings/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { searchProperties: { packagingId?: number, packagingName?: string, materialId?: number, packagingShortName?: string }[], $keys: { MaterialId?: number, PackagingId?: number }[] }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, BasePackagingQuantity?: number, Packaging?: { Name?: string, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.searchProperties)) {
      missingRequiredInParams.push('\'searchProperties\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintApiManager/datasources/ds_find_material_packagings/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      searchProperties: { packagingId?: number, packagingName?: string, materialId?: number, packagingShortName?: string }[]    }) {
        if(isNil(inParams.searchProperties)) {
          return true; 
        }
      return false;
    }
}
