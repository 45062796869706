import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_storage_categories_gridComponent } from './FootPrintManager.storage_categories_grid.component';
import { FootPrintManager_storage_category_rules_gridComponent } from './FootPrintManager.storage_category_rules_grid.component';
import { FootPrintManager_temperature_capture_reading_points_gridComponent } from './FootPrintManager.temperature_capture_reading_points_grid.component';
import { FootPrintManager_temperature_capture_reading_types_gridComponent } from './FootPrintManager.temperature_capture_reading_types_grid.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_storage_categories_gridComponent),
    forwardRef(() => FootPrintManager_storage_category_rules_gridComponent),
    forwardRef(() => FootPrintManager_temperature_capture_reading_points_gridComponent),
    forwardRef(() => FootPrintManager_temperature_capture_reading_types_gridComponent),
  ],
  selector: 'FootPrintManager-configurations_storage_categories_hub',
  templateUrl: './FootPrintManager.configurations_storage_categories_hub.component.html'
})
export class FootPrintManager_configurations_storage_categories_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
  });
  
  toolbar = {
  };

  actionbar = {
  };

 filters = {
  };


  filtersets = {
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      storage_categories: new TabItemModel(
        this.rootTabGroup, 
        'Storage categories', 
        false,
        ),
      storage_category_rules: new TabItemModel(
        this.rootTabGroup, 
        'Storage rules', 
        false,
        ),
      temperature_capture_reading_points: new TabItemModel(
        this.rootTabGroup, 
        'Temperature points', 
        false,
        ),
      temperature_capture_reading_types: new TabItemModel(
        this.rootTabGroup, 
        'Temperature types', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_storage_categories', { read: FootPrintManager_storage_categories_gridComponent }) $tabs_storage_categories: FootPrintManager_storage_categories_gridComponent;
      @ViewChild('$tabs_storage_category_rules', { read: FootPrintManager_storage_category_rules_gridComponent }) $tabs_storage_category_rules: FootPrintManager_storage_category_rules_gridComponent;
      @ViewChild('$tabs_temperature_capture_reading_points', { read: FootPrintManager_temperature_capture_reading_points_gridComponent }) $tabs_temperature_capture_reading_points: FootPrintManager_temperature_capture_reading_points_gridComponent;
      @ViewChild('$tabs_temperature_capture_reading_types', { read: FootPrintManager_temperature_capture_reading_types_gridComponent }) $tabs_temperature_capture_reading_types: FootPrintManager_temperature_capture_reading_types_gridComponent;
    //#endregion tabs children


  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Temperature and storage`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Configuration options for temperature capture and storage`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.storage_categories,
      this.tabs.storage_category_rules,
      this.tabs.temperature_capture_reading_points,
      this.tabs.temperature_capture_reading_types,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Storage Configurations';
    
    const $hub = this;
    const $utils = this.utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_storage_categories') {
      if (!isNil(this.$tabs_storage_categories) && !this.tabs.storage_categories.hidden) {
        this.$tabs_storage_categories.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_storage_category_rules') {
      if (!isNil(this.$tabs_storage_category_rules) && !this.tabs.storage_category_rules.hidden) {
        this.$tabs_storage_category_rules.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_temperature_capture_reading_points') {
      if (!isNil(this.$tabs_temperature_capture_reading_points) && !this.tabs.temperature_capture_reading_points.hidden) {
        this.$tabs_temperature_capture_reading_points.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_temperature_capture_reading_types') {
      if (!isNil(this.$tabs_temperature_capture_reading_types) && !this.tabs.temperature_capture_reading_types.hidden) {
        this.$tabs_temperature_capture_reading_types.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_configurations_storage_categories_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'configurations_storage_categories_hub.on_init');
  $hub.title = 'Temperature and storage'
  }
  //#endregion private flows
}
