import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Lots_create_lotService } from './Lots.flow.index';
import { Lots_create_lot_and_vendor_lotService } from './Lots.flow.index';
import { Lots_create_vendor_lotService } from './Lots.flow.index';
import { Lots_delete_lotService } from './Lots.flow.index';
import { Lots_is_lot_deletableService } from './Lots.flow.index';
import { Lots_recommend_lot_creationService } from './Lots.flow.index';
import { Lots_update_lot_statusService } from './Lots.flow.index';

import { $frontendTypes } from './Lots.frontend.types'

@Injectable({ providedIn: 'root' })
export class Lots_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Lots: Lots_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _create_lot: Lots_create_lotService;
  public async create_lot(inParams: { vendorLotId: number, lookupCode: string, statusId: number }): Promise< { lotId?: number }> {
    if(!this._create_lot) { 
      this._create_lot = this.injector.get(Lots_create_lotService);
    }
    return this._create_lot.run(inParams);
  }
   
   

   
 
  private _create_lot_and_vendor_lot: Lots_create_lot_and_vendor_lotService;
  public async create_lot_and_vendor_lot(inParams: { materialId?: number, lotLookupCode?: string, vendorLotLookupCode?: string, lotStatusId?: number, expirationDate?: string, manufactureDate?: string }): Promise< { LotId?: number, VendorLotId?: number }> {
    if(!this._create_lot_and_vendor_lot) { 
      this._create_lot_and_vendor_lot = this.injector.get(Lots_create_lot_and_vendor_lotService);
    }
    return this._create_lot_and_vendor_lot.run(inParams);
  }
   
   

   
 
  private _create_vendor_lot: Lots_create_vendor_lotService;
  public async create_vendor_lot(inParams: { materialId: number, lookupCode: string, manufactureDate?: string, expirationDate?: string }): Promise< { vendorLotId?: number }> {
    if(!this._create_vendor_lot) { 
      this._create_vendor_lot = this.injector.get(Lots_create_vendor_lotService);
    }
    return this._create_vendor_lot.run(inParams);
  }
   
   

   
 
  private _delete_lot: Lots_delete_lotService;
  public async delete_lot(inParams: { lotId?: number, vendorLotId?: number }): Promise< { reason?: string, lotId?: number, vendorLotId?: number }> {
    if(!this._delete_lot) { 
      this._delete_lot = this.injector.get(Lots_delete_lotService);
    }
    return this._delete_lot.run(inParams);
  }
   
   

   
 
  private _is_lot_deletable: Lots_is_lot_deletableService;
  public async is_lot_deletable(inParams: { lotIds?: number[], vendorLotIds?: number[] }): Promise< { lotIds?: number[], vendorLotIds?: number[], exceptions?: { type?: string, id?: number, reason?: string }[] }> {
    if(!this._is_lot_deletable) { 
      this._is_lot_deletable = this.injector.get(Lots_is_lot_deletableService);
    }
    return this._is_lot_deletable.run(inParams);
  }
   
   

   
 
  private _recommend_lot_creation: Lots_recommend_lot_creationService;
  public async recommend_lot_creation(inParams: { vendorLotId?: number, lotLookupCode?: string, vendorLotLookupCode?: string, materialId?: number, recommendVendorLotOnly: boolean, optimalInputs?: { shelfLifeSpan?: number }, controllerTypeId?: number }): Promise< { lotId?: number, lotLookupCode?: string, vendorLotId?: number, vendorLotLookupCode?: string, vat?: string, errors?: string[], manufactureDate?: string, expirationDate?: string }> {
    if(!this._recommend_lot_creation) { 
      this._recommend_lot_creation = this.injector.get(Lots_recommend_lot_creationService);
    }
    return this._recommend_lot_creation.run(inParams);
  }
   
   

   
 
  private _update_lot_status: Lots_update_lot_statusService;
  public async update_lot_status(inParams: { lotId: number, statusId: number, reasonCodeIds?: number[] }): Promise< { lotId?: number, statusId?: number, reason?: string }> {
    if(!this._update_lot_status) { 
      this._update_lot_status = this.injector.get(Lots_update_lot_statusService);
    }
    return this._update_lot_status.run(inParams);
  }
   
   

   
}
