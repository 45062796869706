import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class InventoryCounts_ds_count_accuracy_widgetService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { warehouseIds?: number[], statusIds?: number[] }): Promise<{ result?: { accuracyPercentage?: number } }> {
    let url = `${environment.backendUrl}api/InventoryCounts/datasources/ds_count_accuracy_widget/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      warehouseIds?: number[], statusIds?: number[]    }) {
      return false;
    }
}
