import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_locations_navigational_gridComponent } from './FootPrintManager.locations_navigational_grid.component';
import { FootPrintManager_locations_gridComponent } from './FootPrintManager.locations_grid.component';
import { FootPrintManager_inventory_location_gridComponent } from './FootPrintManager.inventory_location_grid.component';
import { FootPrintManager_locations_replenishments_gridComponent } from './FootPrintManager.locations_replenishments_grid.component';
import { FootPrintManager_contact_addresses_gridComponent } from './FootPrintManager.contact_addresses_grid.component';
import { Locations_locations_empty_by_count_widgetComponent } from './Locations.locations_empty_by_count_widget.component';
import { Locations_locations_by_count_widgetComponent } from './Locations.locations_by_count_widget.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_locations_navigational_gridComponent),
    forwardRef(() => FootPrintManager_locations_gridComponent),
    forwardRef(() => FootPrintManager_inventory_location_gridComponent),
    forwardRef(() => FootPrintManager_locations_replenishments_gridComponent),
    forwardRef(() => FootPrintManager_contact_addresses_gridComponent),
    forwardRef(() => Locations_locations_empty_by_count_widgetComponent),
    forwardRef(() => Locations_locations_by_count_widgetComponent),
  ],
  selector: 'FootPrintManager-single_warehouse_hub',
  templateUrl: './FootPrintManager.single_warehouse_hub.component.html'
})
export class FootPrintManager_single_warehouse_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { warehouseId: number } = { warehouseId: null };
  //#region Inputs
  @Input('warehouseId') set $inParams_warehouseId(v: number) {
    this.inParams.warehouseId = v;
  }
  get $inParams_warehouseId(): number {
    return this.inParams.warehouseId;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { hubTitle?: string, hubDescription?: string } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
  });
  
  toolbar = {
      edit: new ToolModel(new ButtonModel('edit', new ButtonStyles(null, null), false, false, 'Edit', 'ms-Icon ms-Icon--Edit', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      create_location: new ToolModel(new ButtonModel('create_location', new ButtonStyles(null, null), false, false, 'Create location', 'ms-Icon ms-Icon--Add', null)
    , false)
  };

  actionbar = {
  };

 filters = {
  };


  filtersets = {
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      locations: new TabItemModel(
        this.rootTabGroup, 
        'Locations', 
        false,
        ),
      locations_detail: new TabItemModel(
        this.rootTabGroup, 
        'Locations detail', 
        false,
        ),
      inventory: new TabItemModel(
        this.rootTabGroup, 
        'Inventory', 
        false,
        ),
      replenishments: new TabItemModel(
        this.rootTabGroup, 
        'Replenishments', 
        false,
        ),
      contacts: new TabItemModel(
        this.rootTabGroup, 
        'Contacts', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_locations_locations_navigational_grid_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.warehouseId;
      
      return expr;
    }
  
    get $tabs_locations_detail_locations_grid_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.warehouseId;
      
      return expr;
    }
  
    get $tabs_inventory_inventory_location_grid_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.warehouseId;
      
      return expr;
    }
  
    cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_warehouseIds: number[];
    get $tabs_replenishments_locations_replenishments_grid_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = [$hub.inParams.warehouseId];
      
      if(!isEqual(this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_warehouseIds;
    }
  
    get $tabs_contacts_contact_addresses_grid_inParams_entity(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = 'Warehouse';
      
      return expr;
    }
  
    get $tabs_contacts_contact_addresses_grid_inParams_entityId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.warehouseId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_locations', { read: FootPrintManager_locations_navigational_gridComponent }) $tabs_locations: FootPrintManager_locations_navigational_gridComponent;
      @ViewChild('$tabs_locations_detail', { read: FootPrintManager_locations_gridComponent }) $tabs_locations_detail: FootPrintManager_locations_gridComponent;
      @ViewChild('$tabs_inventory', { read: FootPrintManager_inventory_location_gridComponent }) $tabs_inventory: FootPrintManager_inventory_location_gridComponent;
      @ViewChild('$tabs_replenishments', { read: FootPrintManager_locations_replenishments_gridComponent }) $tabs_replenishments: FootPrintManager_locations_replenishments_gridComponent;
      @ViewChild('$tabs_contacts', { read: FootPrintManager_contact_addresses_gridComponent }) $tabs_contacts: FootPrintManager_contact_addresses_gridComponent;
    //#endregion tabs children
    widgets = {
      locations_empty_by_count_widget: new WidgetModel(false),
      locations_by_count_widget: new WidgetModel(false),
    };
  
    //#region widgets inParams
    get $widgets_locations_empty_by_count_widget_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.warehouseId;
      
      return expr;
    }
  
    get $widgets_locations_by_count_widget_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.warehouseId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_locations_empty_by_count_widget', { read:  Locations_locations_empty_by_count_widgetComponent }) $widgets_locations_empty_by_count_widget: Locations_locations_empty_by_count_widgetComponent;
      @ViewChild('$widgets_locations_by_count_widget', { read:  Locations_locations_by_count_widgetComponent }) $widgets_locations_by_count_widget: Locations_locations_by_count_widgetComponent;
    //#endregion widgets children


  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubTitle;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubDescription;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.locations,
      this.tabs.locations_detail,
      this.tabs.inventory,
      this.tabs.replenishments,
      this.tabs.contacts,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.warehouseId)) {
        this.$missingRequiredInParams.push('warehouseId');
      }
  }



  initialized = false;

  async $init() {
    this.title = 'Single Warehouse Hub';
    
    const $hub = this;
    const $utils = this.utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_locations_empty_by_count_widget') {
      if (!isNil(this.$widgets_locations_empty_by_count_widget) && !this.widgets.locations_empty_by_count_widget.hidden) {
        this.$widgets_locations_empty_by_count_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_locations_by_count_widget') {
      if (!isNil(this.$widgets_locations_by_count_widget) && !this.widgets.locations_by_count_widget.hidden) {
        this.$widgets_locations_by_count_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_locations') {
      if (!isNil(this.$tabs_locations) && !this.tabs.locations.hidden) {
        this.$tabs_locations.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_locations_detail') {
      if (!isNil(this.$tabs_locations_detail) && !this.tabs.locations_detail.hidden) {
        this.$tabs_locations_detail.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_inventory') {
      if (!isNil(this.$tabs_inventory) && !this.tabs.inventory.hidden) {
        this.$tabs_inventory.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_replenishments') {
      if (!isNil(this.$tabs_replenishments) && !this.tabs.replenishments.hidden) {
        this.$tabs_replenishments.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_contacts') {
      if (!isNil(this.$tabs_contacts) && !this.tabs.contacts.hidden) {
        this.$tabs_contacts.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_edit_clicked(event = null) {
    return this.on_edit_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_clickedInternal(
    $hub: FootPrintManager_single_warehouse_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  const dialogResult = await $shell.FootPrintManager.openwarehouse_editorDialog({warehouseId: $hub.inParams.warehouseId},'flyout');
  const userConfirmed = dialogResult.confirm;
  
   if (userConfirmed) {
  
       await $hub.on_init();
       await $hub.refresh();
   }
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_single_warehouse_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const warehouse = (await $datasources.Locations.ds_get_warehouse_by_warehouseId.get({ warehouseId: $hub.inParams.warehouseId })).result;
  if ($utils.isDefined(warehouse)) {
  
      $hub.title = `Warehouse ${warehouse.Name}`;
  
      const title = ` ${warehouse.Name}`;
  
      if ($utils.isDefined(warehouse.Notes)) {
          const description = `${warehouse.TimeZoneId}   ${warehouse.Notes}  `;
          $hub.vars.hubDescription = description;
      }
  
      $hub.vars.hubTitle = title;
  
  
  }
  }
  on_create_location_clicked(event = null) {
    return this.on_create_location_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_location_clickedInternal(
    $hub: FootPrintManager_single_warehouse_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const dialogResult = await $shell.FootPrintManager.openlocation_creation_formDialog({
      warehouseId: $hub.inParams.warehouseId
  }, 'flyout'
  );
  const userConfirmed = dialogResult.confirm;
  
  if (userConfirmed) {
      $hub.refresh()
  }
  
  
  }
  //#endregion private flows
}
