<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"


>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="create_shipment" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_new_outbound_shipment($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator1" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="cross_dock" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_cross_dock($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="auto_load" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_auto_load_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="complete" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_complete($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator2" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="on_delete" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator3" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="surveys" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_surveys_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="print" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button 
                    data-cy="splitbutton"
                    class="datex-button splitbutton" 
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matMenuTriggerFor]="dropdownMenuOne"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip">
              <div class="button-label">
                <div *ngIf="tool.control.icon"
                      class="button-icon">
                  <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                </div>
                <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                <div class="button-icon splitbutton-drop-icon">
                  <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                </div>
              </div>
            </button>
            <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
              <div data-cy="splitbutton-options">
                <div *ngIf="!tool.control.buttons.license_plate_labels.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.license_plate_labels.readOnly"
                          [ngStyle]="tool.control.buttons.license_plate_labels.styles.style"
                          [ngClass]="tool.control.buttons.license_plate_labels.styles.classes"
                          [matTooltip]="tool.control.buttons.license_plate_labels.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_so_print_labels($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.license_plate_labels.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.license_plate_labels.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.license_plate_labels.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.print_bill_of_lading.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.print_bill_of_lading.readOnly"
                          [ngStyle]="tool.control.buttons.print_bill_of_lading.styles.style"
                          [ngClass]="tool.control.buttons.print_bill_of_lading.styles.classes"
                          [matTooltip]="tool.control.buttons.print_bill_of_lading.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_bill_of_lading($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.print_bill_of_lading.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.print_bill_of_lading.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.print_bill_of_lading.label}}</div>
                    </div>
                  </button>
                </div>
              </div>
            </mat-menu>
            </ng-container>
          </ng-template>
      </app-toolbar>
  </ng-container>


  <ng-container gridColumnDef="lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.lookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.lookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="state">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.state.displayControl.styles.style"
          [ngClass]="row.cells.state.displayControl.styles.classes"
          [matTooltip]="row.cells.state.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.state.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="licenseplate_count">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.licenseplate_count.displayControl.styles.style"
          [ngClass]="row.cells.licenseplate_count.displayControl.styles.classes"
          [matTooltip]="row.cells.licenseplate_count.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.licenseplate_count.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="load_container">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.load_container.displayControl.styles.style"
          [ngClass]="row.cells.load_container.displayControl.styles.classes"
          [matTooltip]="row.cells.load_container.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.load_container.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="appointment">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.appointment.displayControl.styles.style"
          [ngClass]="row.cells.appointment.displayControl.styles.classes"
          [matTooltip]="row.cells.appointment.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.appointment.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="pickup_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.pickup_date.displayControl.styles.style"
          [ngClass]="row.cells.pickup_date.displayControl.styles.classes"
          [matTooltip]="row.cells.pickup_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.pickup_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <app-datebox 
        data-cy="dateBox"
        [formControl]="row.formGroup.controls['pickup_date_edit']"
        [format]="row.cells.pickup_date.editControl.format"
        [mode]="row.cells.pickup_date.editControl.mode"
        [ngStyle]="row.cells.pickup_date.editControl.styles.style"
        [ngClass]="row.cells.pickup_date.editControl.styles.classes"
        [tooltip]="row.cells.pickup_date.editControl.tooltip">
    </app-datebox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="available_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.available_date.displayControl.styles.style"
          [ngClass]="row.cells.available_date.displayControl.styles.classes"
          [matTooltip]="row.cells.available_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.available_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <app-datebox 
        data-cy="dateBox"
        [formControl]="row.formGroup.controls['available_date_edit']"
        [format]="row.cells.available_date.editControl.format"
        [mode]="row.cells.available_date.editControl.mode"
        [ngStyle]="row.cells.available_date.editControl.styles.style"
        [ngClass]="row.cells.available_date.editControl.styles.classes"
        [tooltip]="row.cells.available_date.editControl.tooltip">
    </app-datebox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="expected_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.expected_date.displayControl.styles.style"
          [ngClass]="row.cells.expected_date.displayControl.styles.classes"
          [matTooltip]="row.cells.expected_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.expected_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <app-datebox 
        data-cy="dateBox"
        [formControl]="row.formGroup.controls['expected_date_edit']"
        [format]="row.cells.expected_date.editControl.format"
        [mode]="row.cells.expected_date.editControl.mode"
        [ngStyle]="row.cells.expected_date.editControl.styles.style"
        [ngClass]="row.cells.expected_date.editControl.styles.classes"
        [tooltip]="row.cells.expected_date.editControl.tooltip">
    </app-datebox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="bill_of_lading">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.bill_of_lading.displayControl.styles.style"
          [ngClass]="row.cells.bill_of_lading.displayControl.styles.classes"
          [matTooltip]="row.cells.bill_of_lading.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.bill_of_lading.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['bill_of_lading_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.bill_of_lading.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.bill_of_lading.editControl.placeholder}}"
            [ngStyle]="row.cells.bill_of_lading.editControl.styles.style"
            [ngClass]="row.cells.bill_of_lading.editControl.styles.classes"
            [matTooltip]="row.cells.bill_of_lading.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="reference_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.reference_number.displayControl.styles.style"
          [ngClass]="row.cells.reference_number.displayControl.styles.classes"
          [matTooltip]="row.cells.reference_number.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.reference_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['reference_number_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.reference_number.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.reference_number.editControl.placeholder}}"
            [ngStyle]="row.cells.reference_number.editControl.styles.style"
            [ngClass]="row.cells.reference_number.editControl.styles.classes"
            [matTooltip]="row.cells.reference_number.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="owner_reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.owner_reference.displayControl.styles.style"
          [ngClass]="row.cells.owner_reference.displayControl.styles.classes"
          [matTooltip]="row.cells.owner_reference.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.owner_reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['owner_reference_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.owner_reference.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.owner_reference.editControl.placeholder}}"
            [ngStyle]="row.cells.owner_reference.editControl.styles.style"
            [ngClass]="row.cells.owner_reference.editControl.styles.classes"
            [matTooltip]="row.cells.owner_reference.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendor_reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.vendor_reference.displayControl.styles.style"
          [ngClass]="row.cells.vendor_reference.displayControl.styles.classes"
          [matTooltip]="row.cells.vendor_reference.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.vendor_reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['vendor_reference_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.vendor_reference.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.vendor_reference.editControl.placeholder}}"
            [ngStyle]="row.cells.vendor_reference.editControl.styles.style"
            [ngClass]="row.cells.vendor_reference.editControl.styles.classes"
            [matTooltip]="row.cells.vendor_reference.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="booking_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.booking_number.displayControl.styles.style"
          [ngClass]="row.cells.booking_number.displayControl.styles.classes"
          [matTooltip]="row.cells.booking_number.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.booking_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['booking_number_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.booking_number.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.booking_number.editControl.placeholder}}"
            [ngStyle]="row.cells.booking_number.editControl.styles.style"
            [ngClass]="row.cells.booking_number.editControl.styles.classes"
            [matTooltip]="row.cells.booking_number.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="broker_reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.broker_reference.displayControl.styles.style"
          [ngClass]="row.cells.broker_reference.displayControl.styles.classes"
          [matTooltip]="row.cells.broker_reference.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.broker_reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['broker_reference_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.broker_reference.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.broker_reference.editControl.placeholder}}"
            [ngStyle]="row.cells.broker_reference.editControl.styles.style"
            [ngClass]="row.cells.broker_reference.editControl.styles.classes"
            [matTooltip]="row.cells.broker_reference.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="trailer_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.trailer_id.displayControl.styles.style"
          [ngClass]="row.cells.trailer_id.displayControl.styles.classes"
          [matTooltip]="row.cells.trailer_id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.trailer_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['trailer_id_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.trailer_id.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.trailer_id.editControl.placeholder}}"
            [ngStyle]="row.cells.trailer_id.editControl.styles.style"
            [ngClass]="row.cells.trailer_id.editControl.styles.classes"
            [matTooltip]="row.cells.trailer_id.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="carrier">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.carrier.displayControl.styles.style"
          [ngClass]="row.cells.carrier.displayControl.styles.classes"
          [matTooltip]="row.cells.carrier.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.carrier.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Carriers-carriers_ownerscarriers_dd_single 
        data-cy="selector"
        [type]="row.cells.carrier.editControl.type"
        [formControl]="row.formGroup.controls['carrier_edit']"
        (displayTextChange)="row.cells.carrier.editControl.displayText=$event"
        [placeholder]="row.cells.carrier.editControl.placeholder"
        [styles]="row.cells.carrier.editControl.styles"
        [tooltip]="row.cells.carrier.editControl.tooltip"
      [ownerId]="row.$fields_carrier_selector_inParams_ownerId"
    >
    </Carriers-carriers_ownerscarriers_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="carrier_service">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.carrier_service.displayControl.styles.style"
          [ngClass]="row.cells.carrier_service.displayControl.styles.classes"
          [matTooltip]="row.cells.carrier_service.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.carrier_service.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Carriers-carrierservicetype_dd_single 
        data-cy="selector"
        [type]="row.cells.carrier_service.editControl.type"
        [formControl]="row.formGroup.controls['carrier_service_edit']"
        (displayTextChange)="row.cells.carrier_service.editControl.displayText=$event"
        [placeholder]="row.cells.carrier_service.editControl.placeholder"
        [styles]="row.cells.carrier_service.editControl.styles"
        [tooltip]="row.cells.carrier_service.editControl.tooltip"
      [carrierId]="row.$fields_carrier_service_selector_inParams_carrierId"
    >
    </Carriers-carrierservicetype_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes"
          [matTooltip]="row.cells.notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['notes_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.notes.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.notes.editControl.placeholder}}"
            [ngStyle]="row.cells.notes.editControl.styles.style"
            [ngClass]="row.cells.notes.editControl.styles.classes"
            [matTooltip]="row.cells.notes.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
