import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class PurchaseOrders_ds_open_putaway_reportService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { startDate?: string, endDate?: string, ownerId?: number, projectId?: number, warehouseId?: number[] }): 
  Promise<{ result: { Id?: number, ActualSourceLicensePlateId?: number, CreatedSysDateTime?: string, ShipmentId?: number, WarehouseId?: number, Warehouse?: { Name?: string }, shipment?: { OrderId?: number, ShipmentId?: number, Order?: { LookupCode?: string }, Shipment?: { LookupCode?: string } }, inventory?: { LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, LicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, Lot?: { Id?: number, Material?: { Description?: string, LookupCode?: string } } } }[] }> 
  {
    let url = `${environment.backendUrl}api/PurchaseOrders/datasources/ds_open_putaway_report/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { startDate?: string, endDate?: string, ownerId?: number, projectId?: number, warehouseId?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualSourceLicensePlateId?: number, CreatedSysDateTime?: string, ShipmentId?: number, WarehouseId?: number, Warehouse?: { Name?: string }, shipment?: { OrderId?: number, ShipmentId?: number, Order?: { LookupCode?: string }, Shipment?: { LookupCode?: string } }, inventory?: { LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, LicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, Lot?: { Id?: number, Material?: { Description?: string, LookupCode?: string } } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/PurchaseOrders/datasources/ds_open_putaway_report/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { startDate?: string, endDate?: string, ownerId?: number, projectId?: number, warehouseId?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualSourceLicensePlateId?: number, CreatedSysDateTime?: string, ShipmentId?: number, WarehouseId?: number, Warehouse?: { Name?: string }, shipment?: { OrderId?: number, ShipmentId?: number, Order?: { LookupCode?: string }, Shipment?: { LookupCode?: string } }, inventory?: { LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, LicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, Lot?: { Id?: number, Material?: { Description?: string, LookupCode?: string } } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PurchaseOrders/datasources/ds_open_putaway_report/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      startDate?: string, endDate?: string, ownerId?: number, projectId?: number, warehouseId?: number[]    }) {
      return false;
    }
}
