import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



interface IFootPrintManager_owners_navigation_gridComponentEntity {
Id?: number, LookupCode?: string, Name?: string, Notes?: string, Status?: { Label?: string }, LicenseplateCount?: { TotalLicenseplates?: number, Lot?: { Material?: { Project?: { OwnerId?: number } } } }, ProjectCount?: { TotalProjects?: number, Owner?: { Id?: number } }, MaterialCount?: { TotalMaterials?: number, Project?: { Owner?: { Id?: number } } }}

interface IFootPrintManager_owners_navigation_gridComponentInParams {
  ownerStatusId?: number}


class FootPrintManager_owners_navigation_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_owners_navigation_gridComponent;
  entity: IFootPrintManager_owners_navigation_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    lookupcode: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    projects: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    materials: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    licenseplate_count: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_owners_navigation_gridComponent, entity: IFootPrintManager_owners_navigation_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_owners_navigation_gridComponent, entity?: IFootPrintManager_owners_navigation_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      ownerStatusId:  $grid.inParams.ownerStatusId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.Owners.ds_owners_navigation_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.projects.displayControl as TextModel).text = $utils.isDefined($row.entity.ProjectCount?.TotalProjects) ? $row.entity.ProjectCount?.TotalProjects?.toString() : '0' ;
    (this.cells.materials.displayControl as TextModel).text = $utils.isDefined($row.entity.MaterialCount?.TotalMaterials) ? $row.entity.MaterialCount?.TotalMaterials?.toString() : '0' ;
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status?.Label;
    (this.cells.licenseplate_count.displayControl as TextModel).text = $utils.isDefined($row.entity.LicenseplateCount?.TotalLicenseplates) ? $row.entity.LicenseplateCount?.TotalLicenseplates?.toString() : '0' ;
    (this.cells.name.displayControl as TextModel).text = $row.entity.Name;
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'lookupcode' : {
        this.on_lookupcode_clicked();
        break;
      }
      case 'materials' : {
        this.on_materials_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_lookupcode_clicked(event = null) {
    return this.on_lookupcode_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lookupcode_clickedInternal(
    $row: FootPrintManager_owners_navigation_gridComponentRowModel,
  $grid: FootPrintManager_owners_navigation_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootPrintManager.opensingle_owner_hub({ownerId: $row.entity.Id});
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_owners_navigation_gridComponentRowModel,
  $grid: FootPrintManager_owners_navigation_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const ownerId = $row.entity.Id;
  
  // Set projects cell with total count
  // var projectCount;
  // const projects = (await $datasources.Owners.ds_get_projects_by_ownerId.get({ownerId: ownerId})).result;
  // if ($utils.isDefined(projects)){
  
  //     projectCount = projects.length;
  // }else{
  //     projectCount = 0
  // }
  // var projectLabel = `(${projectCount})`
  // $row.cells.projects.displayControl.text = projectLabel;
  
  // // Set materials cell with total count
  // var materialCount;
  // const materials = (await $datasources.Materials.ds_get_materials_by_ownerId.get({ownerId: ownerId})).result;
  // if ($utils.isDefined(materials)){
  
  //     materialCount = materials.length;
  // }else{
  //     materialCount = 0
  // }
  // var materialLabel = `(${materialCount})`
  // $row.cells.materials.displayControl.text = materialLabel;
  
  }
  on_materials_clicked(event = null) {
    return this.on_materials_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_materials_clickedInternal(
    $row: FootPrintManager_owners_navigation_gridComponentRowModel,
  $grid: FootPrintManager_owners_navigation_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  $shell.FootPrintManager.openmaterials_by_base_packaging_grid({ownerId: $row.entity.Id});
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-owners_navigation_grid',
  templateUrl: './FootPrintManager.owners_navigation_grid.component.html'
})
export class FootPrintManager_owners_navigation_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_owners_navigation_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_owners_navigation_gridComponentInParams = { ownerStatusId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Owner', false, false, null),       projects: new GridHeaderModel(new HeaderStyles(null, null), 'Projects', false, false, null),       materials: new GridHeaderModel(new HeaderStyles(null, null), 'Materials', false, false, null),       status: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       licenseplate_count: new GridHeaderModel(new HeaderStyles(null, null), 'LP count', false, false, null),       name: new GridHeaderModel(new HeaderStyles(null, null), 'Name', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_owners_navigation_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('ownerStatusId') set $inParams_ownerStatusId(value: any) {
    this.inParams['ownerStatusId'] = value;
  }
  get $inParams_ownerStatusId(): any {
    return this.inParams['ownerStatusId'] ;
  }

  topToolbar = {
      update: new ToolModel(new ButtonModel('update', new ButtonStyles(null, null), false, false, 'Update', 'icon-ic_fluent_edit_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Owners';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 25;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      ownerStatusId:  $grid.inParams.ownerStatusId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
    const data = await this.datasources.Owners.ds_owners_navigation_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_owners_navigation_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $grid: FootPrintManager_owners_navigation_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Owner Delete Error', 'No owners selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              ownerId: row.entity.Id
          };
          const result = await $flows.Owners.is_owner_deletable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Owner ${row.entity.LookupCode} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Delete owner errors';
          const errorMessage = `Owner(s) ${failures.map(c => c.entity.LookupCode).join(',')} cannot be deleted`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Owner(s) ${candidates.map(c => c.entity.LookupCode).join(',')} - Once deleted, the owner(s) cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some owner(s) cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Delete the following owner(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams = {
                          ownerId: candidate.entity.Id
                      }
                      const result = (await $flows.Owners.delete_owner_flow(flowParams));
  
                      if ($utils.isDefined(result?.reason)) {
                          processFailures.push(candidate);
                          errorMsgList.push(result?.reason);
                      }
                      else {
  
                          processSuccess.push(candidate);
                      }
  
  
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Owner ${candidate.entity.LookupCode} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All owner(s) deleted';
                  const message = `Owner(s) ${processSuccess.map(c => c.entity.LookupCode).join(',')} deleted`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All owner(s) failed to delete';
                      const message = `Owner(s) ${processFailures.map(c => c.entity.LookupCode).join(',')} could not be deleted`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some owners could not be deleted';
                      const deleted = `Owner(s) ${processSuccess.map(c => c.entity.LookupCode).join(',')} were deleted.`;
                      const errors = `The following owner(s) could not be deleted: ${processFailures.map(c => c.entity.LookupCode).join(',')}`;
                      const message = `${deleted} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_update(event = null) {
    return this.on_updateInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_updateInternal(
    $grid: FootPrintManager_owners_navigation_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Owner Update Error', 'No owners selected.');
      return;
  } else {
  
      const dialogResult = await $shell.FootPrintManager.openowner_update_formDialog();
      const userConfirmed = dialogResult.confirm;
      const ownerStatusId = dialogResult.ownerStatusId;
  
      let payload: any = {};
  
      if (userConfirmed) {
          for (const row of selectedRows) {
  
              if ($utils.isDefined(ownerStatusId)) {
                  payload.StatusId = ownerStatusId;
              }
  
              await $flows.Utilities.crud_update_flow({ entitySet: 'Owners', id: row.entity.Id, entity: payload });
          }
          await $grid.refresh();
      }
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_owners_navigation_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  
  if (selectedRowsCount > 0) {
      $grid.topToolbar.on_delete.control.readOnly = false;
      $grid.topToolbar.update.control.readOnly = false;
  }
  else {
      $grid.topToolbar.on_delete.control.readOnly = true;
      $grid.topToolbar.update.control.readOnly = true;
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_owners_navigation_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.on_delete.control.readOnly = true;
  $grid.topToolbar.update.control.readOnly = true;
  }
  on_export(event = null) {
    return this.on_exportInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_exportInternal(
    $grid: FootPrintManager_owners_navigation_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  /**
   * Author: Ismail Saleh
   * Date: 2024-09-25
   * Description: Export functionality provided in a more reusable code
   */
  
  
  const settingsExcelExportLimit = $settings.FootPrintManager.ExcelExportLimit;
  const serverMaxAllowedRecordsLimit: number = 5000;// could be changed base on what each request to the server can handle.
  const concurrentLimit = 5;//max concurrent requests
  const timeout = 500;//use it only if you want to set a delay between requests...
  // Initialize
  var workBook = $event;
  var recordsToExport = [];
  var fullDataSet: any[] = [];
  
  
  //parameters to be passed to dataset api call
  const baseParams = {
      ownerStatusId: $grid.inParams.ownerStatusId,
      fullTextSearch: $grid.fullTextSearch
  }
  
  //api call
  const dataApiCall = async (baseParams: any) => {
      return await $datasources.Owners.ds_owners_navigation_grid.getList(baseParams);
  };
  
  //records count api call
  const countApiCall = async (baseParams: any) => {
      const params = { ...baseParams, $top: 0 };
      return await $datasources.Owners.ds_owners_navigation_grid.getList(params);
  };
  
  //api call execution
  async function processApiCall(apiCall: (params: any) => Promise<any>, baseParams: any = {}, offset: number = 0, limit: number = 0): Promise<any> {
      const defaultParams = { $skip: offset, $top: limit };
      // Merge base, default, and additional params
      const params = { ...baseParams, ...defaultParams };
      return await apiCall(params);
  };
  
  //main funcion to handle concurrency in reagard of server and request limits
  async function processControlledApiRequests(totalRecords: number, requestLimit: number, serverLimit: number): Promise<any[]> {
      const totalBatches = Math.ceil(Math.min(totalRecords, serverLimit) / requestLimit);
  
      const result: any[] = [];
      const fetchPromiseQueue: Promise<any[]>[] = [];
  
      for (let i = 0; i < totalBatches; i++) {
          const offset = i * requestLimit;
          const limit = Math.min(requestLimit, serverLimit - (i * requestLimit));
  
          fetchPromiseQueue.push(processApiCall(dataApiCall, baseParams, offset, limit));
  
          //process promiseQueue once reached the limit then clear queue for the next loop round...
          if (fetchPromiseQueue.length === concurrentLimit) {
              result.push(...await Promise.all(fetchPromiseQueue));
              fetchPromiseQueue.length = 0;
          }
      }
  
      // Process any remaining requests after the loop
      if (fetchPromiseQueue.length) result.push(...await Promise.all(fetchPromiseQueue));
  
      return result;
  }
  
  
  try {
  
      if ($grid.selectedRows.length > 0) {
          // Compile all data into JSON
          for (let row of $grid.selectedRows) {
  
              recordsToExport.push({
                  "Lookup": row.entity.LookupCode,
                  "Project": row.entity.ProjectCount?.TotalProjects,// record.projects_count?.porjects_count,//projectLabel,
                  "Material": row.entity.MaterialCount?.TotalMaterials,// record.material_count?.material_count,
                  "Status": row.entity.Status?.Label,
                  "LP count": row.entity.LicenseplateCount?.TotalLicenseplates,
                  "Name": row.entity.Name,
                  "Notes": row.entity.Notes,
  
              });
          }
          var workSheet = $utils.excel.json_to_sheet(recordsToExport);
          $utils.excel.book_append_sheet(workBook, workSheet, "InventoryByLicensePlateExport");
      }
      else {
  
          const { totalCount: requestedRecordsCount } = await countApiCall(baseParams);
  
          if (requestedRecordsCount > settingsExcelExportLimit) {
              await $shell.FootPrintManager.openInfoDialog(`Export limitation of ${settingsExcelExportLimit} exceeded`, `Excel will only export ${settingsExcelExportLimit} records`);
          }
  
          const responses = await processControlledApiRequests(requestedRecordsCount, serverMaxAllowedRecordsLimit, settingsExcelExportLimit);
          responses.forEach(apiResponse => {
              fullDataSet.push(...apiResponse.result);
          });
          // Compile all data into JSON
          for (let record of fullDataSet) {
              recordsToExport.push({
  
                  "Lookup": record.LookupCode,
                  "Project": $utils.isDefined(record.ProjectCount?.TotalProjects) ? record.ProjectCount?.TotalProjects?.toString() : '0',// record.projects_count?.porjects_count,//projectLabel,
                  "Material": $utils.isDefined(record.MaterialCount?.TotalMaterials) ? record.MaterialCount?.TotalMaterials?.toString() : '0',// record.material_count?.material_count,
                  "Status": record.Status?.Label,
                  "LP count": record.LicenseplateCount?.TotalLicenseplates,
                  "Name": record.Name,
                  "Notes": record.Notes,
              });
          }
          var ws = $utils.excel.json_to_sheet(recordsToExport);
          $utils.excel.book_append_sheet(workBook, ws, "OwnersExport");
  
      }
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      throw new Error(errorMessage);
  }
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export(wb);

    writeExcelFile(wb, 'FootPrintManager_owners_navigation_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
