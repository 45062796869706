<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"
[rowCanExpand]="true"
>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="on_delete" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-billing_code" *ngIf="!filters.billing_code.hidden && !filters.billing_code.removed" 
            class="field-container standard {{filters.billing_code.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.billing_code.styles.style"
            [ngClass]="filters.billing_code.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.billing_code.label + (filters.billing_code.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.billing_code.label}}<span *ngIf="filters.billing_code.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Invoices-billingcodes_dd_multi 
            data-cy="selector-multi"
            [type]="filters.billing_code.control.type"
            formControlName="billing_code"
            (displayTextChange)="filters.billing_code.control.displayText=$event"
            [placeholder]="filters.billing_code.control.placeholder"
            [styles]="filters.billing_code.control.styles"
            [tooltip]="filters.billing_code.control.tooltip"
        >
        </Invoices-billingcodes_dd_multi>
        <ng-container *ngIf="filters.billing_code.invalid">
          <ng-container *ngFor="let error of filters.billing_code.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-operation_code" *ngIf="!filters.operation_code.hidden && !filters.operation_code.removed" 
            class="field-container standard {{filters.operation_code.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.operation_code.styles.style"
            [ngClass]="filters.operation_code.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.operation_code.label + (filters.operation_code.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.operation_code.label}}<span *ngIf="filters.operation_code.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Invoices-operation_codes_dd_multi 
            data-cy="selector-multi"
            [type]="filters.operation_code.control.type"
            formControlName="operation_code"
            (displayTextChange)="filters.operation_code.control.displayText=$event"
            [placeholder]="filters.operation_code.control.placeholder"
            [styles]="filters.operation_code.control.styles"
            [tooltip]="filters.operation_code.control.tooltip"
        >
        </Invoices-operation_codes_dd_multi>
        <ng-container *ngIf="filters.operation_code.invalid">
          <ng-container *ngFor="let error of filters.operation_code.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="linenumber">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.linenumber.displayControl.styles.style"
          [ngClass]="row.cells.linenumber.displayControl.styles.classes"
          [matTooltip]="row.cells.linenumber.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.linenumber.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="billing_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.billing_code.displayControl.styles.style"
          [ngClass]="row.cells.billing_code.displayControl.styles.classes"
          [matTooltip]="row.cells.billing_code.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.billing_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Invoices-billingcodes_dd_single 
        data-cy="selector"
        [type]="row.cells.billing_code.editControl.type"
        [formControl]="row.formGroup.controls['billing_code_edit']"
        (displayTextChange)="row.cells.billing_code.editControl.displayText=$event"
        [placeholder]="row.cells.billing_code.editControl.placeholder"
        [styles]="row.cells.billing_code.editControl.styles"
        [tooltip]="row.cells.billing_code.editControl.tooltip"
    >
    </Invoices-billingcodes_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.amount.displayControl.styles.style"
          [ngClass]="row.cells.amount.displayControl.styles.classes"
          [matTooltip]="row.cells.amount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['amount_edit']"
            matInput
            numberBox
            [format]="row.cells.amount.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.amount.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.amount.editControl.placeholder}}"
            [ngStyle]="row.cells.amount.editControl.styles.style"
            [ngClass]="row.cells.amount.editControl.styles.classes"
            [matTooltip]="row.cells.amount.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="unit_price">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.unit_price.displayControl.styles.style"
          [ngClass]="row.cells.unit_price.displayControl.styles.classes"
          [matTooltip]="row.cells.unit_price.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.unit_price.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['unit_price_edit']"
            matInput
            numberBox
            [format]="row.cells.unit_price.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.unit_price.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.unit_price.editControl.placeholder}}"
            [ngStyle]="row.cells.unit_price.editControl.styles.style"
            [ngClass]="row.cells.unit_price.editControl.styles.classes"
            [matTooltip]="row.cells.unit_price.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="line_total">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.line_total.displayControl.styles.style"
          [ngClass]="row.cells.line_total.displayControl.styles.classes"
          [matTooltip]="row.cells.line_total.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.line_total.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="billing_record_count">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.billing_record_count.displayControl.styles.style"
          [ngClass]="row.cells.billing_record_count.displayControl.styles.classes"
          [matTooltip]="row.cells.billing_record_count.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.billing_record_count.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes"
          [matTooltip]="row.cells.notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['notes_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.notes.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.notes.editControl.placeholder}}"
            [ngStyle]="row.cells.notes.editControl.styles.style"
            [ngClass]="row.cells.notes.editControl.styles.classes"
            [matTooltip]="row.cells.notes.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.description.displayControl.styles.style"
          [ngClass]="row.cells.description.displayControl.styles.classes"
          [matTooltip]="row.cells.description.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['description_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.description.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.description.editControl.placeholder}}"
            [ngStyle]="row.cells.description.editControl.styles.style"
            [ngClass]="row.cells.description.editControl.styles.classes"
            [matTooltip]="row.cells.description.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="operation_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.operation_code.displayControl.styles.style"
          [ngClass]="row.cells.operation_code.displayControl.styles.classes"
          [matTooltip]="row.cells.operation_code.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.operation_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>

<ng-template expandableRowDef
              let-row>
    <FootPrintManager-billing_records_by_invoiceline_grid
    [invoiceLineId]="row.$rowExpand_FootPrintManager_billing_records_by_invoiceline_grid_inParams_invoiceLineId"
    ($refreshEvent)="row.refresh()">>
  </FootPrintManager-billing_records_by_invoiceline_grid>
</ng-template>

</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
