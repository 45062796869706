import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Dynamics365BusinessCentral_ShellService } from './Dynamics365BusinessCentral.shell.service';
import { Dynamics365BusinessCentral_OperationService } from './Dynamics365BusinessCentral.operation.service';
import { Dynamics365BusinessCentral_DatasourceService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_FlowService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_ReportService } from './Dynamics365BusinessCentral.report.index';
import { Dynamics365BusinessCentral_LocalizationService } from './Dynamics365BusinessCentral.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Dynamics365BusinessCentral.frontend.types'
import { $frontendTypes as $types} from './Dynamics365BusinessCentral.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



interface IDynamics365BusinessCentral_invoices_gridComponentEntity {
Id?: number, AttentionOf?: string, CreatedSysDateTime?: string, CreatedSysUser?: string, DueDate?: string, InvoiceDate?: string, LookupCode?: string, Notes?: string, ReferenceCode?: string, Status?: { Name?: string }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, InvoiceLines?: { Id?: number, BillingRecords?: { BillingTaskId?: number, BillingTask?: { ShipmentId?: number, WarehouseId?: number, Warehouse?: { Name?: string }, Shipment?: { LookupCode?: string } } }[] }[]}

interface IDynamics365BusinessCentral_invoices_gridComponentInParams {
  ownerId?: number, projectId?: number, statusIds?: number, retryInsert?: string, transaction_id?: string}

interface IDynamics365BusinessCentral_invoices_gridComponentOutParams {
  transaction_id?: string, errmessage?: string}

class Dynamics365BusinessCentral_invoices_gridComponentRowModel extends GridRowModel {
  grid: Dynamics365BusinessCentral_invoices_gridComponent;
  entity: IDynamics365BusinessCentral_invoices_gridComponentEntity;

  vars: { hasCompletedReceivingTasks?: boolean, hasRemainingReceivingTasks?: boolean } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    lookupcode: new GridCellModel(
      new CellStyles(['grid-table-cell-link','left'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    owner: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    fer: new GridCellModel(
      new CellStyles(['left'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    project: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    total: new GridCellModel(
      new CellStyles(['left'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    created_date: new GridCellModel(
      new CellStyles(['left'], null),
      new TextModel(null, null,  'datetime', 'l, LT', null)
,
null
      ),
    due_date: new GridCellModel(
      new CellStyles(['left'], null),
      new TextModel(null, null,  'datetime', 'l, LT', null)
,
null
      ),
    warehouse: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    shipment: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    reference_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    attention_of: new GridCellModel(
      new CellStyles(['left'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    notes: new GridCellModel(
      new CellStyles(['left'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Dynamics365BusinessCentral_ShellService,
private datasources: Dynamics365BusinessCentral_DatasourceService,
private flows: Dynamics365BusinessCentral_FlowService,
private reports: Dynamics365BusinessCentral_ReportService,
private localization: Dynamics365BusinessCentral_LocalizationService,
private operations: Dynamics365BusinessCentral_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: Dynamics365BusinessCentral_invoices_gridComponent, entity: IDynamics365BusinessCentral_invoices_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Dynamics365BusinessCentral_invoices_gridComponent, entity?: IDynamics365BusinessCentral_invoices_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      statusIds:  [1] ,
      fullTextSearch:  $grid.fullTextSearch ,
      createdFrom:  $grid.filters.created_from.control.value ,
      createdTo:  $grid.filters.created_to.control.value ,
    };
    const data = await this.datasources.Dynamics365BusinessCentral.ds_invoices_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.owner.displayControl as TextModel).text = $row.entity.Project.Owner.Name;
    (this.cells.fer.displayControl as TextModel).text = $row.entity.Project.Owner.LookupCode;
    (this.cells.project.displayControl as TextModel).text = $row.entity.Project.Name;
    (this.cells.created_date.displayControl as TextModel).text = $row.entity.InvoiceDate;
    (this.cells.due_date.displayControl as TextModel).text = $row.entity.DueDate;
    (this.cells.warehouse.displayControl as TextModel).text = $row.entity.InvoiceLines[0]?.BillingRecords[0]?.BillingTask?.Warehouse?.Name;
    (this.cells.shipment.displayControl as TextModel).text = $row.entity.InvoiceLines[0]?.BillingRecords[0]?.BillingTask?.Shipment?.LookupCode;
    (this.cells.reference_code.displayControl as TextModel).text = $row.entity.ReferenceCode;
    (this.cells.attention_of.displayControl as TextModel).text = $row.entity.AttentionOf;
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'lookupcode' : {
        this.on_lookupcode_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_lookupcode_clicked(event = null) {
    return this.on_lookupcode_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lookupcode_clickedInternal(
    $row: Dynamics365BusinessCentral_invoices_gridComponentRowModel,
  $grid: Dynamics365BusinessCentral_invoices_gridComponent, 
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
  let application_name = $settings.Dynamics365BusinessCentral.application
  let customer = $settings.Dynamics365BusinessCentral.customer
  
  let GroupLines = (await $flows.Utilities.configurations_get_one({
          input: [{ name: 'GroupLines', string_1:  customer}],
          criteria: { application_name: application_name, decrypt: false },
      }))?.output
  
  let InvoiceId:number=$row.entity.Id
  
  if(GroupLines.value ==='yes')
  {$shell.Dynamics365BusinessCentral.openinvoice_line_grouped_gridDialog({InvoiceId:InvoiceId}, 'flyout', EModalSize.Xlarge)}
  else
  {$shell.Dynamics365BusinessCentral.openinvoice_line_gridDialog({InvoiceId:InvoiceId}, 'flyout', EModalSize.Xlarge)}
  
  
      
  
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: Dynamics365BusinessCentral_invoices_gridComponentRowModel,
  $grid: Dynamics365BusinessCentral_invoices_gridComponent, 
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
  
  // Set Date/Time Formats
  //const format = `${$settings.FootPrintManager.DateFormat}, ${$settings.Utilities.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  // Apply date format   $
  
  // if ($utils.isDefined($row.entity.CreatedSysDateTime)){
  // $row.cells.created_date.displayControl.text = $utils.date.format($row.entity.CreatedSysDateTime , format);
  // }
  
  // if ($utils.isDefined($row.entity.DueDate)){
  // $row.cells.due_date.displayControl.text = $utils.date.format($row.entity.DueDate , format);
  // }
  
  
  // Apply custom project string logic
  if ($row.entity.Project?.LookupCode === $row.entity.Project?.Name) {
      $row.cells.project.displayControl.text = $row.entity.Project.LookupCode
  } else {
      $row.cells.project.displayControl.text = $row.entity.Project.LookupCode + ' ' + $row.entity.Project.Name
  }
  
  // Apply custom owner string logic
  if ($row.entity.Project?.Owner?.LookupCode === $row.entity.Project?.Owner?.Name) {
      $row.cells.owner.displayControl.text = $row.entity.Project.Owner.LookupCode
  } else {
      $row.cells.owner.displayControl.text = $row.entity.Project.Owner.LookupCode + ' ' + $row.entity.Project.Owner.Name
  }
  
  
  
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Dynamics365BusinessCentral-invoices_grid',
  templateUrl: './Dynamics365BusinessCentral.invoices_grid.component.html'
})
export class Dynamics365BusinessCentral_invoices_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IDynamics365BusinessCentral_invoices_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IDynamics365BusinessCentral_invoices_gridComponentInParams = { ownerId: null, projectId: null, statusIds: null, retryInsert: null, transaction_id: null };

  outParams: IDynamics365BusinessCentral_invoices_gridComponentOutParams = { transaction_id: null, errmessage: null };

  //#region Variables
  //#endregion
  //#region Events
  @Output()
  filtersUpdated = new EventEmitter<{ createdFrom?: string, createdTo?: string }>();
  @Output()
  hub_refresh = new EventEmitter<void>();
  @Output()
  outParamsChange = new EventEmitter<{ transaction_id?: string, errmessage?: string }>();
  
  events = {
    filtersUpdated: this.filtersUpdated,
    hub_refresh: this.hub_refresh,
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(['left'], null), 'Invoice', false, false, null),       owner: new GridHeaderModel(new HeaderStyles(null, null), 'Customer', false, false, null),       fer: new GridHeaderModel(new HeaderStyles(['left'], null), 'Reference', false, false, null),       project: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),       total: new GridHeaderModel(new HeaderStyles(['left'], null), 'Total', false, false, null),       created_date: new GridHeaderModel(new HeaderStyles(['left'], null), 'Created on', false, false, null),       due_date: new GridHeaderModel(new HeaderStyles(['left'], null), 'Due date', false, false, null),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'Warehouse', false, false, null),       shipment: new GridHeaderModel(new HeaderStyles(null, null), 'Shipment', false, false, null),       reference_code: new GridHeaderModel(new HeaderStyles(null, null), 'Reference', false, false, null),       attention_of: new GridHeaderModel(new HeaderStyles(['left'], null), 'Attention of', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(['left'], null), 'Notes', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Dynamics365BusinessCentral_invoices_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('statusIds') set $inParams_statusIds(value: any) {
    this.inParams['statusIds'] = value;
  }
  get $inParams_statusIds(): any {
    return this.inParams['statusIds'] ;
  }
  @Input('retryInsert') set $inParams_retryInsert(value: any) {
    this.inParams['retryInsert'] = value;
  }
  get $inParams_retryInsert(): any {
    return this.inParams['retryInsert'] ;
  }
  @Input('transaction_id') set $inParams_transaction_id(value: any) {
    this.inParams['transaction_id'] = value;
  }
  get $inParams_transaction_id(): any {
    return this.inParams['transaction_id'] ;
  }

  topToolbar = {
      post_single: new ToolModel(new ButtonModel('post_single', new ButtonStyles(null, null), false, false, 'Post invoice', 'icon-ic_fluent_document_table_arrow_right_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    created_from: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    created_to: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    created_from: new FieldModel(new DateBoxModel(this.formGroup.controls['created_from'] as DatexFormControl, null, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'From', false, false),
    created_to: new FieldModel(new DateBoxModel(this.formGroup.controls['created_to'] as DatexFormControl, null, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'To', false, false),
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Dynamics365BusinessCentral_ShellService,
    private datasources: Dynamics365BusinessCentral_DatasourceService,
    private flows: Dynamics365BusinessCentral_FlowService,
    private reports: Dynamics365BusinessCentral_ReportService,
    private localization: Dynamics365BusinessCentral_LocalizationService,
    private operations: Dynamics365BusinessCentral_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Invoices';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 15;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['created_from']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_changed();
      });
    this.formGroup
      .controls['created_to']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_changed();
      });

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      statusIds:  [1] ,
      fullTextSearch:  $grid.fullTextSearch ,
      createdFrom:  $grid.filters.created_from.control.value ,
      createdTo:  $grid.filters.created_to.control.value ,
    };
    try {
    const data = await this.datasources.Dynamics365BusinessCentral.ds_invoices_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Dynamics365BusinessCentral_invoices_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: Dynamics365BusinessCentral_invoices_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
  
  
  const selectedRowsCount = $grid.selectedRows.length;
  $grid.topToolbar.post_single.control.readOnly = (selectedRowsCount > 0 ? false : true);
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Dynamics365BusinessCentral_invoices_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
  
  
  try {
  
      let on_export_config = (await $flows.Utilities.configurations_get_one({
          input: [{ name: "label", string_1: "invoices_grid", string_2: "on_export" }],
          criteria: { application_name: "FootprintManager" }
      }))?.output
  
      if ($utils.isDefined(on_export_config?.value)) {
  
      } else {
  
          await $flows.Utilities.configurations_write({
              input: [{ name: "label", string_1: "invoices_grid", string_2: "on_export", value: "Export for accounting", notes: "This is the custom value we'll show on the on_export button for the invoices grid." }],
              criteria: { application_name: "FootprintManager" }
          })
      }
  } catch (e) { /*Do not throw an error */ }
  $grid.events.hub_refresh.emit();
  //Start read only
  
  }
  on_filter_changed(event = null) {
    return this.on_filter_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_filter_changedInternal(
    $grid: Dynamics365BusinessCentral_invoices_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
  await $grid.events.filtersUpdated.emit({
      createdFrom: $grid.filters.created_from.control.value,
      createdTo: $grid.filters.created_to.control.value
  });
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: Dynamics365BusinessCentral_invoices_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
  
  
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  $grid.topToolbar.post_single.control.readOnly = (selectedRowsCount > 0 ? false : true);
  
  
  const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
  
  });
  
  const invoiceIds: number[] = $grid.rows?.map(r => r.entity.Id) ?? [];
  
  // const invoiceLines = (await $datasources.Invoices.ds_get_invoice_lines_grouped_by_invoiceId.get({
  //     invoiceIds: invoiceIds
  // })).result;
  
  // if ($utils.isDefined(invoiceLines)) {
  
  //     for (let row of $grid.rows) {
  
  //         row.cells.total.displayControl.text = formatter.format(invoiceLines?.find(il => il.InvoiceId === row.entity.Id)?.LineTotal ?? 0)
  //     }
  // }
  
  const attn_exists: boolean = $utils.isDefined($grid.rows?.filter(r => $utils.isDefined(r.entity.AttentionOf)))
  
  if (attn_exists) {
      $grid.headers.attention_of.hidden = false;
  } else {
      $grid.headers.attention_of.hidden = true;
  }
  
  const ref_exists: boolean = $utils.isDefined($grid.rows?.filter(r => $utils.isDefined(r.entity.ReferenceCode)))
  
  if (ref_exists) {
      $grid.headers.reference_code.hidden = false;
  } else {
      $grid.headers.reference_code.hidden = true;
  }
  
  const due_exists: boolean = $utils.isDefined($grid.rows?.filter(r => $utils.isDefined(r.entity.DueDate)))
  
  if (due_exists) {
      $grid.headers.due_date.hidden = false;
  } else {
      $grid.headers.due_date.hidden = true;
  }
  
  const war_exists: boolean = $utils.isDefined($grid.rows?.filter(r => $utils.isDefined(r.entity.InvoiceLines[0]?.BillingRecords[0]?.BillingTask?.Warehouse?.Name)))
  
  if (war_exists) {
      $grid.headers.warehouse.hidden = false;
  } else {
      $grid.headers.warehouse.hidden = true;
  }
  
  const shp_exists: boolean = $utils.isDefined($grid.rows?.filter(r => $utils.isDefined(r.entity.InvoiceLines[0]?.BillingRecords[0]?.BillingTask?.Shipment?.LookupCode)))
  
  if (shp_exists) {
      $grid.headers.shipment.hidden = false;
  } else {
      $grid.headers.shipment.hidden = true;
  }
  }
  on_post_all_clicked(event = null) {
    return this.on_post_all_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_post_all_clickedInternal(
    $grid: Dynamics365BusinessCentral_invoices_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'invoices_grid.on_post_all_clicked');
  try{
      const confirm_post = (await $shell.Utilities.openConfirmationDialog(`Post all invoices?`, `All invoices will be transfered to BC fo billing?`, `Post all`, `Cancel`));
  
      if (confirm_post) {
  
   $flows.Dynamics365BusinessCentral.flow_invoice_so_new_header_auto
   $grid.refresh();
      }
  }
   catch (error) {
  
  $shell.Utilities.openErrorDialog('Post error!', error);
      return;
   }
   $grid.refresh();
  }
  on_post_single_clicked(event = null) {
    return this.on_post_single_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_post_single_clickedInternal(
    $grid: Dynamics365BusinessCentral_invoices_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
  
  let application_name = $settings.Dynamics365BusinessCentral.application
  let retryInsert: string= 'no'
  let debug=$settings.Dynamics365BusinessCentral.debug
  let customer = $settings.Dynamics365BusinessCentral.customer
  let date = $utils.date.now();
  let user = (await $flows.Utilities.get_username_flow({})).userName
  
  if ($utils.isDefined($grid.inParams.transaction_id)) { var transaction_id = $grid.inParams.transaction_id }
  else { var transaction_id = $utils.createGuid().toUpperCase() }
  
   const selected_rows = $grid.selectedRows;
  if (selected_rows.length === 0) {
      $shell.Utilities.openErrorDialog('Post error!', 'No records were selected!');
    return
  } else {
  
          for (const row of selected_rows) {
                  let entity = row.entity
                  var Id:number= entity.Id
                  
  
  
            let env_url=$settings.Utilities.FootPrintAPI.url
        if (env_url.toLowerCase()==$settings.Dynamics365BusinessCentral.prod)
        {var env='prod'} else {var env='test'}
  
  let url = (await $flows.Utilities.configurations_get_one({
          input: [{ name: 'url', string_1:  customer, string_2:env}],
          criteria: { application_name: application_name, decrypt: false },
      }))?.output
  
   if (url?.value.length===0) {
   $shell.Utilities.openErrorDialog('Post error!', `Missing url config`);
   }
  
    var CompleteOnExport = (await $flows.Utilities.configurations_get_one({
          input: [{ name: 'CompleteOnExport', string_1:  customer}],
          criteria: { application_name: application_name, decrypt: false },
      }))?.output
  
  
  let compguid = (await $flows.Utilities.configurations_get_one({
      input: [{ name: 'compguid', string_1: customer }],
      criteria: { application_name: application_name, decrypt: false },
  }))?.output
  
   if (compguid?.value.length===0) {
   $shell.Utilities.openErrorDialog('Post error!',`Missing compguid config`);
  
   }
  
  let tenant = (await $flows.Utilities.configurations_get_one({
      input: [{ name: 'TenantID', string_1: customer }],
      criteria: { application_name: application_name, decrypt: false },
  }))?.output
  
   if (tenant?.value.length===0) {
   $shell.Utilities.openErrorDialog('Post error!',`Missing tenant config`);
  
   }
  
  let tokenADate = (await $flows.Utilities.configurations_get_one({
      input: [{ name: 'ADate', string_1: customer }],
      criteria: { application_name: application_name, decrypt: false },
  }))?.output
  
   if (tokenADate?.value.length===0) {
       $shell.Utilities.openErrorDialog('Post error!',`Missing tokenADate config`);
  
   }
  
  const tokenADatez: Date = new Date(tokenADate?.value)
  const nowz: Date = new Date(date)
  
  let diffMs: number = nowz.getTime() - tokenADatez.getTime()
  let findiffd = Math.floor(diffMs / 86400000); // days
  let findiffh = Math.floor((diffMs % 86400000) / 3600000); // hours
  let findiffm = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
  
  if (findiffd > 0) {retryInsert == (await $flows.Dynamics365BusinessCentral.flow_token_generate({ transaction_id: transaction_id })).retryInsert}
  else {
  if (findiffh > 0) {retryInsert == ( await $flows.Dynamics365BusinessCentral.flow_token_generate({ transaction_id: transaction_id })).retryInsert }
  else  {
  if (findiffm >= 56) {retryInsert ==  (await $flows.Dynamics365BusinessCentral.flow_token_generate({ transaction_id: transaction_id })).retryInsert } }}
  
  
  let RefreshACount = findiffd + "d." + findiffh + "h." + findiffm + "m"
  //if (retryInsert != "yes") {
  
      await $flows.Utilities.logs_write({
          input: [{
              process_name: customer,
              transaction_id: transaction_id,
              notes: RefreshACount,
              created_by: user,
              created_on: date.toString()
          }],
          criteria: { application_name: application_name }
  
      })
  
  
  let atoken = (await $flows.Utilities.configurations_get_one({
      input: [{ name: 'AccessToken', string_1:customer }],
      criteria: { application_name: application_name, decrypt: true },
  }))?.output
  
   if (atoken?.value.length===0) {
       $shell.Utilities.openErrorDialog('Post error!',`Missing atoken config`);
  
   }
  
  let InvoiceDetail = (await $datasources.Dynamics365BusinessCentral.ds_get_invoice_detail.get({ Id: Id })).result;
  
  var invoiceLookupCode = InvoiceDetail[0]?.LookupCode ?? InvoiceDetail[0]?.Id.toString();
  let invoiceDate = InvoiceDetail[0]?.InvoiceDate ?? "";
  let invoiceNotes = InvoiceDetail[0]?.Notes ?? "";
  let ownerName = InvoiceDetail[0]?.Project.Owner.Name
  
  if (debug===true)
  {var ownerLookup = "C00030"}
  else 
  {var ownerLookup = InvoiceDetail[0]?.Project.Owner.LookupCode}
  
  
  let ninvoiceDate = invoiceDate.split('T')[0];
  let postdate = date.toString();
  let npostdate = postdate.split('T')[0];
  
  
   const response0 = await fetch(`https://api.businesscentral.dynamics.com/v2.0/` + tenant?.value + `/`+url?.value+`/companies(` + compguid?.value + `)/customers`
          , {
              method: 'GET',
              headers: ({
                  'Authorization': atoken.value,
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
              }),
            
          })   
  
    let  data0 = await response0.json()
    let data01=data0.value
  
  const customerExists = data01.find(c => c.number === ownerLookup);
  
  if (customerExists) {
  
  let Invoiceheader = `{
      "externalDocumentNumber": "${invoiceLookupCode}",
      "invoiceDate": "${ninvoiceDate}",
      "postingDate": "${npostdate}",
      "customerNumber": "${ownerLookup}"
      }`
   // "customerName": "${ownerName}" 
   
  try {
  
  
      const response = await fetch(`https://api.businesscentral.dynamics.com/v2.0/` + tenant?.value + `/`+url?.value+`/companies(` + compguid?.value + `)/salesInvoices`
          , {
              method: 'POST',
              headers: ({
                  'Authorization': atoken.value,
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
              }),
              body: Invoiceheader
          })
  
  
    var data = await response.json()
   
  
  var inv_id = data.id;
  var  inv_status = data.status;
  var  bc_lookup  = data.number;
  
  
  
      let lineerrors = (await $flows.Dynamics365BusinessCentral.flow_invoice_so_accounts_query({ transaction_id: transaction_id, invoice_id: Id, inv_id: inv_id, customer: customer, lookup: invoiceLookupCode }))?.errmessage
      let error= lineerrors ?? 'Ok'
      if (error.match(/Err/)) {
      throw new Error(error.replace('Err..',''))}
  
   const response2 = await fetch(`https://api.businesscentral.dynamics.com/v2.0/` + tenant?.value + `/`+url?.value+`/companies(` + compguid?.value + `)/salesInvoices(` + inv_id + `)?$expand=salesInvoiceLines`
          , {
              method: 'GET',
              headers: ({
                  'Authorization': atoken.value,
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
              }),
            
          })
  
     var data2 = await response2.text()
  
      await $flows.Utilities.messages({
          action: 'Write',
          application_name: application_name,
          payload: [{
              string_1: invoiceLookupCode,
              number_1: Id,
              number_2: 2,
              string_2: 'header',
              string_3: inv_status,
              string_4: inv_id,
              string_5: bc_lookup,
              direction: 'Out',
              process_name: customer,
              status_name: "Ok",
              payload: data2.toString(),
              transaction_id: transaction_id,
              notes: '',
              created_by: user,
              created_on: date.toString()
          }]
      })
  
      if (CompleteOnExport.value ==='yes')
      {var stat=3}
      else
      {var stat=2}
  
  
      const invoices_payload1: any = {};
      invoices_payload1.statusId = stat
      invoices_payload1.referenceCode = bc_lookup
      $flows.Utilities.crud_update_flow({
          entitySet: 'Invoices',
          id: Id,
          entity: invoices_payload1
      })
  
  }
  catch (error) {
  
      let err_config= 'customer: '+customer+' | Id: ' +Id+' | env_url: '+env_url+' | env: '+env+' | url: '+url.value+' | tenant: '+ tenant.value +' | compguid: '+ compguid.value
      let err_token = 'tokenADate: '+tokenADate.value+' | Anker Time: '+ tokenADatez.getTime()+' |  Time diff: '+RefreshACount +' | atoken: '+atoken.value
      let err_query= 'invoiceLookupCode: '+invoiceLookupCode+' | invoiceDate: '+invoiceDate+' | ownerLookup: '+ownerLookup
      let err_hedr= 'inv_id: '+inv_id+' | inv_status: '+inv_status+' | bc_lookup: '+bc_lookup
  
      await $flows.Utilities.messages({
          action: 'Write',
          application_name: application_name,
          payload: [{
              string_1: invoiceLookupCode,
              number_1: Id,
              number_2: 4,
              string_2: 'header',
              direction: 'Out',
              process_name: customer,
              status_name: "Fail",
              payload: err_config +' || '+ err_query+' || '+err_hedr+'  || '+Invoiceheader+' || '+ err_token,
               transaction_id: transaction_id, 
              notes: error.message ,
              created_by: user,
              created_on: date.toString()
          }]
      })
  
  
      const response3 = await fetch(`https://api.businesscentral.dynamics.com/v2.0/` + tenant?.value + `/`+url?.value+`/companies(` + compguid?.value + `)/salesInvoices(` + inv_id + `)`
          , {
              method: 'DELETE',
              headers: ({
                  'Authorization': atoken.value,
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
              }),
          })
  
     let data3 = await response3.status
  
      const invoices_payload1: any = {};
      invoices_payload1.statusId = 4
      $flows.Utilities.crud_update_flow({
          entitySet: 'Invoices',
          id: Id,
          entity: invoices_payload1
      })
  }
  
   
   
   }
   else {
          await $flows.Utilities.messages({
          action: 'Write',
          application_name: application_name,
          payload: [{
              string_1: invoiceLookupCode,
              number_1: Id,
               number_2: 4,
              string_2: 'header',
              direction: 'Out',
              process_name: customer,
              status_name: "Fail",
              payload: ownerLookup,
              transaction_id: transaction_id,
              notes: `"Wavelength invoice `+invoiceLookupCode+` has header issue with customer  "` +ownerName+ `" who does not exist in "Business Centrall customers list" `,
              created_by: user,
              created_on: date.toString()
          }]
      })
  
       const invoices_payload1: any = {};
      invoices_payload1.statusId = 4
       $flows.Utilities.crud_update_flow({
       entitySet: 'Invoices',
      id: Id,
      entity: invoices_payload1
      })
   } 
  }}
  $grid.events.hub_refresh.emit();
  $grid.refresh();
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
   
  
  
  
  
  
  
  
  
  
  }
  on_refresh(event = null) {
    return this.on_refreshInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refreshInternal(
    $grid: Dynamics365BusinessCentral_invoices_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'invoices_grid.on_refresh');
  $grid.events.hub_refresh.emit();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
