import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SalesOrders_ds_outbound_orders_details_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], fullTextSearch?: string, fromDate?: string, toDate?: string, countries?: string[], carrierId?: number, carrierServiceId?: number, reference?: string, take?: number, skip?: number, materialIds?: number[], dateType?: string, orderIds?: number[], waveId?: number, ship_to_search?: string }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, Notes?: string, PackagedAmount?: number, Price?: number, Order?: { Id?: number, CreatedSysDateTime?: string, FulfillmentDate?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredWarehouseId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Id?: number, Name?: string }, OrderClass?: { LookupCode?: string }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, PreferredWarehouse?: { Id?: number, Name?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierServiceType?: { Id?: number, Name?: string }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { LookupCode?: string, ShippedDate?: string, TrackingIdentifier?: string } }[], Addresses?: { AttentionOf?: string, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[] }, Material?: { Id?: number, Description?: string, LookupCode?: string }, ShipmentLines?: { ExpectedPackagedAmount?: number }[] }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_outbound_orders_details_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], fullTextSearch?: string, fromDate?: string, toDate?: string, countries?: string[], carrierId?: number, carrierServiceId?: number, reference?: string, take?: number, skip?: number, materialIds?: number[], dateType?: string, orderIds?: number[], waveId?: number, ship_to_search?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, Notes?: string, PackagedAmount?: number, Price?: number, Order?: { Id?: number, CreatedSysDateTime?: string, FulfillmentDate?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredWarehouseId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Id?: number, Name?: string }, OrderClass?: { LookupCode?: string }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, PreferredWarehouse?: { Id?: number, Name?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierServiceType?: { Id?: number, Name?: string }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { LookupCode?: string, ShippedDate?: string, TrackingIdentifier?: string } }[], Addresses?: { AttentionOf?: string, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[] }, Material?: { Id?: number, Description?: string, LookupCode?: string }, ShipmentLines?: { ExpectedPackagedAmount?: number }[] }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_outbound_orders_details_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], fullTextSearch?: string, fromDate?: string, toDate?: string, countries?: string[], carrierId?: number, carrierServiceId?: number, reference?: string, take?: number, skip?: number, materialIds?: number[], dateType?: string, orderIds?: number[], waveId?: number, ship_to_search?: string, $keys: { OrderId?: number, LineNumber?: number }[] }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, Notes?: string, PackagedAmount?: number, Price?: number, Order?: { Id?: number, CreatedSysDateTime?: string, FulfillmentDate?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredWarehouseId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Id?: number, Name?: string }, OrderClass?: { LookupCode?: string }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, PreferredWarehouse?: { Id?: number, Name?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierServiceType?: { Id?: number, Name?: string }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { LookupCode?: string, ShippedDate?: string, TrackingIdentifier?: string } }[], Addresses?: { AttentionOf?: string, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[] }, Material?: { Id?: number, Description?: string, LookupCode?: string }, ShipmentLines?: { ExpectedPackagedAmount?: number }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_outbound_orders_details_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], fullTextSearch?: string, fromDate?: string, toDate?: string, countries?: string[], carrierId?: number, carrierServiceId?: number, reference?: string, take?: number, skip?: number, materialIds?: number[], dateType?: string, orderIds?: number[], waveId?: number, ship_to_search?: string    }) {
      return false;
    }
}
