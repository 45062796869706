import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Inventory_ds_get_location_by_locationIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { locationId: number }): 
  Promise<{ result: { Id?: number, IsLoose?: boolean, Name?: string, StatusId?: number, TypeId?: number, WarehouseId?: number, Status?: { Name?: string }, Warehouse?: { Name?: string }, Type?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.locationId)) {
      missingRequiredInParams.push('\'locationId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_get_location_by_locationId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { locationId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, IsLoose?: boolean, Name?: string, StatusId?: number, TypeId?: number, WarehouseId?: number, Status?: { Name?: string }, Warehouse?: { Name?: string }, Type?: { Name?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.locationId)) {
      missingRequiredInParams.push('\'locationId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_get_location_by_locationId/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { locationId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, IsLoose?: boolean, Name?: string, StatusId?: number, TypeId?: number, WarehouseId?: number, Status?: { Name?: string }, Warehouse?: { Name?: string }, Type?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.locationId)) {
      missingRequiredInParams.push('\'locationId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_get_location_by_locationId/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      locationId: number    }) {
        if(isNil(inParams.locationId)) {
          return true; 
        }
      return false;
    }
}
