import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Invoices_ds_get_invoice_line_details_by_invoiceIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { invoiceId: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, Description?: string, LineTotal?: number, Quantity?: number, UnitPrice?: number, BillingCode?: { Name?: string }, Details?: { BillingTaskId?: number, BillingTask?: { CreatedSysDateTime?: string, LicensePlateId?: number, LocationId?: number, LotId?: number, MaterialId?: number, Notes?: string, OperationCodeId?: number, OrderId?: number, ShipmentId?: number, VendorLotId?: number, WarehouseId?: number, Warehouse?: { Name?: string }, Lot?: { LookupCode?: string }, LicensePlate?: { LookupCode?: string }, Location?: { Name?: string }, Material?: { Description?: string, LookupCode?: string, Name?: string }, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string }, Order?: { LookupCode?: string, OwnerReference?: string }, Shipment?: { LookupCode?: string }, OperationCode?: { Name?: string } } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.invoiceId)) {
      missingRequiredInParams.push('\'invoiceId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Invoices/datasources/ds_get_invoice_line_details_by_invoiceId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { invoiceId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, Description?: string, LineTotal?: number, Quantity?: number, UnitPrice?: number, BillingCode?: { Name?: string }, Details?: { BillingTaskId?: number, BillingTask?: { CreatedSysDateTime?: string, LicensePlateId?: number, LocationId?: number, LotId?: number, MaterialId?: number, Notes?: string, OperationCodeId?: number, OrderId?: number, ShipmentId?: number, VendorLotId?: number, WarehouseId?: number, Warehouse?: { Name?: string }, Lot?: { LookupCode?: string }, LicensePlate?: { LookupCode?: string }, Location?: { Name?: string }, Material?: { Description?: string, LookupCode?: string, Name?: string }, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string }, Order?: { LookupCode?: string, OwnerReference?: string }, Shipment?: { LookupCode?: string }, OperationCode?: { Name?: string } } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.invoiceId)) {
      missingRequiredInParams.push('\'invoiceId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Invoices/datasources/ds_get_invoice_line_details_by_invoiceId/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { invoiceId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, Description?: string, LineTotal?: number, Quantity?: number, UnitPrice?: number, BillingCode?: { Name?: string }, Details?: { BillingTaskId?: number, BillingTask?: { CreatedSysDateTime?: string, LicensePlateId?: number, LocationId?: number, LotId?: number, MaterialId?: number, Notes?: string, OperationCodeId?: number, OrderId?: number, ShipmentId?: number, VendorLotId?: number, WarehouseId?: number, Warehouse?: { Name?: string }, Lot?: { LookupCode?: string }, LicensePlate?: { LookupCode?: string }, Location?: { Name?: string }, Material?: { Description?: string, LookupCode?: string, Name?: string }, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string }, Order?: { LookupCode?: string, OwnerReference?: string }, Shipment?: { LookupCode?: string }, OperationCode?: { Name?: string } } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.invoiceId)) {
      missingRequiredInParams.push('\'invoiceId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Invoices/datasources/ds_get_invoice_line_details_by_invoiceId/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      invoiceId: number    }) {
        if(isNil(inParams.invoiceId)) {
          return true; 
        }
      return false;
    }
}
