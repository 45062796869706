import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_inventory_by_location_selection_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number, fullTextSearch?: string, take?: number, skip?: number, vendorReference?: string, ownerReference?: string }): 
  Promise<{ result: { LocationId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Location?: { EligibleForAllocation?: boolean, GlobalAllocationSequence?: number, Name?: string, WarehouseId?: number, Status?: { Name?: string } }, Lot?: { LookupCode?: string, MaterialId?: number, VendorLotId?: number, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string }, Material?: { Description?: string, LookupCode?: string } }, Packaging?: { ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_inventory_by_location_selection_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number, fullTextSearch?: string, take?: number, skip?: number, vendorReference?: string, ownerReference?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { LocationId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Location?: { EligibleForAllocation?: boolean, GlobalAllocationSequence?: number, Name?: string, WarehouseId?: number, Status?: { Name?: string } }, Lot?: { LookupCode?: string, MaterialId?: number, VendorLotId?: number, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string }, Material?: { Description?: string, LookupCode?: string } }, Packaging?: { ShortName?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_inventory_by_location_selection_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number, fullTextSearch?: string, take?: number, skip?: number, vendorReference?: string, ownerReference?: string, $keys: { LocationId?: number, LotId?: number, PackagedId?: number }[] }): 
  Promise<{ result: { LocationId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Location?: { EligibleForAllocation?: boolean, GlobalAllocationSequence?: number, Name?: string, WarehouseId?: number, Status?: { Name?: string } }, Lot?: { LookupCode?: string, MaterialId?: number, VendorLotId?: number, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string }, Material?: { Description?: string, LookupCode?: string } }, Packaging?: { ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_inventory_by_location_selection_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number, fullTextSearch?: string, take?: number, skip?: number, vendorReference?: string, ownerReference?: string    }) {
        if(isNil(inParams.warehouseId)) {
          return true; 
        }
        if(isNil(inParams.projectId)) {
          return true; 
        }
      return false;
    }
}
