import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class InventoryCounts_ds_count_task_editorService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { taskId: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ExpectedPackagedAmount?: number, Material?: { Id?: number, LookupCode?: string }, VendorLot?: { Id?: number, LookupCode?: string }, Lot?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedPackagedPack?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string }, InventoryCountTaskProperties?: { InventoryCountId?: number, InventoryCount?: { LookupCode?: string } }[], InventoryCountDiscrepancies?: { ActualAmount?: number, ExpectedAmount?: number, ResolvedDatetime?: string, StatusId?: number }[] } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.taskId)) {
      missingRequiredInParams.push('\'taskId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryCounts/datasources/ds_count_task_editor/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      taskId: number    }) {
        if(isNil(inParams.taskId)) {
          return true; 
        }
      return false;
    }
}
