import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Utilities_ds_messages_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { application_name?: string, date_from?: string, date_to?: string, full_text_search?: string, process_name?: string, transaction_id?: string, status_name?: string, reference?: string, transaction_type?: string, direction?: string, environment?: string, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string, number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number, $top?: number, $skip?: number }): Promise<{ result?: { message_id?: string, application_name?: string, process_name?: string, transaction_type?: string, transaction_id?: string, status_name?: string, environment?: string, direction?: string, reference?: string, payload?: string, notes?: string, message?: string, created_by?: string, created_on?: string, modified_by?: string, modified_on?: string, number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string, date_number?: number }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/Utilities/datasources/ds_messages_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { application_name?: string, date_from?: string, date_to?: string, full_text_search?: string, process_name?: string, transaction_id?: string, status_name?: string, reference?: string, transaction_type?: string, direction?: string, environment?: string, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string, number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number, $keys: string[] }): Promise<{ result?: { message_id?: string, application_name?: string, process_name?: string, transaction_type?: string, transaction_id?: string, status_name?: string, environment?: string, direction?: string, reference?: string, payload?: string, notes?: string, message?: string, created_by?: string, created_on?: string, modified_by?: string, modified_on?: string, number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string, date_number?: number }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Utilities/datasources/ds_messages_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      application_name?: string, date_from?: string, date_to?: string, full_text_search?: string, process_name?: string, transaction_id?: string, status_name?: string, reference?: string, transaction_type?: string, direction?: string, environment?: string, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string, number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number    }) {
      return false;
    }
}
