import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Waves_associate_order_to_wave_flowService } from './Waves.flow.index';
import { Waves_cancel_wave_flowService } from './Waves.flow.index';
import { Waves_create_new_wave_flowService } from './Waves.flow.index';
import { Waves_create_wave_flowService } from './Waves.flow.index';
import { Waves_release_single_wave_flowService } from './Waves.flow.index';

import { $frontendTypes } from './Waves.frontend.types'

@Injectable({ providedIn: 'root' })
export class Waves_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Waves: Waves_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _associate_order_to_wave_flow: Waves_associate_order_to_wave_flowService;
  public async associate_order_to_wave_flow(inParams: { waveId?: number, shipmentIds?: number[] }): Promise< { reason?: string }> {
    if(!this._associate_order_to_wave_flow) { 
      this._associate_order_to_wave_flow = this.injector.get(Waves_associate_order_to_wave_flowService);
    }
    return this._associate_order_to_wave_flow.run(inParams);
  }
   
   

   
 
  private _cancel_wave_flow: Waves_cancel_wave_flowService;
  public async cancel_wave_flow(inParams: { waveId: number }): Promise< { reasons?: string[] }> {
    if(!this._cancel_wave_flow) { 
      this._cancel_wave_flow = this.injector.get(Waves_cancel_wave_flowService);
    }
    return this._cancel_wave_flow.run(inParams);
  }
   
   

   
 
  private _create_new_wave_flow: Waves_create_new_wave_flowService;
  public async create_new_wave_flow(inParams: { warehouseId: number }): Promise< { waveId?: number, reason?: string }> {
    if(!this._create_new_wave_flow) { 
      this._create_new_wave_flow = this.injector.get(Waves_create_new_wave_flowService);
    }
    return this._create_new_wave_flow.run(inParams);
  }
   
   

   
 
  private _create_wave_flow: Waves_create_wave_flowService;
  public async create_wave_flow(inParams: { shipmentIds: number[] }): Promise< { WaveId?: number, reason?: string }> {
    if(!this._create_wave_flow) { 
      this._create_wave_flow = this.injector.get(Waves_create_wave_flowService);
    }
    return this._create_wave_flow.run(inParams);
  }
   
   

   
 
  private _release_single_wave_flow: Waves_release_single_wave_flowService;
  public async release_single_wave_flow(inParams: { waveId: number }): Promise< { reason?: string }> {
    if(!this._release_single_wave_flow) { 
      this._release_single_wave_flow = this.injector.get(Waves_release_single_wave_flowService);
    }
    return this._release_single_wave_flow.run(inParams);
  }
   
   

   
}
