import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Reports_reports_homeComponent } from './Reports.reports_home.component';
import { Reports_custom_inbound_unloading_tally_reportComponent } from './Reports.custom_inbound_unloading_tally_report.component';
import { Reports_custom_invoice_report_sales_invoiceComponent } from './Reports.custom_invoice_report_sales_invoice.component';
import { Reports_custom_outbound_bill_of_lading_reportComponent } from './Reports.custom_outbound_bill_of_lading_report.component';
import { Reports_custom_receiving_label_case_reportComponent } from './Reports.custom_receiving_label_case_report.component';
import { Reports_custom_receiving_label_crossdock_reportComponent } from './Reports.custom_receiving_label_crossdock_report.component';
import { Reports_custom_receiving_label_hellofreshfactor_reportComponent } from './Reports.custom_receiving_label_hellofreshfactor_report.component';
import { Reports_custom_receiving_label_inbound_reportComponent } from './Reports.custom_receiving_label_inbound_report.component';
import { Reports_custom_receiving_reportComponent } from './Reports.custom_receiving_report.component';
import { Reports_custom_shipping_label_reportComponent } from './Reports.custom_shipping_label_report.component';
import { Reports_custom_test_label_reportComponent } from './Reports.custom_test_label_report.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Reports_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Reports: Reports_ShellService = this;

  public openreports_home(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Reports',
        referenceName: 'Reports_reports_home',
        component: Reports_reports_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openreports_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Reports_reports_homeComponent,
      'Reports',
      mode,
      dialogSize
    )
  }
  public opencustom_inbound_unloading_tally_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Custom Unloading Tally Report',
        referenceName: 'Reports_custom_inbound_unloading_tally_report',
        component: Reports_custom_inbound_unloading_tally_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inbound_unloading_tally_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_inbound_unloading_tally_reportComponent,
      'Custom Unloading Tally Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_invoice_report_sales_invoice(inParams:{ Id: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_invoice_report_sales_invoice',
        referenceName: 'Reports_custom_invoice_report_sales_invoice',
        component: Reports_custom_invoice_report_sales_invoiceComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_invoice_report_sales_invoiceDialog(
    inParams:{ Id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_invoice_report_sales_invoiceComponent,
      'custom_invoice_report_sales_invoice',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_outbound_bill_of_lading_report(inParams:{ orderId: number, shipmentId?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Custom Outbound Bill of Lading ',
        referenceName: 'Reports_custom_outbound_bill_of_lading_report',
        component: Reports_custom_outbound_bill_of_lading_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_outbound_bill_of_lading_reportDialog(
    inParams:{ orderId: number, shipmentId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_outbound_bill_of_lading_reportComponent,
      'Custom Outbound Bill of Lading ',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_receiving_label_case_report(inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_receiving_label_case_report',
        referenceName: 'Reports_custom_receiving_label_case_report',
        component: Reports_custom_receiving_label_case_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_receiving_label_case_reportDialog(
    inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_receiving_label_case_reportComponent,
      'custom_receiving_label_case_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_receiving_label_crossdock_report(inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_receiving_label_crossdock_report',
        referenceName: 'Reports_custom_receiving_label_crossdock_report',
        component: Reports_custom_receiving_label_crossdock_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_receiving_label_crossdock_reportDialog(
    inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_receiving_label_crossdock_reportComponent,
      'custom_receiving_label_crossdock_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_receiving_label_hellofreshfactor_report(inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_receiving_label_hellofreshfactor_report',
        referenceName: 'Reports_custom_receiving_label_hellofreshfactor_report',
        component: Reports_custom_receiving_label_hellofreshfactor_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_receiving_label_hellofreshfactor_reportDialog(
    inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_receiving_label_hellofreshfactor_reportComponent,
      'custom_receiving_label_hellofreshfactor_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_receiving_label_inbound_report(inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_receiving_label_inbound_report',
        referenceName: 'Reports_custom_receiving_label_inbound_report',
        component: Reports_custom_receiving_label_inbound_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_receiving_label_inbound_reportDialog(
    inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_receiving_label_inbound_reportComponent,
      'custom_receiving_label_inbound_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_receiving_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Custom Receiving Report',
        referenceName: 'Reports_custom_receiving_report',
        component: Reports_custom_receiving_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_receiving_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_receiving_reportComponent,
      'Custom Receiving Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_shipping_label_report(inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_shipping_label_report',
        referenceName: 'Reports_custom_shipping_label_report',
        component: Reports_custom_shipping_label_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_shipping_label_reportDialog(
    inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_shipping_label_reportComponent,
      'custom_shipping_label_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_test_label_report(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_test_label_report',
        referenceName: 'Reports_custom_test_label_report',
        component: Reports_custom_test_label_reportComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencustom_test_label_reportDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_test_label_reportComponent,
      'custom_test_label_report',
      mode,
      dialogSize
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Reports_reports_home') {
      const title = 'Reports';
      const component = Reports_reports_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_inbound_unloading_tally_report') {
      const title = 'Custom Unloading Tally Report';
      const component = Reports_custom_inbound_unloading_tally_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_invoice_report_sales_invoice') {
      const title = 'custom_invoice_report_sales_invoice';
      const component = Reports_custom_invoice_report_sales_invoiceComponent;
      const inParams:{ Id: number } = { Id: null };
      if (!isNil(params.get('Id'))) {
        const paramValueString = params.get('Id');
        inParams.Id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_outbound_bill_of_lading_report') {
      const title = 'Custom Outbound Bill of Lading ';
      const component = Reports_custom_outbound_bill_of_lading_reportComponent;
      const inParams:{ orderId: number, shipmentId?: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_receiving_label_case_report') {
      const title = 'custom_receiving_label_case_report';
      const component = Reports_custom_receiving_label_case_reportComponent;
      const inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number } = { orderId: null, shipmentId: null, orderLineNumber: null, copies: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderLineNumber'))) {
        const paramValueString = params.get('orderLineNumber');
        inParams.orderLineNumber = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('copies'))) {
        const paramValueString = params.get('copies');
        inParams.copies = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_receiving_label_crossdock_report') {
      const title = 'custom_receiving_label_crossdock_report';
      const component = Reports_custom_receiving_label_crossdock_reportComponent;
      const inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number } = { orderId: null, shipmentId: null, orderLineNumber: null, copies: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderLineNumber'))) {
        const paramValueString = params.get('orderLineNumber');
        inParams.orderLineNumber = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('copies'))) {
        const paramValueString = params.get('copies');
        inParams.copies = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_receiving_label_hellofreshfactor_report') {
      const title = 'custom_receiving_label_hellofreshfactor_report';
      const component = Reports_custom_receiving_label_hellofreshfactor_reportComponent;
      const inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number } = { orderId: null, shipmentId: null, orderLineNumber: null, copies: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderLineNumber'))) {
        const paramValueString = params.get('orderLineNumber');
        inParams.orderLineNumber = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('copies'))) {
        const paramValueString = params.get('copies');
        inParams.copies = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_receiving_label_inbound_report') {
      const title = 'custom_receiving_label_inbound_report';
      const component = Reports_custom_receiving_label_inbound_reportComponent;
      const inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number } = { orderId: null, shipmentId: null, orderLineNumber: null, copies: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderLineNumber'))) {
        const paramValueString = params.get('orderLineNumber');
        inParams.orderLineNumber = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('copies'))) {
        const paramValueString = params.get('copies');
        inParams.copies = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_receiving_report') {
      const title = 'Custom Receiving Report';
      const component = Reports_custom_receiving_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_shipping_label_report') {
      const title = 'custom_shipping_label_report';
      const component = Reports_custom_shipping_label_reportComponent;
      const inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number } = { orderId: null, shipmentId: null, orderLineNumber: null, copies: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderLineNumber'))) {
        const paramValueString = params.get('orderLineNumber');
        inParams.orderLineNumber = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('copies'))) {
        const paramValueString = params.get('copies');
        inParams.copies = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_test_label_report') {
      const title = 'custom_test_label_report';
      const component = Reports_custom_test_label_reportComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
