<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="select" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_select($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator1" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="on_delete" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator2" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="linecount" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
            >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="toolContainer">
      <label class="import-button">
        <input type="file" class="hidden" #excelFileInput (change)="$importExcel(excelFileInput)" multiple="false" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv" />
        <div class="button-label">
          <div class="button-icon"><i class="icon icon-ic_fluent_cloud_arrow_up_20_regular"></i></div>
          <div class="button-text">Import</div>
        </div>
      </label>
    </div>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-advanced" *ngIf="!filters.advanced.hidden && !filters.advanced.removed" 
            class="field-container double {{filters.advanced.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.advanced.styles.style"
            [ngClass]="filters.advanced.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.advanced.label + (filters.advanced.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.advanced.label}}<span *ngIf="filters.advanced.required" class="required-asterisk">*</span>
          </label>
        </div>
        <mat-slide-toggle data-cy="slideToggle" formControlName="advanced"
                      color="primary"
                      class="datex-toggle"
                      [ngStyle]="filters.advanced.control.styles.style"
                      [ngClass]="filters.advanced.control.styles.classes"
                      [matTooltip]="filters.advanced.control.tooltip"
                      matTooltipClass="datex-control-tooltip">{{filters.advanced.control.label}}</mat-slide-toggle>
        <ng-container *ngIf="filters.advanced.invalid">
          <ng-container *ngFor="let error of filters.advanced.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-account_type" *ngIf="!filters.account_type.hidden && !filters.account_type.removed" 
            class="field-container standard {{filters.account_type.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.account_type.styles.style"
            [ngClass]="filters.account_type.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.account_type.label + (filters.account_type.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.account_type.label}}<span *ngIf="filters.account_type.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Addresses-account_types_dd_single 
            data-cy="selector"
            [type]="filters.account_type.control.type"
            formControlName="account_type"
            (displayTextChange)="filters.account_type.control.displayText=$event"
            [placeholder]="filters.account_type.control.placeholder"
            [styles]="filters.account_type.control.styles"
            [tooltip]="filters.account_type.control.tooltip"
        >
        </Addresses-account_types_dd_single>
        <ng-container *ngIf="filters.account_type.invalid">
          <ng-container *ngFor="let error of filters.account_type.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-contact_types" *ngIf="!filters.contact_types.hidden && !filters.contact_types.removed" 
            class="field-container standard {{filters.contact_types.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.contact_types.styles.style"
            [ngClass]="filters.contact_types.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.contact_types.label + (filters.contact_types.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.contact_types.label}}<span *ngIf="filters.contact_types.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Addresses-contact_types_dd_multi 
            data-cy="selector-multi"
            [type]="filters.contact_types.control.type"
            formControlName="contact_types"
            (displayTextChange)="filters.contact_types.control.displayText=$event"
            [placeholder]="filters.contact_types.control.placeholder"
            [styles]="filters.contact_types.control.styles"
            [tooltip]="filters.contact_types.control.tooltip"
        >
        </Addresses-contact_types_dd_multi>
        <ng-container *ngIf="filters.contact_types.invalid">
          <ng-container *ngFor="let error of filters.contact_types.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.type.displayControl.styles.style"
          [ngClass]="row.cells.type.displayControl.styles.classes"
          [matTooltip]="row.cells.type.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Addresses-contact_types_dd_single 
        data-cy="selector"
        [type]="row.cells.type.editControl.type"
        [formControl]="row.formGroup.controls['type_edit']"
        (displayTextChange)="row.cells.type.editControl.displayText=$event"
        [placeholder]="row.cells.type.editControl.placeholder"
        [styles]="row.cells.type.editControl.styles"
        [tooltip]="row.cells.type.editControl.tooltip"
      [typeIds]="row.$fields_type_selector_inParams_typeIds"
    >
    </Addresses-contact_types_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="account_lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.account_lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.account_lookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.account_lookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.account_lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['account_lookupcode_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.account_lookupcode.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.account_lookupcode.editControl.placeholder}}"
            [ngStyle]="row.cells.account_lookupcode.editControl.styles.style"
            [ngClass]="row.cells.account_lookupcode.editControl.styles.classes"
            [matTooltip]="row.cells.account_lookupcode.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="account_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.account_name.displayControl.styles.style"
          [ngClass]="row.cells.account_name.displayControl.styles.classes"
          [matTooltip]="row.cells.account_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.account_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['account_name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.account_name.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.account_name.editControl.placeholder}}"
            [ngStyle]="row.cells.account_name.editControl.styles.style"
            [ngClass]="row.cells.account_name.editControl.styles.classes"
            [matTooltip]="row.cells.account_name.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="reference_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.reference_code.displayControl.styles.style"
          [ngClass]="row.cells.reference_code.displayControl.styles.classes"
          [matTooltip]="row.cells.reference_code.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.reference_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['reference_code_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.reference_code.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.reference_code.editControl.placeholder}}"
            [ngStyle]="row.cells.reference_code.editControl.styles.style"
            [ngClass]="row.cells.reference_code.editControl.styles.classes"
            [matTooltip]="row.cells.reference_code.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="first_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.first_name.displayControl.styles.style"
          [ngClass]="row.cells.first_name.displayControl.styles.classes"
          [matTooltip]="row.cells.first_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.first_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['first_name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.first_name.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.first_name.editControl.placeholder}}"
            [ngStyle]="row.cells.first_name.editControl.styles.style"
            [ngClass]="row.cells.first_name.editControl.styles.classes"
            [matTooltip]="row.cells.first_name.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="last_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.last_name.displayControl.styles.style"
          [ngClass]="row.cells.last_name.displayControl.styles.classes"
          [matTooltip]="row.cells.last_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.last_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['last_name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.last_name.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.last_name.editControl.placeholder}}"
            [ngStyle]="row.cells.last_name.editControl.styles.style"
            [ngClass]="row.cells.last_name.editControl.styles.classes"
            [matTooltip]="row.cells.last_name.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="line1">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.line1.displayControl.styles.style"
          [ngClass]="row.cells.line1.displayControl.styles.classes"
          [matTooltip]="row.cells.line1.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.line1.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['line1_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.line1.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.line1.editControl.placeholder}}"
            [ngStyle]="row.cells.line1.editControl.styles.style"
            [ngClass]="row.cells.line1.editControl.styles.classes"
            [matTooltip]="row.cells.line1.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="line2">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.line2.displayControl.styles.style"
          [ngClass]="row.cells.line2.displayControl.styles.classes"
          [matTooltip]="row.cells.line2.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.line2.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['line2_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.line2.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.line2.editControl.placeholder}}"
            [ngStyle]="row.cells.line2.editControl.styles.style"
            [ngClass]="row.cells.line2.editControl.styles.classes"
            [matTooltip]="row.cells.line2.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="city">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.city.displayControl.styles.style"
          [ngClass]="row.cells.city.displayControl.styles.classes"
          [matTooltip]="row.cells.city.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.city.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['city_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.city.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.city.editControl.placeholder}}"
            [ngStyle]="row.cells.city.editControl.styles.style"
            [ngClass]="row.cells.city.editControl.styles.classes"
            [matTooltip]="row.cells.city.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="state">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.state.displayControl.styles.style"
          [ngClass]="row.cells.state.displayControl.styles.classes"
          [matTooltip]="row.cells.state.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.state.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['state_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.state.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.state.editControl.placeholder}}"
            [ngStyle]="row.cells.state.editControl.styles.style"
            [ngClass]="row.cells.state.editControl.styles.classes"
            [matTooltip]="row.cells.state.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="postal_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.postal_code.displayControl.styles.style"
          [ngClass]="row.cells.postal_code.displayControl.styles.classes"
          [matTooltip]="row.cells.postal_code.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.postal_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['postal_code_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.postal_code.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.postal_code.editControl.placeholder}}"
            [ngStyle]="row.cells.postal_code.editControl.styles.style"
            [ngClass]="row.cells.postal_code.editControl.styles.classes"
            [matTooltip]="row.cells.postal_code.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="country">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.country.displayControl.styles.style"
          [ngClass]="row.cells.country.displayControl.styles.classes"
          [matTooltip]="row.cells.country.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.country.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['country_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.country.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.country.editControl.placeholder}}"
            [ngStyle]="row.cells.country.editControl.styles.style"
            [ngClass]="row.cells.country.editControl.styles.classes"
            [matTooltip]="row.cells.country.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="attention_of">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.attention_of.displayControl.styles.style"
          [ngClass]="row.cells.attention_of.displayControl.styles.classes"
          [matTooltip]="row.cells.attention_of.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.attention_of.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['attention_of_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.attention_of.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.attention_of.editControl.placeholder}}"
            [ngStyle]="row.cells.attention_of.editControl.styles.style"
            [ngClass]="row.cells.attention_of.editControl.styles.classes"
            [matTooltip]="row.cells.attention_of.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="primary_telephone">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.primary_telephone.displayControl.styles.style"
          [ngClass]="row.cells.primary_telephone.displayControl.styles.classes"
          [matTooltip]="row.cells.primary_telephone.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.primary_telephone.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['primary_telephone_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.primary_telephone.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.primary_telephone.editControl.placeholder}}"
            [ngStyle]="row.cells.primary_telephone.editControl.styles.style"
            [ngClass]="row.cells.primary_telephone.editControl.styles.classes"
            [matTooltip]="row.cells.primary_telephone.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="primary_email">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.primary_email.displayControl.styles.style"
          [ngClass]="row.cells.primary_email.displayControl.styles.classes"
          [matTooltip]="row.cells.primary_email.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.primary_email.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['primary_email_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.primary_email.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.primary_email.editControl.placeholder}}"
            [ngStyle]="row.cells.primary_email.editControl.styles.style"
            [ngClass]="row.cells.primary_email.editControl.styles.classes"
            [matTooltip]="row.cells.primary_email.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes"
          [matTooltip]="row.cells.notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['notes_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.notes.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.notes.editControl.placeholder}}"
            [ngStyle]="row.cells.notes.editControl.styles.style"
            [ngClass]="row.cells.notes.editControl.styles.classes"
            [matTooltip]="row.cells.notes.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="error">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.error.displayControl.styles.style"
          [ngClass]="row.cells.error.displayControl.styles.classes"
          [matTooltip]="row.cells.error.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.error.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
