import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SalesOrders_ds_get_full_licenseplate_pick_task_child_tasksService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { taskId: number }): 
  Promise<{ result: { Id?: number, ChainHead?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, LotId?: number, MaterialId?: number, OrderLineNumber?: number, VendorLotId?: number, Material?: { LookupCode?: string }, Lot?: { LookupCode?: string, VendorLot?: { LookupCode?: string } }, ExpectedPackagedPack?: { ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.taskId)) {
      missingRequiredInParams.push('\'taskId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_get_full_licenseplate_pick_task_child_tasks/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { taskId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ChainHead?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, LotId?: number, MaterialId?: number, OrderLineNumber?: number, VendorLotId?: number, Material?: { LookupCode?: string }, Lot?: { LookupCode?: string, VendorLot?: { LookupCode?: string } }, ExpectedPackagedPack?: { ShortName?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.taskId)) {
      missingRequiredInParams.push('\'taskId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_get_full_licenseplate_pick_task_child_tasks/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { taskId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ChainHead?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, LotId?: number, MaterialId?: number, OrderLineNumber?: number, VendorLotId?: number, Material?: { LookupCode?: string }, Lot?: { LookupCode?: string, VendorLot?: { LookupCode?: string } }, ExpectedPackagedPack?: { ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.taskId)) {
      missingRequiredInParams.push('\'taskId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_get_full_licenseplate_pick_task_child_tasks/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      taskId: number    }) {
        if(isNil(inParams.taskId)) {
          return true; 
        }
      return false;
    }
}
