import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Dynamics365BusinessCentral_flow_config_get_compguidService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_config_initial_configService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_invoice_so_accounts_queryService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_invoice_so_delete_invoiceService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_invoice_so_message_status_updateService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_invoice_so_new_header_autoService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_invoice_so_new_header_manualService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_invoice_so_new_lines_insertService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_invoice_so_postService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_material_insert_new_materialService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_material_list_new_materialService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_material_queueService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_material_queue_deleteService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_material_queue_getService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_material_queue_get_oneService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_material_queue_updateService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_material_queue_writeService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_material_update_materialService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_order_po_complete_poService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_order_po_complete_po_lineService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_order_po_create_poService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_order_po_create_po_lineService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_order_po_list_new_ordersService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_order_po_update_bc_gridService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_order_po_update_po_lineService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_order_po_update_wl_gridService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_order_queueService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_order_queue_deleteService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_order_queue_getService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_order_queue_get_oneService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_order_queue_updateService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_order_queue_writeService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_order_so_create_soService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_order_so_create_so_lineService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_order_so_list_new_ordersService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_order_so_update_so_lineService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_token_generateService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_footprint_generic_json_material_import_flowService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_footprint_generic_json_material_to_entity_import_xml_flowService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_submit_entity_import_xml_requestService } from './Dynamics365BusinessCentral.flow.index';

import { $frontendTypes } from './Dynamics365BusinessCentral.frontend.types'

@Injectable({ providedIn: 'root' })
export class Dynamics365BusinessCentral_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Dynamics365BusinessCentral: Dynamics365BusinessCentral_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _flow_config_get_compguid: Dynamics365BusinessCentral_flow_config_get_compguidService;
  public async flow_config_get_compguid(inParams: { displayName: string }): Promise< { name?: string, env?: string, url?: string }> {
    if(!this._flow_config_get_compguid) { 
      this._flow_config_get_compguid = this.injector.get(Dynamics365BusinessCentral_flow_config_get_compguidService);
    }
    return this._flow_config_get_compguid.run(inParams);
  }
   
   

   
 
  private _flow_config_initial_config: Dynamics365BusinessCentral_flow_config_initial_configService;
  public async flow_config_initial_config(inParams: { client_id: string, client_secret: string, scope: string, tenantid: string, companyDisplayName: string, environment?: string, url?: string, cg?: string }): Promise< { tokens?: { AccessToken?: string, RefreshToken?: string, RealmId?: string, Skey?: string } }> {
    if(!this._flow_config_initial_config) { 
      this._flow_config_initial_config = this.injector.get(Dynamics365BusinessCentral_flow_config_initial_configService);
    }
    return this._flow_config_initial_config.run(inParams);
  }
   
   

   
 
  private _flow_invoice_so_accounts_query: Dynamics365BusinessCentral_flow_invoice_so_accounts_queryService;
  public async flow_invoice_so_accounts_query(inParams: { transaction_id: string, invoice_id: number, inv_id: string, customer: string, lookup: string }): Promise< { transaction_id?: string, astatus?: string, errmessage?: string }> {
    if(!this._flow_invoice_so_accounts_query) { 
      this._flow_invoice_so_accounts_query = this.injector.get(Dynamics365BusinessCentral_flow_invoice_so_accounts_queryService);
    }
    return this._flow_invoice_so_accounts_query.run(inParams);
  }
   
   

   
 
  private _flow_invoice_so_delete_invoice: Dynamics365BusinessCentral_flow_invoice_so_delete_invoiceService;
  public async flow_invoice_so_delete_invoice(inParams: { inv_id?: string }): Promise<void> {
    if(!this._flow_invoice_so_delete_invoice) { 
      this._flow_invoice_so_delete_invoice = this.injector.get(Dynamics365BusinessCentral_flow_invoice_so_delete_invoiceService);
    }
    return this._flow_invoice_so_delete_invoice.run(inParams);
  }
   
   

   
 
  private _flow_invoice_so_message_status_update: Dynamics365BusinessCentral_flow_invoice_so_message_status_updateService;
  public async flow_invoice_so_message_status_update(inParams: { m_id: string, inv_id: string }): Promise<void> {
    if(!this._flow_invoice_so_message_status_update) { 
      this._flow_invoice_so_message_status_update = this.injector.get(Dynamics365BusinessCentral_flow_invoice_so_message_status_updateService);
    }
    return this._flow_invoice_so_message_status_update.run(inParams);
  }
   
   

   
 
  private _flow_invoice_so_new_header_auto: Dynamics365BusinessCentral_flow_invoice_so_new_header_autoService;
  public async flow_invoice_so_new_header_auto(inParams: { retryInsert?: string, transaction_id?: string }): Promise< { transaction_id?: string, errmessage?: string }> {
    if(!this._flow_invoice_so_new_header_auto) { 
      this._flow_invoice_so_new_header_auto = this.injector.get(Dynamics365BusinessCentral_flow_invoice_so_new_header_autoService);
    }
    return this._flow_invoice_so_new_header_auto.run(inParams);
  }
   
   

   
 
  private _flow_invoice_so_new_header_manual: Dynamics365BusinessCentral_flow_invoice_so_new_header_manualService;
  public async flow_invoice_so_new_header_manual(inParams: { Id: number, transaction_id?: string, retryInsert?: string }): Promise< { transaction_id?: string, errmessage?: string }> {
    if(!this._flow_invoice_so_new_header_manual) { 
      this._flow_invoice_so_new_header_manual = this.injector.get(Dynamics365BusinessCentral_flow_invoice_so_new_header_manualService);
    }
    return this._flow_invoice_so_new_header_manual.run(inParams);
  }
   
   

   
 
  private _flow_invoice_so_new_lines_insert: Dynamics365BusinessCentral_flow_invoice_so_new_lines_insertService;
  public async flow_invoice_so_new_lines_insert(inParams: { transaction_id: string, invoice_lines: string, customerr: string, lookup: string, id: number, inv_id: string }): Promise< { transaction_id?: string, lstatus?: string, errmessage?: string }> {
    if(!this._flow_invoice_so_new_lines_insert) { 
      this._flow_invoice_so_new_lines_insert = this.injector.get(Dynamics365BusinessCentral_flow_invoice_so_new_lines_insertService);
    }
    return this._flow_invoice_so_new_lines_insert.run(inParams);
  }
   
   

   
 
  private _flow_invoice_so_post: Dynamics365BusinessCentral_flow_invoice_so_postService;
  public async flow_invoice_so_post(inParams: { id?: number, transaction_id?: string }): Promise< { result_err?: string, result_ok?: string }> {
    if(!this._flow_invoice_so_post) { 
      this._flow_invoice_so_post = this.injector.get(Dynamics365BusinessCentral_flow_invoice_so_postService);
    }
    return this._flow_invoice_so_post.run(inParams);
  }
   
   

   
 
  private _flow_material_insert_new_material: Dynamics365BusinessCentral_flow_material_insert_new_materialService;
  public async flow_material_insert_new_material(inParams: {  }): Promise< { con?: { val?: string[] }, addr?: { val?: string[] }, ao_id?: { val?: string[] }, aqid?: { val?: string[] }, materialId?: number, ino?: { val?: any[] }, pid?: { val?: string[] }, plp?: { val?: any[] }, total?: number, cnta?: string }> {
    if(!this._flow_material_insert_new_material) { 
      this._flow_material_insert_new_material = this.injector.get(Dynamics365BusinessCentral_flow_material_insert_new_materialService);
    }
    return this._flow_material_insert_new_material.run(inParams);
  }
   
   

   
 
  private _flow_material_list_new_material: Dynamics365BusinessCentral_flow_material_list_new_materialService;
  public async flow_material_list_new_material(inParams: {  }): Promise< { out?: { val?: string[] }, nww?: string[], upd?: { val?: string[] }, pl?: { val?: string[] }, nmb?: { val?: string[] }, old?: $frontendTypes.Dynamics365BusinessCentral.material_queue[] }> {
    if(!this._flow_material_list_new_material) { 
      this._flow_material_list_new_material = this.injector.get(Dynamics365BusinessCentral_flow_material_list_new_materialService);
    }
    return this._flow_material_list_new_material.run(inParams);
  }
   
   

   
 
  private _flow_material_queue: Dynamics365BusinessCentral_flow_material_queueService;
  public async flow_material_queue(inParams: { payload?: $frontendTypes.Dynamics365BusinessCentral.material_queue[], action?: string, skip?: number, take?: number, full_text_search?: string, application_name?: string, date_from_number?: string, date_to_number?: string }): Promise< { payload?: $frontendTypes.Dynamics365BusinessCentral.material_queue[], success?: boolean, error?: any[], count?: number }> {
    if(!this._flow_material_queue) { 
      this._flow_material_queue = this.injector.get(Dynamics365BusinessCentral_flow_material_queueService);
    }
    return this._flow_material_queue.run(inParams);
  }
   
   

   
 
  private _flow_material_queue_delete: Dynamics365BusinessCentral_flow_material_queue_deleteService;
  public async flow_material_queue_delete(inParams: { input?: $frontendTypes.Dynamics365BusinessCentral.material_queue[], criteria: { application_name: string } }): Promise< { success?: boolean, error?: any[] }> {
    if(!this._flow_material_queue_delete) { 
      this._flow_material_queue_delete = this.injector.get(Dynamics365BusinessCentral_flow_material_queue_deleteService);
    }
    return this._flow_material_queue_delete.run(inParams);
  }
   
   

   
 
  private _flow_material_queue_get: Dynamics365BusinessCentral_flow_material_queue_getService;
  public async flow_material_queue_get(inParams: { input?: $frontendTypes.Dynamics365BusinessCentral.material_queue[], criteria: { application_name: string, decrypt?: string, full_text_search?: string, skip?: number, take?: number } }): Promise< { output?: $frontendTypes.Dynamics365BusinessCentral.order_queue[], success?: boolean, error?: any[], count?: number }> {
    if(!this._flow_material_queue_get) { 
      this._flow_material_queue_get = this.injector.get(Dynamics365BusinessCentral_flow_material_queue_getService);
    }
    return this._flow_material_queue_get.run(inParams);
  }
   
   

   
 
  private _flow_material_queue_get_one: Dynamics365BusinessCentral_flow_material_queue_get_oneService;
  public async flow_material_queue_get_one(inParams: { input?: $frontendTypes.Dynamics365BusinessCentral.material_queue[], criteria: { application_name: string, decrypt?: boolean, full_text_search?: string } }): Promise< { output?: $frontendTypes.Dynamics365BusinessCentral.order_queue, success?: boolean, error?: any[] }> {
    if(!this._flow_material_queue_get_one) { 
      this._flow_material_queue_get_one = this.injector.get(Dynamics365BusinessCentral_flow_material_queue_get_oneService);
    }
    return this._flow_material_queue_get_one.run(inParams);
  }
   
   

   
 
  private _flow_material_queue_update: Dynamics365BusinessCentral_flow_material_queue_updateService;
  public async flow_material_queue_update(inParams: { input?: $frontendTypes.Dynamics365BusinessCentral.material_queue[], criteria: { application_name: string, encrypt?: boolean } }): Promise< { success?: boolean, error?: any[] }> {
    if(!this._flow_material_queue_update) { 
      this._flow_material_queue_update = this.injector.get(Dynamics365BusinessCentral_flow_material_queue_updateService);
    }
    return this._flow_material_queue_update.run(inParams);
  }
   
   

   
 
  private _flow_material_queue_write: Dynamics365BusinessCentral_flow_material_queue_writeService;
  public async flow_material_queue_write(inParams: { input?: $frontendTypes.Dynamics365BusinessCentral.material_queue[], criteria?: { application_name?: string } }): Promise< { output?: { q_id?: string }[], success?: boolean, error?: any[] }> {
    if(!this._flow_material_queue_write) { 
      this._flow_material_queue_write = this.injector.get(Dynamics365BusinessCentral_flow_material_queue_writeService);
    }
    return this._flow_material_queue_write.run(inParams);
  }
   
   

   
 
  private _flow_material_update_material: Dynamics365BusinessCentral_flow_material_update_materialService;
  public async flow_material_update_material(inParams: {  }): Promise< { con?: { val?: string[] }, addr?: { val?: string[] }, ao_id?: { val?: string[] }, aqid?: { val?: string[] } }> {
    if(!this._flow_material_update_material) { 
      this._flow_material_update_material = this.injector.get(Dynamics365BusinessCentral_flow_material_update_materialService);
    }
    return this._flow_material_update_material.run(inParams);
  }
   
   

   
 
  private _flow_order_po_complete_po: Dynamics365BusinessCentral_flow_order_po_complete_poService;
  public async flow_order_po_complete_po(inParams: {  }): Promise< { out?: { val?: string[] }, out_err?: { err?: string[] }, aqid?: { val?: string[] }, aok_id?: { val?: string[] }, ashipmentid?: { val?: string[] }, aorderid?: { val?: string[] }, ao_id?: { val?: string[] }, p_id?: { val?: string[] }, ap_id?: { val?: string[] } }> {
    if(!this._flow_order_po_complete_po) { 
      this._flow_order_po_complete_po = this.injector.get(Dynamics365BusinessCentral_flow_order_po_complete_poService);
    }
    return this._flow_order_po_complete_po.run(inParams);
  }
   
   

   
 
  private _flow_order_po_complete_po_line: Dynamics365BusinessCentral_flow_order_po_complete_po_lineService;
  public async flow_order_po_complete_po_line(inParams: { ok_id?: string, orderid?: number, qid?: string, transaction_id?: string, olookup?: string }): Promise< { out?: { val?: string[] }, out_err?: { err?: string[] }, okline?: { val?: number[] }, totalline?: { val?: number[] }, plookup?: { val?: string[] }, olookup?: { val?: { Id?: string, vendorshipmentno_?: string, vendorinvoiceno_?: string }[] }, uom?: { val?: string[] }, result?: string }> {
    if(!this._flow_order_po_complete_po_line) { 
      this._flow_order_po_complete_po_line = this.injector.get(Dynamics365BusinessCentral_flow_order_po_complete_po_lineService);
    }
    return this._flow_order_po_complete_po_line.run(inParams);
  }
   
   

   
 
  private _flow_order_po_create_po: Dynamics365BusinessCentral_flow_order_po_create_poService;
  public async flow_order_po_create_po(inParams: {  }): Promise< { out?: { val?: string[] }, out_err?: { err?: string[] }, qid?: { val?: string[] }, w_id?: { val?: string[] }, shipmentid?: { val?: string[] }, orderid?: { val?: string[] }, o_id?: { val?: string[] }, p_id?: { val?: string[] } }> {
    if(!this._flow_order_po_create_po) { 
      this._flow_order_po_create_po = this.injector.get(Dynamics365BusinessCentral_flow_order_po_create_poService);
    }
    return this._flow_order_po_create_po.run(inParams);
  }
   
   

   
 
  private _flow_order_po_create_po_line: Dynamics365BusinessCentral_flow_order_po_create_po_lineService;
  public async flow_order_po_create_po_line(inParams: { ok_id?: string, p_id?: number, o_id?: number, shipmentid?: number, orderid?: number, qid?: string, transaction_id?: string }): Promise< { out?: { val?: string[] }, out_err?: { err?: string[] }, okline?: { val?: number[] }, totalline?: { val?: number[] }, plookup?: { val?: string[] }, olookup?: { val?: string[] }, uom?: { val?: string[] }, result?: string, oln?: { val?: string[] } }> {
    if(!this._flow_order_po_create_po_line) { 
      this._flow_order_po_create_po_line = this.injector.get(Dynamics365BusinessCentral_flow_order_po_create_po_lineService);
    }
    return this._flow_order_po_create_po_line.run(inParams);
  }
   
   

   
 
  private _flow_order_po_list_new_orders: Dynamics365BusinessCentral_flow_order_po_list_new_ordersService;
  public async flow_order_po_list_new_orders(inParams: {  }): Promise< { out?: { val?: string[] }, lastmodif?: string, orderdate?: string }> {
    if(!this._flow_order_po_list_new_orders) { 
      this._flow_order_po_list_new_orders = this.injector.get(Dynamics365BusinessCentral_flow_order_po_list_new_ordersService);
    }
    return this._flow_order_po_list_new_orders.run(inParams);
  }
   
   

   
 
  private _flow_order_po_update_bc_grid: Dynamics365BusinessCentral_flow_order_po_update_bc_gridService;
  public async flow_order_po_update_bc_grid(inParams: {  }): Promise<void> {
    if(!this._flow_order_po_update_bc_grid) { 
      this._flow_order_po_update_bc_grid = this.injector.get(Dynamics365BusinessCentral_flow_order_po_update_bc_gridService);
    }
    return this._flow_order_po_update_bc_grid.run(inParams);
  }
   
   

   
 
  private _flow_order_po_update_po_line: Dynamics365BusinessCentral_flow_order_po_update_po_lineService;
  public async flow_order_po_update_po_line(inParams: {  }): Promise< { out?: { val?: string[] }, out_err?: { err?: string[] }, okline?: { val?: number[] }, totalline?: { val?: number[] }, plookup?: { val?: string[] }, olookup?: { val?: string[] }, uom?: { val?: string[] }, result?: string }> {
    if(!this._flow_order_po_update_po_line) { 
      this._flow_order_po_update_po_line = this.injector.get(Dynamics365BusinessCentral_flow_order_po_update_po_lineService);
    }
    return this._flow_order_po_update_po_line.run(inParams);
  }
   
   

   
 
  private _flow_order_po_update_wl_grid: Dynamics365BusinessCentral_flow_order_po_update_wl_gridService;
  public async flow_order_po_update_wl_grid(inParams: {  }): Promise<void> {
    if(!this._flow_order_po_update_wl_grid) { 
      this._flow_order_po_update_wl_grid = this.injector.get(Dynamics365BusinessCentral_flow_order_po_update_wl_gridService);
    }
    return this._flow_order_po_update_wl_grid.run(inParams);
  }
   
   

   
 
  private _flow_order_queue: Dynamics365BusinessCentral_flow_order_queueService;
  public async flow_order_queue(inParams: { payload?: $frontendTypes.Dynamics365BusinessCentral.order_queue[], action?: string, skip?: number, take?: number, full_text_search?: string, application_name?: string, date_from_number?: string, date_to_number?: string }): Promise< { payload?: $frontendTypes.Dynamics365BusinessCentral.order_queue[], success?: boolean, error?: any[], count?: number }> {
    if(!this._flow_order_queue) { 
      this._flow_order_queue = this.injector.get(Dynamics365BusinessCentral_flow_order_queueService);
    }
    return this._flow_order_queue.run(inParams);
  }
   
   

   
 
  private _flow_order_queue_delete: Dynamics365BusinessCentral_flow_order_queue_deleteService;
  public async flow_order_queue_delete(inParams: { input?: $frontendTypes.Dynamics365BusinessCentral.order_queue[], criteria: { application_name: string } }): Promise< { success?: boolean, error?: any[] }> {
    if(!this._flow_order_queue_delete) { 
      this._flow_order_queue_delete = this.injector.get(Dynamics365BusinessCentral_flow_order_queue_deleteService);
    }
    return this._flow_order_queue_delete.run(inParams);
  }
   
   

   
 
  private _flow_order_queue_get: Dynamics365BusinessCentral_flow_order_queue_getService;
  public async flow_order_queue_get(inParams: { input?: $frontendTypes.Dynamics365BusinessCentral.order_queue[], criteria: { application_name: string, decrypt?: string, full_text_search?: string, skip?: number, take?: number } }): Promise< { output?: $frontendTypes.Dynamics365BusinessCentral.order_queue[], success?: boolean, error?: any[], count?: number }> {
    if(!this._flow_order_queue_get) { 
      this._flow_order_queue_get = this.injector.get(Dynamics365BusinessCentral_flow_order_queue_getService);
    }
    return this._flow_order_queue_get.run(inParams);
  }
   
   

   
 
  private _flow_order_queue_get_one: Dynamics365BusinessCentral_flow_order_queue_get_oneService;
  public async flow_order_queue_get_one(inParams: { input?: $frontendTypes.Dynamics365BusinessCentral.order_queue[], criteria: { application_name: string, decrypt?: boolean, full_text_search?: string } }): Promise< { output?: $frontendTypes.Dynamics365BusinessCentral.order_queue, success?: boolean, error?: any[] }> {
    if(!this._flow_order_queue_get_one) { 
      this._flow_order_queue_get_one = this.injector.get(Dynamics365BusinessCentral_flow_order_queue_get_oneService);
    }
    return this._flow_order_queue_get_one.run(inParams);
  }
   
   

   
 
  private _flow_order_queue_update: Dynamics365BusinessCentral_flow_order_queue_updateService;
  public async flow_order_queue_update(inParams: { input?: $frontendTypes.Dynamics365BusinessCentral.order_queue[], criteria: { application_name: string, encrypt?: boolean } }): Promise< { success?: boolean, error?: any[] }> {
    if(!this._flow_order_queue_update) { 
      this._flow_order_queue_update = this.injector.get(Dynamics365BusinessCentral_flow_order_queue_updateService);
    }
    return this._flow_order_queue_update.run(inParams);
  }
   
   

   
 
  private _flow_order_queue_write: Dynamics365BusinessCentral_flow_order_queue_writeService;
  public async flow_order_queue_write(inParams: { input?: $frontendTypes.Dynamics365BusinessCentral.order_queue[], criteria?: { application_name?: string } }): Promise< { output?: { q_id?: string }[], success?: boolean, error?: any[] }> {
    if(!this._flow_order_queue_write) { 
      this._flow_order_queue_write = this.injector.get(Dynamics365BusinessCentral_flow_order_queue_writeService);
    }
    return this._flow_order_queue_write.run(inParams);
  }
   
   

   
 
  private _flow_order_so_create_so: Dynamics365BusinessCentral_flow_order_so_create_soService;
  public async flow_order_so_create_so(inParams: {  }): Promise< { out?: { val?: string[] }, out_err?: { err?: string[] }, qid?: { val?: string[] }, w_id?: { val?: string[] }, shipmentid?: { val?: string[] }, orderid?: { val?: string[] }, o_id?: { val?: string[] }, p_id?: { val?: string[] }, output?: { val?: number[] }, guid?: { val?: string[] } }> {
    if(!this._flow_order_so_create_so) { 
      this._flow_order_so_create_so = this.injector.get(Dynamics365BusinessCentral_flow_order_so_create_soService);
    }
    return this._flow_order_so_create_so.run(inParams);
  }
   
   

   
 
  private _flow_order_so_create_so_line: Dynamics365BusinessCentral_flow_order_so_create_so_lineService;
  public async flow_order_so_create_so_line(inParams: { guid: string, p_id: number, o_id: number, shipmentid: number, orderid: number, qid: string, order_type: string, transaction_id: string }): Promise< { out?: { val?: string[] }, out_err?: { err?: string[] }, okline?: { val?: number[] }, totalline?: { val?: number[] }, plookup?: { val?: string[] }, olookup?: { val?: string[] }, uom?: { val?: string[] }, result?: string, oln?: { val?: string[] } }> {
    if(!this._flow_order_so_create_so_line) { 
      this._flow_order_so_create_so_line = this.injector.get(Dynamics365BusinessCentral_flow_order_so_create_so_lineService);
    }
    return this._flow_order_so_create_so_line.run(inParams);
  }
   
   

   
 
  private _flow_order_so_list_new_orders: Dynamics365BusinessCentral_flow_order_so_list_new_ordersService;
  public async flow_order_so_list_new_orders(inParams: {  }): Promise< { out?: { val?: string[] } }> {
    if(!this._flow_order_so_list_new_orders) { 
      this._flow_order_so_list_new_orders = this.injector.get(Dynamics365BusinessCentral_flow_order_so_list_new_ordersService);
    }
    return this._flow_order_so_list_new_orders.run(inParams);
  }
   
   

   
 
  private _flow_order_so_update_so_line: Dynamics365BusinessCentral_flow_order_so_update_so_lineService;
  public async flow_order_so_update_so_line(inParams: {  }): Promise< { out?: { val?: string[] }, out_err?: { err?: string[] }, okline?: { val?: number[] }, totalline?: { val?: number[] }, plookup?: { val?: string[] }, olookup?: { val?: string[] }, uom?: { val?: string[] }, result?: string }> {
    if(!this._flow_order_so_update_so_line) { 
      this._flow_order_so_update_so_line = this.injector.get(Dynamics365BusinessCentral_flow_order_so_update_so_lineService);
    }
    return this._flow_order_so_update_so_line.run(inParams);
  }
   
   

   
 
  private _flow_token_generate: Dynamics365BusinessCentral_flow_token_generateService;
  public async flow_token_generate(inParams: { transaction_id?: string }): Promise< { tokens?: { AccessToken?: string }, transaction_id?: string, retryInsert?: string }> {
    if(!this._flow_token_generate) { 
      this._flow_token_generate = this.injector.get(Dynamics365BusinessCentral_flow_token_generateService);
    }
    return this._flow_token_generate.run(inParams);
  }
   
   

   
 
  private _footprint_generic_json_material_import_flow: Dynamics365BusinessCentral_footprint_generic_json_material_import_flowService;
  public async footprint_generic_json_material_import_flow(inParams: { materials?: $frontendTypes.Dynamics365BusinessCentral.DatexMaterialInfo[], transaction_id?: string, ingress_id?: string, application_name?: string, project?: string, owner?: string, lookup?: string, matname?: string, matdesc?: string, matgroup?: string, cost?: string, price?: string, packg?: string }): Promise< { messages?: any[], results?: { requestId?: string, referenceCode?: string, project?: string, message?: string, entities?: any, transactionId?: string }[], reasons?: string[] }> {
    if(!this._footprint_generic_json_material_import_flow) { 
      this._footprint_generic_json_material_import_flow = this.injector.get(Dynamics365BusinessCentral_footprint_generic_json_material_import_flowService);
    }
    return this._footprint_generic_json_material_import_flow.run(inParams);
  }
   
   

   
 
  private _footprint_generic_json_material_to_entity_import_xml_flow: Dynamics365BusinessCentral_footprint_generic_json_material_to_entity_import_xml_flowService;
  public async footprint_generic_json_material_to_entity_import_xml_flow(inParams: { material?: $frontendTypes.Dynamics365BusinessCentral.DatexMaterialInfo, transaction_id?: string, source_lookup_code?: string, source_platform?: string }): Promise< { messages?: string[], project?: string, payload_xml?: string, payload_json?: any }> {
    if(!this._footprint_generic_json_material_to_entity_import_xml_flow) { 
      this._footprint_generic_json_material_to_entity_import_xml_flow = this.injector.get(Dynamics365BusinessCentral_footprint_generic_json_material_to_entity_import_xml_flowService);
    }
    return this._footprint_generic_json_material_to_entity_import_xml_flow.run(inParams);
  }
   
   

   
 
  private _submit_entity_import_xml_request: Dynamics365BusinessCentral_submit_entity_import_xml_requestService;
  public async submit_entity_import_xml_request(inParams: { referenceCode?: string, entityImportXml?: string, project?: string, warehouse?: string, groupId?: string, transactionGroupId?: string }): Promise< { requestId?: string }> {
    if(!this._submit_entity_import_xml_request) { 
      this._submit_entity_import_xml_request = this.injector.get(Dynamics365BusinessCentral_submit_entity_import_xml_requestService);
    }
    return this._submit_entity_import_xml_request.run(inParams);
  }
   
   

   
}
