import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { BarTender_ShellService } from './BarTender.shell.service';
import { BarTender_OperationService } from './BarTender.operation.service';
import { BarTender_DatasourceService } from './BarTender.datasource.index';
import { BarTender_FlowService } from './BarTender.flow.index';
import { BarTender_ReportService } from './BarTender.report.index';
import { BarTender_LocalizationService } from './BarTender.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './BarTender.frontend.types'
import { $frontendTypes as $types} from './BarTender.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



interface IBarTender_azure_blob_storage_navigation_gridComponentEntity {
Name?: string, Type?: string, Path?: string}



class BarTender_azure_blob_storage_navigation_gridComponentRowModel extends GridRowModel {
  grid: BarTender_azure_blob_storage_navigation_gridComponent;
  entity: IBarTender_azure_blob_storage_navigation_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    Type: new GridCellModel(
      new CellStyles(null, null),
      new ButtonModel('', new ButtonStyles(null, null), true, false, ' ', '', null)
,
null
      ),
    Name: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: BarTender_ShellService,
private datasources: BarTender_DatasourceService,
private flows: BarTender_FlowService,
private reports: BarTender_ReportService,
private localization: BarTender_LocalizationService,
private operations: BarTender_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: BarTender_azure_blob_storage_navigation_gridComponent, entity: IBarTender_azure_blob_storage_navigation_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Name].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: BarTender_azure_blob_storage_navigation_gridComponent, entity?: IBarTender_azure_blob_storage_navigation_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Name].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Name;
    const inParams = {
      $keys:[$resultKey],
      navigation_path:  $grid.vars.navigation_path ,
      recursive:  false ,
      use_explicit_file_path:  false ,
    };
    const data = await this.datasources.BarTender.ds_get_azure_blob_folder_content.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.Name.displayControl as TextModel).text = $row.entity.Name;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'Name' : {
        this.on_name_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: BarTender_azure_blob_storage_navigation_gridComponentRowModel,
  $grid: BarTender_azure_blob_storage_navigation_gridComponent, 
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.logger.log('BarTender', 'azure_blob_storage_navigation_grid.on_row_data_loaded');
  if ($row.entity.Type !== 'directory') {
      $row.cells.Name.styles.setStyle('color', 'lightgreen');
  }
  
  $row.cells.Type.displayControl.icon = $row.entity.Type === 'directory' ? 'icon-ic_fluent_folder_20_regular' : 'icon-ic_fluent_document_20_regular';
  }
  on_name_clicked(event = null) {
    return this.on_name_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_name_clickedInternal(
    $row: BarTender_azure_blob_storage_navigation_gridComponentRowModel,
  $grid: BarTender_azure_blob_storage_navigation_gridComponent, 
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.logger.log('BarTender', 'azure_blob_storage_navigation_grid.on_name_clicked');
  if ($row.entity.Type == 'directory') {
  
      $grid.vars.navigation_path += `/${$row.entity.Name}`;
  
      $grid.refresh();
  } else {
      const fileContent = (await $flows.BarTender.azure_blob_storage_get_file_content_flow({ file_path: $row.entity.Path, file_name: $row.entity.Name, use_explicit_path: true })).file_content;
      const blob = new Blob([fileContent], { type: 'text/plain' });
      const fileSource = await $utils.blob.toBase64(blob, true);
  
      await $shell.BarTender.openview_fileDialog({ file_content: fileSource }, 'modal', EModalSize.Xlarge);
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'BarTender-azure_blob_storage_navigation_grid',
  templateUrl: './BarTender.azure_blob_storage_navigation_grid.component.html'
})
export class BarTender_azure_blob_storage_navigation_gridComponent extends BaseComponent implements OnInit, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IBarTender_azure_blob_storage_navigation_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('fixedWidth', ['default','set-width-table']);

  fullTextSearch: string;



  //#region Variables
  vars: { navigation_path?: string } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     Type: new GridHeaderModel(new HeaderStyles(null, null), ' ', false, false, 50),       Name: new GridHeaderModel(new HeaderStyles(null, null), 'Name', false, false, 800),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: BarTender_azure_blob_storage_navigation_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;


  topToolbar = {
      create_directory: new ToolModel(new ButtonModel('create_directory', new ButtonStyles(null, null), false, false, 'New directory', 'icon-ic_fluent_folder_add_20_regular', null)
    , false),
      upload_file: new ToolModel(new ButtonModel('upload_file', new ButtonStyles(null, null), false, false, 'Upload file(s)', 'icon-ic_fluent_arrow_upload_20_regular', null)
    , false),
      download_file: new ToolModel(new ButtonModel('download_file', new ButtonStyles(null, null), false, false, 'Download file', 'icon-ic_fluent_arrow_download_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      delete_selected: new ToolModel(new ButtonModel('delete_selected', new ButtonStyles(['destructive'], null), false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
  });

  filters = {
    nav_back: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, false, ' ', 'icon-ic_fluent_arrow_step_back_20_filled', null)
, new ControlContainerStyles(null, null), '', false, false),
    navigation_path: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Path', false, false),
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: BarTender_ShellService,
    private datasources: BarTender_DatasourceService,
    private flows: BarTender_FlowService,
    private reports: BarTender_ReportService,
    private localization: BarTender_LocalizationService,
    private operations: BarTender_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Azure blob storage';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 50;

    (this.filters.navigation_path.control as TextModel).text = $grid.vars.navigation_path;

    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      navigation_path:  $grid.vars.navigation_path ,
      recursive:  false ,
      use_explicit_file_path:  false ,
    };
    try {
    const data = await this.datasources.BarTender.ds_get_azure_blob_folder_content.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new BarTender_azure_blob_storage_navigation_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: BarTender_azure_blob_storage_navigation_gridComponent,
  
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.logger.log('BarTender', 'azure_blob_storage_navigation_grid.on_init');
  $grid.vars.navigation_path = '';
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: BarTender_azure_blob_storage_navigation_gridComponent,
  
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.logger.log('BarTender', 'azure_blob_storage_navigation_grid.on_data_loaded');
  $grid.filters.navigation_path.control.text = $grid.vars.navigation_path;
  
  $grid.filters.nav_back.control.readOnly = !$utils.isDefinedTrimmed($grid.vars.navigation_path);
  }
  on_navigate_back_clicked(event = null) {
    return this.on_navigate_back_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_navigate_back_clickedInternal(
    $grid: BarTender_azure_blob_storage_navigation_gridComponent,
  
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.logger.log('BarTender', 'azure_blob_storage_navigation_grid.on_navigate_back_clicked');
  const currentPathItems = $grid.vars.navigation_path.split('/');
  
  $grid.vars.navigation_path = currentPathItems.filter((directory, index) => index !== currentPathItems.length - 1).join('/');
  
  $grid.refresh();
  }
  on_delete_selected_clicked(event = null) {
    return this.on_delete_selected_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_selected_clickedInternal(
    $grid: BarTender_azure_blob_storage_navigation_gridComponent,
  
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.logger.log('BarTender', 'azure_blob_storage_navigation_grid.on_delete_selected_clicked');
  try {
      let deletedCount = 0;
      if ($grid.selectedRows.length > 0) {
          for (let row of $grid.selectedRows) {
              if (row.entity.Type === 'directory') {
                  const childItems = (await $datasources.BarTender.ds_get_azure_blob_folder_content.getList({ navigation_path: row.entity.Path + row.entity.Name + '/', recursive: true, use_explicit_file_path: true })).result;
  
                  if (childItems.length > 0) {
                      const isConfirmed = await $shell.BarTender.openConfirmationDialog('Delete directory with child items?', `Deleting directory ${row.entity.Name} will also delete all ${childItems.length} child items.`, 'Delete', 'Cancel');
                      if (!isConfirmed) { continue; }
                  }
  
                  childItems.sort((a, b) => b.Path.length - a.Path.length);
  
                  for (let childItem of childItems) {
                      await $flows.BarTender.azure_blob_storage_delete_item_flow({ file_path: childItem.Path, file_name: childItem.Name, use_explicit_path: true });
                      deletedCount++;
                  }
              }
  
              await $flows.BarTender.azure_blob_storage_delete_item_flow({ file_path: $grid.vars.navigation_path, file_name: row.entity.Name });
              deletedCount++;
          }
  
          if (deletedCount > 0) {
              $shell.BarTender.openToaster(`${deletedCount} items deleted`, null, EToasterType.Success, { timeOut: 5000, tapToDismiss: true, progressBar: false });
          }
  
          $grid.refresh();
      }
  } catch (error) {
      $shell.BarTender.openToaster('Error deleting selected items', error.message, EToasterType.Error, { timeOut: 10000, tapToDismiss: true, progressBar: true });
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: BarTender_azure_blob_storage_navigation_gridComponent,
  
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.logger.log('BarTender', 'azure_blob_storage_navigation_grid.on_row_selected');
  $grid.topToolbar.delete_selected.control.readOnly = $grid.selectedRows.length === 0;
  $grid.topToolbar.download_file.control.readOnly = $grid.selectedRows.filter(row => row.entity.Type === 'file').length !== 1;
  
  const pluralChar = $grid.selectedRows.length > 1 ? 's' : '';
  
  $grid.topToolbar.delete_selected.control.label = `Delete file${pluralChar}`;
  $grid.topToolbar.download_file.control.label = `Download file${pluralChar}`;
  }
  on_create_directory_clicked(event = null) {
    return this.on_create_directory_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_directory_clickedInternal(
    $grid: BarTender_azure_blob_storage_navigation_gridComponent,
  
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.logger.log('BarTender', 'azure_blob_storage_navigation_grid.on_create_directory_clicked');
  const result = await $shell.BarTender.openazure_blob_storage_create_directory_formDialog({ path: $grid.vars.navigation_path }, 'modal', EModalSize.Standard);
  
  if (result.is_confirmed) { $grid.refresh(); }
  }
  on_upload_file_clicked(event = null) {
    return this.on_upload_file_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_upload_file_clickedInternal(
    $grid: BarTender_azure_blob_storage_navigation_gridComponent,
  
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.logger.log('BarTender', 'azure_blob_storage_navigation_grid.on_upload_file_clicked');
  const selectedFiles = await $utils.blob.openFile({ multiple: true });
  
  for (const file of selectedFiles) {
      const reader = new FileReader();
      reader.onload = async function (e) {
          const fileContent = e.target.result;
  
          const response = await $flows.BarTender.azure_blob_storage_upload_file_flow({
              file_path: $grid.vars.navigation_path,
              file_name: file.name,
              file_content: fileContent
          });
  
          if ($utils.isDefined(response.reasons)) {
              throw new Error(response.reasons.join(','));
          }
  
          $grid.refresh();
      }
  
      reader.readAsText(file);
  }
  const pluralChar = selectedFiles.length > 1 ? 's' : '';
  
  $shell.BarTender.openToaster(`${selectedFiles.length} file${pluralChar} uploaded`, null, EToasterType.Success, { timeOut: 5000, positionClass: EToasterPosition.topRight });
  
  }
  on_download_clicked(event = null) {
    return this.on_download_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_download_clickedInternal(
    $grid: BarTender_azure_blob_storage_navigation_gridComponent,
  
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.logger.log('BarTender', 'azure_blob_storage_navigation_grid.on_download_clicked');
  
  
  for (let row of $grid.selectedRows) {
      const fileContent = (await $flows.BarTender.azure_blob_storage_get_file_content_flow({ file_path: row.entity.Path, file_name: row.entity.Name, use_explicit_path: true })).file_content;
  
      await $utils.blob.saveFile(new Blob([fileContent], {type: "text/plain"}), { fileName: row.entity.Name });
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
