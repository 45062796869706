<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="browse_containers_button" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_browse_containers_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="unassign_containers" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_unassign_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Name.displayControl.styles.style"
          [ngClass]="row.cells.Name.displayControl.styles.classes"
          [matTooltip]="row.cells.Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ShippingContainers-container_type_dd_single 
        data-cy="selector"
        [type]="row.cells.Name.editControl.type"
        [formControl]="row.formGroup.controls['Name_edit']"
        (displayTextChange)="row.cells.Name.editControl.displayText=$event"
        [placeholder]="row.cells.Name.editControl.placeholder"
        [styles]="row.cells.Name.editControl.styles"
        [tooltip]="row.cells.Name.editControl.tooltip"
      [ownerId]="row.$fields_Name_selector_inParams_ownerId"
      [projectId]="row.$fields_Name_selector_inParams_projectId"
      [materialId]="row.$fields_Name_selector_inParams_materialId"
    >
    </ShippingContainers-container_type_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Description.displayControl.styles.style"
          [ngClass]="row.cells.Description.displayControl.styles.classes"
          [matTooltip]="row.cells.Description.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Length">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Length.displayControl.styles.style"
          [ngClass]="row.cells.Length.displayControl.styles.classes"
          [matTooltip]="row.cells.Length.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Length.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Width">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Width.displayControl.styles.style"
          [ngClass]="row.cells.Width.displayControl.styles.classes"
          [matTooltip]="row.cells.Width.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Width.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Height">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Height.displayControl.styles.style"
          [ngClass]="row.cells.Height.displayControl.styles.classes"
          [matTooltip]="row.cells.Height.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Height.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Dimension_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Dimension_uom.displayControl.styles.style"
          [ngClass]="row.cells.Dimension_uom.displayControl.styles.classes"
          [matTooltip]="row.cells.Dimension_uom.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Dimension_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="WeightCapacity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.WeightCapacity.displayControl.styles.style"
          [ngClass]="row.cells.WeightCapacity.displayControl.styles.classes"
          [matTooltip]="row.cells.WeightCapacity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.WeightCapacity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Weight_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Weight_uom.displayControl.styles.style"
          [ngClass]="row.cells.Weight_uom.displayControl.styles.classes"
          [matTooltip]="row.cells.Weight_uom.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Weight_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="CatchAll">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['CatchAll_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.CatchAll.displayControl.styles.style"
                  [ngClass]="row.cells.CatchAll.displayControl.styles.classes"
                  [matTooltip]="row.cells.CatchAll.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.CatchAll.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
