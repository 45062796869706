import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class DockAppointments_ds_get_dock_appointment_by_orderId_top1Service {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number }): 
  Promise<{ result: { Id?: number, DockAppointmentId?: number, DockAppointment?: { AssignedlLocationContainerId?: number, CheckedInOn?: string, LookupCode?: string, ScheduledArrival?: string, ScheduledDeparture?: string, ScheduledLocationContainerId?: number, StatusId?: number, TypeId?: number, WarehouseId?: number, Warehouse?: { TimeZoneId?: string }, AssignedLocation?: { Name?: string }, ScheduledLocation?: { Name?: string } }, convertedScheduledArrival?: { id?: number, convertedDate?: string }, convertedCheckInOn?: { id?: number, convertedDate?: string } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_get_dock_appointment_by_orderId_top1/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId: number    }) {
        if(isNil(inParams.orderId)) {
          return true; 
        }
      return false;
    }
}
