import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Equipment_ShellService } from './Equipment.shell.service';
import { Equipment_OperationService } from './Equipment.operation.service';
import { Equipment_DatasourceService } from './Equipment.datasource.index';
import { Equipment_FlowService } from './Equipment.flow.index';
import { Equipment_ReportService } from './Equipment.report.index';
import { Equipment_LocalizationService } from './Equipment.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Equipment.frontend.types'
import { $frontendTypes as $types} from './Equipment.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Equipment_equipment_type_dd_singleComponent } from './Equipment.equipment_type_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Equipment_equipment_type_dd_singleComponent),
  ],
  selector: 'Equipment-create_equipment_form',
  templateUrl: './Equipment.create_equipment_form.component.html'
})
export class Equipment_create_equipment_formComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirmed?: boolean } = { confirmed: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirmed?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    equipmentType: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    equipmentName: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    macAddress: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm_button: new ToolModel(new ButtonModel('confirm_button', new ButtonStyles(['primary'], null), false, false, 'Confirm', '', null)
    , false),
      cancel_button: new ToolModel(new ButtonModel('cancel_button', new ButtonStyles(null, null), false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    equipmentType: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['equipmentType'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Equipment type', true, false),
    equipmentName: new FieldModel(new TextBoxModel(this.formGroup.controls['equipmentName'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Equipment name', true, false),
    macAddress: new FieldModel(new TextBoxModel(this.formGroup.controls['macAddress'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'MAC address', false, false),
  };

  fieldsets = {
  equipmentDetail: new FieldsetModel('Equipment detail', false, false, true, false),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    equipmentType: this.fields.equipmentType.control.valueChanges
    ,
    equipmentName: this.fields.equipmentName.control.valueChanges
    ,
    macAddress: this.fields.macAddress.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Equipment_ShellService,
private datasources: Equipment_DatasourceService,
private flows: Equipment_FlowService,
private reports: Equipment_ReportService,
private localization: Equipment_LocalizationService,
private operations: Equipment_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Create Equipment';
  
    const $form = this;
    const $utils = this.utils;

    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .equipmentType
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .equipmentName
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .macAddress
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: Equipment_create_equipment_formComponent,
  
    $shell: Equipment_ShellService,
    $datasources: Equipment_DatasourceService,
    $flows: Equipment_FlowService,
    $reports: Equipment_ReportService,
    $settings: SettingsValuesService,
    $operations: Equipment_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Equipment_LocalizationService,
    $event: any
  ) {
  
  await new Promise (resolve => setTimeout(resolve, 200));
  
  $form.fields.equipmentType.control.focus();
  }
  on_confirm_click(event = null) {
    return this.on_confirm_clickInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickInternal(
    $form: Equipment_create_equipment_formComponent,
  
    $shell: Equipment_ShellService,
    $datasources: Equipment_DatasourceService,
    $flows: Equipment_FlowService,
    $reports: Equipment_ReportService,
    $settings: SettingsValuesService,
    $operations: Equipment_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Equipment_LocalizationService,
    $event: any
  ) {
  try {
  
      $form.toolbar.confirm_button.control.readOnly = true;
      $form.toolbar.cancel_button.control.readOnly = true;
  
      //Form validation
      if(!$utils.isDefined($form.fields.equipmentName.control.value))
          throw new Error('Equipment name is required');
  
      if(!$utils.isDefined($form.fields.equipmentType.control.value))
          throw new Error('Equipment type is required')
  
      const payload: any = {};
      
      payload.equipment = {} as any;
      payload.equipment.lookupCode = $form.fields.equipmentName.control.value as string;
      payload.equipment.typeId = $form.fields.equipmentType.control.value as number;
      payload.equipment.macAddress = $form.fields.macAddress.control.value as string;
      
      const result = await $flows.Equipment.create_equipment_flow( payload );
  
      if(result.reasons.length > 0)
          throw new Error(result.reasons[0]);
      
      console.log(JSON.stringify(result));
  
      $form.outParams.confirmed = true;
      $form.close();
  
  } catch(error) {
      
      $form.outParams.confirmed = false;
      await $shell.Utilities.openErrorDialog('Error creating equipment', error.message);
  
  } finally {
  
      $form.toolbar.confirm_button.control.readOnly = false;
      $form.toolbar.cancel_button.control.readOnly = false;
  
  }
  }
  on_cancel_click(event = null) {
    return this.on_cancel_clickInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickInternal(
    $form: Equipment_create_equipment_formComponent,
  
    $shell: Equipment_ShellService,
    $datasources: Equipment_DatasourceService,
    $flows: Equipment_FlowService,
    $reports: Equipment_ReportService,
    $settings: SettingsValuesService,
    $operations: Equipment_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Equipment_LocalizationService,
    $event: any
  ) {
  $form.close();
  }
  //#endregion private flows
}
