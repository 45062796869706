import { 
  Component, 
  OnInit,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  NgZone,
  Inject
} from '@angular/core';

import {
  ChartComponent,
  ApexTitleSubtitle,
  ApexChart,
  ApexLegend,
  ApexTooltip,
  ApexDataLabels,
  ApexStroke,
  ApexTheme
} from 'ng-apexcharts';

import { isNil } from 'lodash-es';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Owners_ShellService } from './Owners.shell.service';
import { Owners_OperationService } from './Owners.operation.service';
import { Owners_DatasourceService } from './Owners.datasource.index';
import { Owners_FlowService } from './Owners.flow.index';
import { Owners_ReportService } from './Owners.report.index';
import { Owners_LocalizationService } from './Owners.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Owners.frontend.types'
import { $frontendTypes as $types} from './Owners.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Owners-owners_by_status_widget',
  templateUrl: './Owners.owners_by_status_widget.component.html'
})
export class Owners_owners_by_status_widgetComponent extends BaseComponent implements OnInit {
  //#region Outputs
  @Output()
  outParamsChange = new EventEmitter<{ slice?: { entity?: { Count?: number, StatusId?: number, Status?: { Label?: string } } } }>();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs


  //#region Variables
  //#endregion
  @ViewChild('pieChart') 
  pieChart: ChartComponent;

  private selected = false;
  private entities: { Count?: number, StatusId?: number, Status?: { Label?: string } }[];

  series: number[] = [];
  labels: string[] = [];

  chart: ApexChart = {
    type: 'pie',
    width: '100%',
    height: '170px', // fixed heigh because of issues with ApexCharts not being able to respect the container height
    redrawOnParentResize: true,
    fontFamily: '"Segoe UI", "Roboto", sans-serif',
    events: {
      dataPointSelection: (event, chartContext, config) => {
        // run inside the Angular zone, since ng-apexcharts runs almost everything outside of it
        // see: https://github.com/apexcharts/ng-apexcharts/blob/master/projects/ng-apexcharts/src/lib/chart/chart.component.ts
        this.ngZone.run(() => {
          if (this.isPiePointSelected(config.dataPointIndex)) {
            this.outParamsChange.emit({ slice: null});
          } else {
            this.outParamsChange.emit({ slice: { entity: this.entities[config.dataPointIndex] } } );
          }
        });
      }
    }
  };
  dataLabels: ApexDataLabels = {
    enabled: false,
  };
  stroke: ApexStroke = {
    width: 0
  };
  title: ApexTitleSubtitle = {
  };
  legend: ApexLegend = {
    show: false
  };
  tooltip: ApexTooltip = {
    followCursor: false,
    fillSeriesColor: true,
    onDatasetHover: {
      highlightDataSeries: true,
    },
    style: {
      fontSize: '14px',
      fontFamily: '"Segoe UI", "Roboto", sans-serif',
    },
    enabled: true
  };
  theme: ApexTheme = {
    palette: 'palette2',
  };

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Owners_ShellService,
    private datasources: Owners_DatasourceService,
    private flows: Owners_FlowService,
    private reports: Owners_ReportService,
    private localization: Owners_LocalizationService,
    private operations: Owners_OperationService,
    private logger: CleanupLoggerService,
    private ngZone: NgZone
  ) { 
    super();
  }

  ngOnInit(): void {
    this.$init();
  }
  


  initialized = false;

  async $init() {
    
    await this.$dataLoad();

    this.initialized = true;
  }
  
  async $dataLoad() {
    const $widget = this;
    const $utils = this.utils;

    const dsParams = {
    };

    const data = await this.datasources.Owners.ds_owners_by_status_widget.getList(dsParams);
    this.entities = data.result;
    await this.$dataLoaded();
  }

   async $dataLoaded() {
    const $widget = this;
    const $utils = this.utils;

    if(this.entities) {
      this.labels = this.entities.map(e => { 
        const $slice = { entity:  e };
        return $slice.entity.Status.Label;
      });

      this.series = this.entities.map(e => { 
        const $slice = { entity:  e };
        return $slice.entity.Count;
      });
    }

  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  private isPiePointSelected(pointIndex: number) {
    if (this.pieChart) {
      const pieSlice = (this.pieChart as any).chartObj.w.globals.dom.Paper.select(`.apexcharts-pie-slice-${pointIndex}`).members[0];
      const isSelected = pieSlice.attr('data:pieClicked');
      return isSelected === 'true';
    }
    return false;
  }

}
