<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="complete" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_complete_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="refresh" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_refresh_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator1" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="on_delete" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="cancel" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_cancel_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-show_all_operation_codes" *ngIf="!filters.show_all_operation_codes.hidden && !filters.show_all_operation_codes.removed" 
            class="field-container standard {{filters.show_all_operation_codes.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.show_all_operation_codes.styles.style"
            [ngClass]="filters.show_all_operation_codes.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.show_all_operation_codes.label + (filters.show_all_operation_codes.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.show_all_operation_codes.label}}<span *ngIf="filters.show_all_operation_codes.required" class="required-asterisk">*</span>
          </label>
        </div>
        <mat-slide-toggle data-cy="slideToggle" formControlName="show_all_operation_codes"
                      color="primary"
                      class="datex-toggle"
                      [ngStyle]="filters.show_all_operation_codes.control.styles.style"
                      [ngClass]="filters.show_all_operation_codes.control.styles.classes"
                      [matTooltip]="filters.show_all_operation_codes.control.tooltip"
                      matTooltipClass="datex-control-tooltip">{{filters.show_all_operation_codes.control.label}}</mat-slide-toggle>
        <ng-container *ngIf="filters.show_all_operation_codes.invalid">
          <ng-container *ngFor="let error of filters.show_all_operation_codes.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-auto_complete_on_creation" *ngIf="!filters.auto_complete_on_creation.hidden && !filters.auto_complete_on_creation.removed" 
            class="field-container standard {{filters.auto_complete_on_creation.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.auto_complete_on_creation.styles.style"
            [ngClass]="filters.auto_complete_on_creation.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.auto_complete_on_creation.label + (filters.auto_complete_on_creation.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.auto_complete_on_creation.label}}<span *ngIf="filters.auto_complete_on_creation.required" class="required-asterisk">*</span>
          </label>
        </div>
        <mat-slide-toggle data-cy="slideToggle" formControlName="auto_complete_on_creation"
                      color="primary"
                      class="datex-toggle"
                      [ngStyle]="filters.auto_complete_on_creation.control.styles.style"
                      [ngClass]="filters.auto_complete_on_creation.control.styles.classes"
                      [matTooltip]="filters.auto_complete_on_creation.control.tooltip"
                      matTooltipClass="datex-control-tooltip">{{filters.auto_complete_on_creation.control.label}}</mat-slide-toggle>
        <ng-container *ngIf="filters.auto_complete_on_creation.invalid">
          <ng-container *ngFor="let error of filters.auto_complete_on_creation.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="owner">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.owner.displayControl.styles.style"
          [ngClass]="row.cells.owner.displayControl.styles.classes"
          [matTooltip]="row.cells.owner.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.owner.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Owners-owners_dd_single 
        data-cy="selector"
        [type]="row.cells.owner.editControl.type"
        [formControl]="row.formGroup.controls['owner_edit']"
        (displayTextChange)="row.cells.owner.editControl.displayText=$event"
        [placeholder]="row.cells.owner.editControl.placeholder"
        [styles]="row.cells.owner.editControl.styles"
        [tooltip]="row.cells.owner.editControl.tooltip"
      [statusId]="row.$fields_owner_selector_inParams_statusId"
      [projectId]="row.$fields_owner_selector_inParams_projectId"
    >
    </Owners-owners_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes"
          [matTooltip]="row.cells.project.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Owners-projects_dd_single 
        data-cy="selector"
        [type]="row.cells.project.editControl.type"
        [formControl]="row.formGroup.controls['project_edit']"
        (displayTextChange)="row.cells.project.editControl.displayText=$event"
        [placeholder]="row.cells.project.editControl.placeholder"
        [styles]="row.cells.project.editControl.styles"
        [tooltip]="row.cells.project.editControl.tooltip"
      [statusId]="row.$fields_project_selector_inParams_statusId"
      [ownerId]="row.$fields_project_selector_inParams_ownerId"
    >
    </Owners-projects_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="operation_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.operation_code.displayControl.styles.style"
          [ngClass]="row.cells.operation_code.displayControl.styles.classes"
          [matTooltip]="row.cells.operation_code.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.operation_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <WorkOrders-accessorial_operationcodes_by_projectId_dd_single 
        data-cy="selector"
        [type]="row.cells.operation_code.editControl.type"
        [formControl]="row.formGroup.controls['operation_code_edit']"
        (displayTextChange)="row.cells.operation_code.editControl.displayText=$event"
        [placeholder]="row.cells.operation_code.editControl.placeholder"
        [styles]="row.cells.operation_code.editControl.styles"
        [tooltip]="row.cells.operation_code.editControl.tooltip"
      [projectId]="row.$fields_operation_code_selector_inParams_projectId"
      [showAllOpCodes]="row.$fields_operation_code_selector_inParams_showAllOpCodes"
    >
    </WorkOrders-accessorial_operationcodes_by_projectId_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="expected_amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.expected_amount.displayControl.styles.style"
          [ngClass]="row.cells.expected_amount.displayControl.styles.classes"
          [matTooltip]="row.cells.expected_amount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.expected_amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['expected_amount_edit']"
            matInput
            numberBox
            [format]="row.cells.expected_amount.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.expected_amount.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.expected_amount.editControl.placeholder}}"
            [ngStyle]="row.cells.expected_amount.editControl.styles.style"
            [ngClass]="row.cells.expected_amount.editControl.styles.classes"
            [matTooltip]="row.cells.expected_amount.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="actual_amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.actual_amount.displayControl.styles.style"
          [ngClass]="row.cells.actual_amount.displayControl.styles.classes"
          [matTooltip]="row.cells.actual_amount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.actual_amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['actual_amount_edit']"
            matInput
            numberBox
            [format]="row.cells.actual_amount.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.actual_amount.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.actual_amount.editControl.placeholder}}"
            [ngStyle]="row.cells.actual_amount.editControl.styles.style"
            [ngClass]="row.cells.actual_amount.editControl.styles.classes"
            [matTooltip]="row.cells.actual_amount.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.uom.displayControl.styles.style"
          [ngClass]="row.cells.uom.displayControl.styles.classes"
          [matTooltip]="row.cells.uom.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Locations-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.uom.editControl.type"
        [formControl]="row.formGroup.controls['uom_edit']"
        (displayTextChange)="row.cells.uom.editControl.displayText=$event"
        [placeholder]="row.cells.uom.editControl.placeholder"
        [styles]="row.cells.uom.editControl.styles"
        [tooltip]="row.cells.uom.editControl.tooltip"
    >
    </Locations-measurement_units_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes"
          [matTooltip]="row.cells.notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['notes_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.notes.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.notes.editControl.placeholder}}"
            [ngStyle]="row.cells.notes.editControl.styles.style"
            [ngClass]="row.cells.notes.editControl.styles.classes"
            [matTooltip]="row.cells.notes.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.status.displayControl.styles.style"
          [ngClass]="row.cells.status.displayControl.styles.classes"
          [matTooltip]="row.cells.status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="billing_record">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.billing_record.displayControl.styles.style"
          [ngClass]="row.cells.billing_record.displayControl.styles.classes"
          [matTooltip]="row.cells.billing_record.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.billing_record.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="invoice">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.invoice.displayControl.styles.style"
          [ngClass]="row.cells.invoice.displayControl.styles.classes"
          [matTooltip]="row.cells.invoice.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.invoice.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
