import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



interface IFootPrintManager_transload_order_received_inventory_gridComponentEntity {
Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, GrossWeight?: number, LotId?: number, MaterialId?: number, Material?: { LookupCode?: string }, Lot?: { LookupCode?: string, VendorLot?: { LookupCode?: string, Vat?: string } }, ActualTargetLicensePlate?: { Archived?: boolean, LookupCode?: string, ShipmentId?: number, Location?: { Name?: string }, ParentLicensePlate?: { Id?: number, LookupCode?: string, ShipmentId?: number, ShipmentAssociation?: { Shipmentid?: number, Shipment?: { Id?: number, LookupCode?: string, StatusId?: number, WaveId?: number, Wave?: { Id?: number, StatusId?: number, Status?: { Name?: string } }, OrderLookups?: { OrderId?: number, Order?: { OrderStatusId?: number, Status?: { Name?: string } } }[], Status?: { Name?: string } } }[] }, Shipment?: { Id?: number, LookupCode?: string, StatusId?: number, WaveId?: number, Wave?: { StatusId?: number, Status?: { Name?: string } }, OrderLookups?: { OrderId?: number, Order?: { OrderStatusId?: number, Status?: { Name?: string } } }[], Status?: { Name?: string } } }, ActualPackagedPack?: { ShortName?: string }, WeightUOM?: { Short_name?: string }, ShipmentLine?: { ExpectedPackagedAmount?: number, OrderLineNumber?: number, ExpectedPackaged?: { ShortName?: string } }}

interface IFootPrintManager_transload_order_received_inventory_gridComponentInParams {
  orderId: number, shipmentId: number, transload_id: number}


class FootPrintManager_transload_order_received_inventory_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_transload_order_received_inventory_gridComponent;
  entity: IFootPrintManager_transload_order_received_inventory_gridComponentEntity;

  vars: { stateId?: number } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    linenumber: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    material: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    Lot: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    vendorlot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    total_expected: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    actual_packaged_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    licenseplate: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    location: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    outbound_shipment: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    outbound_shipment_state: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_transload_order_received_inventory_gridComponent, entity: IFootPrintManager_transload_order_received_inventory_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_transload_order_received_inventory_gridComponent, entity?: IFootPrintManager_transload_order_received_inventory_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      orderId:  $grid.inParams.orderId ,
      shipmentId:  $grid.inParams.shipmentId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.FootPrintManager.ds_transload_received_inventory_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.linenumber.displayControl as TextModel).text = $row.entity.ShipmentLine?.OrderLineNumber?.toString();
    (this.cells.material.displayControl as TextModel).text = $row.entity.Material.LookupCode;
    (this.cells.Lot.displayControl as TextModel).text = $row.entity.Lot.LookupCode;
    (this.cells.licenseplate.displayControl as TextModel).text = $row.entity.ActualTargetLicensePlate?.LookupCode;
    (this.cells.location.displayControl as TextModel).text = $row.entity.ActualTargetLicensePlate?.Location?.Name;
    (this.cells.outbound_shipment.displayControl as TextModel).text = $row.entity.ActualTargetLicensePlate?.ParentLicensePlate?.ShipmentAssociation[0]?.Shipment?.LookupCode ?? $row.entity.ActualTargetLicensePlate?.Shipment?.LookupCode;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'material' : {
        this.on_material_clicked();
        break;
      }
      case 'Lot' : {
        this.on_lot_clicked();
        break;
      }
      case 'licenseplate' : {
        this.on_licenseplate_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_material_clicked(event = null) {
    return this.on_material_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_clickedInternal(
    $row: FootPrintManager_transload_order_received_inventory_gridComponentRowModel,
  $grid: FootPrintManager_transload_order_received_inventory_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootPrintManager.opensingle_material_hub({materialId: $row.entity.MaterialId});
  }
  on_lot_clicked(event = null) {
    return this.on_lot_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_clickedInternal(
    $row: FootPrintManager_transload_order_received_inventory_gridComponentRowModel,
  $grid: FootPrintManager_transload_order_received_inventory_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootPrintManager.opensingle_lot_hub({lotId: $row.entity.LotId});
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_transload_order_received_inventory_gridComponentRowModel,
  $grid: FootPrintManager_transload_order_received_inventory_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  if ($utils.isDefined($row.entity.Lot?.VendorLot?.Vat)) {
      $row.cells.vendorlot.displayControl.text = $row.entity.Lot?.VendorLot?.LookupCode + ' ' + $row.entity.Lot?.VendorLot?.Vat
  }
  else {
      $row.cells.vendorlot.displayControl.text = $row.entity.Lot?.VendorLot.LookupCode
  }
  
  
  // Set expected column
  $row.cells.total_expected.displayControl.text = $row.entity.ShipmentLine?.ExpectedPackagedAmount?.toString() + ' ' +  $row.entity.ShipmentLine?.ExpectedPackaged?.ShortName
  
  
  // Set actual column
  $row.cells.actual_packaged_amount.displayControl.text = $row.entity.ActualPackagedAmount?.toString() + ' ' +  $row.entity.ActualPackagedPack?.ShortName
  }
  on_licenseplate_clicked(event = null) {
    return this.on_licenseplate_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_licenseplate_clickedInternal(
    $row: FootPrintManager_transload_order_received_inventory_gridComponentRowModel,
  $grid: FootPrintManager_transload_order_received_inventory_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootPrintManager.opensingle_licenseplate_hub({licenseplateId: $row.entity.ActualTargetLicensePlateId});
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-transload_order_received_inventory_grid',
  templateUrl: './FootPrintManager.transload_order_received_inventory_grid.component.html'
})
export class FootPrintManager_transload_order_received_inventory_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_transload_order_received_inventory_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_transload_order_received_inventory_gridComponentInParams = { orderId: null, shipmentId: null, transload_id: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     linenumber: new GridHeaderModel(new HeaderStyles(null, null), 'Line #', false, false, null),       material: new GridHeaderModel(new HeaderStyles(null, null), 'Material', false, false, null),       Lot: new GridHeaderModel(new HeaderStyles(null, null), 'Lot', false, false, null),       vendorlot: new GridHeaderModel(new HeaderStyles(null, null), 'Vendor lot', false, false, null),       total_expected: new GridHeaderModel(new HeaderStyles(null, null), 'Expected', false, false, null),       actual_packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Actual', false, false, null),       licenseplate: new GridHeaderModel(new HeaderStyles(null, null), 'License plate', false, false, null),       location: new GridHeaderModel(new HeaderStyles(null, null), 'Current location', false, false, null),       outbound_shipment: new GridHeaderModel(new HeaderStyles(null, null), 'Outbound shipment', false, false, null),       outbound_shipment_state: new GridHeaderModel(new HeaderStyles(null, null), 'Outbound status', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_transload_order_received_inventory_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }
  @Input('shipmentId') set $inParams_shipmentId(value: any) {
    this.inParams['shipmentId'] = value;
  }
  get $inParams_shipmentId(): any {
    return this.inParams['shipmentId'] ;
  }
  @Input('transload_id') set $inParams_transload_id(value: any) {
    this.inParams['transload_id'] = value;
  }
  get $inParams_transload_id(): any {
    return this.inParams['transload_id'] ;
  }

  topToolbar = {
      unreceive: new ToolModel(new ButtonModel('unreceive', new ButtonStyles(null, null), false, false, 'Unreceive', 'icon-ic_fluent_screen_cut_20_regular', null)
    , false),
      outbound_shipment_transfer: new ToolModel(new ButtonModel('outbound_shipment_transfer', new ButtonStyles(null, null), false, false, 'Assign shipment', 'icon-datex-Outbound', null)
    , false),
      unload: new ToolModel(new ButtonModel('unload', new ButtonStyles(null, null), false, false, 'Unload', 'ms-Icon ms-Icon--DependencyAdd', null)
    , false)
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Received inventory';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
      if(isNil(this.inParams.shipmentId)) {
        this.$missingRequiredInParams.push('shipmentId');
      }
      if(isNil(this.inParams.transload_id)) {
        this.$missingRequiredInParams.push('transload_id');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 200;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      orderId:  $grid.inParams.orderId ,
      shipmentId:  $grid.inParams.shipmentId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
    const data = await this.datasources.FootPrintManager.ds_transload_received_inventory_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_transload_order_received_inventory_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_transload_order_received_inventory_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let order = (await $datasources.PurchaseOrders.ds_get_order_by_orderId.get({ orderId: $grid.inParams.orderId })).result
  if (order.OrderStatusId !== 4) {
      const selected_row_count = $grid.selectedRows.length;
      // { stateId: 1, stateName: 'Empty' },
      // { stateId: 3, stateName: 'Ready to process wave' },
      // { stateId: 4, stateName: 'Ready to cross dock' },
      // { stateId: 5, stateName: 'Ready to cross dock' },
      // { stateId: 6, stateName: 'Cross dock in progress' },
      // { stateId: 7, stateName: 'Ready to load' },
      // { stateId: 8, stateName: 'Ready to complete' },
      // { stateId: 9, stateName: 'Ready to cancel' },
      // { stateId: 10, stateName: 'Loading in progress' },
      // { stateId: 11, stateName: 'Completed' },
      // { stateId: 12, stateName: 'Cancelled' }
  
  
      if (selected_row_count == 1) {
          const outbound_shipment_state = $grid.selectedRows[0]?.vars.stateId ?? 0;
          if (outbound_shipment_state === 0 || outbound_shipment_state === 1) {
  
              $grid.topToolbar.unreceive.control.readOnly = false;
              $grid.topToolbar.unload.control.readOnly = true;
              $grid.topToolbar.outbound_shipment_transfer.control.readOnly = false;
  
          } else if (outbound_shipment_state === 3 || outbound_shipment_state === 4 || outbound_shipment_state === 5) {
  
              $grid.topToolbar.unreceive.control.readOnly = false;
              $grid.topToolbar.unload.control.readOnly = true;
              $grid.topToolbar.outbound_shipment_transfer.control.readOnly = false;
  
          }
          else if (outbound_shipment_state === 8 || outbound_shipment_state === 10) {
              $grid.topToolbar.unreceive.control.readOnly = true;
              $grid.topToolbar.unload.control.readOnly = false;
              $grid.topToolbar.outbound_shipment_transfer.control.readOnly = true;
          }
          else {
              $grid.topToolbar.unreceive.control.readOnly = true;
              $grid.topToolbar.unload.control.readOnly = true;
              $grid.topToolbar.outbound_shipment_transfer.control.readOnly = true
          }
      }
  
      else if (selected_row_count > 1) {
          for (let selectedRow of $grid.selectedRows) {
              let outbound_shipment_state = selectedRow.vars.stateId ?? 0;
  
              if (outbound_shipment_state === 0 || outbound_shipment_state === 1) {
                  $grid.topToolbar.outbound_shipment_transfer.control.readOnly = false;
  
              } else if (outbound_shipment_state === 3 || outbound_shipment_state === 4 || outbound_shipment_state === 5) {
                  $grid.topToolbar.outbound_shipment_transfer.control.readOnly = false;
  
              }
              else if (outbound_shipment_state === 8 || outbound_shipment_state === 10) {
                  $grid.topToolbar.outbound_shipment_transfer.control.readOnly = true;
                  break;
  
              }
              else {
                  $grid.topToolbar.outbound_shipment_transfer.control.readOnly = true
                  break;
  
              }
          }
      }
      else {
          $grid.topToolbar.unreceive.control.readOnly = true;
          $grid.topToolbar.outbound_shipment_transfer.control.readOnly = true
          $grid.topToolbar.unload.control.readOnly = true;
      }
  
  } else {
      $grid.topToolbar.unreceive.control.readOnly = true;
      $grid.topToolbar.outbound_shipment_transfer.control.readOnly = true
      $grid.topToolbar.unload.control.readOnly = true;
  }
  
  
  
  
  }
  on_unreceive_clicked(event = null) {
    return this.on_unreceive_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_unreceive_clickedInternal(
    $grid: FootPrintManager_transload_order_received_inventory_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const dialogResult = await $shell.FootPrintManager.openunreceiving_single_taskId_formDialog({
      licenseplateId: $grid.selectedRows[0].entity.ActualTargetLicensePlateId,
      lotId: $grid.selectedRows[0].entity.LotId,
      packagingId: $grid.selectedRows[0].entity.ActualPackagedPackId,
      packagedAmount:  $grid.selectedRows[0].entity.ActualPackagedAmount,
      taskId: $grid.selectedRows[0].entity.Id
  });
  const userConfirmed = dialogResult.confirm;
  
  
  
  if (userConfirmed) {
      await $grid.refresh();
  
  }
  }
  on_assign_shipment(event = null) {
    return this.on_assign_shipmentInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_assign_shipmentInternal(
    $grid: FootPrintManager_transload_order_received_inventory_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const selectedRows = $grid.selectedRows;
  
  let current_outbound_shipment_id = $grid.selectedRows[0]?.entity.ActualTargetLicensePlate?.ParentLicensePlate?.ShipmentAssociation[0]?.Shipmentid ?? 0;
  let licenseplate_Ids: number[] = [];
  let return_error = false;
  
  for (let selectedRow of selectedRows) {
  
      let outbound_shipment_id = selectedRow.entity.ActualTargetLicensePlate?.ParentLicensePlate?.ShipmentAssociation[0]?.Shipmentid ?? 0;
      if (outbound_shipment_id !== current_outbound_shipment_id) {
          return_error = true;
          break;
      }
      else {
          licenseplate_Ids.push(selectedRow.entity.ActualTargetLicensePlateId)
      }
  }
  
  if (return_error) {
  await $shell.FootPrintManager.openErrorDialog('Assign shipment error','Please make sure that the selected licenseplates are all from the same shipment or contain no shipment before assigning to a new shipment.')
  }
  else {
      const dialogResult = await $shell.FootPrintManager.opentransfer_transload_licenseplate_formDialog({
          licenseplate_ids: licenseplate_Ids,
          transload_id: $grid.inParams.transload_id,
          current_outbound_shipment_id: current_outbound_shipment_id
      });
  
  
      const userConfirmed = dialogResult.confirm;
  
  
      if (userConfirmed) {
  
          await $grid.refresh();
      }
  
  }
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootPrintManager_transload_order_received_inventory_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const order_data = $grid.rows;
  
  const orders = order_data.map(o => {
      return {
          orderId: o.entity.ActualTargetLicensePlate?.ParentLicensePlate?.ShipmentAssociation[0]?.Shipment?.OrderLookups[0]?.OrderId ?? o.entity.ActualTargetLicensePlate?.Shipment?.OrderLookups[0]?.OrderId,
          orderStatusId: o.entity.ActualTargetLicensePlate?.ParentLicensePlate?.ShipmentAssociation[0]?.Shipment?.OrderLookups[0]?.Order?.OrderStatusId ?? o.entity.ActualTargetLicensePlate?.Shipment?.OrderLookups[0]?.Order?.OrderStatusId,
          orderStatus: o.entity.ActualTargetLicensePlate?.ParentLicensePlate?.ShipmentAssociation[0]?.Shipment?.OrderLookups[0]?.Order?.Status?.Name ?? o.entity.ActualTargetLicensePlate?.Shipment?.OrderLookups[0]?.Order?.Status?.Name,
          shipmentId: o.entity.ActualTargetLicensePlate?.ParentLicensePlate?.ShipmentAssociation[0]?.Shipmentid ?? o.entity.ActualTargetLicensePlate?.ShipmentId,
          shipmentStatusId: o.entity.ActualTargetLicensePlate?.ParentLicensePlate?.ShipmentAssociation[0]?.Shipment?.StatusId ?? o.entity.ActualTargetLicensePlate?.Shipment?.StatusId,
          waveId: o.entity.ActualTargetLicensePlate?.ParentLicensePlate?.ShipmentAssociation[0]?.Shipment?.WaveId ?? o.entity.ActualTargetLicensePlate?.Shipment?.WaveId,
          waveStatus: o.entity.ActualTargetLicensePlate?.ParentLicensePlate?.ShipmentAssociation[0]?.Shipment?.Wave?.Status?.Name ?? o.entity.ActualTargetLicensePlate?.Shipment?.Wave?.Status?.Name,
          waveStatusId: o.entity.ActualTargetLicensePlate?.ParentLicensePlate?.ShipmentAssociation[0]?.Shipment?.Wave?.StatusId ?? o.entity.ActualTargetLicensePlate?.Shipment?.Wave?.StatusId
      }
  });
  
  const filteredOrders = orders.filter(order => $utils.isDefined(order.orderId));
  
  if ($utils.isDefined(filteredOrders)) {
      const order_states = (await $flows.TransloadOrders.get_transload_shipment_state({ orders: filteredOrders })).states;
  
      if ($utils.isDefined(order_states)) {
          const state_array = order_states.map(row => row.shipmentId);
  
          for (let row of order_data) {
  
              let orderIndex = -1;
              let shipmentId = row.entity.ActualTargetLicensePlate?.ParentLicensePlate?.ShipmentAssociation[0]?.Shipmentid ?? row.entity.ActualTargetLicensePlate?.ShipmentId;
              if (!$utils.isDefined(shipmentId))
                  continue;
  
              let orderStatusId = row.entity.ActualTargetLicensePlate?.ParentLicensePlate?.ShipmentAssociation[0]?.Shipment?.OrderLookups[0]?.Order?.OrderStatusId ?? row.entity.ActualTargetLicensePlate?.Shipment?.OrderLookups[0]?.Order?.OrderStatusId;
  
              let stateId: number = orderStatusId;
              let stateName: string = row.entity.ActualTargetLicensePlate?.ParentLicensePlate?.ShipmentAssociation[0]?.Shipment?.Status?.Name ?? row.entity.ActualTargetLicensePlate?.Shipment?.Status?.Name;
  
              orderIndex = state_array.indexOf(shipmentId)
              if (orderIndex > -1) {
                  stateId = order_states[orderIndex].stateId;
                  stateName = order_states[orderIndex].stateName;
              };
  
              row.vars.stateId = stateId;
              row.cells.outbound_shipment_state.displayControl.text = stateName;
  
  
          };
  
      }
  
  }
  }
  on_unload_clicked(event = null) {
    return this.on_unload_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_unload_clickedInternal(
    $grid: FootPrintManager_transload_order_received_inventory_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const dialogResult = await $shell.FootPrintManager.openunloading_formDialog({
      licenseplateId: $grid.selectedRows[0].entity.ActualTargetLicensePlateId,
      shipmentId: $grid.selectedRows[0].entity.ActualTargetLicensePlate?.ParentLicensePlate?.ShipmentAssociation[0]?.Shipmentid ?? $grid.selectedRows[0].entity.ActualTargetLicensePlate?.ShipmentId
  });
  const userConfirmed = dialogResult.confirm;
  
  
  
  if (userConfirmed) {
      await $grid.refresh();
  
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
