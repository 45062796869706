<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden && !filtersets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-owner" *ngIf="!filters.owner.hidden && !filters.owner.removed" 
                                  class="field-container standard {{filters.owner.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.owner.styles.style"
                                  [ngClass]="filters.owner.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.owner.label + (filters.owner.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.owner.label}}<span *ngIf="filters.owner.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <ExcelMaterialImport-owners_dd_single 
                                  data-cy="selector"
                                  [type]="filters.owner.control.type"
                                  formControlName="owner"
                                  (displayTextChange)="filters.owner.control.displayText=$event"
                                  [placeholder]="filters.owner.control.placeholder"
                                  [styles]="filters.owner.control.styles"
                                  [tooltip]="filters.owner.control.tooltip"
                                [statusId]="$fields_owner_selector_inParams_statusId"
                                [projectId]="$fields_owner_selector_inParams_projectId"
                              >
                              </ExcelMaterialImport-owners_dd_single>
                              <ng-container *ngIf="filters.owner.invalid">
                                <ng-container *ngFor="let error of filters.owner.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-project" *ngIf="!filters.project.hidden && !filters.project.removed" 
                                  class="field-container standard {{filters.project.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.project.styles.style"
                                  [ngClass]="filters.project.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.project.label + (filters.project.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.project.label}}<span *ngIf="filters.project.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <ExcelMaterialImport-projects_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.project.control.type"
                                  formControlName="project"
                                  (displayTextChange)="filters.project.control.displayText=$event"
                                  [placeholder]="filters.project.control.placeholder"
                                  [styles]="filters.project.control.styles"
                                  [tooltip]="filters.project.control.tooltip"
                                [ownerId]="$fields_project_selector_inParams_ownerId"
                              >
                              </ExcelMaterialImport-projects_dd_multi>
                              <ng-container *ngIf="filters.project.invalid">
                                <ng-container *ngFor="let error of filters.project.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-warehouse" *ngIf="!filters.warehouse.hidden && !filters.warehouse.removed" 
                                  class="field-container standard {{filters.warehouse.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.warehouse.styles.style"
                                  [ngClass]="filters.warehouse.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.warehouse.label + (filters.warehouse.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.warehouse.label}}<span *ngIf="filters.warehouse.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <ExcelOrderImport-warehouses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.warehouse.control.type"
                                  formControlName="warehouse"
                                  (displayTextChange)="filters.warehouse.control.displayText=$event"
                                  [placeholder]="filters.warehouse.control.placeholder"
                                  [styles]="filters.warehouse.control.styles"
                                  [tooltip]="filters.warehouse.control.tooltip"
                              >
                              </ExcelOrderImport-warehouses_dd_multi>
                              <ng-container *ngIf="filters.warehouse.invalid">
                                <ng-container *ngFor="let error of filters.warehouse.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-operation_code" *ngIf="!filters.operation_code.hidden && !filters.operation_code.removed" 
                                  class="field-container standard {{filters.operation_code.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.operation_code.styles.style"
                                  [ngClass]="filters.operation_code.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.operation_code.label + (filters.operation_code.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.operation_code.label}}<span *ngIf="filters.operation_code.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Invoices-operation_codes_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.operation_code.control.type"
                                  formControlName="operation_code"
                                  (displayTextChange)="filters.operation_code.control.displayText=$event"
                                  [placeholder]="filters.operation_code.control.placeholder"
                                  [styles]="filters.operation_code.control.styles"
                                  [tooltip]="filters.operation_code.control.tooltip"
                              >
                              </Invoices-operation_codes_dd_multi>
                              <ng-container *ngIf="filters.operation_code.invalid">
                                <ng-container *ngFor="let error of filters.operation_code.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-status" *ngIf="!filters.status.hidden && !filters.status.removed" 
                                  class="field-container double {{filters.status.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.status.styles.style"
                                  [ngClass]="filters.status.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.status.label + (filters.status.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.status.label}}<span *ngIf="filters.status.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Inventory-task_statuses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.status.control.type"
                                  formControlName="status"
                                  (displayTextChange)="filters.status.control.displayText=$event"
                                  [placeholder]="filters.status.control.placeholder"
                                  [styles]="filters.status.control.styles"
                                  [tooltip]="filters.status.control.tooltip"
                              >
                              </Inventory-task_statuses_dd_multi>
                              <ng-container *ngIf="filters.status.invalid">
                                <ng-container *ngFor="let error of filters.status.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.activity.hasTabToShow" class="tab" data-cy="tab-activity">
              <h2 [className]="tabs.activity.active? 'active': ''" (click)="tabs.activity.activate()">{{tabs.activity.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-activity_grid *ngIf="tabs.activity.active"
              #$tabs_activity
              [project_ids]="$tabs_activity_activity_grid_inParams_project_ids"
              [owner_ids]="$tabs_activity_activity_grid_inParams_owner_ids"
              [warehouse_ids]="$tabs_activity_activity_grid_inParams_warehouse_ids"
              [operation_codes]="$tabs_activity_activity_grid_inParams_operation_codes"
              [status_ids]="$tabs_activity_activity_grid_inParams_status_ids"
              [seven_days_before_flag]="$tabs_activity_activity_grid_inParams_seven_days_before_flag"
              ($refreshEvent)="refresh(false, false, '$tabs_activity')">
              </FootPrintManager-activity_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>