import { Component, OnInit, ElementRef, ViewChild, forwardRef, Input, SimpleChanges, OnChanges, HostListener, HostBinding, Output, EventEmitter, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNil, set } from 'lodash-es';
import { Styles } from './models/style';
import { SelectorComponent } from './components/selector.component';
import { ESelectorType } from './components/selector.component';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { InventoryCounts_ShellService } from './InventoryCounts.shell.service';
import { InventoryCounts_OperationService } from './InventoryCounts.operation.service';
import { InventoryCounts_DatasourceService } from './InventoryCounts.datasource.index';
import { InventoryCounts_FlowService } from './InventoryCounts.flow.index';
import { InventoryCounts_ReportService } from './InventoryCounts.report.index';
import { InventoryCounts_LocalizationService } from './InventoryCounts.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './InventoryCounts.frontend.types'
import { $frontendTypes as $types} from './InventoryCounts.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'InventoryCounts-count_task_statuses_dd_single',
  templateUrl: './InventoryCounts.count_task_statuses_dd_single.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InventoryCounts_count_task_statuses_dd_singleComponent),
      multi: true
    }
  ]
})
export class InventoryCounts_count_task_statuses_dd_singleComponent extends BaseComponent implements OnInit, ControlValueAccessor {

  @Input() type?: ESelectorType;
  _disabled = false;
  @Input() get disabled() { 
      return this._disabled 
      ; }
  set disabled(val: any) { this._disabled = val; }

  @Input() placeholder = '';
  @Input() styles: Styles;
  @Input() tooltip = '';

  @Output() displayTextChange: EventEmitter<string> = new EventEmitter();

  @ViewChild(SelectorComponent) selector: SelectorComponent;
  
  @HostBinding('tabIndex') get tabIndex() { return -1; }
  @HostListener('focus')
  focus() {
    this.selector?.focus();
  }

  get isMulti() {
    return false;
  }

  get isSingle() {
    return true;
  }

  options: Array<{ key: any; name: string; disabled?: boolean; }> = [
    {
      key: 1,
      name: 'Released',
      disabled: null
    },  
    {
      key: 2,
      name: 'Completed',
      disabled: null
    },  
    {
      key: 3,
      name: 'Cancelled',
      disabled: null
    },  
    {
      key: 4,
      name: 'Planned',
      disabled: null
    },  
  ];


  fullTextSearch: string;

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: InventoryCounts_ShellService,
    private datasources: InventoryCounts_DatasourceService,
    private flows: InventoryCounts_FlowService,
    private reports: InventoryCounts_ReportService,
    private localization: InventoryCounts_LocalizationService,
    private operations: InventoryCounts_OperationService,
    private logger: CleanupLoggerService,
    private _elementRef: ElementRef
    ) {
    super();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  
  async $init() {
    this.selector?.init();
  }

  onKeyDown($event: KeyboardEvent) {
    //TODO:KeyDown    
  }

  //ControlValueAccessor
  _innerValue: any;

  onChange: any = () => { };
  onTouch: any = () => { };

  get value(): number | number[] {
    return this._innerValue;
  }

  set value(val: number | number[]) {
    if (this._innerValue !== val) {
      this._innerValue = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  writeValue(value: any): void {
    this._innerValue = value;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }


  displayWithFn(value: any): Promise<string> {
    const displayTextResult = this.options.find(o => o.key === value).name;
    this.displayTextChange.emit(displayTextResult);
    return Promise.resolve(displayTextResult);
  }

  optionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {
    if (filterText.length > 0) {
      const list = this.options.filter(item => item.name.startsWith(filterText))
      return Promise.resolve({ list, totalCount: this.options.length, top: null });
    } else {
      const list = [...this.options];
      return Promise.resolve({ list, totalCount: this.options.length, top: null });
    }
  }

}
