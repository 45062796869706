import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class AsnOrders_ds_asn_orderlines_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number, fullTextSearch?: string }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, PackagedAmount?: number, Material?: { IsFixedWeight?: boolean, LookupCode?: string }, VendorLot?: { LookupCode?: string }, Lot?: { LookupCode?: string }, MaterialPackagingLookup?: { PackagingId?: number, Packaging?: { ShortName?: string } }, Status?: { Name?: string }, Tasks?: { SerialNumberId?: number, SerialNumber?: { Id?: number, GrossWeight?: number, LookupCode?: string, NetWeight?: number, WeightUomId?: number } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/AsnOrders/datasources/ds_asn_orderlines_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, fullTextSearch?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, PackagedAmount?: number, Material?: { IsFixedWeight?: boolean, LookupCode?: string }, VendorLot?: { LookupCode?: string }, Lot?: { LookupCode?: string }, MaterialPackagingLookup?: { PackagingId?: number, Packaging?: { ShortName?: string } }, Status?: { Name?: string }, Tasks?: { SerialNumberId?: number, SerialNumber?: { Id?: number, GrossWeight?: number, LookupCode?: string, NetWeight?: number, WeightUomId?: number } }[] }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/AsnOrders/datasources/ds_asn_orderlines_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, fullTextSearch?: string, $keys: { OrderId?: number, LineNumber?: number }[] }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, PackagedAmount?: number, Material?: { IsFixedWeight?: boolean, LookupCode?: string }, VendorLot?: { LookupCode?: string }, Lot?: { LookupCode?: string }, MaterialPackagingLookup?: { PackagingId?: number, Packaging?: { ShortName?: string } }, Status?: { Name?: string }, Tasks?: { SerialNumberId?: number, SerialNumber?: { Id?: number, GrossWeight?: number, LookupCode?: string, NetWeight?: number, WeightUomId?: number } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/AsnOrders/datasources/ds_asn_orderlines_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId: number, fullTextSearch?: string    }) {
        if(isNil(inParams.orderId)) {
          return true; 
        }
      return false;
    }
}
