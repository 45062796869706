import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Waves_ds_outbound_header_pick_slip_reportService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { waveId: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, LookupCode?: string, RequestedDeliveryDate?: string, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { ExpectedDate?: string, FobLocation?: string, GrossWeight?: number, LookupCode?: string, NetWeight?: number, Notes?: string, ShippedDate?: string, Wave?: { Id?: number, PickSlips?: { Id?: number, LookupCode?: string }[], Warehouse?: { Name?: string } }, Carrier?: { Name?: string } } }[], Project?: { LookupCode?: string, Owner?: { LookupCode?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.waveId)) {
      missingRequiredInParams.push('\'waveId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Waves/datasources/ds_outbound_header_pick_slip_report/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { waveId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, LookupCode?: string, RequestedDeliveryDate?: string, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { ExpectedDate?: string, FobLocation?: string, GrossWeight?: number, LookupCode?: string, NetWeight?: number, Notes?: string, ShippedDate?: string, Wave?: { Id?: number, PickSlips?: { Id?: number, LookupCode?: string }[], Warehouse?: { Name?: string } }, Carrier?: { Name?: string } } }[], Project?: { LookupCode?: string, Owner?: { LookupCode?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.waveId)) {
      missingRequiredInParams.push('\'waveId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Waves/datasources/ds_outbound_header_pick_slip_report/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { waveId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, LookupCode?: string, RequestedDeliveryDate?: string, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { ExpectedDate?: string, FobLocation?: string, GrossWeight?: number, LookupCode?: string, NetWeight?: number, Notes?: string, ShippedDate?: string, Wave?: { Id?: number, PickSlips?: { Id?: number, LookupCode?: string }[], Warehouse?: { Name?: string } }, Carrier?: { Name?: string } } }[], Project?: { LookupCode?: string, Owner?: { LookupCode?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.waveId)) {
      missingRequiredInParams.push('\'waveId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Waves/datasources/ds_outbound_header_pick_slip_report/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      waveId: number    }) {
        if(isNil(inParams.waveId)) {
          return true; 
        }
      return false;
    }
}
