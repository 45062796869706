import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';

import { Utilities_OperationService } from './Utilities.operation.service';


@Injectable({ providedIn: 'root' })
export class Catalogs_OperationService {

  constructor(
    private injector: Injector,
    private utils: UtilsService,
  ) {
    this.Utilities = this.injector.get(Utilities_OperationService);
  }

    public Utilities: Utilities_OperationService;
  public Catalogs: Catalogs_OperationService = this;

  public Disable_Material_Catalog_View = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Material_Catalog_View');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Material_Catalog_View');
    }
  }

  private isAuthorized(operationName: string): Promise<boolean> {
    return this.utils.http.post(`${environment.backendUrl}api/Catalogs/operations/${operationName}/isauthorized`, null);
  }
}
