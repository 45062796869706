import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Inspections_ds_inspection_task_editorService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { taskId: number }): 
  Promise<{ result: { Id?: number, CancelledDateTime?: string, CompletedDateTime?: string, CreatedSysDateTime?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, ReleaseDateTime?: string, StartDateTime?: string, OperationCode?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }, Shipment?: { Id?: number, LookupCode?: string }, Order?: { Id?: number, LookupCode?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualSourceLocation?: { Id?: number, Name?: string }, ActualTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, Material?: { Id?: number, LookupCode?: string }, Lot?: { Id?: number, LookupCode?: string }, VendorLot?: { Id?: number, LookupCode?: string }, ReasonCode?: { Id?: number, Name?: string }, Project?: { Id?: number, LookupCode?: string } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.taskId)) {
      missingRequiredInParams.push('\'taskId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inspections/datasources/ds_inspection_task_editor/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      taskId: number    }) {
        if(isNil(inParams.taskId)) {
          return true; 
        }
      return false;
    }
}
