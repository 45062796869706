<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-copy_sales_order"
                     *ngIf="!fieldsets.copy_sales_order.hidden && !fieldsets.copy_sales_order.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.copy_sales_order.collapsible }">
                    <div *ngIf="!fieldsets.copy_sales_order.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.copy_sales_order.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.copy_sales_order.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.copy_sales_order.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.copy_sales_order.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.copy_sales_order.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.copy_sales_order.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-copy_addresses" *ngIf="!fields.copy_addresses.hidden && !fields.copy_addresses.removed" 
                            class="field-container standard {{fields.copy_addresses.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.copy_addresses.styles.style"
                            [ngClass]="fields.copy_addresses.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.copy_addresses.label + (fields.copy_addresses.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.copy_addresses.label}}<span *ngIf="fields.copy_addresses.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="copy_addresses"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.copy_addresses.control.styles.style"
                                      [ngClass]="fields.copy_addresses.control.styles.classes"
                                      [matTooltip]="fields.copy_addresses.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.copy_addresses.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.copy_addresses.invalid">
                          <ng-container *ngFor="let error of fields.copy_addresses.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-open_new_order" *ngIf="!fields.open_new_order.hidden && !fields.open_new_order.removed" 
                            class="field-container standard {{fields.open_new_order.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.open_new_order.styles.style"
                            [ngClass]="fields.open_new_order.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.open_new_order.label + (fields.open_new_order.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.open_new_order.label}}<span *ngIf="fields.open_new_order.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="open_new_order"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.open_new_order.control.styles.style"
                                      [ngClass]="fields.open_new_order.control.styles.classes"
                                      [matTooltip]="fields.open_new_order.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.open_new_order.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.open_new_order.invalid">
                          <ng-container *ngFor="let error of fields.open_new_order.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>