import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_get_orders_ready_to_waveService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { warehouseId?: number[], projectId?: number, ownerId?: number, fullTextSearch?: string, carrierId?: number, carrierServiceId?: number }): 
  Promise<{ result: { Id?: number, AvailableDate?: string, Cartonized?: boolean, CreatedSysDateTime?: string, DateNeeded?: string, DatePromised?: string, DeliveredDate?: string, DockDate?: string, ExpectedDate?: string, ExpectedWarehouseId?: number, LoadingStartDate?: string, LookupCode?: string, PickupDate?: string, WaveId?: number, Carrier?: { Name?: string, ScacCode?: string, ShortName?: string }, CarrierServiceType?: { Id?: number, Name?: string, ShortName?: string }, Status?: { Id?: number, Name?: string }, OrderLookups?: { OrderId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } } } }[], ShipmentLines?: { Id?: number, StatusId?: number }[], ExpectedWarehouse?: { Id?: number, Name?: string }, ShipToContact?: { FirstName?: string, Address?: { City?: string, Country?: string, State?: string } }, LoadContainer?: { LookupCode?: string }, Account?: { Name?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_get_orders_ready_to_wave/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { warehouseId?: number[], projectId?: number, ownerId?: number, fullTextSearch?: string, carrierId?: number, carrierServiceId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, AvailableDate?: string, Cartonized?: boolean, CreatedSysDateTime?: string, DateNeeded?: string, DatePromised?: string, DeliveredDate?: string, DockDate?: string, ExpectedDate?: string, ExpectedWarehouseId?: number, LoadingStartDate?: string, LookupCode?: string, PickupDate?: string, WaveId?: number, Carrier?: { Name?: string, ScacCode?: string, ShortName?: string }, CarrierServiceType?: { Id?: number, Name?: string, ShortName?: string }, Status?: { Id?: number, Name?: string }, OrderLookups?: { OrderId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } } } }[], ShipmentLines?: { Id?: number, StatusId?: number }[], ExpectedWarehouse?: { Id?: number, Name?: string }, ShipToContact?: { FirstName?: string, Address?: { City?: string, Country?: string, State?: string } }, LoadContainer?: { LookupCode?: string }, Account?: { Name?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_get_orders_ready_to_wave/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { warehouseId?: number[], projectId?: number, ownerId?: number, fullTextSearch?: string, carrierId?: number, carrierServiceId?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, AvailableDate?: string, Cartonized?: boolean, CreatedSysDateTime?: string, DateNeeded?: string, DatePromised?: string, DeliveredDate?: string, DockDate?: string, ExpectedDate?: string, ExpectedWarehouseId?: number, LoadingStartDate?: string, LookupCode?: string, PickupDate?: string, WaveId?: number, Carrier?: { Name?: string, ScacCode?: string, ShortName?: string }, CarrierServiceType?: { Id?: number, Name?: string, ShortName?: string }, Status?: { Id?: number, Name?: string }, OrderLookups?: { OrderId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } } } }[], ShipmentLines?: { Id?: number, StatusId?: number }[], ExpectedWarehouse?: { Id?: number, Name?: string }, ShipToContact?: { FirstName?: string, Address?: { City?: string, Country?: string, State?: string } }, LoadContainer?: { LookupCode?: string }, Account?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_get_orders_ready_to_wave/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      warehouseId?: number[], projectId?: number, ownerId?: number, fullTextSearch?: string, carrierId?: number, carrierServiceId?: number    }) {
      return false;
    }
}
