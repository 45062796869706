import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { TemperatureReadings_temperature_measurement_units_singleComponent } from './TemperatureReadings.temperature_measurement_units_single.component'
import { TemperatureReadings_temperature_reading_points_singleComponent } from './TemperatureReadings.temperature_reading_points_single.component'
import { TemperatureReadings_temperature_reading_types_singleComponent } from './TemperatureReadings.temperature_reading_types_single.component'
import { TemperatureReadings_shipment_lines_singleComponent } from './TemperatureReadings.shipment_lines_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => TemperatureReadings_temperature_measurement_units_singleComponent),
    forwardRef(() => TemperatureReadings_temperature_reading_points_singleComponent),
    forwardRef(() => TemperatureReadings_temperature_reading_types_singleComponent),
    forwardRef(() => TemperatureReadings_shipment_lines_singleComponent),
  ],
  selector: 'FootPrintManager-temperature_capture_form',
  templateUrl: './FootPrintManager.temperature_capture_form.component.html'
})
export class FootPrintManager_temperature_capture_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { shipmentId?: number, shipmentLineId?: number, licensePlateId?: number, loadContainerId?: number } = { shipmentId: null, shipmentLineId: null, licensePlateId: null, loadContainerId: null };
  //#region Inputs
  @Input('shipmentId') set $inParams_shipmentId(v: number) {
    this.inParams.shipmentId = v;
  }
  get $inParams_shipmentId(): number {
    return this.inParams.shipmentId;
  }
  @Input('shipmentLineId') set $inParams_shipmentLineId(v: number) {
    this.inParams.shipmentLineId = v;
  }
  get $inParams_shipmentLineId(): number {
    return this.inParams.shipmentLineId;
  }
  @Input('licensePlateId') set $inParams_licensePlateId(v: number) {
    this.inParams.licensePlateId = v;
  }
  get $inParams_licensePlateId(): number {
    return this.inParams.licensePlateId;
  }
  @Input('loadContainerId') set $inParams_loadContainerId(v: number) {
    this.inParams.loadContainerId = v;
  }
  get $inParams_loadContainerId(): number {
    return this.inParams.loadContainerId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    temperature_1: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    temperature_measurement_unit_1: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    temperature_2: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    temperature_measurement_unit_2: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    temperature_3: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    temperature_measurement_unit_3: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    reading_point: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    reading_type: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    shipment: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    shipment_line: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    license_plate: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    load_container: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      save_and_close: new ToolModel(new ButtonModel('save_and_close', new ButtonStyles(['primary'], null), false, false, 'Save and close', '', null)
    , false),
      save_and_new: new ToolModel(new ButtonModel('save_and_new', new ButtonStyles(['tertiary'], null), false, false, 'Save and new', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    temperature_1: new FieldModel(new NumberBoxModel(this.formGroup.controls['temperature_1'] as DatexFormControl, null, false, '', '', null)
, new ControlContainerStyles(null, null), 'Temperature 1', true, false),
    temperature_measurement_unit_1: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['temperature_measurement_unit_1'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Temperature measurement unit 1', true, false),
    temperature_2: new FieldModel(new NumberBoxModel(this.formGroup.controls['temperature_2'] as DatexFormControl, null, false, '', '', null)
, new ControlContainerStyles(null, null), 'Temperature 2', false, false),
    temperature_measurement_unit_2: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['temperature_measurement_unit_2'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Temperature measurement unit 2', false, false),
    temperature_3: new FieldModel(new NumberBoxModel(this.formGroup.controls['temperature_3'] as DatexFormControl, null, false, '', '', null)
, new ControlContainerStyles(null, null), 'Temperature 3', false, false),
    temperature_measurement_unit_3: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['temperature_measurement_unit_3'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Temperature measurement unit 3', false, false),
    reading_point: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['reading_point'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Reading point', true, false),
    reading_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['reading_type'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Reading type', true, false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Notes', false, false),
    shipment: new FieldModel(new TextBoxModel(this.formGroup.controls['shipment'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Shipment', false, false),
    shipment_line: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['shipment_line'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Shipment line', false, false),
    license_plate: new FieldModel(new TextBoxModel(this.formGroup.controls['license_plate'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'License plate', false, false),
    load_container: new FieldModel(new TextBoxModel(this.formGroup.controls['load_container'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Load container', false, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Temperatures', false, false, true, false),
};

  //#region fields inParams
  get $fields_shipment_line_selector_inParams_shipmentId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.inParams.shipmentId;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    temperature_1: this.fields.temperature_1.control.valueChanges
    ,
    temperature_measurement_unit_1: this.fields.temperature_measurement_unit_1.control.valueChanges
    ,
    temperature_2: this.fields.temperature_2.control.valueChanges
    ,
    temperature_measurement_unit_2: this.fields.temperature_measurement_unit_2.control.valueChanges
    ,
    temperature_3: this.fields.temperature_3.control.valueChanges
    ,
    temperature_measurement_unit_3: this.fields.temperature_measurement_unit_3.control.valueChanges
    ,
    reading_point: this.fields.reading_point.control.valueChanges
    ,
    reading_type: this.fields.reading_type.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
    shipment: this.fields.shipment.control.valueChanges
    ,
    shipment_line: this.fields.shipment_line.control.valueChanges
    ,
    license_plate: this.fields.license_plate.control.valueChanges
    ,
    load_container: this.fields.load_container.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Temperature capture form';
  
    const $form = this;
    const $utils = this.utils;

    
    
    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .temperature_1
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .temperature_measurement_unit_1
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .temperature_2
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .temperature_measurement_unit_2
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .temperature_3
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .temperature_measurement_unit_3
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .reading_point
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .reading_type
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .shipment
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .shipment_line
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .license_plate
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .load_container
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_temperature_capture_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/18/2023
  
  if ($utils.isDefined($form.inParams.shipmentId)) {
  
      const shipment = (await $datasources.SalesOrders.ds_get_shipments_by_shipmentId.get({ shipmentId: $form.inParams.shipmentId })).result;
  
      if ($utils.isDefined(shipment)) {
          $form.fields.shipment.control.value = shipment.LookupCode;
      } else {
          $form.fields.shipment.control.value = $form.inParams.shipmentId.toString();
      };
  
      if ($utils.isDefined($form.inParams.shipmentLineId)) {
          $form.fields.shipment.control.value = $form.inParams.shipmentLineId;
      };
  
  } else {
      disableComponent($form.fields.shipment);
      disableComponent($form.fields.shipment_line);
  };
  
  if ($utils.isDefined($form.inParams.licensePlateId)) {
  
      const license_plate = (await $datasources.Inventory.ds_get_licenseplate_by_licenseplateId.get({ licenseplateId: $form.inParams.licensePlateId })).result;
  
      if ($utils.isDefined(license_plate)) {
          $form.fields.license_plate.control.value = license_plate.LookupCode;
      } else {
          $form.fields.license_plate.control.value = $form.inParams.licensePlateId.toString();
      };
  
  } else {
      disableComponent($form.fields.license_plate);
  };
  
  if ($utils.isDefined($form.inParams.loadContainerId)) {
  
      const load_container = (await $datasources.LoadContainers.ds_load_container_editor.get({ loadContainerId: $form.inParams.loadContainerId })).result;
  
      if ($utils.isDefined(load_container)) {
          $form.fields.load_container.control.value = load_container.LookupCode;
      } else {
          $form.fields.load_container.control.value = $form.inParams.loadContainerId.toString();
      };
  
  } else {
      disableComponent($form.fields.load_container);
  };
  
  //Set the default temperature unit
  let default_temperature = $settings.FootPrintManager.DefaultTemperature;
  if ($utils.isDefined(default_temperature)) {
      let units = (await $datasources.TemperatureReadings.ds_measurement_units_dd.get({ fullTextSearch: default_temperature, typeId: 5 })).result;
      if ($utils.isDefined(units)) {
          let unit_id = units[0].Id;
          if ($utils.isDefined(unit_id)) {
              $form.fields.temperature_measurement_unit_1.control.value = unit_id;
              $form.fields.temperature_measurement_unit_2.control.value = unit_id;
              $form.fields.temperature_measurement_unit_3.control.value = unit_id;
          };
      };
  };
  
  function disableComponent(component: any) {
      if ($utils.isDefined(component?.control?.readOnly)) {
          component.control.readOnly = true;
      }
      component.hidden = true;
  };
  function enableComponent(component: any) {
      if ($utils.isDefined(component?.control?.readOnly)) {
          component.control.readOnly = false;
      }
      component.hidden = false;
  };
  }
  on_save_and_close(event = null) {
    return this.on_save_and_closeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_and_closeInternal(
    $form: FootPrintManager_temperature_capture_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/18/2023
  
  $form.toolbar.save_and_new.control.readOnly = true;
  $form.toolbar.save_and_close.control.readOnly = true;
  
  
  if (!$utils.isDefined($form.inParams.licensePlateId) && !$utils.isDefined($form.inParams.shipmentId) && !$utils.isDefined($form.inParams.loadContainerId)) {
      $form.toolbar.save_and_new.control.readOnly = false;
      $form.toolbar.save_and_close.control.readOnly = false;
      $shell.FootPrintManager.openErrorDialog('Temperature reading creation error', 'Please supply in at least one parameter.');
      return;
  }
  
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.temperature_1.control.value,
      $form.fields.temperature_measurement_unit_1.control.value,
      $form.fields.reading_point.control.value,
      $form.fields.reading_type.control.value
  );
  
  if (!allRequiredFieldHaveValue) {
      $form.toolbar.save_and_new.control.readOnly = false;
      $form.toolbar.save_and_close.control.readOnly = false;
      $shell.FootPrintManager.openErrorDialog('Temperature reading creation error', 'Please enter in data in all required fields.');
      return;
  }
  
  try {
  
      const temperature_reading_request = (await $flows.TemperatureReadings.create_temperature_reading_flow({
          loadContainerId: $form.inParams.loadContainerId,
          shipmentId: $form.inParams.shipmentId,
          shipmentLineId: $form.fields.shipment_line.control.value,
          licensePlateId: $form.inParams.licensePlateId,
          temperature1: $form.fields.temperature_1.control.value,
          measurementUnit1: $form.fields.temperature_measurement_unit_1.control.value,
          temperature2: $form.fields.temperature_2.control.value,
          measurementUnit2: $form.fields.temperature_measurement_unit_2.control.value,
          temperature3: $form.fields.temperature_3.control.value,
          measurementUnit3: $form.fields.temperature_measurement_unit_3.control.value,
          readingPointId: $form.fields.reading_point.control.value,
          readingTypeId: $form.fields.reading_type.control.value,
          notes: $form.fields.notes.control.value
      }));
  
      if ($utils.isDefined(temperature_reading_request)) {
  
          var temperatureReadingId = temperature_reading_request.temperatureReadingId;
          var reasons = temperature_reading_request.reasons;
  
          if ($utils.isDefined(reasons) || !$utils.isDefined(temperatureReadingId)) {
              throw reasons[0] ?? "Uncaught exception. We failed to capture the reading.";
              
          } else {
              $form.close();
          }
      }
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
      const errorDescription = `Temperature reeading creation - ${errorMessage}`;
      await $shell.FootPrintManager.openErrorDialog('Create remperature reading error', 'An error occurred during the creation of the temperature reading', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  }
  }
  on_save_and_new(event = null) {
    return this.on_save_and_newInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_and_newInternal(
    $form: FootPrintManager_temperature_capture_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/18/2023
  
  $form.toolbar.save_and_new.control.readOnly = true;
  $form.toolbar.save_and_close.control.readOnly = true;
  
  
  if (!$utils.isDefined($form.inParams.licensePlateId) && !$utils.isDefined($form.inParams.shipmentId) && !$utils.isDefined($form.inParams.loadContainerId)) {
      $form.toolbar.save_and_new.control.readOnly = false;
      $form.toolbar.save_and_close.control.readOnly = false;
      $shell.FootPrintManager.openErrorDialog('Temperature reading creation error', 'Please supply in at least one parameter.');
      return;
  }
  
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.temperature_1.control.value,
      $form.fields.temperature_measurement_unit_1.control.value,
      $form.fields.reading_point.control.value,
      $form.fields.reading_type.control.value
  );
  
  if (!allRequiredFieldHaveValue) {
      $form.toolbar.save_and_new.control.readOnly = false;
      $form.toolbar.save_and_close.control.readOnly = false;
      $shell.FootPrintManager.openErrorDialog('Temperature reading creation error', 'Please enter in data in all required fields.');
      return;
  }
  
  try {
  
      const temperature_reading_request = (await $flows.TemperatureReadings.create_temperature_reading_flow({
          loadContainerId: $form.inParams.loadContainerId,
          shipmentId: $form.inParams.shipmentId,
          shipmentLineId: $form.fields.shipment_line.control.value,
          licensePlateId: $form.inParams.licensePlateId,
          temperature1: $form.fields.temperature_1.control.value,
          measurementUnit1: $form.fields.temperature_measurement_unit_1.control.value,
          temperature2: $form.fields.temperature_2.control.value,
          measurementUnit2: $form.fields.temperature_measurement_unit_2.control.value,
          temperature3: $form.fields.temperature_3.control.value,
          measurementUnit3: $form.fields.temperature_measurement_unit_3.control.value,
          readingPointId: $form.fields.reading_point.control.value,
          readingTypeId: $form.fields.reading_type.control.value,
          notes: $form.fields.notes.control.value
      }));
  
      if ($utils.isDefined(temperature_reading_request)) {
  
          var temperatureReadingId = temperature_reading_request.temperatureReadingId;
          var reasons = temperature_reading_request.reasons;
  
          if ($utils.isDefined(reasons) || !$utils.isDefined(temperatureReadingId)) {
              throw reasons[0] ?? "Uncaught exception. We failed to capture the reading.";
  
          } else {
              $form.close();
              await $shell.FootPrintManager.opentemperature_capture_formDialog({ loadContainerId: $form.inParams.loadContainerId, shipmentId: $form.inParams.shipmentId, shipmentLineId: $form.inParams.shipmentLineId, licensePlateId: $form.inParams.licensePlateId });
          }
  
      }
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
      const errorDescription = `Temperature reeading creation - ${errorMessage}`;
      await $shell.FootPrintManager.openErrorDialog('Create remperature reading error', 'An error occurred during the creation of the temperature reading', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  }
  
  }
  on_cancel(event = null) {
    return this.on_cancelInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancelInternal(
    $form: FootPrintManager_temperature_capture_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/18/2023
  
  $form.close();
  
  }
  //#endregion private flows
}
