<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
              </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.activity.hasTabToShow" class="tab" data-cy="tab-activity">
              <h2 [className]="tabs.activity.active? 'active': ''" (click)="tabs.activity.activate()">{{tabs.activity.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-billing_record_activity_list *ngIf="tabs.activity.active"
              #$tabs_activity
              [billingTaskId]="$tabs_activity_billing_record_activity_list_inParams_billingTaskId"
              ($refreshEvent)="refresh(false, false, '$tabs_activity')">
              </FootPrintManager-billing_record_activity_list>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>