<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="delete_log" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-level" *ngIf="!filters.level.hidden && !filters.level.removed" 
            class="field-container standard {{filters.level.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.level.styles.style"
            [ngClass]="filters.level.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.level.label + (filters.level.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.level.label}}<span *ngIf="filters.level.required" class="required-asterisk">*</span>
          </label>
        </div>
        <input data-cy="numberBox" formControlName="level"
                matInput
                numberBox
                [format]="filters.level.control.format"
                autocomplete="off"
                spellcheck="false"
                class="datex-numberbox {{filters.level.control.readOnly ? 'readonly ' : ''}}"
                placeholder="{{filters.level.control.placeholder}}"
                [ngStyle]="filters.level.control.styles.style"
                [ngClass]="filters.level.control.styles.classes"
                [matTooltip]="filters.level.control.tooltip"
                matTooltipClass="datex-control-tooltip">
        <ng-container *ngIf="filters.level.invalid">
          <ng-container *ngFor="let error of filters.level.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-process" *ngIf="!filters.process.hidden && !filters.process.removed" 
            class="field-container double {{filters.process.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.process.styles.style"
            [ngClass]="filters.process.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.process.label + (filters.process.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.process.label}}<span *ngIf="filters.process.required" class="required-asterisk">*</span>
          </label>
        </div>
        <FootPrintApiManager-processes_dd_single 
            data-cy="selector"
            [type]="filters.process.control.type"
            formControlName="process"
            (displayTextChange)="filters.process.control.displayText=$event"
            [placeholder]="filters.process.control.placeholder"
            [styles]="filters.process.control.styles"
            [tooltip]="filters.process.control.tooltip"
        >
        </FootPrintApiManager-processes_dd_single>
        <ng-container *ngIf="filters.process.invalid">
          <ng-container *ngFor="let error of filters.process.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.id.displayControl.styles.style"
          [ngClass]="row.cells.id.displayControl.styles.classes"
          [matTooltip]="row.cells.id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="integration_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.integration_name.displayControl.styles.style"
          [ngClass]="row.cells.integration_name.displayControl.styles.classes"
          [matTooltip]="row.cells.integration_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.integration_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="level">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.level.displayControl.styles.style"
          [ngClass]="row.cells.level.displayControl.styles.classes"
          [matTooltip]="row.cells.level.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.level.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="process">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.process.displayControl.styles.style"
          [ngClass]="row.cells.process.displayControl.styles.classes"
          [matTooltip]="row.cells.process.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.process.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="transaction_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.transaction_id.displayControl.styles.style"
          [ngClass]="row.cells.transaction_id.displayControl.styles.classes"
          [matTooltip]="row.cells.transaction_id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.transaction_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ingress_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ingress_id.displayControl.styles.style"
          [ngClass]="row.cells.ingress_id.displayControl.styles.classes"
          [matTooltip]="row.cells.ingress_id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ingress_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="egress_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.egress_id.displayControl.styles.style"
          [ngClass]="row.cells.egress_id.displayControl.styles.classes"
          [matTooltip]="row.cells.egress_id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.egress_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes"
          [matTooltip]="row.cells.notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="message">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.message.displayControl.styles.style"
          [ngClass]="row.cells.message.displayControl.styles.classes"
          [matTooltip]="row.cells.message.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.message.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_by">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.created_by.displayControl.styles.style"
          [ngClass]="row.cells.created_by.displayControl.styles.classes"
          [matTooltip]="row.cells.created_by.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.created_by.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.created_on.displayControl.styles.style"
          [ngClass]="row.cells.created_on.displayControl.styles.classes"
          [matTooltip]="row.cells.created_on.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.created_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="alerted_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.alerted_on.displayControl.styles.style"
          [ngClass]="row.cells.alerted_on.displayControl.styles.classes"
          [matTooltip]="row.cells.alerted_on.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.alerted_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
