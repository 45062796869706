import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootPrintManager_ds_sales_order_line_substitutions_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { material_id: number, packaging_id: number, full_text_search?: string }): 
  Promise<{ result: { Id?: number, MaterialId?: number, PackagingId?: number, Priority?: number, Ratio?: number, SubstitutionMaterialId?: number, SubstitutionPackagingId?: number, SubstitutionMaterialPackagingLookup?: { MaterialId?: number, Material?: { LookupCode?: string }, Packaging?: { ShortName?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.material_id)) {
      missingRequiredInParams.push('\'material_id\'');
    }
    if (isNil(inParams.packaging_id)) {
      missingRequiredInParams.push('\'packaging_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintManager/datasources/ds_sales_order_line_substitutions_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { material_id: number, packaging_id: number, full_text_search?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, MaterialId?: number, PackagingId?: number, Priority?: number, Ratio?: number, SubstitutionMaterialId?: number, SubstitutionPackagingId?: number, SubstitutionMaterialPackagingLookup?: { MaterialId?: number, Material?: { LookupCode?: string }, Packaging?: { ShortName?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.material_id)) {
      missingRequiredInParams.push('\'material_id\'');
    }
    if (isNil(inParams.packaging_id)) {
      missingRequiredInParams.push('\'packaging_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintManager/datasources/ds_sales_order_line_substitutions_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { material_id: number, packaging_id: number, full_text_search?: string, $keys: number[] }): 
  Promise<{ result: { Id?: number, MaterialId?: number, PackagingId?: number, Priority?: number, Ratio?: number, SubstitutionMaterialId?: number, SubstitutionPackagingId?: number, SubstitutionMaterialPackagingLookup?: { MaterialId?: number, Material?: { LookupCode?: string }, Packaging?: { ShortName?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.material_id)) {
      missingRequiredInParams.push('\'material_id\'');
    }
    if (isNil(inParams.packaging_id)) {
      missingRequiredInParams.push('\'packaging_id\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintManager/datasources/ds_sales_order_line_substitutions_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      material_id: number, packaging_id: number, full_text_search?: string    }) {
        if(isNil(inParams.material_id)) {
          return true; 
        }
        if(isNil(inParams.packaging_id)) {
          return true; 
        }
      return false;
    }
}
