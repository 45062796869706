import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Dynamics365BusinessCentral_ds_order_get_list_so_order_lines_by_order_idService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { guid?: string, order_type?: string, $top?: number, $skip?: number }): Promise<{ result?: { id?: string }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/Dynamics365BusinessCentral/datasources/ds_order_get_list_so_order_lines_by_order_id/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      guid?: string, order_type?: string    }) {
      return false;
    }
}
