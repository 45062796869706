<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden && !filtersets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-status" *ngIf="!filters.status.hidden && !filters.status.removed" 
                                  class="field-container standard {{filters.status.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.status.styles.style"
                                  [ngClass]="filters.status.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.status.label + (filters.status.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.status.label}}<span *ngIf="filters.status.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <EntityImport-entity_import_statuses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.status.control.type"
                                  formControlName="status"
                                  (displayTextChange)="filters.status.control.displayText=$event"
                                  [placeholder]="filters.status.control.placeholder"
                                  [styles]="filters.status.control.styles"
                                  [tooltip]="filters.status.control.tooltip"
                              >
                              </EntityImport-entity_import_statuses_dd_multi>
                              <ng-container *ngIf="filters.status.invalid">
                                <ng-container *ngFor="let error of filters.status.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-requestId" *ngIf="!filters.requestId.hidden && !filters.requestId.removed" 
                                  class="field-container double {{filters.requestId.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.requestId.styles.style"
                                  [ngClass]="filters.requestId.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.requestId.label + (filters.requestId.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.requestId.label}}<span *ngIf="filters.requestId.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="requestId"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.requestId.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.requestId.control.placeholder}}"
                                      [ngStyle]="filters.requestId.control.styles.style"
                                      [ngClass]="filters.requestId.control.styles.classes"
                                      [matTooltip]="filters.requestId.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.requestId.invalid">
                                <ng-container *ngFor="let error of filters.requestId.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                <div data-cy="fieldset-id-project_filters"
                     *ngIf="!filtersets.project_filters.hidden && !filtersets.project_filters.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.project_filters.collapsible }">
                    <div *ngIf="!filtersets.project_filters.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.project_filters.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.project_filters.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.project_filters.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.project_filters.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.project_filters.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.project_filters.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-no_project" *ngIf="!filters.no_project.hidden && !filters.no_project.removed" 
                                  class="field-container standard {{filters.no_project.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.no_project.styles.style"
                                  [ngClass]="filters.no_project.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.no_project.label + (filters.no_project.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.no_project.label}}<span *ngIf="filters.no_project.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <mat-slide-toggle data-cy="slideToggle" formControlName="no_project"
                                            color="primary"
                                            class="datex-toggle"
                                            [ngStyle]="filters.no_project.control.styles.style"
                                            [ngClass]="filters.no_project.control.styles.classes"
                                            [matTooltip]="filters.no_project.control.tooltip"
                                            matTooltipClass="datex-control-tooltip">{{filters.no_project.control.label}}</mat-slide-toggle>
                              <ng-container *ngIf="filters.no_project.invalid">
                                <ng-container *ngFor="let error of filters.no_project.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-owners" *ngIf="!filters.owners.hidden && !filters.owners.removed" 
                                  class="field-container standard {{filters.owners.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.owners.styles.style"
                                  [ngClass]="filters.owners.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.owners.label + (filters.owners.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.owners.label}}<span *ngIf="filters.owners.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Owners-owners_dd_single 
                                  data-cy="selector"
                                  [type]="filters.owners.control.type"
                                  formControlName="owners"
                                  (displayTextChange)="filters.owners.control.displayText=$event"
                                  [placeholder]="filters.owners.control.placeholder"
                                  [styles]="filters.owners.control.styles"
                                  [tooltip]="filters.owners.control.tooltip"
                                [statusId]="$fields_owners_selector_inParams_statusId"
                              >
                              </Owners-owners_dd_single>
                              <ng-container *ngIf="filters.owners.invalid">
                                <ng-container *ngFor="let error of filters.owners.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-projects" *ngIf="!filters.projects.hidden && !filters.projects.removed" 
                                  class="field-container standard {{filters.projects.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.projects.styles.style"
                                  [ngClass]="filters.projects.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.projects.label + (filters.projects.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.projects.label}}<span *ngIf="filters.projects.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Owners-projects_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.projects.control.type"
                                  formControlName="projects"
                                  (displayTextChange)="filters.projects.control.displayText=$event"
                                  [placeholder]="filters.projects.control.placeholder"
                                  [styles]="filters.projects.control.styles"
                                  [tooltip]="filters.projects.control.tooltip"
                                [statusId]="$fields_projects_selector_inParams_statusId"
                                [ownerId]="$fields_projects_selector_inParams_ownerId"
                              >
                              </Owners-projects_dd_multi>
                              <ng-container *ngIf="filters.projects.invalid">
                                <ng-container *ngFor="let error of filters.projects.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                <div data-cy="fieldset-id-warehouse_filters"
                     *ngIf="!filtersets.warehouse_filters.hidden && !filtersets.warehouse_filters.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.warehouse_filters.collapsible }">
                    <div *ngIf="!filtersets.warehouse_filters.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.warehouse_filters.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.warehouse_filters.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.warehouse_filters.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.warehouse_filters.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.warehouse_filters.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.warehouse_filters.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-no_warehouse" *ngIf="!filters.no_warehouse.hidden && !filters.no_warehouse.removed" 
                                  class="field-container standard {{filters.no_warehouse.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.no_warehouse.styles.style"
                                  [ngClass]="filters.no_warehouse.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.no_warehouse.label + (filters.no_warehouse.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.no_warehouse.label}}<span *ngIf="filters.no_warehouse.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <mat-slide-toggle data-cy="slideToggle" formControlName="no_warehouse"
                                            color="primary"
                                            class="datex-toggle"
                                            [ngStyle]="filters.no_warehouse.control.styles.style"
                                            [ngClass]="filters.no_warehouse.control.styles.classes"
                                            [matTooltip]="filters.no_warehouse.control.tooltip"
                                            matTooltipClass="datex-control-tooltip">{{filters.no_warehouse.control.label}}</mat-slide-toggle>
                              <ng-container *ngIf="filters.no_warehouse.invalid">
                                <ng-container *ngFor="let error of filters.no_warehouse.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-warehouses" *ngIf="!filters.warehouses.hidden && !filters.warehouses.removed" 
                                  class="field-container standard {{filters.warehouses.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.warehouses.styles.style"
                                  [ngClass]="filters.warehouses.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.warehouses.label + (filters.warehouses.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.warehouses.label}}<span *ngIf="filters.warehouses.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Locations-warehouses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.warehouses.control.type"
                                  formControlName="warehouses"
                                  (displayTextChange)="filters.warehouses.control.displayText=$event"
                                  [placeholder]="filters.warehouses.control.placeholder"
                                  [styles]="filters.warehouses.control.styles"
                                  [tooltip]="filters.warehouses.control.tooltip"
                              >
                              </Locations-warehouses_dd_multi>
                              <ng-container *ngIf="filters.warehouses.invalid">
                                <ng-container *ngFor="let error of filters.warehouses.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.entity_import_total_errors_by_date_widget.hidden && !widgets.entity_import_total_errors_by_date_widget.removed" class="widget-container">
            <EntityImport-entity_import_total_errors_by_date_widget
            #$widgets_entity_import_total_errors_by_date_widget 
              [date]="$widgets_entity_import_total_errors_by_date_widget_inParams_date"
            ($refreshEvent)="refresh(false, false, '$widgets_entity_import_total_errors_by_date_widget')"
            >
            </EntityImport-entity_import_total_errors_by_date_widget>
          </div>
          <div *ngIf="!widgets.entity_import_total_ready_by_date_widget.hidden && !widgets.entity_import_total_ready_by_date_widget.removed" class="widget-container">
            <EntityImport-entity_import_total_ready_by_date_widget
            #$widgets_entity_import_total_ready_by_date_widget 
              [date]="$widgets_entity_import_total_ready_by_date_widget_inParams_date"
            ($refreshEvent)="refresh(false, false, '$widgets_entity_import_total_ready_by_date_widget')"
            >
            </EntityImport-entity_import_total_ready_by_date_widget>
          </div>
          <div *ngIf="!widgets.entity_import_total_completed_by_date_widget.hidden && !widgets.entity_import_total_completed_by_date_widget.removed" class="widget-container">
            <EntityImport-entity_import_total_completed_by_date_widget
            #$widgets_entity_import_total_completed_by_date_widget 
              [date]="$widgets_entity_import_total_completed_by_date_widget_inParams_date"
            ($refreshEvent)="refresh(false, false, '$widgets_entity_import_total_completed_by_date_widget')"
            >
            </EntityImport-entity_import_total_completed_by_date_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.entity_import_requests.hasTabToShow" class="tab" data-cy="tab-entity_import_requests">
              <h2 [className]="tabs.entity_import_requests.active? 'active': ''" (click)="tabs.entity_import_requests.activate()">{{tabs.entity_import_requests.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-entity_import_requests_grid *ngIf="tabs.entity_import_requests.active"
              #$tabs_entity_import_requests
              [statusCodes]="$tabs_entity_import_requests_entity_import_requests_grid_inParams_statusCodes"
              [projects]="$tabs_entity_import_requests_entity_import_requests_grid_inParams_projects"
              [warehouses]="$tabs_entity_import_requests_entity_import_requests_grid_inParams_warehouses"
              [singleWarehouse]="$tabs_entity_import_requests_entity_import_requests_grid_inParams_singleWarehouse"
              [singleProjectName]="$tabs_entity_import_requests_entity_import_requests_grid_inParams_singleProjectName"
              [singleProjectLookupCode]="$tabs_entity_import_requests_entity_import_requests_grid_inParams_singleProjectLookupCode"
              [requestId]="$tabs_entity_import_requests_entity_import_requests_grid_inParams_requestId"
              [projectsExact]="$tabs_entity_import_requests_entity_import_requests_grid_inParams_projectsExact"
              [warehousesExact]="$tabs_entity_import_requests_entity_import_requests_grid_inParams_warehousesExact"
              [dateFrom]="$tabs_entity_import_requests_entity_import_requests_grid_inParams_dateFrom"
              [dateTo]="$tabs_entity_import_requests_entity_import_requests_grid_inParams_dateTo"
              [dateType]="$tabs_entity_import_requests_entity_import_requests_grid_inParams_dateType"
              (DateParamsChange)="on_date_params_change($event)"
              ($refreshEvent)="refresh(false, false, '$tabs_entity_import_requests')">
              </FootPrintManager-entity_import_requests_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>