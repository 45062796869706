import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Surveys_ds_submitted_surveys_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { entities?: { type?: string, ids?: number[] }[], dates?: { dateFilter?: string, fromDate?: string, toDate?: string }, operationContexts?: string[], warehouseIds?: number[] }): 
  Promise<{ result: { id?: number, createdSysDateTime?: string, createdSysUser?: string, modifiedSysDateTime?: string, modifiedSysUser?: string, SurveyDefinition?: { description?: string, name?: string }, OperationContextType?: { Name?: string }, ContextAppointment?: { LookupCode?: string }, ContextLicensePlate?: { LookupCode?: string }, ContextLocation?: { Name?: string }, ContextProject?: { LookupCode?: string }, ContextShipment?: { LookupCode?: string }, ContextShippingContainer?: { LookupCode?: string }, ContextTask?: { Id?: number, OperationCode?: { Name?: string } }, Equipment?: { LookupCode?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/Surveys/datasources/ds_submitted_surveys_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { entities?: { type?: string, ids?: number[] }[], dates?: { dateFilter?: string, fromDate?: string, toDate?: string }, operationContexts?: string[], warehouseIds?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { id?: number, createdSysDateTime?: string, createdSysUser?: string, modifiedSysDateTime?: string, modifiedSysUser?: string, SurveyDefinition?: { description?: string, name?: string }, OperationContextType?: { Name?: string }, ContextAppointment?: { LookupCode?: string }, ContextLicensePlate?: { LookupCode?: string }, ContextLocation?: { Name?: string }, ContextProject?: { LookupCode?: string }, ContextShipment?: { LookupCode?: string }, ContextShippingContainer?: { LookupCode?: string }, ContextTask?: { Id?: number, OperationCode?: { Name?: string } }, Equipment?: { LookupCode?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Surveys/datasources/ds_submitted_surveys_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { entities?: { type?: string, ids?: number[] }[], dates?: { dateFilter?: string, fromDate?: string, toDate?: string }, operationContexts?: string[], warehouseIds?: number[], $keys: number[] }): 
  Promise<{ result: { id?: number, createdSysDateTime?: string, createdSysUser?: string, modifiedSysDateTime?: string, modifiedSysUser?: string, SurveyDefinition?: { description?: string, name?: string }, OperationContextType?: { Name?: string }, ContextAppointment?: { LookupCode?: string }, ContextLicensePlate?: { LookupCode?: string }, ContextLocation?: { Name?: string }, ContextProject?: { LookupCode?: string }, ContextShipment?: { LookupCode?: string }, ContextShippingContainer?: { LookupCode?: string }, ContextTask?: { Id?: number, OperationCode?: { Name?: string } }, Equipment?: { LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Surveys/datasources/ds_submitted_surveys_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      entities?: { type?: string, ids?: number[] }[], dates?: { dateFilter?: string, fromDate?: string, toDate?: string }, operationContexts?: string[], warehouseIds?: number[]    }) {
      return false;
    }
}
