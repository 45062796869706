import { Component, OnInit, ElementRef, ViewChild, forwardRef, Input, SimpleChanges, OnChanges, HostListener, HostBinding, Output, EventEmitter, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNil, set } from 'lodash-es';
import { Styles } from './models/style';
import { SelectorComponent } from './components/selector.component';
import { ESelectorType } from './components/selector.component';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-lots_dd_multi',
  templateUrl: './FootPrintManager.lots_dd_multi.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FootPrintManager_lots_dd_multiComponent),
      multi: true
    }
  ]
})
export class FootPrintManager_lots_dd_multiComponent extends BaseComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input() type?: ESelectorType;
  _disabled = false;
  @Input() get disabled() { 
      return this._disabled 
      || this.$hasMissingRequiredInParams
      ; }
  set disabled(val: any) { this._disabled = val; }

  @Input() placeholder = '';
  @Input() styles: Styles;
  @Input() tooltip = '';

  @Output() displayTextChange: EventEmitter<string> = new EventEmitter();

  @ViewChild(SelectorComponent) selector: SelectorComponent;

  @HostBinding('tabIndex') get tabIndex() { return -1; }
  @HostListener('focus')
  focus() {
    this.selector?.focus();
  }

  get isMulti() {
    return true;
  }

  get isSingle() {
    return false;
  }


  inParams: { materialId: number, vendorLotId?: number, lotLookupCode?: string, vendorLotLookupCode?: string } = { materialId: null, vendorLotId: null, lotLookupCode: null, vendorLotLookupCode: null };
  @Input('materialId') set $inParams_materialId(value: any) {
    this.inParams['materialId'] = value;
  }
  get $inParams_materialId(): any {
    return this.inParams['materialId'] ;
  }
  @Input('vendorLotId') set $inParams_vendorLotId(value: any) {
    this.inParams['vendorLotId'] = value;
  }
  get $inParams_vendorLotId(): any {
    return this.inParams['vendorLotId'] ;
  }
  @Input('lotLookupCode') set $inParams_lotLookupCode(value: any) {
    this.inParams['lotLookupCode'] = value;
  }
  get $inParams_lotLookupCode(): any {
    return this.inParams['lotLookupCode'] ;
  }
  @Input('vendorLotLookupCode') set $inParams_vendorLotLookupCode(value: any) {
    this.inParams['vendorLotLookupCode'] = value;
  }
  get $inParams_vendorLotLookupCode(): any {
    return this.inParams['vendorLotLookupCode'] ;
  }

  fullTextSearch: string;

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    private _elementRef: ElementRef
    ) {
      super();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.materialId)) {
        this.$missingRequiredInParams.push('materialId');
      }
  }

  async $init() {
    this.selector?.init();
  }

  onKeyDown($event: KeyboardEvent) {
    //TODO:KeyDown    
  }

  //ControlValueAccessor
  _innerValue: any;

  onChange: any = () => { };
  onTouch: any = () => { };

  get value(): number | number[] {
    return this._innerValue;
  }

  set value(val:  number | number[]) {
    if (this._innerValue !== val) {
      this._innerValue = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  writeValue(value: any): void {
    this._innerValue = value;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  displayWithFn(value: any): Promise<{displayText:string, selected :Array<{ key: any; name: string; disabled?: boolean; selected?: boolean;}>}> {
    const promise = new Promise<{displayText:string, selected :Array<{ key: any; name: string; disabled?: boolean; selected?: boolean;}>}>((resolve, reject) => {

      const $selector = this;
      const $utils = this.utils;

      this.fullTextSearch = null;
      const $fullTextSearch = null;
      this.datasources.Lots.ds_lots_dd.getByKeys({
        $keys: value,
        materialId:  $selector.inParams.materialId ,
        fullTextSearch:  $selector.fullTextSearch ,
        vendorLotId:  $selector.inParams.vendorLotId ,
      }).then(result => {
        const selected = value.map(k => {
          const found = result.result
            .find(i => i.Id === k);
          const e: { Id?: number, LookupCode?: string } = found 
            ?? { 
              Id : k
            };

          const $option = { entity: e };
          const $entity = e;
          const $resultOption = { key: null, name: found ? $option.entity.LookupCode : 'No data to display', disabled: false, selected:true };
          $resultOption.key = $option.entity.Id;
          return $resultOption;
        });
        const displayText = value.map(k => {
          const found = result.result
            .find(i => i.Id === k);
          if (found) {
            const $option = { entity: found };
            const $entity = found;
            return $option.entity.LookupCode;
          } else {
            return 'No data to display';
          }
        })
        .join(
           ', '
        );
        this.displayTextChange.emit(displayText);
        resolve({displayText, selected});
      });
    });
    return promise;
  }

  optionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {

    const promise = new Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }>((resolve, reject) => {

      const $selector = this;
      const $utils = this.utils;

      this.fullTextSearch = filterText;
      const $fullTextSearch = filterText;
      this.datasources.Lots.ds_lots_dd.getList({
        $top: 15,
        $skip: 0,
        materialId:  $selector.inParams.materialId ,
        fullTextSearch:  $selector.fullTextSearch ,
        vendorLotId:  $selector.inParams.vendorLotId ,
      }).then(result => {
        resolve({
          list: result.result.map(item => {
            const $option = { entity: item };
            const $entity = item;
            const $resultOption = { key: null, name: $option.entity.LookupCode, disabled: null };
            $resultOption.key = $option.entity.Id;
            return $resultOption;
          }),
          totalCount: result.totalCount,
          top: 15
        });
      });

    });

    return promise;

  }

  //#region private flows
  on_create_new_lot(event = null) {
    return this.on_create_new_lotInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_new_lotInternal(
    $selector: FootPrintManager_lots_dd_multiComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Get target lot and vendorlot lookup code
  let lotLookupCode = $utils.isDefined($selector.fullTextSearch) ? $selector.fullTextSearch : $selector.inParams.lotLookupCode;
  
  let vendorlot = $utils.isDefined($selector.inParams.vendorLotId) ?
      (await $datasources.Lots.ds_find_vendorlots.get({ id: $selector.inParams.vendorLotId })).result[0] : undefined;
  
  let vendorLotLookupCode = vendorlot?.LookupCode ??
      $utils.isDefinedTrimmed($selector.fullTextSearch) ? $selector.fullTextSearch : 
      $selector.inParams.vendorLotLookupCode ?? lotLookupCode;
  
  let lotId = (await $shell.FootPrintManager.openlot_creation_formDialog({ 
      materialId: $selector.inParams.materialId, 
      lotLookupCode: lotLookupCode, 
      vendorLotLookupCode: vendorLotLookupCode,
      vendorLotId: $selector.inParams.vendorLotId
  })).lotId;
  
  if ($utils.isDefined(lotId)) {
      $selector.value = lotId;
  }
  else {
      $selector.fullTextSearch = $selector.fullTextSearch;
  }
  }
  //#endregion private flows
}
