<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"


>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="create_material" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_create_material_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="copy_to_subscriber" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_copy_to_subscriber($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator1" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="on_delete" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.lookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.lookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.description.displayControl.styles.style"
          [ngClass]="row.cells.description.displayControl.styles.classes"
          [matTooltip]="row.cells.description.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['description_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.description.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.description.editControl.placeholder}}"
            [ngStyle]="row.cells.description.editControl.styles.style"
            [ngClass]="row.cells.description.editControl.styles.classes"
            [matTooltip]="row.cells.description.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packaging_short_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.packaging_short_name.displayControl.styles.style"
          [ngClass]="row.cells.packaging_short_name.displayControl.styles.classes"
          [matTooltip]="row.cells.packaging_short_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.packaging_short_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material_status_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.material_status_name.displayControl.styles.style"
          [ngClass]="row.cells.material_status_name.displayControl.styles.classes"
          [matTooltip]="row.cells.material_status_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.material_status_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-material_statuses_dd_single 
        data-cy="selector"
        [type]="row.cells.material_status_name.editControl.type"
        [formControl]="row.formGroup.controls['material_status_name_edit']"
        (displayTextChange)="row.cells.material_status_name.editControl.displayText=$event"
        [placeholder]="row.cells.material_status_name.editControl.placeholder"
        [styles]="row.cells.material_status_name.editControl.styles"
        [tooltip]="row.cells.material_status_name.editControl.tooltip"
    >
    </Materials-material_statuses_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material_group_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.material_group_name.displayControl.styles.style"
          [ngClass]="row.cells.material_group_name.displayControl.styles.classes"
          [matTooltip]="row.cells.material_group_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.material_group_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-material_groups_dd_single 
        data-cy="selector"
        [type]="row.cells.material_group_name.editControl.type"
        [formControl]="row.formGroup.controls['material_group_name_edit']"
        (displayTextChange)="row.cells.material_group_name.editControl.displayText=$event"
        [placeholder]="row.cells.material_group_name.editControl.placeholder"
        [styles]="row.cells.material_group_name.editControl.styles"
        [tooltip]="row.cells.material_group_name.editControl.tooltip"
    >
    </Materials-material_groups_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="allocation_strategy">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.allocation_strategy.displayControl.styles.style"
          [ngClass]="row.cells.allocation_strategy.displayControl.styles.classes"
          [matTooltip]="row.cells.allocation_strategy.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.allocation_strategy.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-allocation_strategies_dd_single 
        data-cy="selector"
        [type]="row.cells.allocation_strategy.editControl.type"
        [formControl]="row.formGroup.controls['allocation_strategy_edit']"
        (displayTextChange)="row.cells.allocation_strategy.editControl.displayText=$event"
        [placeholder]="row.cells.allocation_strategy.editControl.placeholder"
        [styles]="row.cells.allocation_strategy.editControl.styles"
        [tooltip]="row.cells.allocation_strategy.editControl.tooltip"
    >
    </Materials-allocation_strategies_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="allocation_strategy_workflow">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.allocation_strategy_workflow.displayControl.styles.style"
          [ngClass]="row.cells.allocation_strategy_workflow.displayControl.styles.classes"
          [matTooltip]="row.cells.allocation_strategy_workflow.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.allocation_strategy_workflow.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-allocation_strategy_workflows_dd_single 
        data-cy="selector"
        [type]="row.cells.allocation_strategy_workflow.editControl.type"
        [formControl]="row.formGroup.controls['allocation_strategy_workflow_edit']"
        (displayTextChange)="row.cells.allocation_strategy_workflow.editControl.displayText=$event"
        [placeholder]="row.cells.allocation_strategy_workflow.editControl.placeholder"
        [styles]="row.cells.allocation_strategy_workflow.editControl.styles"
        [tooltip]="row.cells.allocation_strategy_workflow.editControl.tooltip"
      [is_active]="row.$fields_allocation_strategy_workflow_selector_inParams_is_active"
    >
    </Materials-allocation_strategy_workflows_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot_controlled">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['lot_controlled_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.lot_controlled.displayControl.styles.style"
                  [ngClass]="row.cells.lot_controlled.displayControl.styles.classes"
                  [matTooltip]="row.cells.lot_controlled.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.lot_controlled.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="serial_controlled">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['serial_controlled_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.serial_controlled.displayControl.styles.style"
                  [ngClass]="row.cells.serial_controlled.displayControl.styles.classes"
                  [matTooltip]="row.cells.serial_controlled.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.serial_controlled.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['serial_controlled_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.serial_controlled.editControl.styles.style"
                  [ngClass]="row.cells.serial_controlled.editControl.styles.classes"
                  [matTooltip]="row.cells.serial_controlled.editControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.serial_controlled.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="is_fixed_weight">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['is_fixed_weight_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.is_fixed_weight.displayControl.styles.style"
                  [ngClass]="row.cells.is_fixed_weight.displayControl.styles.classes"
                  [matTooltip]="row.cells.is_fixed_weight.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.is_fixed_weight.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['is_fixed_weight_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.is_fixed_weight.editControl.styles.style"
                  [ngClass]="row.cells.is_fixed_weight.editControl.styles.classes"
                  [matTooltip]="row.cells.is_fixed_weight.editControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.is_fixed_weight.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="is_fixed_volume">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['is_fixed_volume_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.is_fixed_volume.displayControl.styles.style"
                  [ngClass]="row.cells.is_fixed_volume.displayControl.styles.classes"
                  [matTooltip]="row.cells.is_fixed_volume.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.is_fixed_volume.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['is_fixed_volume_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.is_fixed_volume.editControl.styles.style"
                  [ngClass]="row.cells.is_fixed_volume.editControl.styles.classes"
                  [matTooltip]="row.cells.is_fixed_volume.editControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.is_fixed_volume.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="is_fixed_dimension">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['is_fixed_dimension_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.is_fixed_dimension.displayControl.styles.style"
                  [ngClass]="row.cells.is_fixed_dimension.displayControl.styles.classes"
                  [matTooltip]="row.cells.is_fixed_dimension.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.is_fixed_dimension.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['is_fixed_dimension_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.is_fixed_dimension.editControl.styles.style"
                  [ngClass]="row.cells.is_fixed_dimension.editControl.styles.classes"
                  [matTooltip]="row.cells.is_fixed_dimension.editControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.is_fixed_dimension.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="length">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.length.displayControl.styles.style"
          [ngClass]="row.cells.length.displayControl.styles.classes"
          [matTooltip]="row.cells.length.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.length.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['length_edit']"
            matInput
            numberBox
            [format]="row.cells.length.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.length.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.length.editControl.placeholder}}"
            [ngStyle]="row.cells.length.editControl.styles.style"
            [ngClass]="row.cells.length.editControl.styles.classes"
            [matTooltip]="row.cells.length.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="width">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.width.displayControl.styles.style"
          [ngClass]="row.cells.width.displayControl.styles.classes"
          [matTooltip]="row.cells.width.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.width.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['width_edit']"
            matInput
            numberBox
            [format]="row.cells.width.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.width.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.width.editControl.placeholder}}"
            [ngStyle]="row.cells.width.editControl.styles.style"
            [ngClass]="row.cells.width.editControl.styles.classes"
            [matTooltip]="row.cells.width.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="height">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.height.displayControl.styles.style"
          [ngClass]="row.cells.height.displayControl.styles.classes"
          [matTooltip]="row.cells.height.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.height.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['height_edit']"
            matInput
            numberBox
            [format]="row.cells.height.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.height.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.height.editControl.placeholder}}"
            [ngStyle]="row.cells.height.editControl.styles.style"
            [ngClass]="row.cells.height.editControl.styles.classes"
            [matTooltip]="row.cells.height.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="weight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.weight.displayControl.styles.style"
          [ngClass]="row.cells.weight.displayControl.styles.classes"
          [matTooltip]="row.cells.weight.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.weight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['weight_edit']"
            matInput
            numberBox
            [format]="row.cells.weight.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.weight.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.weight.editControl.placeholder}}"
            [ngStyle]="row.cells.weight.editControl.styles.style"
            [ngClass]="row.cells.weight.editControl.styles.classes"
            [matTooltip]="row.cells.weight.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="shipping_weight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.shipping_weight.displayControl.styles.style"
          [ngClass]="row.cells.shipping_weight.displayControl.styles.classes"
          [matTooltip]="row.cells.shipping_weight.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.shipping_weight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['shipping_weight_edit']"
            matInput
            numberBox
            [format]="row.cells.shipping_weight.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.shipping_weight.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.shipping_weight.editControl.placeholder}}"
            [ngStyle]="row.cells.shipping_weight.editControl.styles.style"
            [ngClass]="row.cells.shipping_weight.editControl.styles.classes"
            [matTooltip]="row.cells.shipping_weight.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="volume">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.volume.displayControl.styles.style"
          [ngClass]="row.cells.volume.displayControl.styles.classes"
          [matTooltip]="row.cells.volume.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.volume.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['volume_edit']"
            matInput
            numberBox
            [format]="row.cells.volume.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.volume.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.volume.editControl.placeholder}}"
            [ngStyle]="row.cells.volume.editControl.styles.style"
            [ngClass]="row.cells.volume.editControl.styles.classes"
            [matTooltip]="row.cells.volume.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="shipping_volume">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.shipping_volume.displayControl.styles.style"
          [ngClass]="row.cells.shipping_volume.displayControl.styles.classes"
          [matTooltip]="row.cells.shipping_volume.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.shipping_volume.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['shipping_volume_edit']"
            matInput
            numberBox
            [format]="row.cells.shipping_volume.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.shipping_volume.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.shipping_volume.editControl.placeholder}}"
            [ngStyle]="row.cells.shipping_volume.editControl.styles.style"
            [ngClass]="row.cells.shipping_volume.editControl.styles.classes"
            [matTooltip]="row.cells.shipping_volume.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="pallet_high">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.pallet_high.displayControl.styles.style"
          [ngClass]="row.cells.pallet_high.displayControl.styles.classes"
          [matTooltip]="row.cells.pallet_high.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.pallet_high.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['pallet_high_edit']"
            matInput
            numberBox
            [format]="row.cells.pallet_high.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.pallet_high.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.pallet_high.editControl.placeholder}}"
            [ngStyle]="row.cells.pallet_high.editControl.styles.style"
            [ngClass]="row.cells.pallet_high.editControl.styles.classes"
            [matTooltip]="row.cells.pallet_high.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="pallet_tie">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.pallet_tie.displayControl.styles.style"
          [ngClass]="row.cells.pallet_tie.displayControl.styles.classes"
          [matTooltip]="row.cells.pallet_tie.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.pallet_tie.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['pallet_tie_edit']"
            matInput
            numberBox
            [format]="row.cells.pallet_tie.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.pallet_tie.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.pallet_tie.editControl.placeholder}}"
            [ngStyle]="row.cells.pallet_tie.editControl.styles.style"
            [ngClass]="row.cells.pallet_tie.editControl.styles.classes"
            [matTooltip]="row.cells.pallet_tie.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="upc_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.upc_code.displayControl.styles.style"
          [ngClass]="row.cells.upc_code.displayControl.styles.classes"
          [matTooltip]="row.cells.upc_code.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.upc_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['upc_code_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.upc_code.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.upc_code.editControl.placeholder}}"
            [ngStyle]="row.cells.upc_code.editControl.styles.style"
            [ngClass]="row.cells.upc_code.editControl.styles.classes"
            [matTooltip]="row.cells.upc_code.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="shelf_life_span">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.shelf_life_span.displayControl.styles.style"
          [ngClass]="row.cells.shelf_life_span.displayControl.styles.classes"
          [matTooltip]="row.cells.shelf_life_span.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.shelf_life_span.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['shelf_life_span_edit']"
            matInput
            numberBox
            [format]="row.cells.shelf_life_span.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.shelf_life_span.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.shelf_life_span.editControl.placeholder}}"
            [ngStyle]="row.cells.shelf_life_span.editControl.styles.style"
            [ngClass]="row.cells.shelf_life_span.editControl.styles.classes"
            [matTooltip]="row.cells.shelf_life_span.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ready_to_ship">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['ready_to_ship_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.ready_to_ship.displayControl.styles.style"
                  [ngClass]="row.cells.ready_to_ship.displayControl.styles.classes"
                  [matTooltip]="row.cells.ready_to_ship.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.ready_to_ship.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['ready_to_ship_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.ready_to_ship.editControl.styles.style"
                  [ngClass]="row.cells.ready_to_ship.editControl.styles.classes"
                  [matTooltip]="row.cells.ready_to_ship.editControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.ready_to_ship.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
