import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Invoices_ds_freight_billing_detailsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { date_from?: string, date_to?: string, full_text_search?: string, $top?: number, $skip?: number }): Promise<{ result?: { header_id?: string, freight_header_lookupcode?: string, charge_type?: string, charge_amount?: number, pallet_range_from?: number, pallet_range_to?: number, created_date?: string, date_number?: number, order?: number }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/Invoices/datasources/ds_freight_billing_details/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { date_from?: string, date_to?: string, full_text_search?: string, $keys: string[] }): Promise<{ result?: { header_id?: string, freight_header_lookupcode?: string, charge_type?: string, charge_amount?: number, pallet_range_from?: number, pallet_range_to?: number, created_date?: string, date_number?: number, order?: number }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Invoices/datasources/ds_freight_billing_details/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      date_from?: string, date_to?: string, full_text_search?: string    }) {
      return false;
    }
}
