import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_ds_get_invoice_header_by_invoiceIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { invoiceId: number }): 
  Promise<{ result: { Id?: number, DueDate?: string, InvoiceDate?: string, LookupCode?: string, Notes?: string, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string, Enterprise?: { Name?: string } } } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.invoiceId)) {
      missingRequiredInParams.push('\'invoiceId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/ds_get_invoice_header_by_invoiceId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      invoiceId: number    }) {
        if(isNil(inParams.invoiceId)) {
          return true; 
        }
      return false;
    }
}
