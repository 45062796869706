<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="save_and_close" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_save_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-general"
                     *ngIf="!fieldsets.general.hidden && !fieldsets.general.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.general.collapsible }">
                    <div *ngIf="!fieldsets.general.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.general.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.general.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.general.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.general.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.general.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.general.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden && !fields.lookupcode.removed" 
                            class="field-container standard {{fields.lookupcode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.lookupcode.label + (fields.lookupcode.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lookupcode.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"
                                [matTooltip]="fields.lookupcode.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-name" *ngIf="!fields.name.hidden && !fields.name.removed" 
                            class="field-container standard {{fields.name.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.name.styles.style"
                            [ngClass]="fields.name.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.name.label + (fields.name.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.name.label}}<span *ngIf="fields.name.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="name"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.name.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.name.control.placeholder}}"
                                [ngStyle]="fields.name.control.styles.style"
                                [ngClass]="fields.name.control.styles.classes"
                                [matTooltip]="fields.name.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.name.invalid">
                          <ng-container *ngFor="let error of fields.name.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-priority" *ngIf="!fields.priority.hidden && !fields.priority.removed" 
                            class="field-container standard {{fields.priority.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.priority.styles.style"
                            [ngClass]="fields.priority.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.priority.label + (fields.priority.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.priority.label}}<span *ngIf="fields.priority.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="priority"
                                matInput
                                numberBox
                                [format]="fields.priority.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.priority.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.priority.control.placeholder}}"
                                [ngStyle]="fields.priority.control.styles.style"
                                [ngClass]="fields.priority.control.styles.classes"
                                [matTooltip]="fields.priority.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.priority.invalid">
                          <ng-container *ngFor="let error of fields.priority.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-description" *ngIf="!fields.description.hidden && !fields.description.removed" 
                            class="field-container full {{fields.description.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.description.styles.style"
                            [ngClass]="fields.description.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.description.label + (fields.description.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.description.label}}<span *ngIf="fields.description.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" formControlName="description"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.description.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.description.control.placeholder}}"
                                [ngStyle]="fields.description.control.styles.style"
                                [ngClass]="fields.description.control.styles.classes"
                                [matTooltip]="fields.description.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> </textarea>
                        <ng-container *ngIf="fields.description.invalid">
                          <ng-container *ngFor="let error of fields.description.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-assign_equipment_types" *ngIf="!fields.assign_equipment_types.hidden && !fields.assign_equipment_types.removed" 
                            class="field-container full {{fields.assign_equipment_types.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.assign_equipment_types.styles.style"
                            [ngClass]="fields.assign_equipment_types.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.assign_equipment_types.label + (fields.assign_equipment_types.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.assign_equipment_types.label}}<span *ngIf="fields.assign_equipment_types.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="assign_equipment_types"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.assign_equipment_types.control.styles.style"
                                      [ngClass]="fields.assign_equipment_types.control.styles.classes"
                                      [matTooltip]="fields.assign_equipment_types.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.assign_equipment_types.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.assign_equipment_types.invalid">
                          <ng-container *ngFor="let error of fields.assign_equipment_types.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-equipment_types" *ngIf="!fields.equipment_types.hidden && !fields.equipment_types.removed" 
                            class="field-container full {{fields.equipment_types.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.equipment_types.styles.style"
                            [ngClass]="fields.equipment_types.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.equipment_types.label + (fields.equipment_types.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.equipment_types.label}}<span *ngIf="fields.equipment_types.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <LaborManagement-equipment_types_dd_multi 
                            data-cy="selector-multi"
                            [type]="fields.equipment_types.control.type"
                            formControlName="equipment_types"
                            (displayTextChange)="fields.equipment_types.control.displayText=$event"
                            [placeholder]="fields.equipment_types.control.placeholder"
                            [styles]="fields.equipment_types.control.styles"
                            [tooltip]="fields.equipment_types.control.tooltip"
                        >
                        </LaborManagement-equipment_types_dd_multi>
                        <ng-container *ngIf="fields.equipment_types.invalid">
                          <ng-container *ngFor="let error of fields.equipment_types.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.operation_codes.hasTabToShow" class="tab" data-cy="tab-operation_codes">
              <h2 [className]="tabs.operation_codes.active? 'active': ''" (click)="tabs.operation_codes.activate()">{{tabs.operation_codes.title}}</h2>
            </div>
            <div *ngIf="tabs.users.hasTabToShow" class="tab" data-cy="tab-users">
              <h2 [className]="tabs.users.active? 'active': ''" (click)="tabs.users.activate()">{{tabs.users.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-work_class_operation_codes_grid *ngIf="tabs.operation_codes.active"
              #$tabs_operation_codes
              [workClassId]="$tabs_operation_codes_work_class_operation_codes_grid_inParams_workClassId"
              [operationCodes]="$tabs_operation_codes_work_class_operation_codes_grid_inParams_operationCodes"
              (operationCodesInitialized)="on_operation_codes_initialized($event)"
              (outParamsChange)="on_operation_codes_changed($event)"
              ($refreshEvent)="refresh(false, false, '$tabs_operation_codes')">
              </FootPrintManager-work_class_operation_codes_grid>
              <FootPrintManager-work_class_users_grid *ngIf="tabs.users.active"
              #$tabs_users
              [users]="$tabs_users_work_class_users_grid_inParams_users"
              (usersInitialized)="on_users_initialized($event)"
              (outParamsChange)="on_users_changed($event)"
              ($refreshEvent)="refresh(false, false, '$tabs_users')">
              </FootPrintManager-work_class_users_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>